export const AUDIENCES_SET = "AUDIENCES_SET";

const state = {
  audiences: {}
};

const getters = {
  audiences: state => state.audiences
};

const actions = {
  setAudiences: ({ commit }, audiences) => {
    commit(AUDIENCES_SET, {
      audiences: audiences
    });
  }
};

const mutations = {
  AUDIENCES_SET: (state, payload) => {
    state.audiences = Object.assign({}, state.audiences, payload.audiences);
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
