<template>
  <div class="relative">
    <div v-if="!registration">
      <spinner></spinner>
    </div>
    <div v-else>
      <div class="bg-blue-panel pb-12 pt-10 md:pl-5">
        <div v-if="saved" class="container mx-auto">
          <h1 class="mb-2">{{ form.title }}</h1>
          <div class="bg-white p-10 rounded">
            <p>Post-survey submitted, you can now close this window</p>
          </div>
        </div>
        <div v-else class="container mx-auto">
          <div class="bg-white p-8 md:p-10 rounded">
            <div class="mb-10" v-for="input in inputs" :key="input.id">
              <component
                :is="`${input.type}-input`"
                :input="input"
                :name="getInputName(input)"
                v-model="responses[input.id]"
                v-validate="input.validation"
              >
              </component>
              <div
                v-show="errors.has(getInputName(input))"
                class="text-red text-xs italic mt-2"
              >
                Field is required
              </div>
            </div>
            <button
              @click.stop.prevent="save"
              class="btn btn-wide btn-blue mt-4"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="blue-bar"></div>
  </div>
</template>

<script>
import CheckboxInput from "../forms/inputs/CheckboxInput.vue";
import RadioInput from "../forms/inputs/RadioInput.vue";
import SectionInput from "../forms/inputs/SectionInput.vue";
import Spinner from "../Spinner.vue";
import TextInput from "../forms/inputs/TextInput.vue";
import TextareaInput from "../forms/inputs/TextareaInput.vue";
import LikertInput from "../forms/inputs/LikertInput.vue";

import { mapGetters } from "vuex";

export default {
  name: "Evaluation",
  props: ["registrationSurvey"],
  components: {
    CheckboxInput,
    RadioInput,
    SectionInput,
    Spinner,
    TextareaInput,
    TextInput,
    LikertInput
  },
  data: () => ({
    registration: null,
    responses: {},
    saved: false
  }),
  computed: {
    ...mapGetters({
      isLoggedIn: "isLoggedIn"
    }),
    user() {
      return this.registration.user;
    },
    workshop() {
      return this.registration.workshop;
    },
    inputs() {
      let schema = this.form.schema;
      return schema ? schema.inputs : [];
    },
    form() {
      return this.workshop.evaluation;
    }
  },
  watch: {
    $route: "load"
  },
  methods: {
    getInputName(input) {
      if (input.type == "section") {
        return input.header;
      }

      return input.attributes.name;
    },
    load() {
      window.axios
        .get(`/public/api/evaluation/${this.registrationSurvey.uuid}`)
        .then(response => {
          this.registration = response.data;
        })
        .catch(error => {
          console.log(error);
        });
    },
    save() {
      this.$validator.validateAll().then(result => {
        if (!result) {
          this.$toasted.show(
            "There are still some required fields to work on."
          );
          return;
        }

        this.responses.form_id = this.workshop.evaluation.id;
        this.responses.user_id = this.user.id;
        this.responses.workshop_id = this.workshop.id;

        window.axios
          .post("/public/api/evaluation", this.responses)
          .then(() => {
            this.saved = true;
            this.$toasted.show(
              "Your save is successful and all required fields are complete!"
            );
          })
          .catch(error => {
            console.log(error);
          });
      });
    }
  },
  created() {
    this.load();
  }
};
</script>
