<template>
  <!-- GOALS -->
  <div v-if="page === 'goals'">
    <div class="border-goals" v-if="input.type === 'textarea'">
      <label class="text-h3 font-bold"
        >{{ input.attributes.title }}
        <span v-if="required" class="text-red">*</span></label
      >
      <p class="leading-loose text-h4 mb-2">{{ input.description }}</p>
      <textarea
        :name="input.attributes.name"
        :type="input.type"
        :disabled="disabled"
        v-model="value"
        class="taWidth"
      >
      </textarea>
    </div>
  </div>

  <!-- RUBRIC -->
  <!-- start -->
  <div v-else-if="page === 'rubric'">
    <div class="border-rubric mb-2" v-if="input.type === 'textarea'">
      <label class="text-h3 font-bold ml-10">{{
        input.attributes.title
      }}</label>
      <textarea
        :name="input.attributes.name"
        :type="input.type"
        :disabled="disabled"
        v-model="value"
        class="taWidth"
      >
      </textarea>
    </div>
  </div>

  <!-- end -->
  <div v-else-if="page === 'rubric-end'">
    <div class="border-rubric-end mb-2" v-if="input.type === 'textarea'">
      <label class="text-h3 font-bold ml-10">{{
        input.attributes.title
      }}</label>
      <textarea
        :name="input.attributes.name"
        :type="input.type"
        :disabled="disabled"
        v-model="value"
        class="taWidth"
      >
      </textarea>
    </div>
  </div>

  <!-- else/ EVALUATION -->
  <div v-else>
    <div class="pb-12 pt-4" v-if="input.type === 'textarea'">
      <label class="text-h3 text-blue-primary font-bold"
        >{{ input.attributes.title }}
        <span v-if="required" class="text-red">*</span></label
      >
      <p class="leading-loose text-h4 mb-2">{{ input.description }}</p>
      <textarea
        :name="input.attributes.name"
        :type="input.type"
        :disabled="disabled"
        v-model="value"
        class="taWidth"
      >
      </textarea>
    </div>
  </div>
</template>

<style scoped>
.border-goals {
  border: 1px solid grey;
  border-radius: 8px 8px 0px 0px;
  border-style: solid solid none solid;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 20px;
  padding-top: 30px;
}

.border-rubric-end {
  border: 1px solid grey;
  border-radius: 8px;
  border-style: solid;
  padding-top: 5vh;
  padding-bottom: 5vh;
  padding-right: 10px;
  padding-left: 10px;
}

.border-rubric {
  border: 1px solid grey;
  border-radius: 0px 0px 8px 8px;
  border-style: none solid solid solid;
  padding-bottom: 5vh;
  padding-right: 10px;
  padding-left: 10px;
}

.taWidth {
  width: 90%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>

<script>
export default {
  name: "TextareaInput",
  props: {
    answer: String,
    page: String,
    disabled: Boolean,
    input: Object
  },
  data() {
    return {
      value: this.answer ? this.answer : ""
    };
  },
  computed: {
    required() {
      return (
        this.input.validation && this.input.validation.includes("required")
      );
    }
  },
  watch: {
    value() {
      this.$emit("input", this.value);
    },
    answer() {
      this.value = this.answer;
    }
  },
  mounted() {
    this.value = this.answer;
  }
};
</script>
