<template>
  <div class="relative">
    <div class="container m-auto pb-24">
      <view-participants-modal
        v-if="workshopParticipantsModalOpen == true"
        v-model="workshopParticipantsModalOpen"
        v-bind:workshop="this.workshop"
      >
      </view-participants-modal>

      <workshop-materials-modal
        v-if="workshopMaterialsModalOpen == true"
        v-model="workshopMaterialsModalOpen"
        v-bind:workshop="this.workshop"
      >
      </workshop-materials-modal>

      <team-materials-modal
        v-if="teamMaterialsModalOpen == true"
        v-model="teamMaterialsModalOpen"
        :workshop="this.workshop"
        :participant="this.selectedTeamForMaterialEdit"
      >
      </team-materials-modal>

      <spinner v-if="!workshop && !user"></spinner>
      <div v-else-if="!allowedAccess">
        <header class="flex flex-wrap border-b border-gray mb-10 pb-10">
          <div class="w-full md:flex-1 md:pr-20">
            <div class="w-1/3">
              <router-link v-if="workshop" :to="{ name: 'workshops-list' }">
                <p class="text-blue-primary font-bold container m-auto">
                  &lt; Back to {{ workshop.title }}
                </p>
              </router-link>
            </div>
          </div>
        </header>
        <div>
          <h2 class="text-center">No access to this data</h2>
        </div>
      </div>
      <div v-else>
        <div class="flex flex-wrap border-gray mb-5">
          <div class="w-full md:flex-1 md:pr-20">
            <breadcrumbs
              :breadcrumbs="crumbs"
              class="text-blue-primary underlined"
            ></breadcrumbs>
            <div>
              <h1 class="mb-3">{{ workshop.title }}</h1>
              <h3 class="mb-3">
                {{ formatDate(workshop.start_time) }} -
                {{ formatDate(workshop.end_time) }} {{ workshop.timezone }}
              </h3>
              <div class="flex justify-start">
                <div
                  class="text-blue-primary font-bold text-sm bg-grey-light mb-5 flex justify-center items-center rounded w-32 h-5 leading-loose"
                >
                  <span>
                    {{ workshop.virtual ? "Virtual" : "In-person" }} meeting
                  </span>
                </div>

                <div
                  class="ml-2 font-bold text-sm bg-grey-light mb-5 flex justify-center items-center rounded h-5 leading-loose validated-workshop"
                  :class="
                    workshop.validated === 'rejected'
                      ? 'bg-red-light text-white'
                      : 'text-blue-primary'
                  "
                >
                  <span>{{ workshop.validated }} Workshop</span>
                </div>
              </div>
              <div class="mb-3" v-html="workshop.description"></div>
              <p class="leading-loose text-sm mt-3">
                Refresh the page to view form completion progress during an
                active workshop
              </p>
            </div>
          </div>
          <div class="w-full md:w-64 md:pt-12 flex md:justify-end">
            <div class="flex flex-col items-top">
              <button class="mb-5">
                <router-link
                  v-if="workshop.id"
                  class="btn btn-blue w-full"
                  :to="{ name: 'workshop-edit', params: { id: workshop.id } }"
                  >Edit Workshop</router-link
                >
              </button>
              <button
                @click="showLEAInviteModal"
                class="btn btn-blue w-full mb-5"
              >
                Invite LEAs
              </button>
              <button
                v-if="isReviewer"
                @click="workshopApproval(workshop.validated)"
                class="btn btn-blue w-full"
              >
                <span v-if="workshop.validated === 'approved'">
                  Reject
                </span>
                <span v-else>Approve</span>
              </button>
            </div>

            <reject-modal
              v-show="confirmRejectModal"
              @input="rejectionText($event)"
              @cancel="confirmRejectModal = false"
              @confirm="rejectWorkshop()"
            >
            </reject-modal>

            <lea-invite-modal
              v-if="inviteLEAModalOpen == true"
              v-model="inviteLEAModalOpen"
              v-bind:workshop="this.workshop"
              @reload="reloadWorkshops"
            >
            </lea-invite-modal>
          </div>
        </div>
        <div>
          <div class="border-b mt-10 mb-16 pb-8">
            <div class="flex float-right ">
              <img
                class="w-4 mr-1"
                src="@/assets/img/halp-tri.png"
                alt="blue triangle with white exclamation point indside it"
              />
              <a
                href="https://forms.gle/iPxAJf66mxhCwixh9"
                class="text-sm"
                target="_blank"
                >Having workshop trouble? <b> Contact SCRIPT</b></a
              >
            </div>
          </div>
          <div class="flex flex-wrap items-top mb-10">
            <div class="w-full md:w-1/4">
              <h2 class="text-blue-primary text-24 mb-3">
                Registered LEA Teams
              </h2>
            </div>
            <div class="w-full md:w-3/4 flex md:justify-end">
              <button
                @click="showWorkshopParticipantsModal"
                class="btn btn-blue flex flex-col items-top mr-5"
              >
                View Participants
              </button>
              <button class="flex flex-col items-top mr-5">
                <a class="btn btn-blue" @click.prevent="downloadCsv"
                  >Download Response Data</a
                >
              </button>
            </div>
          </div>

          <!-- col 1-3 -->
          <div class="flex flex-wrap">
            <div class="w-full md:w-4/5 md:pr-5">
              <div class="flex flex-wrap -mx-5">
                <div
                  v-for="participant in workshop.participants"
                  :key="participant.id"
                  class="mb-10 w-full md:w-1/3 px-5"
                >
                  <div
                    class="flex flex-col bg-blue-panel text-blue-primary rounded-lg relative h-full"
                  >
                    <team-card
                      :participant="participant"
                      :rubrics="workshop.rubrics"
                      :evaluation="workshop.evaluation"
                      :answers="participantAnswers(participant)"
                      @deregister="deregister($event)"
                    ></team-card>

                    <!-- team materials -->
                    <div class=" ml-5 mr-5 mb-5">
                      <div class="bg-red-bright rounded-lg p-3">
                        <h3 class="text-white mb-5">
                          Team Materials
                          <span
                            v-if="countTeamMaterials(participant.member.id) > 0"
                            >({{
                              countTeamMaterials(participant.member.id)
                            }})</span
                          >
                        </h3>
                        <button
                          class="btn btn-sm btn-blue-light text-center w-full"
                          v-on:click="
                            setTeamForMaterialEdit(participant.member.id)
                          "
                        >
                          <span
                            v-if="countTeamMaterials(participant.member.id) > 0"
                          >
                            Edit
                          </span>
                          <span v-else>Add</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- col 4 -->
            <div class="flex flex-col w-full md:w-1/5">
              <!-- workshop materials -->
              <div
                class="bg-red-bright text-white mb-5 rounded-lg pr-5 pl-5 pt-5"
              >
                <h3 class="mb-2">Workshop Materials</h3>
                <div v-if="workshop.materials.length === 0">
                  <div class=" mb-5">
                    <p class="mb-3 text-md leading-tight">
                      Any workshop Materials will appear here
                    </p>
                  </div>
                </div>
                <div v-else>
                  <div
                    class=" mb-5"
                    v-for="material in workshop.materials"
                    :key="material.id"
                  >
                    <div class="bg-white rounded-lg p-3">
                      <a
                        class="text-blue-primary mb-5 break-words"
                        :href="material.form_url"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <h4>
                          <b style="margin-right: 7px;">
                            {{ material.form_name }}
                          </b>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            x="0px"
                            y="0px"
                            width="16px"
                            height="16px"
                            viewBox="0 0 16 16"
                          >
                            <g transform="translate(0, 0)">
                              <polygon
                                data-color="color-2"
                                fill="#3061ac"
                                points="16,0 10,0 12.293,2.293 6.022,8.564 7.436,9.978 13.707,3.707 16,6 "
                              ></polygon>
                              <path
                                fill="#3061ac"
                                d="M13,16H1c-0.552,0-1-0.448-1-1V3c0-0.552,0.448-1,1-1h6v2H2v10h10V9h2v6C14,15.552,13.552,16,13,16z"
                              ></path>
                            </g>
                          </svg>
                        </h4>
                      </a>
                    </div>
                  </div>
                </div>
                <button
                  class="btn btn-sm btn-blue-light text-center w-full mb-5 "
                  @click="showWorkshopMaterialsModal"
                >
                  Edit
                </button>
              </div>

              <!-- Post Survs -->
              <div
                class="bg-red-bright text-white mb-5 rounded-lg pr-5 pl-5 pt-5"
              >
                <h3 class="mb-2">Post-Surveys</h3>
                <div
                  class=" mb-5"
                  v-for="(item, index) in qualtricsResponse()"
                  :key="index"
                >
                  <div class="bg-white rounded-lg p-3">
                    <a
                      class="text-blue-primary mb-5 break-words"
                      :href="item.url"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <h4>
                        <b style="margin-right: 7px;">
                          {{ item.label }}
                        </b>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          x="0px"
                          y="0px"
                          width="16px"
                          height="16px"
                          viewBox="0 0 16 16"
                        >
                          <g transform="translate(0, 0)">
                            <polygon
                              data-color="color-2"
                              fill="#3061ac"
                              points="16,0 10,0 12.293,2.293 6.022,8.564 7.436,9.978 13.707,3.707 16,6 "
                            ></polygon>
                            <path
                              fill="#3061ac"
                              d="M13,16H1c-0.552,0-1-0.448-1-1V3c0-0.552,0.448-1,1-1h6v2H2v10h10V9h2v6C14,15.552,13.552,16,13,16z"
                            ></path>
                          </g>
                        </svg>
                      </h4>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- end flex cols -->
        </div>
      </div>
    </div>
    <div class="blue-bar"></div>
  </div>
</template>

<style scoped>
.validated-workshop {
  text-transform: capitalize;
  padding: 0 10px;
}
</style>

<script>
import { mapGetters } from "vuex";
import Breadcrumbs from "../Breadcrumbs.vue";
import TeamCard from "./TeamCard.vue";
import LEAInviteModal from "../modals/LEAInviteModal.vue";
import ViewParticipantsModal from "../modals/ViewParticipantsModal.vue";
import TeamMaterialsModal from "../modals/TeamMaterialsModal";
import RejectedModal from "../modals/RejectedModal";
import WorkshopMaterialsModal from "../modals/WorkshopMaterialsModal";
import { USA_STATES } from "../../util/states";

import _ from "lodash";
import { formatWorkshopDate } from "../../util/formatDate";

export default {
  name: "FacilitatorWorkshopShow",
  components: {
    "reject-modal": RejectedModal,
    breadcrumbs: Breadcrumbs,
    "team-card": TeamCard,
    "lea-invite-modal": LEAInviteModal,
    "view-participants-modal": ViewParticipantsModal,
    "team-materials-modal": TeamMaterialsModal,
    "workshop-materials-modal": WorkshopMaterialsModal
  },
  data: () => ({
    rejectDescription: "",
    confirmRejectModal: false,
    confirmDeregister: false,
    inviteLEAModalOpen: false,
    workshopParticipantsModalOpen: false,
    teamMaterialsModalOpen: false,
    workshopMaterialsModalOpen: false,
    selectedTeamForMaterialEdit: null,
    qry: {},
    qualtricsURLlist: [],
    postSurvs: [
      {
        label: "Pre Survey",
        url: "https://csforall.qualtrics.com/jfe/form/SV_0PNvlYCwbDAbfpA"
      },
      {
        label: "Landscape Survey",
        url: "https://csforall.qualtrics.com/jfe/form/SV_bsKdt30P0UaIjOu"
      },
      {
        label: "Post-Workshop Survey",
        url: "https://csforall.qualtrics.com/jfe/form/SV_7QEBAcClBxkZBvE"
      },
      {
        label: "3-Month Survey",
        url: "https://csforall.qualtrics.com/jfe/form/SV_4Zq50OIyrEKEAu2"
      },
      {
        label: "6-Month Survey",
        url: "https://csforall.qualtrics.com/jfe/form/SV_8dcgUHw8WzXqtZc"
      },
      {
        label: "12-Month Survey",
        url: "https://csforall.qualtrics.com/jfe/form/SV_8jqvjtc0ENI4kXI"
      }
    ]
  }),
  computed: {
    ...mapGetters({
      user: "user",
      workshop: "facilitatorWorkshop"
    }),
    allowedAccess() {
      if (this.user && this.workshop) {
        return _.map(this.workshop.staff, "id").includes(this.user.id);
      } else {
        return undefined;
      }
    },
    crumbs: function() {
      return [
        {
          link: "/home",
          label: "Dashboard"
        },
        {
          link: "/workshops",
          label: "Workshops"
        }
      ];
    },
    downloadDataUrl() {
      return `/api/workshops/${this.workshop.id}/rubrics/download`;
    },
    id() {
      return this.$route.params.id;
    },
    teamMaterials() {
      return this.workshop.team_materials;
    },
    isReviewer() {
      return this.user.is_reviewer || this.user.is_admin;
    }
  },
  methods: {
    reloadWorkshops(updated) {
      if (updated) {
        this.$emit("reload");
      }
    },
    countTeamMaterials(memberId) {
      return this.teamMaterials.filter(mat => mat.member_id == memberId).length;
    },
    showWorkshopParticipantsModal() {
      this.workshopParticipantsModalOpen = !this.workshopParticipantsModalOpen;
    },
    showLEAInviteModal() {
      this.inviteLEAModalOpen = !this.inviteLEAModalOpen;
    },
    showTeamMaterialsModal() {
      this.teamMaterialsModalOpen = !this.teamMaterialsModalOpen;
    },
    showWorkshopMaterialsModal() {
      this.workshopMaterialsModalOpen = !this.workshopMaterialsModalOpen;
    },
    rejectionText(text) {
      this.rejectDescription = text;
    },
    qualtricsResponse() {
      const checkPersonalInfo = [
        this.qry.first_name,
        this.qry.last_name,
        this.qry.email,
        this.qry.title
      ].some(Boolean);
      const checkUserInfo = [this.qry.state, this.qry.org_name].some(Boolean);

      // ZERO Pre-Survey
      const queryUserPersonalInfoZero = {
        QID9: {
          "1": this.qry.first_name ? this.qry.first_name : "",
          "2": this.qry.last_name ? this.qry.last_name : "",
          "4": this.qry.email ? this.qry.email : ""
        }
      };
      const queryUserInfoZero = {
        QID60: this.qry.state ? this.qry.state : "",
        QID61: this.qry.org_name ? this.qry.org_name : ""
      };
      const paramsZero = {
        ...(checkPersonalInfo ? queryUserPersonalInfoZero : {}),
        ...(checkUserInfo ? queryUserInfoZero : {})
      };

      // ONE Landscape Survey
      const queryUserPersonalInfoOne = {
        QID2: {
          "1": this.qry.first_name ? this.qry.first_name : "",
          "2": this.qry.last_name ? this.qry.last_name : "",
          "3": this.qry.title ? this.qry.title : "",
          "4": this.qry.email ? this.qry.email : ""
        }
      };
      const queryUserInfoOne = {
        QID17: this.qry.state ? this.qry.state : "",
        QID3: this.qry.org_name ? this.qry.org_name : ""
      };
      const paramsOne = {
        ...(checkPersonalInfo ? queryUserPersonalInfoOne : {}),
        ...(checkUserInfo ? queryUserInfoOne : {})
      };

      // TWO Post-Survey
      const queryUserPersonalInfoTwo = {
        QID4: {
          "1": this.qry.first_name ? this.qry.first_name : "",
          "2": this.qry.last_name ? this.qry.last_name : "",
          "4": this.qry.email ? this.qry.email : ""
        }
      };
      const queryUserInfoTwo = {
        QID35: this.qry.state ? this.qry.state : "",
        QID38: this.qry.org_name ? this.qry.org_name : ""
      };
      const paramsTwo = {
        ...(checkPersonalInfo ? queryUserPersonalInfoTwo : {}),
        ...(checkUserInfo ? queryUserInfoTwo : {})
      };

      // THREE 3-Month Survey
      const queryUserPersonalInfothree = {
        QID9: {
          "1": this.qry.first_name ? this.qry.first_name : "",
          "2": this.qry.last_name ? this.qry.last_name : "",
          "5": this.qry.email ? this.qry.email : ""
        }
      };
      const queryUserInfoThree = {
        QID60: this.qry.state ? this.qry.state : "",
        QID77: this.qry.org_name ? this.qry.org_name : ""
      };
      const paramsThree = {
        ...(checkPersonalInfo ? queryUserPersonalInfothree : {}),
        ...(checkUserInfo ? queryUserInfoThree : {})
      };

      // FIVE 6-Month Survey
      const queryUserPersonalInfoFour = {
        QID9: {
          "1": this.qry.first_name ? this.qry.first_name : "",
          "2": this.qry.last_name ? this.qry.last_name : "",
          "5": this.qry.email ? this.qry.email : "",
          "9": this.qry.title ? this.qry.title : ""
        }
      };
      const queryUserInfoFour = {
        QID60: this.qry.state ? this.qry.state : "",
        QID82: this.qry.org_name ? this.qry.org_name : ""
      };
      const paramsFour = {
        ...(checkPersonalInfo ? queryUserPersonalInfoFour : {}),
        ...(checkUserInfo ? queryUserInfoFour : {})
      };

      // FIVE 12-Month Survey
      const queryUserPersonalInfoFive = {
        QID9: {
          "1": this.qry.first_name ? this.qry.first_name : "",
          "2": this.qry.last_name ? this.qry.last_name : "",
          "5": this.qry.email ? this.qry.email : ""
        }
      };
      const queryUserInfoFive = {
        QID60: this.qry.state ? this.qry.state : "",
        QID85: this.qry.org_name ? this.qry.org_name : ""
      };
      const paramsFive = {
        ...(checkPersonalInfo ? queryUserPersonalInfoFive : {}),
        ...(checkUserInfo ? queryUserInfoFive : {})
      };

      const uriRes = "?Q_PopulateResponse=";

      return this.postSurvs.map((item, i) => ({
        ...item,
        id: i,
        ...(i == 0
          ? {
              url:
                item.url +
                uriRes +
                encodeURIComponent(JSON.stringify(paramsZero))
            }
          : {}),
        ...(i == 1
          ? {
              url:
                item.url +
                uriRes +
                encodeURIComponent(JSON.stringify(paramsOne))
            }
          : {}),
        ...(i == 2
          ? {
              url:
                item.url +
                uriRes +
                encodeURIComponent(JSON.stringify(paramsTwo))
            }
          : {}),
        ...(i == 3
          ? {
              url:
                item.url +
                uriRes +
                encodeURIComponent(JSON.stringify(paramsThree))
            }
          : {}),
        ...(i == 4
          ? {
              url:
                item.url +
                uriRes +
                encodeURIComponent(JSON.stringify(paramsFour))
            }
          : {}),
        ...(i == 5
          ? {
              url:
                item.url +
                uriRes +
                encodeURIComponent(JSON.stringify(paramsFive))
            }
          : {})
      }));
    },
    async fetchProfile() {
      const resp = await window.axios.get("/api/me");
      const userData = resp.data.data[0];

      const profileData = {
        ...userData,
        member: userData.member.data
      };

      const checkUsaState = profileData.member?.state
        ? profileData.member.state
        : "";

      const usaStateValue = USA_STATES.filter(
        h => h.value === checkUsaState
      )[0];

      this.qry = {
        email: profileData.email,
        first_name: profileData.first_name,
        last_name: profileData.last_name,
        state: usaStateValue ? usaStateValue.number : "",
        org_name: profileData.member.name,
        title: profileData.title
      };
    },
    deregister(id) {
      this.confirmDeregister = false;
      window.axios
        .delete(`/api/registration/${id}`)
        .then(() => {
          const index = this.workshop.participants.findIndex(
            participant => participant.pivot.id == id
          );
          this.workshop.participants.splice(index, 1);
        })
        .catch(error => {
          console.log(error);
        });
    },
    downloadCsv() {
      window.axios
        .get(`/api/workshops/${this.workshop.id}/rubrics/download`, {
          responseType: "blob"
        })
        .then(response => {
          const blob = new Blob([response.data], { type: "application/zip" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = this.workshop.title + ".zip";
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },
    rejectWorkshop() {
      let data = {
        ...this.workshop,
        validated: "rejected",
        rejection_reason: this.rejectDescription
      };
      window.axios
        .patch(`/api/workshops/${this.workshop.id}`, data)
        .then(() => {
          this.$store.dispatch("getFacilitatorWorkshop", this.workshop.id);
          this.confirmRejectModal = false;
        })
        .catch(error => {
          console.log(error);
        });
    },
    confirmWorkshop() {
      const today = new Date();
      let data = {
        ...this.workshop,
        validated: "approved",
        rejection_reason: null,
        validation_date: today
      };
      window.axios
        .patch(`/api/workshops/${this.workshop.id}`, data)
        .then(() => {
          this.$store.dispatch("getFacilitatorWorkshop", this.workshop.id);
          this.$toasted.show("Workshop Approved");
        })
        .catch(error => {
          console.log(error);
        });
    },
    workshopApproval() {
      let validProperty =
        this.workshop.validated === "approved" ? "rejected" : "approved";

      if (validProperty === "approved") {
        this.confirmWorkshop();
      } else {
        this.confirmRejectModal = true;
      }
    },
    formatDate(date) {
      return formatWorkshopDate(date);
    },
    participantAnswers(participant) {
      return _.filter(this.workshop.answers, {
        member_id: participant.member_id
      });
    },
    setTeamForMaterialEdit(participantId) {
      this.workshop.participants.map(participant => {
        if (participant.member.id === participantId) {
          this.selectedTeamForMaterialEdit = participant;
        }
      });
      this.showTeamMaterialsModal();
    }
  },
  mounted() {
    if (!this.workshop.id || this.workshop.id != this.$route.params.id) {
      //refresh last visited workshop if the current stored one is different
      this.$store.dispatch("getFacilitatorWorkshop", this.$route.params.id);
    }
    this.fetchProfile();
  }
};
</script>
