export const OTHERS_SET = "OTHERS_SET";

const state = {
  others: {}
};

const getters = {
  others: state => state.others
};

const actions = {
  setOthers: ({ commit }, others) => {
    commit(OTHERS_SET, {
      others: others
    });
  }
};

const mutations = {
  OTHERS_SET: (state, payload) => {
    state.others = Object.assign({}, state.others, payload.others);
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
