<template>
  <div class="relative">
    <div class="container mx-auto pb-24">
      <breadcrumbs :breadcrumbs="crumbs"></breadcrumbs>
      <div class="flex flex-wrap md:flex-row-reverse">
        <div class="w-full md:w-64 mb-5 md:py-10 sidebar">
          <div v-if="!isCertificationMode">
            <div v-if="isDraft">
              <div class="bg-blue-panel p-4 rounded">
                <div class="flex items-center mb-2">
                  <h3 class="flex-1 mr-2 text-blue-primary">
                    Finish Alignment
                  </h3>
                  <div class="w-1/5 text-right">
                    <img src="@/assets/img/check-ribbon.svg" alt="" />
                  </div>
                </div>
                <p class="text-md leading-loose mb-5 font-medium">
                  You have an alignment draft that is not yet submitted.
                </p>
                <div class="text-center">
                  <router-link
                    :to="'/home/curriculum/' + curriculum.uuid + '/align'"
                    class="btn btn-sm btn-blue m-auto"
                    >Continue Alignment</router-link
                  >
                </div>
              </div>
            </div>
            <div v-else-if="isSubmitted">
              <div class="bg-blue-panel p-4 rounded">
                <div class="flex items-center mb-2">
                  <h3 class="flex-1 mr-2 text-blue-primary">
                    Verifying Alignment
                  </h3>
                  <div class="w-1/5 text-right">
                    <img src="@/assets/img/check-ribbon.svg" alt="" />
                  </div>
                </div>
                <p class="text-md leading-loose font-medium">
                  We have received your alignment submission and will be
                  reviewing shortly. You will receive an email notification once
                  complete.
                </p>
              </div>
            </div>
            <div v-else-if="isPublished">
              <div class="bg-blue-panel p-4 rounded">
                <div class="flex items-center mb-2">
                  <h3 class="flex-1 mr-2 text-blue-primary">Certified!</h3>
                  <div class="w-1/5 text-right">
                    <img src="@/assets/img/check-ribbon.svg" alt="" />
                  </div>
                </div>
                <p class="text-md leading-loose font-medium">
                  Your curriculum standards alignment has been approved!
                </p>
                <div class="text-center mt-4">
                  <router-link
                    :to="'/home/curriculum/' + curriculum.uuid + '/align'"
                    class="btn btn-sm btn-blue"
                    >Revise Alignment?</router-link
                  >
                </div>
              </div>
            </div>
            <div v-else-if="isRejected">
              <div class="bg-blue-panel p-4 rounded">
                <div class="flex items-center mb-2">
                  <h3 class="flex-1 mr-2 text-blue-primary">
                    Your Curriculum is not Approved
                  </h3>
                  <div class="w-1/5 text-right">
                    <img src="@/assets/img/check-ribbon.svg" alt="" />
                  </div>
                </div>
                <p class="text-md leading-loose font-medium">
                  Please contact the CSforALL member team for guidance on next
                  steps.
                </p>
                <div class="text-center mt-4">
                  <router-link
                    :to="'/home/curriculum/' + curriculum.uuid + '/align'"
                    class="btn btn-sm btn-blue"
                    >Revise Alignment?</router-link
                  >
                </div>
              </div>
            </div>
            <div v-else>
              <div class="bg-blue-panel p-4">
                <div class="flex items-center mb-2">
                  <h3 class="flex-1 mr-2 text-blue-primary">
                    Verify Alignment
                  </h3>
                  <div class="w-1/5 text-right">
                    <img src="@/assets/img/check-ribbon.svg" alt="" />
                  </div>
                </div>
                <p class="text-md leading-loose font-medium">
                  Align your curriculum to CS standards for review by our team.
                  Once approved your curriculum will get our
                  <strong>K12 CS Framework Alignment Badge, rank higher</strong>
                  in our directory search and appear in drop-down menu searches.
                </p>
                <div class="text-center mt-4">
                  <router-link
                    :to="'/home/curriculum/' + curriculum.uuid + '/align'"
                    class="btn btn-sm btn-blue"
                    >Align Curriculum</router-link
                  >
                </div>
                <label
                  class="text-md text-blue-primary font-medium text-center mt-5 cursor-pointer hover:underline"
                  for="tip"
                  >What is this?</label
                >
              </div>
              <input class="hidden" type="checkbox" id="tip" />
              <div
                class="mt-5 bg-blue-panel p-5 tip-panel invisible rounded relative"
              >
                <label
                  class="absolute pin-r pin-t mt-3 mr-3 text-blue-primary cursor-pointer"
                  for="tip"
                  >X</label
                >
                <h3 class="text-blue-primary font-semibold text-md mb-2">
                  Align to Standards
                </h3>
                <p class="text-md leading-loose font-medium">
                  Align your curriculum to CS standards for review by our team.
                  Once approved your curriculum will be get our standards
                  certification badge and rank higher in our directory search.
                </p>
              </div>
            </div>
          </div>
          <div v-else>
            <div v-if="isClaimedByCurrent()">
              <div v-if="curriculum.submitted">
                <div
                  class="btn btn-sm btn-blue mb-2"
                  @click="certifyAlignments()"
                >
                  Certify Alignments
                </div>
                <br />
                <div
                  class="btn btn-sm btn-blue mb-2"
                  @click="rejectAlignments()"
                >
                  Reject Alignments
                </div>
                <br />
              </div>
              <div class="btn btn-sm btn-blue mb-2" @click="unClaim()">
                Unclaim
              </div>
            </div>
            <span v-else-if="isClaimable">
              <div class="btn btn-sm btn-green mb-2" @click="claim()">
                Claim
              </div>
            </span>
            <div
              class="btn btn-sm btn-blue mb-2"
              @click="clearClaim()"
              v-if="canBeClear"
            >
              Clear Claim
            </div>
            <div class="divider pt-6 my-2"></div>
            <div class="pb-2 mb-5">
              <label class="text-h3 font-bold mb-4">Notes to reviewer</label>
              <div
                v-html="
                  curriculum.certification_notes.replace(
                    /(?:\r\n|\r|\n)/g,
                    '<br />'
                  )
                "
              ></div>
            </div>

            <div class="mb-5">
              <label class="text-h3 font-bold mb-4"
                >Curriculum Credentials</label
              >
              <div class="mb-2">
                <strong>Username:</strong>
                <span v-text="curriculum.username"></span>
              </div>
              <div>
                <strong>Password:</strong>
                <span v-text="curriculum.password"></span>
              </div>
            </div>
            <div class="divider mb-2 mt-8"></div>
            <div class="pb-2 mb-5">
              <label class="text-h3 font-bold mb-4">Reviewer Notes</label>
              <textarea
                rows="4"
                class="rounded w-full border-2 mb-2 p-4 leading-loose"
                v-model="notes"
                maxlength="140"
              ></textarea>
              <span class="btn btn-sm btn-blue block" @click="saveNotes()"
                >Save Notes</span
              >
            </div>
          </div>
        </div>
        <div class="w-full md:flex-1 md:pr-16 lg:pr-32">
          <div class="flex flex-wrap justify-between items-center my-10">
            <h1 class="w-full md:w-auto mb-2 pr-2 md:mb-0">
              {{ curriculum.name }}
            </h1>
            <div
              class="w-full md:flex-1 md:text-right"
              v-show="!isCertificationMode || this.user.is_admin"
            >
              <router-link :to="editLink" class="btn btn-sm btn-blue"
                ><img src="@/assets/img/pen.svg" width="13" class="mr-2" />Edit
                Curriculum</router-link
              >
            </div>
          </div>
          <h3 class="mt-3 mb-2">Teaser</h3>
          <div class="divider mb-4 md:mb-8 pb-4 md:pb-8">
            <p v-text="curriculum.teaser"></p>
          </div>

          <h3 class="mt-3 mb-2">Description</h3>
          <div v-html="curriculum.description" class="mb-8 pb-8"></div>

          <div v-if="curriculum.url">
            <h3 class="mt-3 mb-2">URL</h3>
            <div class="mb-4 md:mb-8 pb-4 md:pb-8 font-bold">
              <a
                target="_blank"
                v-bind:href="curriculum.url"
                v-text="curriculum.url"
              ></a>
            </div>
          </div>

          <h3 class="mt-3 mb-2">Cost</h3>
          <div class="mb-4 md:mb-8 pb-4 md:pb-8 text-blue-primary">
            <p class="font-bold" v-if="curriculum.cost_level == 0">Free</p>
            <p class="font-bold" v-else-if="curriculum.cost_level == 1">Paid</p>
            <p class="font-bold" v-else>Some Paid Content</p>
          </div>

          <h3 class="mt-3 mb-2">Grades</h3>
          <div class="mb-4 md:mb-8 pb-4 md:pb-8">
            <div v-if="curriculum.grade_bands">
              <ul
                v-for="(grade, index) in curriculum.grade_bands"
                v-bind:key="index"
              >
                <li>{{ grade }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full" v-if="alignments.length > 0">
        <div class="w-full bg-grey-lighter p-4 md:p-8">
          <div class="flex flex-wrap mt-3 pb-2 bg-grey-lighter mb-5">
            <div class="w-full mb-5 md:mb-0 md:flex-1">
              <h3>K12 CS Framework Components</h3>
            </div>
            <div
              class="w-full md:w-auto"
              v-show="isCertificationMode && !user.is_reviewer"
            >
              <router-link
                :to="'/home/certify/' + curriculum.uuid + '/align'"
                :class="{
                  'disabled text-grey': !curriculum.name,
                  'btn btn-blue btn-sm': true
                }"
                >Edit Alignments</router-link
              >
            </div>
          </div>
          <div class="hidden lg:flex text-white bg-blue-primary rounded-t">
            <div class="w-full lg:w-32 p-4 font-bold">Grade Band</div>
            <div class="w-full lg:w-64 p-4 font-bold">Concept</div>
            <div class="w-full lg:w-48 p-4 font-bold">Sub-Concept</div>
            <div class="w-full lg:flex-1 p-4 font-bold">Statement</div>
          </div>
          <div
            v-for="(alignment, index) in alignments"
            v-bind:key="'curriculum' + alignment.id"
            v-bind:class="{
              'block lg:flex lg:border-b-2 lg:border-grey-lighter leading-loose': true,
              'lg:bg-grey-lightest': !(index % 2)
            }"
            class="bg-white mb-4 lg:mb-0 border-2 border-gray-light"
          >
            <div class="w-full lg:w-32 p-4">
              <div class="lg:hidden text-blue-primary font-bold">
                Grade Band:
              </div>
              {{ alignment.grade_band }}
            </div>
            <div class="w-full lg:w-64 p-4 lg:text-blue-primary">
              <div class="lg:hidden text-blue-primary font-bold">Concept:</div>
              {{ alignment.concept }}
            </div>
            <div class="w-full lg:w-48 p-4">
              <div class="lg:hidden text-blue-primary font-bold">
                Sub-Concept:
              </div>
              {{ alignment.subconcept }}
            </div>
            <div class="w-full lg:flex-1 p-4">
              <div class="lg:hidden text-blue-primary font-bold">
                Statements:
              </div>
              {{ alignment.statement }}
            </div>
          </div>
        </div>
      </div>

      <spinner v-show="isSaving ? true : false"></spinner>
    </div>
    <div class="blue-bar"></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  data() {
    return {
      isSaving: false,
      notes: "",
      curriculum: {
        claimedBy: {},
        certification_notes: ""
      }
    };
  },
  computed: {
    ...mapGetters({
      user: "user",
      isLoggedIn: "isLoggedIn"
    }),
    isDraft: function() {
      return (
        this.curriculum.uuid &&
        !this.curriculum.rejected &&
        this.curriculum.draftAlignments.data.length
      );
    },
    isSubmitted: function() {
      return (
        this.curriculum.uuid &&
        this.curriculum.submitted &&
        this.curriculum.submittedAlignments.data.length
      );
    },
    isPublished: function() {
      return (
        this.curriculum.uuid &&
        !this.curriculum.rejected &&
        this.curriculum.publishedAlignments.data.length
      );
    },
    isRejected: function() {
      return this.curriculum.uuid && this.curriculum.rejected;
    },
    returnTo: function() {
      if (this.isAdminMode) {
        return "/admin/profile/" + this.$route.params.token;
      }
      return "/home";
    },
    editLink: function() {
      if (
        this.user.is_admin &&
        this.user.member.id != this.curriculum.member_id
      ) {
        return "/admin/curriculum/edit/" + this.curriculum.uuid;
      }

      return "/home/curriculum/edit/" + this.curriculum.uuid;
    },
    alignments: function() {
      if (!this.curriculum || !this.curriculum.uuid) {
        return [];
      }

      if (this.curriculum.draftAlignments.data.length) {
        return this.curriculum.draftAlignments.data;
      } else if (this.curriculum.submittedAlignments.data.length) {
        return this.curriculum.submittedAlignments.data;
      } else {
        return this.curriculum.publishedAlignments.data;
      }
    },
    alignmentsCount: function() {
      return this.alignments.length;
    },
    isCertificationMode: function() {
      return this.$route.name == "curricula-cert";
    },
    crumbs: function() {
      if (this.isAdminMode) {
        return [
          {
            link:
              "/admin/profile/" +
              (this.curriculum.member ? this.curriculum.member.data.uuid : ""),
            label: this.curriculum ? this.curriculum.member.data.name : ""
          },
          {
            link: false,
            label: this.curriculum ? this.curriculum.name : "Edit Curriculum"
          }
        ];
      } else {
        return [
          {
            link: "/home",
            label: this.user ? this.user.member.name : ""
          },
          {
            link: false,
            label: this.curriculum ? this.curriculum.name : "Edit Curriculum"
          }
        ];
      }
    },
    hasClaim: function() {
      return this.curriculum.claimedBy.data.uuid.length > 1;
    },
    canBeClear: function() {
      return this.hasClaim && this.curriculum.submitted && this.user.is_admin;
    },
    isClaimable: function() {
      return (
        !this.isClaimedByCurrent() &&
        this.curriculum.submitted &&
        !this.user.is_reviewer
      );
    }
  },
  methods: {
    isClaimedByCurrent() {
      if (
        !this.curriculum.claimedBy.data ||
        !this.curriculum.claimedBy.data.uuid
      ) {
        return false;
      }
      if (this.curriculum.claimedBy.data.uuid == this.user.uuid) {
        return true;
      }
    },
    unClaim() {
      this.isSaving = true;
      const data = {
        claim: 1
      };

      window.axios
        .delete("/api/user/curriculum/" + this.curriculum.uuid + "/claim", data)
        .then(() => {
          this.$toasted.show("Curriculum unclaimed");
          this.$router.push({ name: "curricula-cert-list" });
        });
    },
    clearClaim() {
      this.isSaving = true;

      window.axios
        .delete("/api/user/curriculum/" + this.curriculum.uuid + "/clear-claim")
        .then(() => {
          this.isSaving = false;
          this.$toasted.show("Curriculum cleared");
          this.loadCurriculum();
          this.$router.push({ name: "curricula-cert-list" });
        });
    },
    claim() {
      this.isSaving = true;
      const data = {
        claim: 1
      };

      window.axios
        .put("/api/user/curriculum/" + this.curriculum.uuid + "/claim", data)
        .then(() => {
          this.isSaving = false;
          this.$toasted.show("Curriculum claimed");
          this.loadCurriculum();
        });
    },
    setCurriculum(item) {
      this.curriculum = item;
    },
    loadCurriculum() {
      if (!this.$route.params.token) {
        return;
      }

      if (!this.isCertificationMode) {
        window.axios
          .get("/api/me/curriculum/" + this.$route.params.token)
          .then(resp => {
            this.setCurriculum(resp.data.data[0]);
          });
      } else {
        window.axios
          .get("/api/user/curriculum/" + this.$route.params.token)
          .then(resp => {
            this.setCurriculum(resp.data.data[0]);
            this.loadNotes();
          });
      }
    },
    checkAccess: function() {
      if (!this.user.id) {
        // defer
        return;
      }
      if (!this.user.is_admin && !this.user.can_approve_alignments) {
        this.$router.push("/home");
      }
    },
    certifyAlignments: function() {
      this.isSaving = true;
      const data = {
        certify: 1,
        frameWorkCount: this.alignmentsCount
      };

      window.axios
        .post("/api/user/curriculum/" + this.$route.params.token, data)
        .then(() => {
          this.$toasted.show("Curriculum certified");
          this.$router.push({ name: "curricula-cert-list" });
        });
    },
    rejectAlignments: function() {
      this.isSaving = true;
      const data = {
        reject: 1,
        frameWorkCount: this.alignmentsCount
      };

      window.axios
        .post("/api/user/curriculum/" + this.$route.params.token, data)
        .then(() => {
          this.$toasted.show("Curriculum rejected");
          this.$router.push({ name: "curricula-cert-list" });
        });
    },
    loadNotes: function() {
      window.axios
        .get("/api/user/curriculum/" + this.$route.params.token + "/notes")
        .then(resp => {
          this.notes = resp.data.notes;
        });
    },
    saveNotes: function() {
      this.isSaving = true;
      const data = {
        notes: this.notes
      };

      window.axios
        .put(
          "/api/user/curriculum/" + this.$route.params.token + "/notes",
          data
        )
        .then(() => {
          this.isSaving = false;
          this.$toasted.show("Notes saved");
        });
    }
  },
  created() {
    if (this.isCertificationMode) {
      this.$store.dispatch("setActiveNav", "certify"); // set flag for navbar to know what options to display
    } else {
      this.$store.dispatch("setActiveNav", "profile");
    }
  },
  mounted() {
    if (this.isCertificationMode) {
      this.checkAccess();
    }

    window.axios.get("/api/alignment").then(resp => {
      this.$store.dispatch("setAlignments", resp.data.data);
      this.loadCurriculum();
    });
  }
};
</script>
