<template>
  <!-- <spinner v-if="!paginatedData"></spinner> -->
  <div v-if="isLoading">
    <spinner></spinner>
  </div>
  <div v-else>
    <div class="relative">
      <div class="bg-blue-panel">
        <header class="fixed top-0 w-full z-10 bg-white border-b-2 pt-2 pb-2">
          <div class="flex flex-col md:flex-row">
            <div class="w-full md:w-1/5 ml:w-1/5">
              <router-link
                :to="{
                  name: 'facilitator-workshop-show',
                  params: { id: $route.params.workshop }
                }"
              >
                <p class="text-blue-primary font-bold container m-auto">
                  &lt; Back to {{ workshop.title }}
                </p>
              </router-link>
            </div>
            <div class="w-full md:w-1/5 ml:w-2/5 text-center md:text-center">
              <img
                class="w-40"
                src="@/assets/img/scriptlogo.png"
                alt="script"
              />
            </div>
            <div
              class="w-full md:w-3/5 ml:w-2/5 text-center items-center md:text-right"
            >
              <div class="fixed-pagination">
                <pagination
                  :data="paginatedData"
                  @pagination-change-page="getEvaluations"
                >
                  <span slot="prev-nav">&lt; Previous</span>
                  <span slot="next-nav">Next &gt;</span>
                </pagination>
              </div>
            </div>
          </div>
        </header>

        <div class="h-full bg-blue-panel pb-12 pt-34">
          <div class="container mx-auto">
            <div v-if="!allowedAccess" class="text-center">
              <h2>Not Authorized</h2>
            </div>
            <div v-else-if="!evaluations">
              <h2 class="text-center">No Post-survey's submitted yet.</h2>
            </div>
            <div class="mb-32" v-else>
              <h1 class="mb-8 text-center pt-8">{{ form.title }} Responses</h1>
              <div class="bg-white p-10 rounded">
                <div v-for="input in form.inputs" :key="input.id">
                  <!-- section head -->
                  <div
                    v-if="input.type === 'section'"
                    class="pb-4 center-div"
                  ></div>
                  <div v-else>
                    <!-- first radio -->
                    <div v-if="input.id.includes('role-choice')">
                      <component
                        :is="`${input.type}-input`"
                        :input="input"
                        :name="getInputName(input)"
                        :answer="answers[input.id]"
                        v-model="formData[input.id]"
                        :disabled="true"
                        class="radio-list"
                        :page="`evaluation`"
                      >
                      </component>
                    </div>
                    <!-- center radio -->
                    <div v-else-if="input.id.includes('rating')">
                      <component
                        :is="`${input.type}-input`"
                        :input="input"
                        :name="getInputName(input)"
                        :answer="answers[input.id]"
                        v-model="formData[input.id]"
                        :disabled="true"
                        :page="`evaluation`"
                        :class="setclass(input)"
                      >
                      </component>
                    </div>
                    <!-- left radio -->
                    <div
                      v-else-if="
                        input.id.includes('survey3-clear-steps-choice') ||
                          input.id.includes('survey3-recom-choice') ||
                          input.id.includes('survey3-goalsid-pr&cm-choice') ||
                          input.id.includes('survey3-believe-pr&cm-choice') ||
                          input.id.includes('survey3-goalsid-tc-choice') ||
                          input.id.includes('survey3-believe-tc-choice') ||
                          input.id.includes('survey3-goalsid-mc-choice') ||
                          input.id.includes('survey3-believe-mc-choice') ||
                          input.id.includes('survey3-goalsid-ld-choice') ||
                          input.id.includes('survey3-believe-ld-choice')
                      "
                    >
                      <component
                        :is="`${input.type}-input`"
                        :input="input"
                        :name="getInputName(input)"
                        :answer="answers[input.id]"
                        v-model="formData[input.id]"
                        :disabled="true"
                        :page="`evaluation-left-radio`"
                        :class="setclass(input)"
                      >
                      </component>
                    </div>

                    <!-- all else -->
                    <div v-else>
                      <component
                        :is="`${input.type}-input`"
                        :input="input"
                        :name="getInputName(input)"
                        :answer="answers[input.id]"
                        v-model="formData[input.id]"
                        :disabled="true"
                        :page="`evaluation`"
                        :class="setclass(input)"
                      >
                      </component>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.radio-list {
  margin: auto;
  text-align: left !important;
  display: block !important;
}

.radio-list h3 {
  color: #3061ac;
}

.radio-list label {
  float: none;
  clear: none;
  display: block;
  padding: 0px 1em 0px 8px;
}

.border-bottom {
  border: 1px solid grey;
  border-style: none none solid none;
  padding-bottom: 2vh;
  margin-bottom: 8vh;
}

.border-top {
  border: 1px solid grey;
  border-style: solid none none none;
  /* padding-top: 2vh;
  margin-top: 8vh; */
}
</style>
<script>
import Spinner from "../../components/Spinner";
import Pagination from "laravel-vue-pagination";
import { mapGetters } from "vuex";
import _ from "lodash";

// sections
import RadioInput from "../inputsResults/RadioInput.vue";
import Radio from "../inputsResults/Radio.vue";
import TextInput from "../inputsResults/TextInput.vue";
import TextareaInput from "../inputsResults/TextareaInput.vue";
import LikertInput from "../inputsResults/LikertInput.vue";

export default {
  name: "EvaluationShow",
  components: {
    Spinner,
    Pagination,
    // sections
    RadioInput,
    Radio,
    TextInput,
    TextareaInput,
    LikertInput
  },
  data: () => ({
    paginatedData: null,
    evaluations: [],
    answered: false,
    answers: {},
    formData: {},
    isLoading: true,
    registration: {}
    // workshop: {}
  }),
  computed: {
    ...mapGetters({
      user: "user",
      workshop: "facilitatorWorkshop"
    }),
    inputs() {
      return this.form ? this.form.inputs : [];
    },
    questions() {
      if (this.form) {
        let questionData = this.form.schema;
        return questionData.inputs;
      } else {
        return undefined;
      }
    },
    allowedAccess() {
      return _.map(this.workshop.staff, "id").includes(this.user.id);
    }
  },
  methods: {
    setclass(input) {
      if (input.id.includes("believe")) {
        return "border-bottom mb-4 pb-8";
      }
      if (
        input.attributes.title.includes("Did the workshop help you identify")
      ) {
        return " mt-4 pt-8";
      }
      if (input.id === "survey3-title-qs-shrtxt") {
        return "border-bottom pb-8";
      }
      if (input.id === "survey3-visions-fb-longtxt") {
        return "border-top pt-16";
      }
      if (input.id === "survey3-recom-choice") {
        return "border-bottom pb-8";
      }
      if (input.id === "survey3-testimonial-longtxt") {
        return "border-top mb-4 pb-4";
      }
    },

    getInputName(input) {
      if (input.type == "section") {
        return input.header;
      }
      return input.attributes.name;
    },
    getEvaluations(evaluation = 1) {
      return window.axios
        .get(`/api/facilitator/evaluations/${this.$route.params.workshop}`, {
          params: {
            evaluation: evaluation
          }
        })
        .then(response => {
          if (response.data !== []) {
            console.log(response);
            this.paginatedData = response.data;
            this.evaluations = this.paginatedData.data[0];
            // this.workshop = this.evaluations.workshop;
            this.answers = this.evaluations.answers;
            this.form = this.evaluations.form.schema;

            if (this.answers && Object.keys(this.answers).length) {
              this.formData = Object.assign({}, this.answers);
            }
          }
        })
        .catch(error => {
          console.log("error");
          console.log(error);
        });
    }
  },
  async mounted() {
    await this.getEvaluations();
    if (!this.workshop.id || this.workshop.id != this.$route.params.workshop) {
      //refresh last visited workshop if the current stored one is different
      this.$store.dispatch(
        "getFacilitatorWorkshop",
        this.$route.params.workshop
      );
    }

    this.isLoading = false;
  }
};
</script>
