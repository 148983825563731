export const TYPES_SET = "TYPES_SET";

const state = {
  types: {}
};

const getters = {
  types: state => state.types
};

const actions = {
  setTypes: ({ commit }, types) => {
    commit(TYPES_SET, {
      types: types
    });
  }
};

const mutations = {
  TYPES_SET: (state, payload) => {
    state.types = Object.assign({}, state.types, payload.types);
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
