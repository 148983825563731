<template>
  <div class="relative">
    <header class="fixed top-0 w-full z-10 py-5 bg-white border-b-2">
      <div class="flex container mx-auto px-5 items-end">
        <div class="w-1/2 md:w-1/3">
          <router-link
            :to="{
              name: 'workshop-show',
              params: { id: this.workshopId }
            }"
            class="btn"
            :class="backButtonClass"
            >&lt; Back</router-link
          >
        </div>
        <div class="hidden md:block w-1/3 text-center">
          <img class="w-40" src="@/assets/img/scriptlogo.png" alt="script" />
        </div>
        <div class="w-1/2 md:w-1/3 text-right" :class="{ hidden: !canEdit }">
          <button
            @click.stop.prevent="save"
            class="btn btn-blue"
            :disabled="!canEdit"
          >
            Save
          </button>
        </div>
      </div>
      <div class="flex container mx-auto px-5 mt-3 items-center">
        <div class="w-full text-center ">
          <h5 v-if="!canEdit">View Only</h5>
        </div>
      </div>
      <spinner v-show="isSyncing || isLoading"></spinner>
    </header>
    <div v-if="!isLoading">
      <div class="bg-blue-panel pt-34">
        <div class="container mx-auto">
          <div v-if="!allowedAccess" class="text-center">
            <h2>Not Authorized</h2>
          </div>
          <div v-else>
            <h1 class="mb-8 text-blue-primary">{{ form.title }}</h1>
            <h4 class="mb-8 font-semibold">{{ form.description }}</h4>
            <div class="bg-white p-10 rounded ">
              <div v-for="input in form.inputs" :key="input.id">
                <!-- if goals -->
                <div v-if="form.title === 'Goal Setting'">
                  <div :class="getGoalsClass(input.type)">
                    <component
                      :is="`${input.type}-input`"
                      :input="input"
                      :name="getInputName(input)"
                      :answer="answers[input.id]"
                      v-model="formData[input.id]"
                      v-validate="validation(input)"
                      :disabled="!canEdit"
                    >
                    </component>
                    <div
                      v-show="errors.has(getInputName(input))"
                      class="text-red text-xs italic mt-2"
                    >
                      Field is required
                    </div>
                  </div>
                </div>
                <!-- else for the others -->
                <div v-else>
                  <div v-if="input.type === 'section'" class="pb-2">
                    <component
                      :is="`description-input`"
                      :input="input"
                      :name="getInputName(input)"
                      v-model="formData[input.id]"
                    >
                    </component>
                  </div>
                  <div v-else>
                    <div :class="getFormsClass(input.type)">
                      <component
                        :is="`${input.type}-input`"
                        :input="input"
                        :name="getInputName(input)"
                        :answer="answers[input.id]"
                        v-model="formData[input.id]"
                        v-validate="validation(input)"
                        :disabled="!canEdit"
                      >
                      </component>
                      <div
                        v-show="errors.has(getInputName(input))"
                        class="text-red text-xs italic mt-2"
                      >
                        Field is required
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end if else -->
              </div>
            </div>
          </div>
        </div>
        <div class="blue-bar"></div>
      </div>
    </div>
  </div>
</template>

<script>
import CheckboxInput from "../forms/inputs/CheckboxInput.vue";
import RadioInput from "../forms/inputs/RadioInput.vue";
import SectionInput from "../forms/inputs/SectionInput.vue";
import DescriptionInput from "../forms/inputs/DescriptionInput.vue";
import TextInput from "../forms/inputs/TextInput.vue";
import TextareaInput from "../forms/inputs/TextareaInput.vue";
import { mapGetters } from "vuex";
import { ROLES, FORMMODE } from "../../const";
import _ from "lodash";

export default {
  name: "WorkshopFormCreate",
  components: {
    CheckboxInput,
    RadioInput,
    DescriptionInput,
    SectionInput,
    TextInput,
    TextareaInput
  },
  data: () => ({
    answered: false,
    answers: {},
    form: {},
    formData: {},
    workshopId: null,
    isLoading: true,
    isSyncing: false,
    isAllValid: false
  }),
  computed: {
    ...mapGetters({
      user: "user",
      workshop: "workshop"
    }),
    backButtonClass() {
      if (this.isAllValid) {
        return "btn-green";
      } else {
        return "btn-blue";
      }
    },
    allowedAccess() {
      if (this.user && this.user.member.registrations && this.workshop) {
        return _.find(
          this.user.member.registrations.data,
          registration => registration.workshop_id == this.workshop.id
        );
      } else {
        return undefined;
      }
    },
    inputs() {
      return this.form ? this.form.inputs : [];
    },
    registration() {
      if (this.user && this.user.member.registrations) {
        return _.find(this.user.member.registrations.data, [
          "workshop_id",
          this.workshop.id
        ]);
      } else {
        return undefined;
      }
    },
    isLEAAdmin() {
      return this.user.roles.includes(ROLES.LEA_ADMIN);
    },
    isViewOnlyMode() {
      return this.$route.params.mode === FORMMODE.VIEW;
    },
    canEdit() {
      return this.isLEAAdmin && this.workshop.active && !this.isViewOnlyMode;
    },
    isFormDirty() {
      return Object.keys(this.fields).some(key => this.fields[key].dirty);
    }
  },
  watch: {
    user: function() {
      this.getForm();
      this.getAnswers();
    }
  },
  methods: {
    getGoalsClass(type) {
      if (type === "textarea") return "pb-12";
      if (type === "text" || type === "checkbox") return "pb-6";
      if (type === "section") return "pb-2";
      return "";
    },
    getFormsClass(type) {
      if (type === "textarea") return "pb-20";
      if (type === "radio") return "pb-2";
      return "";
    },
    getInputName(input) {
      if (input.type == "section") {
        return input.header;
      }

      return input.attributes.name;
    },
    getAnswers() {
      return window.axios
        .get(`/api/get-form-answers/${this.$route.params.form}`, {
          params: {
            registration_id: this.registration.id
          }
        })
        .then(response => {
          if (response.data) {
            this.answers = response.data.answers;
            if (response.data.answers && Object.keys(this.answers).length) {
              this.formData = Object.assign({}, this.answers);
            }
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    getForm() {
      return window.axios
        .get(`/api/forms/${this.$route.params.form}`)
        .then(response => {
          this.form = response.data;
        })
        .catch(error => {
          console.log(error);
        });
    },
    isQuestionAnswered(question) {
      return !_.isEmpty(this.formData[question.id]);
    },
    async setAnsweredStatus() {
      await this.$store.dispatch("getWorkshop", this.workshopId);
      this.inputs.forEach(input => {
        input.answered = this.isQuestionAnswered(input);
      });
    },
    async displayCompletionMessage() {
      let message =
        "Your save is successful but there are still some required fields to work on.";
      if (this.isAllValid) {
        message =
          "Your save is successful and all required fields are complete!";
      }

      this.$toasted.show(message);
    },
    validation(input) {
      if (!this.workshop.active) {
        return "";
      }

      return input.validation;
    },

    registerFormData() {
      this.formData.form_id = this.$route.params.form;
      this.formData.registration_id = this.registration.id;
      this.formData.workshop_id = this.workshop.id;
    },
    async save() {
      this.isSyncing = true;

      this.registerFormData();

      try {
        if (this.isFormDirty) {
          // This check should be on whether the form has changed, not if it is dirty.
          // However, the current version of vee-validate (2.2.15) has a bug where text inputs are marked as unchanged
          // if the change is from a string to a non-empty string.
          // see: https://github.com/logaretm/vee-validate/issues/2081
          // Checking if the form is dirty should be good enough and additional checks to work around this bug
          // are probably unnecessary. Updating the library would also resolve this concern.
          await window.axios.post("/api/answers", this.formData);
          await this.setAnsweredStatus();
          await this.$validator.reset();
        }
        this.isSyncing = false;
        this.isAllValid = await this.$validator.validateAll();
        await this.displayCompletionMessage();
      } catch (error) {
        this.isSyncing = false;
        console.log(error);
      }
    }
  },
  async mounted() {
    this.workshopId = this.$route.params.workshop;
    await this.$store.dispatch("getWorkshop", this.workshopId);
    await this.getForm();
    if (this.registration) {
      await this.getAnswers();
    }
    this.isLoading = false;
    await this.$validator.reset();
  }
};
</script>
