export const ALIGNMENTS_SET = "ALIGNMENTS_SET";

const state = {
  alignments: {}
};

const getters = {
  alignments: state => state.alignments
};

const actions = {
  setAlignments: ({ commit }, alignments) => {
    commit(ALIGNMENTS_SET, {
      alignments: alignments
    });
  }
};

const mutations = {
  ALIGNMENTS_SET: (state, payload) => {
    state.alignments = Object.assign({}, state.alignments, payload.alignments);
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
