<template>
  <div class="relative">
    <confirm-modal
      v-show="showConfirmationModel"
      title=""
      body="Your profile updates are saved but you are still missing some fields required for activation."
      actionButton="Finish Later"
      cancelButton="Finish Now"
      actionButtonColor="btn-blue-light"
      cancelButtonColor="btn-blue"
      @cancel="showConfirmationModel = false"
      @confirm="returnHome"
    ></confirm-modal>

    <div v-if="profile" class="container mx-auto pb-24">
      <breadcrumbs :breadcrumbs="crumbs"></breadcrumbs>

      <h1>Edit Member Profile</h1>
      <div class="mt-8 divider">
        <div class="w-full lg:w-3/4 pb-8">
          <label class="text-h3 font-bold mb-4 pl-0">Profile Name</label>
          <p v-if="isAOrgMember" class="leading-loose mb-6 text-h4">
            This is the name of your company or organization.
          </p>
          <input type="text" v-model="name" autocomplete="nope" />
        </div>
      </div>
      <div v-if="isFacilitatorProfile" class="w-full pb-8 mt-8">
        <label class="text-h3 font-bold mb-4 pl-0">Professional Title</label>
        <input
          type="text"
          v-model="title"
          name="title"
          autocomplete="nope"
          v-validate="'required'"
        />
        <div class="my-4">
          <span v-show="errors.has('title')" class="text-xs italic text-red">{{
            errors.first("title")
          }}</span>
        </div>
      </div>
      <div v-if="isFacilitatorProfile" class="w-full pb-8">
        <label class="w-4/5 text-h3 font-bold mb-4 pl-0">
          If your SCRIPT Facilitator Training was sponsored, please write in the
          name of the sponsoring organization.
        </label>
        <input
          type="text"
          v-model="training_sponsor"
          name="training_sponsor"
          autocomplete="nope"
        />
      </div>

      <div class="mt-8 divider" v-show="isAdminMode">
        <div class="w-full lg:w-3/4 pb-8">
          <label class="text-h3 font-bold mb-4 pl-0">Approve Alignments</label>
          <p class="leading-loose mb-6 text-h4">
            ADMIN ONLY: This member can approve curriculum alignments. Check
            this box to turn a user into a reviewer. If a user is an admin, this
            box will be checked by default.
          </p>
          <div class="select-box">
            <label for="can_approve_alignments" class="pl-0">
              <input
                id="can_approve_alignments"
                type="checkbox"
                v-model="can_approve_alignments"
              /><span class="check inline-block mr-2"></span>
              Approve Alignments
            </label>
          </div>
        </div>
      </div>

      <div class="mt-8 divider" v-show="isAdminMode">
        <div class="w-full lg:w-3/4 pb-8">
          <label class="text-h3 font-bold mb-4 pl-0">Make user admin</label>
          <p class="leading-loose mb-6 text-h4">
            ADMIN ONLY: This will make this user an admin.
          </p>
          <div class="select-box">
            <label for="is_admin" class="pl-0">
              <input id="is_admin" type="checkbox" v-model="is_admin" /><span
                class="check inline-block mr-2"
              ></span>
              Make User Admin
            </label>
          </div>
        </div>
      </div>

      <div class="mt-8 divider" v-show="isAdminMode">
        <div class="w-full lg:w-3/4 pb-8">
          <label class="text-h3 font-bold mb-4 pl-0">
            Make user facilitator
          </label>
          <p class="leading-loose mb-6 text-h4">
            ADMIN ONLY: This will make this user a facilitator.
          </p>
          <div class="select-box">
            <label for="is_facilitator" class="pl-0">
              <input
                id="is_facilitator"
                type="checkbox"
                v-model="is_facilitator"
              /><span class="check inline-block mr-2"></span>
              Make User Facilitator
            </label>
          </div>

          <p v-if="is_facilitator" class="leading-loose mt-6 mb-6 text-h4">
            ADMIN ONLY: This will make this user a facilitator as Active.
          </p>

          <select
            class="border-2 bg-white rounded h-12 w-full"
            v-if="is_facilitator"
            v-model="inactive_facilitator"
          >
            <option
              v-for="(item, key) in inactiveFacilitatorOpt.itemList"
              :value="item.inactive_facilitator"
              :key="key"
            >
              {{ item.state }}
            </option>
          </select>
        </div>
      </div>

      <div class="mt-8 divider">
        <div class="w-full lg:w-3/4 pb-8">
          <label class="text-h3 font-bold mb-4 pl-0" v-if="isAOrgMember">
            Logo
          </label>
          <label class="text-h3 font-bold mb-4 pl-0" v-else>
            Profile Photo
          </label>
          <p class="leading-loose mb-6 text-h4">
            Accepted file formats include: JPG, JPEG, GIF and PNG. File size may
            not exceed 500K.
          </p>
          <span v-show="profile.member.logo_url && !isSaving && !memberLogo">
            <img
              :src="profile.member.logo_url"
              v-show="profile.member.logo_url"
              class="h-32 max-h-small mb-4 mr-2 block"
            />
          </span>
          <span v-show="memberLogo">
            Selected File: {{ memberLogo ? memberLogo.logo.name : "None" }}
          </span>
          <div class="mt-8">
            <span
              :class="{
                'btn btn-blue btn-sm cursor-pointer inline-block': true,
                'opacity-50 cursor-not-allowed': isSaving
              }"
              @click="handleSelectFile"
              >{{ profile.member.logo_url ? "Replace" : "Upload" }} File</span
            >
          </div>
          <div class="my-4" v-if="profile.member.logo_url ? memberLogo : true">
            <span
              v-show="profile.member.logo_url === null || memberLogo === false"
              class="text-xs italic text-red"
              >This image is required</span
            >
          </div>
        </div>
      </div>

      <div v-if="isAPublicMember" class="mt-8 divider">
        <div class="w-full lg:w-3/4 pb-8">
          <label class="text-h3 font-bold mb-4 pl-0">Photos</label>
          <p class="leading-loose mb-6 text-h4">
            Accepted file formats include: JPG, JPEG, GIF and PNG. File size may
            not exceed 500K. Up to 3 photos may be uploaded.
          </p>
          <div class="mb-2 flex">
            <div v-for="item in existingImages" v-bind:key="item" class="w-1/3">
              <div class="text-center mx-2">
                <a :href="item"><img :src="item" :alt="item"/></a>
                <button
                  class="bg-red-light text-white py-1 px-2 leading-none rounded-full mr-2"
                  @click="handleRemoveMemberImage(item)"
                >
                  x
                </button>
              </div>
            </div>
            <div
              v-for="item in memberImages"
              v-bind:key="item.name"
              class="w-1/3"
            >
              <div class="text-center mx-2">
                <img
                  alt="member image"
                  :src="
                    'data:image/' +
                      item.name.substr(item.name.lastIndexOf('.') + 1) +
                      ';base64,' +
                      item.contents
                  "
                />
                <button
                  class="bg-red-light text-white py-1 px-2 leading-none rounded-full mr-2"
                  @click="handleRemoveSelectedImage(item.name)"
                >
                  x
                </button>
              </div>
            </div>
          </div>
          <span
            :class="{
              'inline-block mt-4 btn btn-blue btn-sm cursor-pointer': true,
              'opacity-50 cursor-not-allowed': isSaving || selectedImages >= 3
            }"
            @click="handleAddImage"
            >Add Image</span
          >
        </div>
      </div>

      <div class="mt-8 divider">
        <div class="w-full lg:w-3/4 pb-8">
          <label class="text-h3 font-bold mb-4 pl-0">Teaser</label>
          <p v-if="isAPublicMember" class="leading-loose mb-6 text-h4">
            This will show up on your member card in the search directory.
          </p>
          <textarea
            rows="4"
            class="rounded w-full border-2 mb-2 p-4 leading-loose"
            v-model="teaserText"
            maxlength="140"
            name="teaser"
            v-validate="'required'"
          ></textarea>
          {{ teaserText ? teaserText.length : "" }} of 140 characters
          <div class="my-4">
            <span
              v-show="errors.has('teaser')"
              class="text-xs italic text-red"
              >{{ errors.first("teaser") }}</span
            >
          </div>
        </div>
      </div>

      <div class="mt-8 divider">
        <div class="w-full lg:w-3/4 pb-8">
          <label class="text-h3 font-bold mb-4 pl-0">
            {{ descriptionLabel }}
          </label>
          <p v-if="isAPublicMember" class="leading-loose mb-6 text-h4">
            This will show up on your member profile page.
          </p>
          <vue-editor
            v-model="descriptionText"
            :editorToolbar="descriptionToolbar"
            data-vv-name="description"
            v-validate="`required|min:${VALIDATION.MIN_DESC_LEN}`"
            class="mb-2"
          ></vue-editor>
          {{ descriptionText ? descriptionText.length : "" }} of
          {{ VALIDATION.MIN_DESC_LEN }} characters
          <div class="my-4">
            <span
              v-show="errors.has('description')"
              class="text-xs italic text-red"
              >{{ errors.first("description") }}</span
            >
          </div>
        </div>
        <div v-if="isCS100org" class="w-full lg:w-1/2 mb-10 mapimage">
          <p><b>CS100</b> Institution. <br />Click image to expand map.</p>
          <br />
          <a
            href="https://www.csiselementary.org/s/cs100-awards-2023"
            target="_blank"
          >
            <img
              class="w-full"
              src="@/assets/img/cs100map.png"
              alt="cs100 map"
            />
          </a>
        </div>
      </div>

      <div v-if="isAPublicMember">
        <h3 class="mt-8 mb-4">Profile Links:</h3>
        <p class="leading-loose mb-6 text-h4">
          Add links to your website or social media profiles that will help
          people connect with your organization.
        </p>
        <div v-for="(link, index) in profileLinks" :key="link.uuid">
          <div class="mt-8">
            <div class="w-full lg:w-3/4">
              <label class="mr-2 pl-0 inline-block mb-4 font-medium">{{
                link.label ? link.label : "New Link"
              }}</label>
              <span v-show="index > 0">
                <i
                  title="Remove link"
                  class="far fa-minus-square cursor-pointer text-blue-dark inline-block"
                  @click="removeProfileLink(link.uuid)"
                ></i>
              </span>
            </div>
            <div class="w-full lg:w-3/4">
              <input
                type="text"
                class="mb-4"
                placeholder="Link Label"
                autocomplete="nope"
                v-model.trim="link.label"
                :name="`label ${index + 1}`"
                v-validate="'required'"
              />
              <div class="my-4">
                <span
                  v-show="errors.has(`label ${index + 1}`)"
                  class="text-xs italic text-red"
                  >{{ errors.first(`label ${index + 1}`) }}</span
                >
              </div>
              <input
                type="text"
                class="mb-4"
                placeholder="http://example.com"
                autocomplete="nope"
                v-model.trim="link.link"
                :name="`url ${index + 1}`"
                v-validate="{
                  required: true,
                  url: { require_protocol: true, protocols: ['http', 'https'] }
                }"
              />
              <div class="my-4">
                <span
                  v-show="errors.has(`url ${index + 1}`)"
                  class="text-xs italic text-red"
                  >{{ errors.first(`url ${index + 1}`) }}</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="w-full lg:w-3/4 mt-4">
          <span class="btn btn-blue btn-sm inline-block" @click="addProfileLink"
            >Add Profile Link</span
          >
        </div>
      </div>
      <div v-else>
        <h3 class="mt-8 mb-4">LinkedIn URL</h3>
        <div class="mt-8">
          <div class="w-full lg:w-3/4">
            <input
              type="text"
              class="mb-4"
              placeholder="http://www.linkedin.com/in/your-profile/"
              autocomplete="nope"
              v-model="profileLinks[0].link"
              :name="'linkedin_url'"
              v-validate="{
                url: {
                  require_protocol: true,
                  protocols: ['http', 'https']
                }
              }"
            />
            <div class="my-4">
              <span
                v-show="errors.has('linkedin_url')"
                class="text-xs italic text-red"
                >{{ errors.first("linkedin_url") }}</span
              >
            </div>
          </div>
        </div>
      </div>

      <div v-if="isAPublicMember" class="mt-8 mb-10 border-t pt-8">
        <h3>Categories:</h3>
        <p class="mt-2 mb-10">
          You may select as many options that apply to your organization.
        </p>
        <div class="flex flex-wrap">
          <multi-select-field-wide
            v-if="profile.member.type == 'member'"
            :options="customTypes"
            :values="profile.member.types"
            option="types"
            label="Organization Type"
            v-on:optionUpdate="onSelectUpdate"
          >
          </multi-select-field-wide>
          <multi-select-field
            v-if="profile.member.type == 'researcher'"
            :options="researchTypes"
            :values="profile.member.researchTypes"
            option="researchTypes"
            label="Research Types"
            v-on:optionUpdate="onSelectUpdate"
          >
          </multi-select-field>
          <multi-select-field
            :options="typeAudiences"
            :values="profile.member.audiences"
            option="audiences"
            label="Audience"
            v-on:optionUpdate="onSelectUpdate"
          >
          </multi-select-field>
          <multi-select-field
            :options="typeLevels"
            :values="profile.member.levels"
            option="levels"
            label="Level"
            v-on:optionUpdate="onSelectUpdate"
          >
          </multi-select-field>
          <multi-select-field
            v-if="user.member.type == 'member'"
            :options="settings"
            :values="profile.member.settings"
            option="settings"
            label="Setting"
            v-on:optionUpdate="onSelectUpdate"
          >
          </multi-select-field>
          <select-field
            v-if="profile.member.type == 'member'"
            :options="students"
            :value="profile.member.student"
            option="student"
            label="Students Served"
            v-on:optionUpdate="onSelectUpdate"
          >
          </select-field>
          <select-field
            v-if="profile.member.type == 'member'"
            :options="teachers"
            :value="profile.member.teacher"
            option="teacher"
            label="Teachers Served"
            v-on:optionUpdate="onSelectUpdate"
          >
          </select-field>
          <multi-select-field
            v-if="user.member.type == 'researcher'"
            :options="others"
            :values="profile.member.others"
            option="others"
            label="Other"
            v-on:optionUpdate="onSelectUpdate"
          >
          </multi-select-field>
        </div>
      </div>

      <div class="mt-8 mb-10 border-t pt-8">
        <div class="flex flex-wrap">
          <multi-select-field
            v-if="isAPublicMember"
            :options="regions"
            :values="profile.member.regions"
            option="regions"
            label="Region"
            description="Select the region(s) impacted by your work."
            v-on:optionUpdate="onSelectUpdate"
          >
          </multi-select-field>

          <div class="mb-10 w-full lg:w-1/2">
            <h3>Location</h3>
            <p class="mt-2 mb-4 pl-0">
              Select your organization's primary location.
            </p>

            <div class="w-full mb-5">
              <label for="countries" class="text-h3 mb-4 pl-0">
                Country
              </label>
              <v-select
                id="countries"
                label="name"
                name="country"
                autocomplete="nope"
                :options="countries"
                :reduce="country => country.code3"
                v-model="country"
                v-validate="'required'"
              >
              </v-select>
              <div class="my-4">
                <span
                  v-show="errors.has('country')"
                  class="text-xs italic text-red"
                  >{{ errors.first("country") }}</span
                >
              </div>
            </div>

            <div v-show="isUsa" class="w-full mb-5">
              <label for="state" class="text-h3 pl-0">
                State or Territory
              </label>
              <v-select
                id="state"
                name="state"
                label="name"
                autocomplete="nope"
                :options="statesAndTerritories"
                :reduce="state => state.abbreviation"
                v-model.trim="state"
                v-validate="{ required: this.isUsa }"
              >
              </v-select>
              <div class="my-4">
                <span
                  v-show="errors.has('state')"
                  class="text-xs italic text-red"
                  >{{ errors.first("state") }}</span
                >
              </div>
            </div>

            <div class="w-full mb-5">
              <label for="city" class="text-h3 mb-4 pl-0">
                City
              </label>
              <input
                id="city"
                name="city"
                autocomplete="nope"
                v-model.trim="city"
                v-validate="'required'"
              />
              <div class="my-4">
                <span
                  v-show="errors.has('city')"
                  class="text-xs italic text-red"
                  >{{ errors.first("city") }}</span
                >
              </div>
            </div>

            <div v-show="isUsa" class="w-full mb-5">
              <label for="zip" class="text-h3  pl-0">
                Zip Code
              </label>
              <input
                id="zip"
                name="zip code"
                v-model.trim="zipCode"
                autocomplete="nope"
                v-validate="{
                  required: this.isUsa,
                  regex: /^\d{5}(-\d{4})?$/
                }"
              />
              <div class="my-4">
                <span
                  v-show="errors.has('zip code')"
                  class="text-xs italic text-red"
                  >{{ errors.first("zip code") }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex">
        <div class="w-full mt-4">
          <button
            type="button"
            class="btn bg-grey-lighter text-grey-darkest no-underline hover:bg-grey"
            @click="cancel"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-green ml-4"
            :class="{ 'opacity-50 cursor-not-allowed': this.isSaving }"
            @click="onSave"
          >
            Save
          </button>
        </div>
      </div>

      <spinner v-show="isSaving"></spinner>
      <input
        type="file"
        id="logo-file"
        @change="handleProcessFile"
        class="hidden"
      />
      <input
        type="file"
        id="member-file"
        @change="handleProcessMemberFile"
        class="hidden"
      />
    </div>
    <div class="blue-bar"></div>
  </div>
</template>

<style>
.mapimage img {
  border: 1px solid #c8c8c8;
  box-shadow: 0px 1px 5px 1px #00000017;
  transition: all 0.2s ease;
}
.mapimage img:hover {
  box-shadow: 0px 2px 5px 2px #00000023;
}
</style>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";
import { VueEditor } from "vue2-editor";
import uuidv4 from "uuid/v4";
import ConfirmModal from "../modals/ConfirmModal";

import { MEMBER_TYPES, VALIDATION, ROLES, LINKEDIN_LABEL } from "@/const";
import { COUNTRIES } from "@/util/countries";
import { STATE_AND_TERRITORIES } from "@/util/statesAndTerritories";
import vSelect from "vue-select";

export default {
  components: {
    VueEditor,
    "confirm-modal": ConfirmModal,
    "v-select": vSelect
  },
  data() {
    return {
      MEMBER_TYPES: MEMBER_TYPES,
      VALIDATION: VALIDATION,
      teaserToolbar: [["bold", "italic"]],
      descriptionToolbar: [
        ["bold", "italic", "link"],
        [{ list: "ordered" }, { list: "bullet" }]
      ],
      changedUser: {},
      teaserText: null,
      descriptionText: null,
      name: null,
      title: null,
      training_sponsor: null,
      isSaving: false,
      profileLinks: [],
      memberLogo: false,
      memberImages: [],
      existingImages: [],
      selectedImages: 0,
      can_approve_alignments: false,
      is_admin: false,
      is_facilitator: false,
      showConfirmationModel: false,
      isAPublicMember: true,
      isAOrgMember: true,
      inactive_facilitator: false,
      profile: {
        member: {
          types: false,
          audiences: false,
          levels: false,
          regions: false,
          researchTypes: false,
          settings: false,
          student: false,
          teacher: false,
          teaser: false,
          description: false,
          logo_url: false,
          profileLinks: [],
          memberImages: []
        }
      },
      city: "",
      state: null,
      country: null,
      zipCode: "",
      countries: COUNTRIES,
      statesAndTerritories: STATE_AND_TERRITORIES,
      linkedInLabel: LINKEDIN_LABEL,
      inactiveFacilitatorOpt: {
        selectedValue: {
          state: "Active",
          inactive_facilitator: false
        },
        itemList: [
          {
            state: "Active",
            inactive_facilitator: false
          },
          {
            state: "Inactive",
            inactive_facilitator: true
          }
        ]
      }
    };
  },
  methods: {
    handleProcessMemberFile(e) {
      let reader = new FileReader();
      let contents;
      if (!e.target.files.length) {
        return;
      }
      const file = e.target.files[0];
      const fileTypes = [".jpg", ".png", ".gif", ".jpeg"];
      const urlType = fileTypes.filter(type => file.name.search(type) > 0)[0];
      let newUrl = urlType
        ? file.name.replace(urlType, file.lastModified + urlType)
        : null;

      reader.onloadend = e => {
        contents = e.target.result;
        const name = newUrl;

        if (file.size > 500000) {
          // 500000 bytes = 500 kb
          this.$toasted.show("Oops! Images must be less than 500KB");
          return;
        }
        if (name.toLowerCase().search(/(jpe?g|gif|png)/g) === -1) {
          this.$toasted.show("Oops! Images must be a JPG, JPEG, GIF or PNG.");
          return;
        }

        const fileData = {
          name: name,
          contents: btoa(contents)
        };

        this.selectedImages++;
        this.memberImages.push(fileData);
      };
      reader.readAsBinaryString(file);
    },
    handleProcessFile(e) {
      let reader = new FileReader();
      let contents;
      if (!e.target.files.length) {
        return;
      }
      const file = e.target.files[0];
      const fileTypes = [".jpg", ".png", ".gif", ".jpeg"];
      const urlType = fileTypes.filter(type => file.name.search(type) > 0)[0];
      let newUrl = urlType
        ? file.name.replace(urlType, file.lastModified + urlType)
        : null;

      reader.onloadend = e => {
        contents = e.target.result;
        const name = newUrl;

        if (file.size > 500000) {
          // 500000 bytes = 500 kb
          this.$toasted.show("Oops! Logo files must be less than 500KB");
          return;
        }
        if (name.toLowerCase().search(/(jpe?g|gif|png)/g) === -1) {
          this.$toasted.show(
            "Oops! Logo files must be a JPG, JPEG, GIF or PNG."
          );
          return;
        }
        // this.isSaving = true;
        this.memberLogo = {
          logo: {
            name: name,
            contents: btoa(contents)
          }
        };
      };
      reader.readAsBinaryString(file);
    },
    handleRemoveSelectedImage(name) {
      this.selectedImages--;
      this.memberImages = _.reject(this.memberImages, item => {
        return item.name == name;
      });
    },
    handleRemoveMemberImage(name) {
      this.selectedImages--;
      this.existingImages = _.reject(this.existingImages, item => {
        return item == name;
      });
    },
    handleSelectFile() {
      document.getElementById("logo-file").click();
    },
    handleAddImage() {
      document.getElementById("member-file").click();
    },
    removeProfileLink(uuid) {
      this.profileLinks = _.filter(this.profileLinks, function(link) {
        return link.uuid != uuid;
      });
    },
    addProfileLink() {
      this.profileLinks.push({
        uuid: uuidv4(),
        label: "",
        link: ""
      });
    },
    async setProfile() {
      await this.$validator.validateAll();

      if (this.isAdminMode) {
        this.can_approve_alignments = this.profile.can_approve_alignments;
        this.is_admin = this.profile.is_admin;

        this.is_facilitator = this.profile.inactive_facilitator
          ? true
          : this.profile.is_facilitator;

        this.inactive_facilitator = this.profile.inactive_facilitator;
      }

      this.name = this.profile.member.name;
      this.title = this.profile.title || "";
      this.training_sponsor = this.profile.training_sponsor || "";
      this.teaserText = this.getTeaserText(this.profile);
      this.descriptionText = this.profile.member.description;
      this.city = this.profile.member?.city || null;
      this.state = this.profile.member?.state || null;
      this.country = this.profile.member?.country || null;
      this.zipCode = this.profile.member?.zipCode || "";

      if (this.profile?.member?.profileLinks?.length) {
        this.profileLinks = this.profile.member.profileLinks;
      } else if (this.profileLinks.length < 1) {
        this.addProfileLink();
      }

      if (this.profile.member?.memberImages?.length) {
        this.existingImages = this.profile.member.memberImages;
        this.selectedImages = this.profile.member.memberImages.length;
      }

      this.isAPublicMember = await this.isPublicMember();
      this.isAOrgMember = this.isOrgMember();
    },
    async fetchProfile() {
      const resp = await window.axios.get(this.userPath);

      const userData = resp.data.data[0];

      this.profile = {
        ...userData,
        member: userData.member.data
      };
    },
    isOrgMember() {
      const memberType = this.profile?.member?.type;
      return memberType === MEMBER_TYPES.MEMBER;
    },
    async isPublicMember() {
      const memberType = this.profile?.member?.type;
      return memberType === MEMBER_TYPES.RESEARCHER || this.isOrgMember();
    },
    getTeaserText(profile) {
      const teaserHtml = profile?.member?.teaser;

      if (!teaserHtml) {
        return "";
      }

      const tmp = document.createElement("div");
      tmp.innerHTML = teaserHtml;

      return tmp.innerText;
    },
    onSelectUpdate(e) {
      let update = {};
      update[e.property] = e.value;
      this.changedUser = Object.assign({}, this.changedUser, update);
    },
    async checkProfileLinkURL() {
      const returnPromise = await Promise.all(
        this.profileLinks.map(async (input, i) => {
          return await this.$validator.validate(`url ${i + 1}`);
        })
      );
      return returnPromise;
    },
    async onSave() {
      this.isSaving = true;

      if (this.teaserText) {
        this.changedUser.teaser = this.teaserText;
      }
      if (this.descriptionText && this.descriptionText.length >= 250) {
        this.changedUser.description = this.descriptionText;
      }
      if (this.name) {
        this.changedUser.name = this.name;
      }
      if (this.isAdminMode) {
        this.changedUser.can_approve_alignments = this.can_approve_alignments;
      }
      if (this.isAdminMode) {
        this.changedUser.is_admin = this.is_admin;
      }
      if (this.isAdminMode) {
        this.changedUser.is_facilitator = this.is_facilitator;

        if (!this.is_facilitator) {
          this.changedUser.inactive_facilitator = false;
          this.inactive_facilitator = false;
        } else {
          this.changedUser.inactive_facilitator = this.inactive_facilitator;
        }
      }

      if (this.title) {
        this.changedUser.title = this.title;
      }
      if (this.training_sponsor) {
        this.changedUser.training_sponsor = this.training_sponsor;
      }
      if (this.memberLogo) {
        this.changedUser.logo = this.memberLogo.logo;
      }
      if (this.memberImages) {
        this.changedUser.newImages = this.memberImages;
      }
      if (this.city) {
        this.changedUser.city = this.city;
      }
      if (this.country) {
        this.changedUser.country = this.country;
      }
      if (this.state && this.isUsa) {
        this.changedUser.state = this.state;
      } else {
        this.changedUser.state = null;
      }
      if (this.zipCode && this.isUsa) {
        this.changedUser.zipCode = this.zipCode;
      } else {
        this.changedUser.zipCode = null;
      }
      this.changedUser.memberImages = this.existingImages;

      let urlLinkValidator;
      if (this.isAPublicMember) {
        let validateProfileLink = await this.checkProfileLinkURL();
        urlLinkValidator = !validateProfileLink.some(v => v === false);
        this.changedUser.profileLinks = this.profileLinks;
      } else {
        urlLinkValidator = await this.$validator.validate("linkedin_url");
        if (urlLinkValidator) {
          this.changedUser.profileLinks = this.profileLinks;
        }
      }

      if (!urlLinkValidator) {
        this.isSaving = false;
        this.$toasted.show("Please enter a valid Profile Link URL.");
      } else {
        try {
          await window.axios.put(this.userPath, this.changedUser);

          this.$toasted.show("Profile updated");
        } catch (error) {
          console.log(error);
        } finally {
          this.isSaving = false;
        }

        const isAllValid = await this.validateProfileFields();

        if (isAllValid) {
          this.returnHome();
        } else {
          this.showConfirmationModel = true;
        }
      }
    },
    async validateProfileFields() {
      const validator = await this.$validator.validateAll();
      return validator && (this.memberLogo || this.profile.member.logo_url);
    },
    returnHome() {
      let returnPath;

      if (this.isAdminMode) {
        returnPath = `/admin/profile/${this.$route.params.token}`;
      } else {
        returnPath = "/profile";
      }

      this.$router.push(returnPath);
    },
    async cancel() {
      await this.setProfile();
      this.returnHome();
    }
  },
  computed: {
    ...mapGetters({
      user: "user",
      isLoggedIn: "isLoggedIn",
      types: "types",
      researchTypes: "researchTypes",
      audiences: "audiences",
      levels: "levels",
      regions: "regions",
      settings: "settings",
      teachers: "teachers",
      students: "students",
      others: "others"
    }),
    isCS100org() {
      return this.profile.member.org_id == 1 ? true : false;
    },
    isFacilitatorProfile() {
      const facRole = this.profile?.roles?.find(
        role => role === ROLES.FACILITATOR || role === ROLES.PENDING_FACILITATOR
      );
      return facRole;
    },
    customTypes() {
      let typesObj = Object.values(this.types);
      return typesObj.map(item =>
        item.id === 1 ? { ...item, option: `${item.option} (Non-K12)` } : item
      );
    },
    isUsa() {
      return this.country === "USA";
    },
    descriptionLabel() {
      if (this.isFacilitatorProfile) {
        return "Personal Bio";
      } else {
        return "Description";
      }
    },
    typeAudiences: function() {
      if (!this.audiences) {
        return [];
      }
      return _.filter(this.audiences, audience => {
        return (
          audience.for == this.profile.member.type || audience.for == "both"
        );
      });
    },
    typeLevels: function() {
      if (!this.levels) {
        return [];
      }
      return _.filter(this.levels, level => {
        return level.for == this.profile.member.type || level.for == "both";
      });
    },
    isAdminMode: function() {
      return !!this.$route.params.token;
    },
    crumbs: function() {
      if (this.isAdminMode) {
        return [
          {
            link:
              "/admin/profile/" +
              (this.profile ? this.profile.member.uuid : ""),
            label: this.profile ? this.profile.member.name : ""
          },
          {
            link: false,
            label: "Edit Profile"
          }
        ];
      } else {
        return [
          {
            link: "/home",
            label: this.profile ? this.profile.member.name : ""
          },
          {
            link: false,
            label: "Edit Profile"
          }
        ];
      }
    },
    userPath() {
      if (this.isAdminMode) {
        return `/api/user/${this.$route.params.token}`;
      } else {
        return "/api/me";
      }
    }
  },
  created() {
    if (this.$route.params.token) {
      this.$store.dispatch("setActiveNav", "admin");
    } else {
      this.$store.dispatch("setActiveNav", "profile");
    }

    this.$store.subscribe(mutation => {
      if (mutation.type != "USER_SET") {
        return;
      }
    });
  },
  async mounted() {
    await this.fetchProfile();
    this.setProfile();
  }
};
</script>
