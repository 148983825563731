<template>
  <div v-if="isLoading">
    <spinner></spinner>
  </div>
  <div v-else>
    <div class="relative">
      <header class="fixed top-0 w-full z-10 py-5 bg-white border-b-2">
        <div class="flex container mx-auto px-5 items-end">
          <div class="w-1/2 md:w-1/3 ">
            <router-link
              :to="{
                name: 'facilitator-workshop-show',
                params: { id: workshop.id }
              }"
            >
              <p class="text-blue-primary font-bold container m-auto">
                &lt; Back to {{ workshop.title }}
              </p>
            </router-link>
          </div>
          <div class="hidden md:block w-1/3 text-center">
            <img class="w-40" src="@/assets/img/scriptlogo.png" alt="script" />
          </div>
        </div>
        <div class="flex container mx-auto px-5 mt-3 items-center">
          <div class="w-full text-center ">
            <h5>View Only</h5>
          </div>
        </div>
      </header>

      <div class="bg-blue-panel pt-34">
        <div class="container mx-auto">
          <div v-if="!allowedAccess" class="text-center">
            <h2>Not Authorized</h2>
          </div>
          <div v-else>
            <!-- if goals -->
            <div v-if="form.title === 'Goal Setting'">
              <GoalsSection
                :form="form"
                :answers="answers"
                :formData="formData"
                :user="user"
              >
              </GoalsSection>
            </div>
            <!-- end groups -->

            <!-- else for the others -->
            <div v-else>
              <RubricSection
                :form="form"
                :answers="answers"
                :formData="formData"
                :user="user"
              >
              </RubricSection>
              <!-- end if else -->
            </div>
          </div>
        </div>
        <div class="pb-34"></div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.center-div {
  margin: 0 auto;
}
</style>

<script>
import Spinner from "../../components/Spinner";

import GoalsSection from "./FormAnswersGoals.vue";
import RubricSection from "./FormAnswersRubric.vue";

import { mapGetters } from "vuex";
import { FORMMODE } from "../../const";
import _ from "lodash";

export default {
  name: "FormAnswers",
  components: {
    Spinner,
    GoalsSection,
    RubricSection
  },
  data: () => ({
    answered: false,
    answers: {},
    form: {},
    formData: {},
    isLoading: true,
    registration: {},
    workshop: {}
  }),
  computed: {
    ...mapGetters({
      user: "user"
    }),
    allowedAccess() {
      return (
        _.map(this.workshop.staff, "id").includes(this.user.id) &&
        this.registration.consent != "none"
      );
    },
    inputs() {
      return this.form ? this.form.inputs : [];
    },
    isViewOnlyMode() {
      return this.$route.params.mode === FORMMODE.VIEW;
    }
  },
  methods: {
    getAnswers() {
      return window.axios
        .get(`/api/answers/${this.$route.params.id}`)
        .then(response => {
          if (response.data) {
            this.workshop = response.data.workshop;
            this.registration = response.data.registration;
            this.answers = response.data.answers;
            this.form = response.data.form.schema;
            if (response.data.answers && Object.keys(this.answers).length) {
              this.formData = Object.assign({}, this.answers);
            }
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  async mounted() {
    await this.getAnswers();
    this.isLoading = false;
  }
};
</script>
