<template>
  <transition name="modal" v-show="value">
    <div class="modal-mask h-full flex justicy-center items-center">
      <div class="modal-wrapper flex h-full justify-center items-center w-full">
        <div
          class="modal-box flex flex-col p-2 modal-container border-2 bg-white rounded shadow m-auto w-full md:w-3/4 lg:w-3/4 pb-0 pr-0 pt-0 pl-0"
        >
          <div class="flex close-icon items-center mt-2 mr-5">
            <button @click.prevent="close">
              <h1 class="text-blue-primary">X</h1>
            </button>
          </div>
          <div class="modal-overflow">
            <Evaluation :registrationSurvey="registration" />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import "vue-select/dist/vue-select.css";
import Evaluation from "../public/WorkshopEvaluation.vue";

export default {
  name: "SurveyModal",
  components: {
    Evaluation
  },
  props: {
    value: {
      required: true
    },
    registration: Object
  },
  methods: {
    close() {
      this.$emit("input", !this.value);
    }
  }
};
</script>
<style lang="css" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.85);
  transition: opacity 0.3s ease;
}

.modal-container {
  transition: all 0.3s ease;
}

.modal-box {
  max-height: 85%;
  position: relative;
}
.modal-box .close-icon {
  position: absolute;
  z-index: 1;
  right: 0;
}

label {
  padding-left: 22px;
  text-indent: -22px;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-overflow {
  overflow-y: scroll;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
