import Vue from "vue";
import VueRouter from "vue-router";

import UserLogin from "../components/user/UserLogin.vue";
import UserLogout from "../components/user/UserLogout.vue";
import UserForgotPassword from "../components/user/UserForgotPassword.vue";
import UserResetForgottenPassword from "../components/user/UserResetForgottenPassword.vue";
import UserResetPassword from "../components/user/UserResetPassword.vue";
import UserDashboard from "../components/user/UserDashboard.vue";
import UserProfile from "../components/user/UserProfile.vue";
import UserProfileEdit from "../components/user/UserProfileEdit.vue";
import UserProfileCreate from "../components/user/UserProfileCreate.vue";
import UserProfileConfirmation from "../components/user/UserProfileConfirmation.vue";
import UserAccount from "../components/user/UserAccount.vue";
import UserPreRequisites from "../components/user/UserPreRequisites.vue";
import LEAParticipantRegister from "../components/user/LEAParticipantRegister.vue";
import CurriculumEdit from "../components/user/CurriculumEdit.vue";
import CurriculumAlign from "../components/user/CurriculumAlign.vue";
import CurriculumView from "../components/user/CurriculumView.vue";
import CurriculumList from "../components/user/CurriculumList.vue";

import AdminIndex from "../components/admin/AdminIndex.vue";

import FormModule from "../components/forms/FormModule.vue";
import FormList from "../components/forms/FormList.vue";

import WorkshopModule from "../components/workshops/WorkshopModule.vue";
import WorkshopList from "../components/workshops/WorkshopList.vue";
import WorkshopCreateEdit from "../components/workshops/WorkshopCreateEdit.vue";
import WorkshopShow from "../components/workshops/WorkshopShow.vue";
import WorkshopFormCreate from "../components/workshops/WorkshopFormCreate.vue";

import FacilitatorModule from "../components/facilitators/BaseModule.vue";
import FacilitatorWorkshopShow from "../components/facilitators/FacilitatorWorkshopShow.vue";
import FacilitatorEvaluationShow from "../components/facilitators/EvaluationShow.vue";
import FormAnswers from "../components/forms/FormAnswers.vue";

import PublicModule from "../components/public/PublicModule.vue";
import PublicWorkshopShow from "../components/public/PublicWorkshopShow.vue";
import PublicFormAnswers from "../components/public/PublicFormAnswers.vue";
import WorkshopEvaluation from "../components/public/WorkshopEvaluation.vue";
import NotFound from "@/components/NotFound";

import Register from "@/components/signup/Register";
import LearnMore from "@/components/signup/LearnMore";
import SelectOrganization from "@/components/signup/SelectOrganization";
import AlreadyRegistered from "@/components/signup/AlreadyRegistered";
import SelectOrganizationTypes from "@/components/signup/SelectOrganizationTypes";
import RegisterOrganization from "@/components/signup/RegisterOrganization";
import SelectLeaType from "@/components/signup/SelectLeaType";
import WorkshopPocInvite from "@/components/signup/WorkshopPocInvite";
import WorkshopBadInvite from "@/components/signup/WorkshopBadInvite";

import { BAD_INVITES } from "@/const";

Vue.use(VueRouter);

const routes = [
  {
    path: "/auth/login",
    name: "user-login",
    component: UserLogin,
    meta: {
      title: "Login",
      hideMainNav: true,
      public: true
    }
  },
  {
    path: "/auth/login/invitation/:memInvitationUuid",
    name: "user-workshop-register-by-user-login",
    component: UserLogin,
    meta: {
      title: "Login",
      hideMainNav: true,
      public: true
    }
  },
  {
    path: "/auth/logout",
    name: "user-logout",
    component: UserLogout
  },
  {
    path: "/auth/register/workshop/invitation/:inviteId",
    name: "WorkshopPocInvite",
    component: WorkshopPocInvite,
    props: true,
    meta: {
      title: "Register",
      public: true,
      hideMainNav: true
    }
  },
  {
    path: "/signup/workshop-poc-register/:inviteId",
    name: "WorkshopPocRegister",
    component: RegisterOrganization,
    props(route) {
      return {
        isLeaSignup: true,
        inviteId: route.params.inviteId
      };
    },
    meta: {
      title: "Register",
      public: true,
      hideMainNav: true
    },
    beforeEnter(to, from, next) {
      if (from.name === "WorkshopPocInvite") {
        return next();
      } else {
        return next("/auth/login");
      }
    }
  },
  {
    path: "/signup/workshop-not-found/",
    name: "WorkshopNotFound",
    component: WorkshopBadInvite,
    props: {
      inviteType: BAD_INVITES.WORKSHOP_NOT_FOUND
    },
    meta: {
      title: "Register",
      public: true,
      hideMainNav: true
    }
  },
  {
    path: "/signup/invite-already-used/",
    name: "WorkshopInviteUsed",
    component: WorkshopBadInvite,
    props: {
      inviteType: BAD_INVITES.WORKSHOP_STALE
    },
    meta: {
      title: "Register",
      public: true,
      hideMainNav: true
    }
  },
  {
    path: "/signup/team-invite-not-found/",
    name: "TeamInviteNotFound",
    component: WorkshopBadInvite,
    props: {
      inviteType: BAD_INVITES.TEAM_NOT_FOUND
    },
    meta: {
      title: "Register",
      public: true,
      hideMainNav: true
    }
  },
  {
    path: "/signup/team-invite-already-used/",
    name: "TeamInviteUsed",
    component: WorkshopBadInvite,
    props: {
      inviteType: BAD_INVITES.TEAM_STALE
    },
    meta: {
      title: "Register",
      public: true,
      hideMainNav: true
    }
  },
  {
    path: "/auth/pre-req",
    name: "user-pre-req",
    component: UserPreRequisites,
    meta: {
      title: "User Pre Requisites",
      public: true,
      hideMainNav: true
    }
  },
  {
    path: "/signup/facilitator/register",
    name: "FacilitatorRegister",
    component: Register,
    meta: {
      title: "Register",
      public: true,
      hideMainNav: true
    },
    beforeEnter(to, from, next) {
      if (from.name === "user-pre-req") {
        return next();
      } else {
        return next("/auth/login");
      }
    }
  },
  {
    path: "/signup/reviewer/register",
    name: "ReviewerRegister",
    component: Register,
    meta: {
      title: "Register",
      public: true,
      hideMainNav: true
    },
    beforeEnter(to, from, next) {
      if (from.name === "user-pre-req") {
        return next();
      } else {
        return next("/auth/login");
      }
    }
  },
  {
    path: "/signup/researcher/register",
    name: "ResearcherRegister",
    component: Register,
    meta: {
      title: "Register",
      public: true,
      hideMainNav: true
    },
    beforeEnter(to, from, next) {
      if (from.name === "user-pre-req") {
        return next();
      } else {
        return next("/auth/login");
      }
    }
  },
  {
    path: "/signup/register",
    name: "Register",
    component: Register,
    meta: {
      title: "Register",
      public: true,
      hideMainNav: true
    }
  },
  {
    path: "/signup/org/register-organization",
    name: "OrgRegisterOrganization",
    component: RegisterOrganization,
    meta: {
      title: "Register Your Organization",
      public: true,
      hideMainNav: true
    },
    beforeEnter(to, from, next) {
      if (from.name === "SelectOrganizationTypes") {
        return next();
      } else {
        return next("/auth/login");
      }
    }
  },
  {
    path: "/signup/lea/register-organization",
    name: "LeaRegisterOrganization",
    component: RegisterOrganization,
    props: {
      isLeaSignup: true
    },
    meta: {
      title: "Register Your Organization",
      public: true,
      hideMainNav: true
    },
    beforeEnter(to, from, next) {
      if (from.name === "SelectLeaType") {
        return next();
      } else {
        return next("/auth/login");
      }
    }
  },
  {
    path: "/signup/lea/select-organization",
    name: "LeaSelectOrganization",
    component: SelectOrganization,
    props: {
      nextStep: "SelectLeaType"
    },
    meta: {
      title: "Select Your Organization",
      public: true,
      hideMainNav: true
    },
    beforeEnter(to, from, next) {
      const expectedRoutes = [
        "user-pre-req",
        "SelectLeaType",
        "AlreadyRegistered",
        null
      ];

      if (expectedRoutes.includes(from.name)) {
        return next();
      } else {
        return next("/auth/login");
      }
    }
  },
  {
    path: "/signup/org/select-organization",
    name: "OrgSelectOrganization",
    component: SelectOrganization,
    props: {
      nextStep: "SelectOrganizationTypes"
    },
    meta: {
      title: "Select Your Organization",
      public: true,
      hideMainNav: true
    },
    beforeEnter(to, from, next) {
      const expectedRoutes = [
        "user-pre-req",
        "SelectOrganizationTypes",
        "AlreadyRegistered",
        null
      ];

      if (expectedRoutes.includes(from.name)) {
        return next();
      } else {
        return next("/auth/login");
      }
    }
  },
  {
    path: "/signup/learn-more",
    name: "LearnMore",
    component: LearnMore,
    meta: {
      title: "Learn More",
      public: true,
      hideMainNav: true
    }
  },
  {
    path: "/signup/existing-registration",
    name: "AlreadyRegistered",
    component: AlreadyRegistered,
    meta: {
      title: "Existing Registration",
      public: true,
      hideMainNav: true
    }
  },
  {
    path: "/signup/org/select-organization-types",
    name: "SelectOrganizationTypes",
    component: SelectOrganizationTypes,
    meta: {
      title: "Select Organization Types",
      public: true,
      hideMainNav: true
    },
    beforeEnter(to, from, next) {
      const expectedRoutes = [
        "OrgSelectOrganization",
        "OrgRegisterOrganization",
        null
      ];

      if (expectedRoutes.includes(from.name)) {
        return next();
      } else {
        return next("/auth/login");
      }
    }
  },
  {
    path: "/signup/lea/select-lea-type",
    name: "SelectLeaType",
    component: SelectLeaType,
    meta: {
      title: "Select Association Type",
      public: true,
      hideMainNav: true
    },
    beforeEnter(to, from, next) {
      const expectedRoutes = [
        "LeaSelectOrganization",
        "LeaRegisterOrganization",
        "LearnMore",
        null
      ];

      if (expectedRoutes.includes(from.name)) {
        return next();
      } else {
        return next("/auth/login");
      }
    }
  },
  {
    path: "/auth/lea-participant-registration/:invitationUuid",
    name: "user-lea-participant-register",
    component: LEAParticipantRegister,
    meta: {
      title: "LEA Participant Registration",
      public: true,
      hideMainNav: true
    }
  },
  {
    path: "/auth/create-profile/:workshop?",
    name: "user-create-profile",
    component: UserProfileCreate,
    meta: {
      title: "Create Profile",
      hideMainNav: true
      // not public because you should be logged in before viewing this page
    }
  },
  {
    path: "/auth/forgot",
    name: "user-forgot-password",
    component: UserForgotPassword,
    meta: {
      title: "Forgot Password",
      hideMainNav: true,
      public: true
    }
  },
  {
    path: "/auth/forgot/reset",
    name: "user-reset-forgotten-password",
    component: UserResetForgottenPassword,
    meta: {
      title: "Reset Password",
      hideMainNav: true,
      public: true
    }
  },
  {
    path: "/auth/profile-confirm/:workshop?",
    name: "user-profile-confirm",
    component: UserProfileConfirmation,
    meta: {
      title: "Profile Created",
      hideMainNav: true
      // not public because you should be logged in before viewing this page
    }
  },
  {
    path: "/auth/reset",
    name: "user-reset-password",
    component: UserResetPassword,
    meta: {
      title: "Reset Password",
      public: true
    }
  },
  {
    path: "/home",
    name: "dashboard",
    component: UserDashboard,
    meta: {
      title: "Dashboard"
    }
  },
  {
    path: "/profile",
    name: "profile",
    component: UserProfile,
    meta: {
      title: "Profile"
    }
  },
  {
    path: "/home/account",
    name: "account",
    component: UserAccount,
    meta: {
      title: "Account"
    }
  },
  {
    path: "/forms",
    component: FormModule,
    meta: {
      title: "Form"
    },
    children: [
      {
        path: "",
        component: FormList,
        name: "forms-list" // index show all()
      }
    ]
  },
  {
    path: "/answers/:id",
    name: "answers",
    component: FormAnswers,
    meta: {
      title: "Form Answers",
      hideMainNav: true
    }
  },
  {
    path: "/facilitator",
    component: FacilitatorModule,
    children: [
      {
        path: "evaluations/:workshop",
        component: FacilitatorEvaluationShow,
        name: "facilitator.evaluations.show", // index show all()
        meta: {
          title: "Evaluation",
          hideMainNav: true
        }
      }
    ]
  },
  {
    path: "/workshops",
    component: WorkshopModule,
    children: [
      {
        path: "",
        component: WorkshopList,
        name: "workshops-list", // index show all()
        meta: {
          title: "Workshops"
        }
      },
      {
        path: "create",
        component: WorkshopCreateEdit,
        name: "workshop-create", // show(id)
        meta: {
          title: "Create Workshop"
        }
      },
      {
        path: "edit/:id",
        component: WorkshopCreateEdit,
        name: "workshop-edit", // show(id)
        meta: {
          title: "Edit Workshop"
        }
      },
      {
        path: ":id",
        component: WorkshopShow,
        name: "workshop-show",
        meta: {
          title: "Workshop"
        }
      },
      {
        path: ":workshop/forms/:form/:mode?",
        component: WorkshopFormCreate,
        name: "workshop-form-create",
        meta: {
          title: "Form",
          hideMainNav: true
        }
      },
      {
        path: "facilitator/:id",
        component: FacilitatorWorkshopShow,
        name: "facilitator-workshop-show",
        meta: {
          title: "Workshop"
        }
      },
      {
        //Dead code?
        path: ":id/register",
        redirect: "/auth/login?workshop=:id"
      }
    ]
  },
  {
    path: "/public",
    name: "public",
    component: PublicModule,
    meta: {
      public: true
    },
    children: [
      {
        path: "evaluation/:id",
        name: "public.evaluation",
        component: WorkshopEvaluation,
        meta: {
          title: "Post-Survey",
          public: true,
          hideMainNav: true
        }
      },
      {
        path: "answers/:id",
        name: "public.answer",
        component: PublicFormAnswers,
        meta: {
          title: "Responses",
          public: true,
          hideMainNav: true
        }
      },
      {
        path: "workshop/:id",
        name: "public.workshops-show",
        component: PublicWorkshopShow,
        meta: {
          title: "Workshop",
          public: true,
          hideMainNav: true
        }
      }
    ]
  },
  {
    path: "/home/profile/edit",
    name: "profile-edit",
    component: UserProfileEdit,
    meta: {
      title: "Edit Profile"
    }
  },
  {
    path: "/home/curriculum/edit",
    name: "curricula-edit",
    component: CurriculumEdit,
    meta: {
      title: "Edit Curriculum"
    }
  },
  {
    path: "/home/curriculum/edit/:token",
    name: "curricula-edit-curriculum",
    component: CurriculumEdit,
    meta: {
      title: "Edit Curriculum"
    }
  },
  {
    path: "/home/curriculum/:token/align",
    name: "curricula-align",
    component: CurriculumAlign,
    meta: {
      title: "Align Curriculum"
    }
  },
  {
    path: "/home/curriculum/:token",
    name: "curricula-show",
    component: CurriculumView,
    props: {
      mode: "user"
    },
    meta: {
      title: "View Curriculum"
    }
  },
  {
    path: "/home/certify",
    name: "curricula-cert-list",
    component: CurriculumList,
    meta: {
      title: "List Curriculum"
    }
  },
  {
    path: "/home/certify/:token",
    name: "curricula-cert",
    component: CurriculumView,
    meta: {
      title: "Certify Curriculum"
    }
  },
  {
    path: "/home/certify/:token/align",
    name: "curricula-cert-edit",
    component: CurriculumAlign,
    meta: {
      title: "Edit Alignment Curriculum"
    }
  },
  {
    path: "/admin",
    name: "admin",
    component: AdminIndex,
    meta: {
      title: "Members"
    }
  },
  {
    path: "/admin/profile/edit/:token",
    name: "admin-profile-edit",
    component: UserProfileEdit,
    meta: {
      title: "Edit Member"
    }
  },
  {
    path: "/admin/profile/:token",
    name: "admin-profile",
    component: UserProfile,
    meta: {
      title: "Edit Member"
    }
  },
  {
    path: "/admin/curricula",
    name: "curricula-admin",
    component: CurriculumList,
    meta: {
      title: "List Curriculum"
    }
  },
  {
    path: "/admin/curriculum/edit/:token",
    name: "curricula-edit-admin",
    component: CurriculumEdit,
    meta: {
      title: "Edit Curriculum"
    }
  },
  {
    path: "/",
    redirect: {
      name: "dashboard"
    }
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: NotFound,
    meta: {
      public: true,
      title: "Page Not Found"
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    // return desired position
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title + " | CSforALL Members";
  return next(true);
});

export default router;
