export const RESEARCH_TOPICS_SET = "RESEARCH_TOPICS_SET";

const state = {
  researchTopics: {}
};

const getters = {
  researchTopics: state => state.researchTopics
};

const actions = {
  setResearchTopics: ({ commit }, researchTopics) => {
    commit(RESEARCH_TOPICS_SET, {
      researchTopics: researchTopics
    });
  }
};

const mutations = {
  RESEARCH_TOPICS_SET: (state, payload) => {
    state.researchTopics = Object.assign(
      {},
      state.researchTopics,
      payload.researchTopics
    );
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
