<template>
  <div v-if="isLoggedIn || ($route.meta && $route.meta.public)">
    <main-nav v-if="showMainNav"></main-nav>
    <div>
      <router-view></router-view>
    </div>
  </div>
  <div v-else>
    <spinner></spinner>
  </div>
</template>

<script>
import { Auth } from "aws-amplify";
import { mapGetters } from "vuex";
import { getDataOnSignIn } from "../util/getDataOnSignIn";

export default {
  computed: {
    ...mapGetters({
      user: "user",
      tokens: "tokens",
      isLoggedIn: "isLoggedIn"
    }),
    showMainNav() {
      if (this.$route.meta) {
        return !this.$route.meta.hideMainNav;
      }
      return true;
    }
  },
  mounted() {
    // set path of initially requested page prior to login redirection.
    // after login, we will use this value to go to the originally requested page.
    window._redirectedFrom = false;
    if (
      !(this.$route.meta && this.$route.meta.public) &&
      window.location.pathname != "/"
    ) {
      window._redirectedFrom = window.location.pathname;
    }
    Auth.currentAuthenticatedUser()
      .then(async user => {
        this.$store.dispatch("setTokens", user.signInUserSession);
        await getDataOnSignIn(this.$store, user);
      })
      .catch(() => {
        // public pages should go directly to the page without redirecting first to the login page
        // (if there is no currently logged in user on app instantiation)
        if (!(this.$route.meta && this.$route.meta.public)) {
          this.$router.push("/auth/login");
        }
      });
  }
};
</script>
