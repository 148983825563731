export const SETTINGS_SET = "SETTINGS_SET";

const state = {
  settings: {}
};

const getters = {
  settings: state => state.settings
};

const actions = {
  setSettings: ({ commit }, settings) => {
    commit(SETTINGS_SET, {
      settings: settings
    });
  }
};

const mutations = {
  SETTINGS_SET: (state, payload) => {
    state.settings = Object.assign({}, state.settings, payload.settings);
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
