var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative pb-32"},[_c('div',{staticClass:"container mx-auto pb-20 h-screen w-full: lg:w-2/5"},[_vm._m(0),_c('div',{staticClass:"text-center mt-6"},[_vm._m(1),_c('h1',{staticClass:"text-center"},[_vm._v("Password Reset")]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.required),expression:"required"}],staticClass:"mt-3 mb-10 text-center"},[_vm._v(" A password reset is required for your account. ")]),_c('p',{staticClass:"mt-3 mb-10 text-center"},[_vm._v(" A code has been sent to your email address to confirm your password reset. Please enter it below along with your new password. ")]),_c('form',{attrs:{"method":"POST","aria-label":"Forgot Password"},on:{"submit":function($event){$event.preventDefault();return _vm.validateBeforeSubmit.apply(null, arguments)}}},[_c('div',{class:{
            'mb-8 text-left': true,
            'has-error': _vm.errors.has('code')
          }},[_c('label',{attrs:{"for":"email"}},[_vm._v("Verification Code")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.code),expression:"code"},{name:"validate",rawName:"v-validate",value:('required|numeric|length:6'),expression:"'required|numeric|length:6'"}],attrs:{"id":"code","type":"text","name":"code"},domProps:{"value":(_vm.code)},on:{"input":function($event){if($event.target.composing)return;_vm.code=$event.target.value}}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('code')),expression:"errors.has('code')"}],staticClass:"text-red text-xs italic"},[_vm._v(_vm._s(_vm.errors.first("code")))])]),_c('div',{class:{
            'mb-8 text-left': true,
            'has-error': _vm.errors.has('password')
          }},[_c('label',{attrs:{"for":"password"}},[_vm._v("Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"},{name:"validate",rawName:"v-validate",value:(`required|min:${_vm.VALIDATION.MIN_PW_LEN}`),expression:"`required|min:${VALIDATION.MIN_PW_LEN}`"}],ref:"password",staticClass:"form-control",attrs:{"id":"password","type":"password","name":"password"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing)return;_vm.password=$event.target.value}}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('password')),expression:"errors.has('password')"}],staticClass:"text-red text-xs italic"},[_vm._v(_vm._s(_vm.errors.first("password")))])]),_c('div',{class:{
            'mb-8 text-left': true,
            'has-error': _vm.errors.has('password_confirmation')
          }},[_c('label',{attrs:{"for":"password-confirm"}},[_vm._v("Confirm Password")]),_c('input',{directives:[{name:"validate",rawName:"v-validate",value:(
              `required|confirmed:password|min:${_vm.VALIDATION.MIN_PW_LEN}`
            ),expression:"\n              `required|confirmed:password|min:${VALIDATION.MIN_PW_LEN}`\n            "}],staticClass:"form-control",attrs:{"id":"password-confirm","type":"password","name":"password_confirmation"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('password_confirmation')),expression:"errors.has('password_confirmation')"}],staticClass:"text-red text-xs italic"},[_vm._v(_vm._s(_vm.errors.first("password_confirmation")))])]),_vm._m(2),_vm._m(3)])])]),_c('div',{staticClass:"blue-bar"})])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c('img',{staticClass:"relative pin-t margin-auto",attrs:{"src":require("@/assets/img/circuits-grey.svg"),"alt":"Circuits"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"/home"}},[_c('img',{staticClass:"mb-10 main-logo",attrs:{"src":require("@/assets/img/csforall-logo.svg"),"alt":"CSforAll","width":"260"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"text-sm flex float-right",attrs:{"href":"mailto:script@csforall.org"}},[_vm._v(" Having trouble? "),_c('b',{staticClass:"ml-1"},[_vm._v(" Contact Us")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-left"},[_c('button',{staticClass:"btn btn-blue mb-10",attrs:{"type":"submit"}},[_vm._v(" Reset my password ")])])
}]

export { render, staticRenderFns }