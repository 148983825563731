import Vue from "vue";
import VeeValidate from "vee-validate";
import Toasted from "vue-toasted";
import AppRoot from "./components/AppRoot.vue";
import MainNav from "./components/MainNav.vue";
import Breadcrumbs from "./components/Breadcrumbs.vue";
import Spinner from "./components/Spinner.vue";
import MultiSelectField from "./components/options/MultiSelectField.vue";
import MultiSelectFieldWide from "./components/options/MultiSelectFieldWide.vue";
import SelectField from "./components/options/SelectField.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import Amplify from "aws-amplify";
import awsconfig from "./aws-exports";
import { refreshTokenAxiosInterceptor } from "./util/refreshTokenAxiosInterceptor";
import { setMasqueradeHeader } from "./util/setMasqueradeHeader";
import "./styles/app.scss";
import {
  toastedGlobalOptions,
  toastedMessageHandler,
  toastedSuccessOptions
} from "@/util/toastedConfig";

Amplify.configure(awsconfig);

window.axios = axios.create({
  baseURL:
    window.location.origin +
    (process.env.VUE_APP_API_PREFIX ? `/${process.env.VUE_APP_API_PREFIX}` : "")
});
window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
window.axios.interceptors.request.use(refreshTokenAxiosInterceptor, e =>
  Promise.reject(e)
);
window.axios.interceptors.request.use(setMasqueradeHeader, e =>
  Promise.reject(e)
);

Vue.config.productionTip = false;

// plugin registration
Vue.use(VeeValidate);
Vue.use(Toasted, toastedGlobalOptions);
Vue.toasted.register("success", toastedMessageHandler, toastedSuccessOptions);

// global components
Vue.component("main-nav", MainNav);
Vue.component("breadcrumbs", Breadcrumbs);
Vue.component("spinner", Spinner);
Vue.component("multi-select-field", MultiSelectField);
Vue.component("multi-select-field-wide", MultiSelectFieldWide);
Vue.component("select-field", SelectField);

new Vue({
  router,
  store,
  render: h => h(AppRoot)
}).$mount("#app");
