import { mapActions } from "vuex";

export default {
  data() {
    return {
      questionnaire: {}
    };
  },
  methods: {
    ...mapActions(["setQuestionnaire", "clearQuestionnaire"])
  },
  created() {
    this.questionnaire = this.$store.getters.getQuestionnaire;
  }
};
