<template>
  <div class="w-full">
    <div class="relative flex md:flex-row justify-between">
      <a href="/home">
        <img
          src="@/assets/img/csforall-logo.svg"
          alt="CSforAll"
          width="150"
          class="p-2 m-2 ml-10 w-25 text-center"
        />
      </a>
      <button
        v-if="hasBackButton"
        type="button"
        class="p-2 m-2 btn btn-blue"
        @click="back"
      >
        Back
      </button>
      <button
        v-if="canCancel"
        type="button"
        class="p-2 m-2 btn btn-red"
        @click="cancel"
      >
        Cancel
      </button>
    </div>
    <div class="flex flex-col">
      <div
        class="flex justify-around"
        :class="{ 'bg-blue-light-2': memberType === MEMBER_TYPES.NON_PUBLIC }"
      >
        <div
          ref="div1"
          class="w-1/3 bg-blue-primary opacity-100"
          :class="{ 'bg-blue-light-2': stages && stages[0] }"
        >
          &nbsp;
        </div>
        <div
          ref="div2"
          class="w-1/3 bg-blue-primary opacity-100"
          :class="{ 'bg-blue-light-2': stages && stages[1] }"
        >
          &nbsp;
        </div>
        <div
          v-if="memberType !== MEMBER_TYPES.NON_PUBLIC"
          ref="div3"
          class="w-1/3 bg-blue-primary opacity-100"
          :class="{ 'bg-blue-light-2': stages && stages[2] }"
        >
          &nbsp;
        </div>
      </div>
      <div class="flex bg-blue-primary justify-around">
        <h2
          ref="h1"
          class="w-1/3 bg-blue-primary text-white text-center mt-5 opacity-50"
        >
          1. Prerequisites
        </h2>
        <h2
          ref="h2"
          class="w-1/3 bg-blue-primary text-white text-center mt-5 opacity-50"
        >
          2. Register
        </h2>
        <h2
          v-if="memberType !== MEMBER_TYPES.NON_PUBLIC"
          ref="h3"
          class="w-1/3 bg-blue-primary text-white text-center mt-5 opacity-50"
        >
          3. Complete Profile
        </h2>
      </div>
    </div>
  </div>
</template>

<script>
import { MEMBER_TYPES } from "@/const";
import { mapActions } from "vuex";

export default {
  props: ["stages", "memberType", "canCancel", "hasBackButton"],
  data() {
    return {
      MEMBER_TYPES: MEMBER_TYPES
    };
  },
  methods: {
    ...mapActions(["clearQuestionnaire"]),
    cancel() {
      this.clearQuestionnaire();

      this.$router.push("/auth/login");
    },
    back() {
      this.$router.go(-1);
    }
  }
};
</script>
