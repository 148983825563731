<template>
  <signup-layout
    v-if="workshop"
    :can-cancel="true"
    :top-form-background-color="'bg-blue-panel'"
  >
    <template #pageTitle>
      You're invited to a Workshop
    </template>

    <template #pageSubtitle>
      To register a new account with CSforALL please fill in the information
      below.
    </template>

    <template #topForm>
      <div class="p-5 rounded text-blue-primary mx-10 mb-10">
        <h2>Workshop: {{ workshop.title }}</h2>
        <br />
        <h4>
          {{ formatDate(workshop.start_time) }} -
          {{ formatDate(workshop.end_time) }}
          {{ workshop.timezone }}
        </h4>
        <br />
        <p>
          Presenter Name: {{ workshop.presenter_first_name }}
          {{ workshop.presenter_last_name }}
        </p>
        <p>Workshop Sponsored by: {{ workshop.event_sponsor_host }}</p>
      </div>
    </template>

    <form
      aria-label="Register"
      @submit.prevent="submit"
      class="w-full h-full flex flex-col justify-center vertical-align"
    >
      <p class="mx-auto text-center max-w-lg text-h3 font-bold">
        Select Your K12 Local Education Association Type From the List
      </p>

      <p class="mx-20 text-h4">
        If you sign up here you will become the Point of Contact (POC) for
        managing your organization's profile and team if you enroll in a SCRIPT
        workshop. If you feel this isn't your role, please request someone else
        from your organization sign up.
      </p>

      <div class="flex flex-col justify-between">
        <div class="flex flex-col self-center">
          <div
            class="inline-flex items-center my-1"
            v-for="(typeInfo, typeKey) in leaTypes"
            :key="typeKey"
          >
            <label
              :for="typeKey"
              class="inline-flex items-center justify-center"
            >
              <input
                class="inline-flex justify-center "
                :id="typeKey"
                type="radio"
                :value="typeInfo.name"
                v-model="questionnaire.leaType"
              />
              <span class="ml-5 mt-1 -mb-1">
                {{ typeInfo.name }}
                <span
                  v-if="typeInfo.description"
                  class="tooltip right"
                  data-toggle="tooltip"
                  :data-text="typeInfo.description"
                >
                  <span
                    class="tooltip-icon bg-grey-medium text-white items-center"
                    >?</span
                  >
                </span>
              </span>
            </label>
          </div>
        </div>
        <p
          :class="{ invisible: !errorNoLeaType }"
          class="mb-2 text-red text-h5 text-center"
        >
          Please select at least one option before proceeding.
        </p>
        <div class="mx-auto w-1/2 text-center">
          <button type="submit" class="btn btn-blue">
            Next
          </button>
        </div>
      </div>
    </form>
  </signup-layout>
</template>

<script>
import SignupLayout from "@/components/signup/SignupLayout";
import signupMixin from "@/components/signup/mixins/signupMixin";
import { LEA_TYPES, ROLES, ORG_TYPES, MEMBER_TYPES } from "@/const";
import { formatWorkshopDate } from "../../util/formatDate";

export default {
  name: "WorkshopPocInvite",
  components: {
    SignupLayout
  },
  mixins: [signupMixin],
  props: {
    inviteId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      leaTypes: {
        individualSchool: {
          name: LEA_TYPES.INDIVIDUAL_SCHOOL
        },
        schoolDistrict: {
          name: LEA_TYPES.SCHOOL_DISTRICT
        },
        regionalEducationAgency: {
          name: LEA_TYPES.REGIONAL_EDUCATION_AGENCY,
          description:
            "A regional education agency is an educational service agency that is authorized by a regional or state level to develop and provide services and programs to local groups, such as school districts."
        },
        otherSchoolNetwork: {
          name: "Other School Networks",
          description:
            "Other school networks include after or out-of-school learning time providers, local education services and organizations, and other informal education providers."
        },
        noneOfThese: {
          name: "None of these"
        }
      },
      errorNoLeaType: false,
      workshop: null
    };
  },
  methods: {
    formatDate(date) {
      return formatWorkshopDate(date);
    },
    async submit() {
      const leaType = this.questionnaire.leaType;

      this.errorNoLeaType = !leaType;

      if (this.errorNoLeaType) {
        return;
      }

      this.clearQuestionnaire();

      if (leaType === this.leaTypes.noneOfThese.name) {
        this.$router.push({ name: "LearnMore" });
        return;
      }

      this.questionnaire.organizationTypes = [ORG_TYPES.EDUCATION_ASSOCIATION];
      this.questionnaire.memberType = MEMBER_TYPES.MEMBER;
      this.questionnaire.role = {
        type: ROLES.LEA_ADMIN,
        displayName: "LEA Administrator"
      };

      this.setQuestionnaire(this.questionnaire);

      this.$router.push({
        name: "WorkshopPocRegister",
        params: { inviteId: this.inviteId }
      });
    },
    async getWorkshopFromInviteId() {
      try {
        const response = await window.axios.get(
          `/api/me/member/workshop/email-invite/${this.inviteId}`
        );

        const { data } = response;

        if (data.notFound) {
          this.$router.push({ name: "WorkshopNotFound" });
        } else if (data.stale) {
          this.$router.push({ name: "WorkshopInviteUsed" });
        } else {
          this.workshop = data.workshop; // TODO: Only need workshop ID returned, not whole object
          this.$store.dispatch("setWorkshop", this.workshop);
        }
      } catch (error) {
        console.log("error getting workshop info");
        console.log(error);
      }
    }
  },
  created() {
    this.workshop = this.$store.getters.workshop;
    if (!this.workshop.id) {
      // only load new workshop if needed - user can return to this page with back btn.
      this.getWorkshopFromInviteId();
    }
  }
};
</script>

<style scoped></style>
