<template>
  <div class="status flex items-center font-bold text-base">
    <span class="w-8 mr-2" v-if="answer && answer.status == 'started'"
      ><img src="@/assets/img/status-started.svg" alt="started"
    /></span>
    <span class="w-8 mr-2" v-else-if="answer && answer.status == 'completed'"
      ><img src="@/assets/img/status-complete.svg" alt="completed"
    /></span>
    <span class="w-8 mr-2" v-else
      ><img src="@/assets/img/status-not-started.svg" alt="not started"
    /></span>
    <router-link
      class="flex-1"
      v-if="allowLink"
      :to="{
        name: 'answers',
        params: {
          id: answer.id
        }
      }"
      >{{ rubric.title }}</router-link
    >
    <span class="flex-1" v-else>{{ rubric.title }}</span>
  </div>
</template>

<script>
export default {
  name: "RubricSummary",
  props: ["answer", "consent", "index", "rubric"],
  computed: {
    allowLink() {
      return this.answer && this.consent != "none";
    }
  }
};
</script>
