<template>
  <div v-if="page === 'goals'">
    <div class="mb-1 ml-6 mb-2 ">
      <h3 class="text-blue-primary">{{ input.header }}</h3>
    </div>
  </div>

  <div v-else>
    <div class="mb-4">
      <h2 class="text-blue-primary">{{ input.header }}</h2>
      <!-- <p class="text-black">{{ this.input.description }}</p> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "SectionInput",
  props: {
    input: Object,
    page: String
  }
};
</script>
