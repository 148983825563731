<template>
  <signup-layout>
    <verify-email-confirmation-code-modal
      v-show="showConfirmEmailModal"
      :email="questionnaire.email"
      :message="
        `We've sent a confirmation code to ${questionnaire.email}. Please
              re-enter it here to confirm your account.`
      "
      @confirmationComplete="emailConfirmationComplete"
    ></verify-email-confirmation-code-modal>

    <template #pageTitle>
      Register
    </template>

    <template #pageSubtitle>
      <p>
        To register a new account with CSforALL please fill in the information
        below.
      </p>
      <p>You will be able to create a profile once registered.</p>
    </template>

    <form
      aria-label="Register"
      @submit.prevent="submit"
      class=" lg:w-3/5 sm:w-4/5 mx-auto flex flex-col text-left justify-center vertical-align"
    >
      <spinner v-show="isSyncing"></spinner>

      <div v-if="isNotGeneralRole" class="mb-6">
        <label for="role">Role</label>

        <input id="role" type="text" disabled :value="roleName" />
        <div class="mt-4"></div>
      </div>

      <div
        :class="{
          'my-6': true,
          'has-error': errors.has('first name')
        }"
      >
        <label for="first-name">First Name</label>

        <input
          id="first-name"
          type="text"
          name="first name"
          v-model.trim="questionnaire.firstName"
          v-validate="'required'"
        />
        <div class="mt-4">
          <span
            v-show="errors.has('first name')"
            class="text-xs italic text-red"
            >{{ errors.first("first name") }}</span
          >
        </div>
      </div>

      <div
        :class="{
          'mb-6': true,
          'has-error': errors.has('last name')
        }"
      >
        <label for="last-name">Last Name</label>

        <input
          id="last-name"
          type="text"
          name="last name"
          v-model.trim="questionnaire.lastName"
          v-validate="'required'"
        />
        <div class="mt-4">
          <span
            v-show="errors.has('last name')"
            class="text-xs italic text-red"
            >{{ errors.first("last name") }}</span
          >
        </div>
      </div>

      <div
        :class="{
          'mb-6': true,
          'has-error': errors.has('email')
        }"
      >
        <label for="email">Email</label>

        <input
          id="email"
          type="email"
          name="email"
          v-model.trim="questionnaire.email"
          v-validate="'required'"
        />
        <div class="mt-4">
          <span v-show="errors.has('email')" class="text-xs italic text-red">{{
            errors.first("email")
          }}</span>
        </div>
      </div>

      <div
        v-bind:class="{
          'mb-6': true,
          'has-error': errors.has('password')
        }"
      >
        <label for="password">Password</label>
        <input
          id="password"
          v-model.trim="questionnaire.password"
          type="password"
          v-validate="`required|min:${VALIDATION.MIN_PW_LEN}`"
          class="form-control"
          name="password"
          ref="password"
        />
        <div class="mt-4">
          <span
            v-show="errors.has('password')"
            class="text-xs italic text-red"
            >{{ errors.first("password") }}</span
          >
        </div>
      </div>

      <div
        :class="{
          'mb-6': true,
          'has-error': errors.has('password confirmation')
        }"
      >
        <label for="password-confirm">Confirm Password</label>

        <input
          id="password-confirm"
          v-validate="
            `required|confirmed:password|min:${VALIDATION.MIN_PW_LEN}`
          "
          type="password"
          class="form-control"
          name="password confirmation"
        />
        <div class="mt-4">
          <span
            v-show="errors.has('password confirmation')"
            class="text-xs italic text-red"
            >{{ errors.first("password confirmation") }}</span
          >
        </div>
      </div>

      <div class="my-5 flex text-center w-1/2 self-center justify-between">
        <router-link
          type="button"
          class="btn btn-blue"
          :to="{ name: 'user-pre-req' }"
        >
          Back
        </router-link>
        <button type="submit" class="btn btn-blue">
          Submit
        </button>
      </div>
    </form>
  </signup-layout>
</template>
<style scoped>
.vertical-align {
  align-self: center;
}
</style>

<script>
import "vue-select/dist/vue-select.css";
import SignupLayout from "@/components/signup/SignupLayout";
import { ROLES, VALIDATION } from "@/const";
import signupMixin from "@/components/signup/mixins/signupMixin";
import VerifyEmailConfirmationCode from "../modals/VerifyEmailConfirmationCode";
import registerMixin from "@/components/signup/mixins/registerMixin";

export default {
  name: "Register",
  components: {
    SignupLayout,
    "verify-email-confirmation-code-modal": VerifyEmailConfirmationCode
  },
  mixins: [signupMixin, registerMixin],
  data() {
    return {
      VALIDATION
    };
  },
  computed: {
    isNotGeneralRole() {
      return this.questionnaire.role?.type !== ROLES.GENERAL;
    }
  }
};
</script>
