<template>
  <div class="relative">
    <info-modal
      v-show="showInfoModal"
      title=""
      body="Your curriculum is missing some required fields."
      actionButton="Okay"
      actionButtonColor="btn-blue"
      @confirm="showInfoModal = false"
    ></info-modal>

    <div class="container mx-auto pb-24">
      <breadcrumbs :breadcrumbs="crumbs"></breadcrumbs>

      <h1>Edit Curriculum</h1>

      <div class="mt-8 lg:mt-16 divider">
        <div class="w-full lg:w-3/4 pb-16">
          <label class="text-h3 font-bold" for="curriculum_name"
            >Curriculum Name <span class="text-red">*</span></label
          >
          <input type="text" class="my-4" v-model="name" id="curriculum_name" />
          <span v-show="hasNameError" class="text-red text-xs italic"
            >Name is requied</span
          >
        </div>
      </div>

      <div class="lg:mt-16 divider">
        <div class="w-full lg:w-3/4 pb-16">
          <label class="text-h3 font-bold mb-4" for="teaser"
            >Teaser <span class="text-red">*</span></label
          >
          <p class="leading-loose mb-6 text-h4">
            This will show up on your curriculum card in the curriculum
            directory. It will appear the same as in your member card. It should
            provide a brief overview of your curriculum.
          </p>
          <textarea
            id="teaser"
            rows="4"
            class="rounded w-full border-2 mb-2 p-4 leading-loose"
            v-model="teaserText"
            maxlength="140"
          ></textarea>
          {{ teaserText.length }} of 140 characters
          <div class="my-4">
            <span v-show="hasTeaserError" class="text-red text-xs italic"
              >Teaser is required</span
            >
          </div>
        </div>
      </div>

      <div class="lg:mt-16 divider">
        <div class="w-full lg:w-3/4 pb-12">
          <label class="text-h3 font-bold mb-4"
            >Description <span class="text-red">*</span></label
          >
          <p class="leading-loose mb-6 text-h4">
            This will be shown on your curriculum page. Its a more detailed
            description of what your curriculum is.
          </p>
          <vue-editor
            v-model="descriptionText"
            :editorToolbar="descriptionToolbar"
          ></vue-editor>
          <div
            v-show="hasDescriptionError"
            class="text-red text-xs italic mt-2"
          >
            Description is required.
          </div>
        </div>
      </div>

      <div class="lg:mt-16 divider">
        <div class="w-full lg:w-3/4 pb-16">
          <label class="text-h3 font-bold" for="url">URL</label>
          <p class="leading-loose mb-6 text-h4">
            Enter a fully qualified url to a page where a visitor can learn more
            about this curriculum.
          </p>
          <input
            type="text"
            class="my-4"
            placeholder="http://example.com"
            id="url"
            v-model="url"
            name="profile url"
            v-validate="{
              required: false,
              url: { require_protocol: true, protocols: ['http', 'https'] }
            }"
          />
          <div class="my-4">
            <span
              v-show="errors.has('profile url')"
              class="text-xs italic text-red"
              >{{ errors.first("profile url") }}</span
            >
          </div>
        </div>
      </div>

      <div class="lg:mt-16 divider">
        <div class="w-full lg:w-3/4 pb-12">
          <label class="text-h3 font-bold mb-4"
            >Cost <span class="text-red">*</span></label
          >
          <div class="flex mb-2">
            <div class="w-full items-center">
              <label for="is_free">
                <input
                  id="is_free"
                  type="radio"
                  name="cost_level"
                  value="0"
                  v-model="cost_level"
                />
                <span class="ml-2 font-normal cursor-pointer">Free</span>
              </label>
            </div>
          </div>
          <div class="flex mb-2">
            <div class="w-full items-center">
              <label for="is_paid">
                <input
                  id="is_paid"
                  type="radio"
                  name="cost_level"
                  value="1"
                  v-model="cost_level"
                />
                <span class="ml-2 font-normal cursor-pointer">Paid</span>
              </label>
            </div>
          </div>
          <div class="flex">
            <div class="w-full items-center">
              <label for="some_cost">
                <input
                  id="some_cost"
                  type="radio"
                  name="cost_level"
                  value="2"
                  v-model="cost_level"
                />
                <span class="ml-2 font-normal cursor-pointer"
                  >Some Paid Content</span
                >
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="lg:mt-16 divider">
        <div class="w-full lg:w-3/4 pb-12">
          <label class="text-h3 font-bold mb-4"
            >Publish to Curriculum Directory?</label
          >
          <div class="select-box">
            <label for="publish">
              <input
                id="publish"
                type="checkbox"
                name="publish"
                value="1"
                v-model="publish"
              />
              <span class="check inline-block mr-2"></span>
              Yes
            </label>
          </div>
        </div>
      </div>

      <div class="lg:mt-16 divider">
        <div class="w-full lg:w-3/4 pb-12">
          <label class="text-h3 font-bold mb-4"
            >Grades <span class="text-red">*</span></label
          >
          <div v-show="hasGradeError" class="text-red text-xs italic">
            You must select at least one grade band.
          </div>
          <div v-for="(grade, index) in allGrades" v-bind:key="index">
            <div class="select-box">
              <label :for="'grade' + index">
                <input
                  :id="'grade' + index"
                  type="checkbox"
                  :value="grade"
                  v-model="grade_bands"
                />
                <span class="check inline-block mr-2"></span>
                {{ grade }}
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="flex mt-5">
        <div class="w-full mt-4 flex flex-col md:flex-row">
          <div>
            <router-link
              class="btn bg-grey-lighter text-grey-darkest no-underline hover:bg-grey mb-2"
              :to="returnPath"
              >Cancel</router-link
            >
            <button
              v-bind:class="{
                'opacity-50 cursor-not-allowed': this.isSaving ? true : false,
                'btn btn-blue mb-2 ml-4': true
              }"
              @click="onSave"
            >
              Save
            </button>
            <button
              v-show="!isAdminMode"
              v-bind:class="{
                'opacity-50 cursor-not-allowed': this.isSaving ? true : false,
                'btn btn-blue ml-4 mb-2': true
              }"
              @click="onSaveAndAlign"
            >
              Save &amp; Align to Standards
            </button>
            <label
              v-show="!isAdminMode"
              class="text-right text-md text-blue-primary font-light text-center mt-2 cursor-pointer hover:underline"
              for="tip"
            >
              What is this?
            </label>
          </div>
          <div class="flex-1">
            <input class="hidden" type="checkbox" id="tip" />
            <div
              class="ml-5 md:max-w-sm bg-blue-panel p-5 invisible tip-panel rounded relative"
            >
              <label
                class="absolute pin-r pin-t mt-3 mr-3 text-blue-primary cursor-pointer"
                for="tip"
                >X</label
              >
              <h3 class="text-blue-primary font-semibold text-md mb-2">
                Align to Standards
              </h3>
              <p class="text-md leading-loose">
                Align your curriculum to CS standards for review by our team.
                Once approved your curriculum will be get our standards
                certification badge and rank higher in our directory search.
              </p>
            </div>
          </div>
        </div>
      </div>

      <spinner v-show="isSaving ? true : false"></spinner>
    </div>
    <div class="blue-bar"></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { VueEditor } from "vue2-editor";
import InfoModal from "../modals/InfoModal";

export default {
  components: {
    VueEditor,
    "info-modal": InfoModal
  },
  data() {
    return {
      teaserToolbar: [["bold", "italic"]],
      descriptionToolbar: [
        ["bold", "italic", "link"],
        [{ list: "ordered" }, { list: "bullet" }]
      ],
      allGrades: [
        "K",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12"
      ],
      isSaving: false,
      uuid: "",
      name: "",
      teaserText: "",
      descriptionText: "",
      url: "",
      cost_level: 1,
      publish: 0,
      grade_bands: [],
      hasNameError: false,
      hasTeaserError: false,
      hasGradeError: false,
      hasDescriptionError: false,
      member: {},
      showInfoModal: false
    };
  },
  methods: {
    async onSave() {
      const validatorResult = await this.$validator.validateAll();

      this.hasNameError = false;
      this.hasTeaserError = false;
      this.hasGradeError = false;
      this.hasDescriptionError = false;

      if (!this.name.length) {
        this.hasNameError = true;
      }
      if (!this.teaserText.length) {
        this.hasTeaserError = true;
      }
      if (!this.grade_bands.length) {
        this.hasGradeError = true;
      }
      if (!this.descriptionText.length) {
        this.hasDescriptionError = true;
      }

      if (
        !validatorResult ||
        this.hasDescriptionError ||
        this.hasGradeError ||
        this.hasTeaserError ||
        this.hasNameError
      ) {
        this.showInfoModal = true;
        return;
      }

      this.isSaving = true;

      let curriculum = {
        name: this.name,
        teaser: this.teaserText,
        description: this.descriptionText,
        url: this.url,
        cost_level: this.cost_level <= 2 ? this.cost_level : 0,
        publish_to_directory: this.publish ? 1 : 0,
        grade_bands: this.grade_bands
      };

      return this.upsertCurriculum(curriculum);
    },

    onSaveAndAlign() {
      this.onSave().then(() => {
        this.$router.push("/home/curriculum/" + this.uuid + "/align");
      });
    },

    setCurriculum(item) {
      this.uuid = item.uuid;
      this.name = item.name;
      this.teaserText = item.teaser;
      this.descriptionText = item.description;
      this.url = item.url;
      this.cost_level = item.cost_level;
      this.publish = item.publish_to_directory ? 1 : 0;
      this.grade_bands = item.grade_bands;
    },

    loadCurriculum() {
      if (this.$route.params.token) {
        window.axios.get(this.upsertCurriculumPath).then(resp => {
          this.setCurriculum(resp.data.data[0]);
        });
      } else {
        this.member = this.user.member;
      }
    },
    upsertCurriculum: function(curriculum) {
      return this.upserter(curriculum).then(resp => {
        this.isSaving = false;
        this.$toasted.show("Curriculum updated");

        const curriculumData = resp.data.data[0];
        this.setCurriculum(curriculumData);

        const showPath = `${this.showCurriculumPath}/${curriculumData.uuid}`;
        this.$router.push(showPath);
      });
    },
    upserter: function(curriculum) {
      const action = this.$route.params.token ? "put" : "post";
      return window.axios[action](this.upsertCurriculumPath, curriculum);
    }
  },
  computed: {
    ...mapGetters({
      user: "user",
      isLoggedIn: "isLoggedIn",
      alignments: "alignments"
    }),
    showCurriculumPath: function() {
      if (this.isAdminMode) {
        return "/home/certify";
      } else {
        return "/home/curriculum";
      }
    },
    upsertCurriculumPath: function() {
      let path;

      if (this.isAdminMode) {
        path = "/api/user/curriculum";
      } else {
        path = "/api/me/curriculum";
      }

      const token = this.$route.params.token;

      if (token) {
        path = `${path}/${token}`;
      }

      return path;
    },
    returnPath: function() {
      const token = this.$route.params.token;

      if (token) {
        return `${this.showCurriculumPath}/${token}`;
      } else {
        return "/home";
      }
    },
    isAdminMode: function() {
      return this.$route.name == "curricula-edit-admin";
    },
    crumbs: function() {
      if (this.isAdminMode) {
        return [
          {
            link: "/admin/profile/" + (this.member ? this.member.uuid : ""),
            label: this.member ? this.member.name : ""
          },
          {
            link: false,
            label: this.name ? this.name : "Edit Curriculum"
          }
        ];
      } else {
        return [
          {
            link: "/home",
            label: this.member ? this.member.name : ""
          },
          {
            link: false,
            label: this.name ? this.name : "Edit Curriculum"
          }
        ];
      }
    }
  },
  created() {
    if (!this.isAdminMode) {
      this.$store.dispatch("setActiveNav", "profile");
    } else {
      this.$store.dispatch("setActiveNav", "certify");
    }
  },
  mounted() {
    window.axios.get("/api/alignment").then(resp => {
      this.$store.dispatch("setAlignments", resp.data.data);
      this.loadCurriculum();
    });
  }
};
</script>
