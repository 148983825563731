<template>
  <signup-layout>
    <verify-email-confirmation-code-modal
      v-show="showConfirmEmailModal"
      :email="questionnaire.email"
      :message="
        `We've sent a confirmation code to ${questionnaire.email}. Please
              re-enter it here to confirm your account.`
      "
      @confirmationComplete="emailConfirmationComplete"
      @hasQueryParam="hasQueryParam"
    ></verify-email-confirmation-code-modal>

    <template #pageTitle>
      Register
    </template>

    <template #pageSubtitle>
      To register a new account with CSforALL please fill in the information.
    </template>

    <template #topForm>
      <form
        aria-label="Register"
        class=" lg:w-3/5 sm:w-4/5 mx-auto flex flex-col text-left justify-center vertical-align"
      >
        <spinner v-show="isSyncing"></spinner>

        <p class="mx-auto text-center max-w-lg text-h3 font-bold">
          Your Organization
        </p>
        <p class="mx-auto text-center max-w-lg text-h4">
          Tell us about your organization
        </p>
        <div
          class="mb-6 mt-5"
          :class="{ 'has-error': errors.has('organization') }"
        >
          <label for="organization">Organization</label>

          <input
            id="organization"
            type="text"
            name="organization"
            v-model.trim="questionnaire.organization"
            v-validate="'required'"
          />
          <div class="mt-4">
            <span
              v-show="errors.has('organization')"
              class="text-xs italic text-red"
              >{{ errors.first("organization") }}</span
            >
          </div>
        </div>

        <div class="mb-6">
          <label for="organization-type">Organization Type</label>

          <input
            id="organization-type"
            type="text"
            name="organization-type"
            disabled
            :value="displayOrgTypes"
          />
          <div class="mt-4"></div>
        </div>

        <div v-if="isLeaSignup">
          <label for="lea-type">LEA Type</label>

          <input
            id="lea-type"
            type="text"
            name="lea-type"
            disabled
            :value="questionnaire.leaType"
          />
        </div>
      </form>
    </template>

    <form
      aria-label="Register"
      @submit.prevent="submit"
      class=" lg:w-3/5 sm:w-4/5 mx-auto flex flex-col text-left justify-center vertical-align"
    >
      <div class="pt-5">
        <p class=" mx-auto text-center max-w-lg text-h3 font-bold">
          Your Information
        </p>
        <p class="mx-auto text-center max-w-lg text-h4">
          Tell us about yourself
        </p>
      </div>
      <div class="my-6" :class="{ 'has-error': errors.has('first name') }">
        <label for="first-name">First Name</label>

        <input
          id="first-name"
          type="text"
          name="first name"
          v-model.trim="questionnaire.firstName"
          v-validate="'required'"
        />
        <div class="mt-4">
          <span
            v-show="errors.has('first name')"
            class="text-xs italic text-red"
            >{{ errors.first("first name") }}</span
          >
        </div>
      </div>

      <div class="mb-6" :class="{ 'has-error': errors.has('last name') }">
        <label for="last-name">Last Name</label>

        <input
          id="last-name"
          type="text"
          name="last name"
          v-model.trim="questionnaire.lastName"
          v-validate="'required'"
        />
        <div class="mt-4">
          <span
            v-show="errors.has('last name')"
            class="text-xs italic text-red"
            >{{ errors.first("last name") }}</span
          >
        </div>
      </div>

      <div v-if="isLeaSignup">
        <div class="mb-6">
          <label for="role">Your Role</label>

          <input id="role" type="text" disabled :value="roleName" />
          <div class="mt-4"></div>
        </div>

        <div v-if="!isSchool" class="mb-6">
          <label for="schoolName">Your School (If Applicable)</label>
          <input
            id="schoolName"
            name="schoolName"
            v-model.trim="questionnaire.schoolName"
          />
          <div class="mt-4"></div>
        </div>

        <div v-if="!isDistrict" class="mb-6">
          <label for="schoolDistrict"
            >Your School District (If Applicable)</label
          >
          <input
            id="schoolDistrict"
            name="schoolDistrict"
            v-model.trim="questionnaire.schoolDistrict"
          />
          <div class="mt-4"></div>
        </div>
      </div>

      <div class="mb-6" :class="{ 'has-error': errors.has('gender') }">
        <label for="genderOption">Gender</label>
        <v-select
          id="genderOption"
          name="gender"
          :options="genderOptions"
          v-model="questionnaire.gender"
          v-validate="'required'"
        >
        </v-select>

        <div class="mt-4">
          <span v-show="errors.has('gender')" class="text-xs italic text-red">
            {{ errors.first("gender") }}
          </span>
        </div>

        <input
          v-if="questionnaire.gender === 'Other'"
          placeholder="Please specify."
          id="genderOption"
          name="otherGender"
          type="text"
          v-model="questionnaire.other_gender"
          v-validate="'required'"
          class="mt-4"
        />

        <div class="mt-4">
          <span
            v-show="errors.has('otherGender')"
            class="text-xs italic text-red"
          >
            {{ errors.first("otherGender") }}
          </span>
        </div>
      </div>

      <div
        class="mb-6"
        :class="{ 'has-error': errors.has('ethnicityCultural') }"
      >
        <label for="ethnicityCulturalOpt">
          Ethnicity or Cultural Background
        </label>
        <v-select
          id="ethnicityCulturalOpt"
          name="ethnicityCultural"
          :options="ethnicity_cultural_bg"
          v-model="questionnaire.ethnicity_cultural_background"
          v-validate="'required'"
        >
        </v-select>

        <div class="mt-4">
          <span
            v-show="errors.has('ethnicityCultural')"
            class="text-xs italic text-red"
          >
            {{ errors.first("ethnicityCultural") }}
          </span>
        </div>

        <input
          v-if="questionnaire.ethnicity_cultural_background === 'Other'"
          placeholder="Please specify."
          id="ethnicityCulturalOpt"
          name="otherEthnicityCulturalOpt"
          type="text"
          v-model="questionnaire.other_ethnicity_cultural_background"
          v-validate="'required'"
          class="mt-4"
        />

        <div class="mt-4">
          <span
            v-show="errors.has('otherEthnicityCulturalOpt')"
            class="text-xs italic text-red"
          >
            {{ errors.first("otherEthnicityCulturalOpt") }}
          </span>
        </div>
      </div>

      <div class="mb-6" :class="{ 'has-error': errors.has('title') }">
        <label for="title">Title</label>

        <input
          id="title"
          name="title"
          v-model.trim="questionnaire.title"
          v-validate="'required'"
        />
        <div class="mt-4">
          <span v-show="errors.has('title')" class="text-xs italic text-red">{{
            errors.first("title")
          }}</span>
        </div>
      </div>

      <div
        v-if="isLeaSignup"
        class="mb-6"
        :class="{ 'has-error': errors.has('institutional role') }"
      >
        <label for="institutionalRole">Institutional Role</label>
        <v-select
          id="institutionalRole"
          name="institutional role"
          :options="institutionalRoles"
          v-model="questionnaire.institutionalRole"
          v-validate="'required'"
        >
        </v-select>

        <div class="mt-4">
          <span
            v-show="errors.has('institutional role')"
            class="text-xs italic text-red"
            >{{ errors.first("institutional role") }}</span
          >
        </div>

        <input
          v-if="questionnaire.institutionalRole === 'Other'"
          placeholder="Please provide an institution role explanation."
          id="institution-role"
          name="institution role"
          type="text"
          v-model="questionnaire.otherInstitutionRole"
          v-validate="'required'"
          class="mt-4"
        />

        <div class="mt-4">
          <span
            v-show="errors.has('institution role')"
            class="text-xs italic text-red"
            >{{ errors.first("institution role") }}</span
          >
        </div>
      </div>

      <p class="mx-auto text-center max-w-lg text-h3 font-bold">
        Create Account
      </p>
      <p class="max-w-lg text-h4">
        Email entered will be the account username. We recommend using an email
        that will be accessible to all who will need access to the
        organization's account.
      </p>

      <div class="mb-6 mt-5" :class="{ 'has-error': errors.has('email') }">
        <label for="email">Email</label>

        <input
          id="email"
          type="email"
          name="email"
          v-model.trim="questionnaire.email"
          v-validate="'required'"
        />
        <div class="mt-4">
          <span v-show="errors.has('email')" class="text-xs italic text-red">
            {{ errors.first("email") }}
          </span>
        </div>
      </div>

      <div class="mb-6" :class="{ 'has-error': errors.has('password') }">
        <label for="password">Password</label>
        <input
          id="password"
          v-model.trim="questionnaire.password"
          type="password"
          v-validate="`required|min:${VALIDATION.MIN_PW_LEN}`"
          class="form-control"
          name="password"
          ref="password"
        />
        <div class="mt-4">
          <span
            v-show="errors.has('password')"
            class="text-xs italic text-red"
            >{{ errors.first("password") }}</span
          >
        </div>
      </div>

      <div
        class="mb-6"
        :class="{ 'has-error': errors.has('password confirmation') }"
      >
        <label for="password-confirm">Confirm Password</label>

        <input
          id="password-confirm"
          v-validate="
            `required|confirmed:password|min:${VALIDATION.MIN_PW_LEN}`
          "
          type="password"
          class="form-control"
          name="password confirmation"
        />
        <div class="mt-4">
          <span
            v-show="errors.has('password confirmation')"
            class="text-xs italic text-red"
            >{{ errors.first("password confirmation") }}</span
          >
        </div>
      </div>

      <div class="my-5 flex w-1/2 self-center justify-between text-center">
        <router-link type="button" class="btn btn-blue" :to="previousComponent">
          Back
        </router-link>
        <button type="submit" class="btn btn-blue">
          Submit
        </button>
      </div>
    </form>
  </signup-layout>
</template>
<style scoped>
.vertical-align {
  align-self: center;
}
</style>

<script>
import "vue-select/dist/vue-select.css";
import vSelect from "vue-select";
import SignupLayout from "@/components/signup/SignupLayout";
import {
  LEA_TYPES,
  PARTICIPANT_ROLES,
  VALIDATION,
  GENDER,
  ETHNICITY_CULTURAL_BACKGROUNDS
} from "@/const";
import signupMixin from "@/components/signup/mixins/signupMixin";
import VerifyEmailConfirmationCode from "@/components/modals/VerifyEmailConfirmationCode";
import registerMixin from "@/components/signup/mixins/registerMixin";

export default {
  name: "RegisterOrganization",
  components: {
    SignupLayout,
    "verify-email-confirmation-code-modal": VerifyEmailConfirmationCode,
    vSelect
  },
  mixins: [signupMixin, registerMixin],
  props: {
    inviteId: {
      type: String,
      default: null
    },
    isLeaSignup: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      genderOptions: Object.values(GENDER),
      institutionalRoles: Object.values(PARTICIPANT_ROLES),
      ethnicity_cultural_bg: Object.values(ETHNICITY_CULTURAL_BACKGROUNDS),
      VALIDATION
    };
  },
  computed: {
    queryUrlExist() {
      return Object.keys(this.$route.query).length !== 0;
    },
    previousComponent() {
      if (this.isLeaSignup) {
        if (this.queryUrlExist) {
          return {
            name: "SelectLeaType",
            query: this.$route.query
          };
        } else {
          return {
            name: "SelectLeaType"
          };
        }
      } else {
        if (this.queryUrlExist) {
          return {
            name: "SelectOrganizationTypes",
            query: this.$route.query
          };
        } else {
          return {
            name: "SelectOrganizationTypes"
          };
        }
      }
    },
    displayOrgTypes() {
      return this.questionnaire.organizationTypes?.join(", ");
    },
    isDistrict() {
      return this.questionnaire.leaType === LEA_TYPES.SCHOOL_DISTRICT;
    },
    isSchool() {
      return this.questionnaire.leaType === LEA_TYPES.INDIVIDUAL_SCHOOL;
    }
  },
  created() {
    // TODO: Remove when implement fetching and storing workshop in store.
    this.workshop = this.$store.getters.workshop;

    if (this.isWorkshop && !this.workshop.id) {
      this.getWorkshopFromInviteId();
    }
  }
};
</script>
