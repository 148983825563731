<template>
  <signup-layout>
    <template #pageTitle>
      Account Creation
    </template>

    <div class="text-left text-21 mx-auto mx-2 max-w-lg">
      <form
        aria-label="Select Organization Types"
        class="w-full h-full flex flex-col text-center justify-center vertical-align p-10"
        @submit.prevent="submit"
      >
        <p class="mx-auto text-center max-w-lg text-h3 font-bold">
          Otherwise, is your organization one of these types? Check all that
          apply.
        </p>
        <div class="h-full flex flex-col justify-between mt-6">
          <div class="flex flex-col self-center">
            <div
              class="inline-flex items-center my-1"
              v-for="(typeInfo, typeKey) in orgTypes"
              :key="typeKey"
            >
              <label
                :for="typeKey"
                class="inline-flex items-center justify-center"
              >
                <input
                  class="inline-flex justify-center "
                  :id="typeKey"
                  type="checkbox"
                  :value="typeInfo.value"
                  v-model="selectedOrgTypes"
                />
                <span class="ml-5 mt-1 -mb-1">
                  {{ typeInfo.name }}
                  <span
                    class="tooltip right"
                    data-toggle="tooltip"
                    :data-text="typeInfo.description"
                  >
                    <span
                      class="tooltip-icon bg-grey-medium text-white items-center"
                      >?</span
                    >
                  </span>
                </span>
              </label>
            </div>
          </div>
          <div class="h-4 w-full"></div>
          <div class="flex w-1/2 self-center justify-between text-center my-5">
            <router-link
              type="button"
              class="btn btn-blue"
              :to="{ name: 'OrgSelectOrganization' }"
            >
              Back
            </router-link>
            <button type="submit" class="btn btn-blue">
              Next
            </button>
          </div>
        </div>
      </form>
    </div>
  </signup-layout>
</template>
<style scoped></style>

<script>
import "vue-select/dist/vue-select.css";
import SignupLayout from "@/components/signup/SignupLayout";
import { ORG_TYPES } from "@/const";
import signupMixin from "@/components/signup/mixins/signupMixin";

export default {
  name: "SelectOrganizationTypes",
  components: {
    SignupLayout
  },
  mixins: [signupMixin],
  data() {
    return {
      selectedOrgTypes: [],
      orgTypes: {
        contentProvider: {
          name: ORG_TYPES.CONTENT_PROVIDER,
          value: ORG_TYPES.CONTENT_PROVIDER,
          description:
            "An organization which provides content for K12 Computer Science of STEM education. Content includes curriculum, members can publish and align curricula in the CSforALL curriculum directory"
        },
        outOfSchoolProvider: {
          name: ORG_TYPES.OUT_OF_SCHOOL_PROVIDER,
          value: ORG_TYPES.OUT_OF_SCHOOL_PROVIDER,
          description:
            "OST providers create opportunities for interest driven real-world learning, problem-solving, creativity, and experimentation to nurture the passion for and interest in computer science in our youth. "
        },
        serviceProvider: {
          name: ORG_TYPES.SERVICE_PROVIDER,
          value: ORG_TYPES.SERVICE_PROVIDER,
          description:
            " An organization which provides services such as classes, events,  workshops, or other programming involving K12 Computer Science or STEM education."
        },
        teacherPreparationPrograms: {
          name: ORG_TYPES.TEACHER_PREPARATION_PROGRAMS,
          value: ORG_TYPES.TEACHER_PREPARATION_PROGRAMS,
          description:
            "Teacher Preparation Program members are post secondary institutions that offer one or more courses designed to educate pre-service or inservice teachers in CS education. TPPs should be accredited institutions and provide credits or certificates for teacher learners who complete the coursework."
        },
        funder: {
          name: ORG_TYPES.FUNDER,
          value: ORG_TYPES.FUNDER,
          description:
            "Any organization which provides funding to other institutions or organizations involved in the CSed ecosystem. Funders play a critical role in building the ecosystem through investments of financial support as well as knowledge, volunteer time, and other forms of in-kind support. "
        },
        educationAssociation: {
          name: `${ORG_TYPES.EDUCATION_ASSOCIATION} (Non-K12)`,
          value: ORG_TYPES.EDUCATION_ASSOCIATION,
          description:
            "Includes colleges, universities, school districts, or other local education organizations involved in K-12 CSed research, outreach, programming, or training. School districts, private, or charter schools with K-12 computer science education initiatives or  individual schools involved with SCRIPT or JROTC are also included."
        },
        nationalNetworkandAlliances: {
          name: ORG_TYPES.NATIONAL_NETWORK_AND_ALLIANCES,
          value: ORG_TYPES.NATIONAL_NETWORK_AND_ALLIANCES,
          description: "TBD"
        }
      }
    };
  },
  methods: {
    submit() {
      this.questionnaire = {
        ...this.questionnaire,
        organizationTypes: this.selectedOrgTypes
      };

      this.setQuestionnaire(this.questionnaire);
      this.$router.push({ name: "OrgRegisterOrganization" });
    }
  },
  created() {
    if (this.questionnaire.organizationTypes?.length) {
      this.selectedOrgTypes = this.questionnaire.organizationTypes;
    }
  }
};
</script>
