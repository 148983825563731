<template>
  <div class="h-full">
    <confirm-modal
      v-show="confirmDeregister"
      title=""
      :body="`Are you sure you want to Un-Enroll ${participant.member.name}?`"
      actionButton="Yes! Un-Enroll"
      cancelButton="No. Cancel."
      actionButtonColor="btn-blue"
      cancelButtonColor="btn-red"
      @cancel="confirmDeregister = false"
      @confirm="$emit('deregister', participant.pivot.id)"
    ></confirm-modal>

    <div
      class="flex flex-col bg-blue-panel text-blue-primary rounded-lg relative h-full"
    >
      <div class="border-b border-white p-5">
        <h2 class="mb-1">{{ participant.member.name }}</h2>
        <button
          class="underline text-sm text-blue-primary font-bold"
          @click="confirmDeregister = true"
        >
          Un-Enroll
        </button>
      </div>
      <ul class="p-5 list-reset">
        <li v-for="(rubric, index) in rubrics" v-bind:key="index">
          <rubric-summary
            :answer="answer(rubric.id)"
            :consent="participant.pivot.consent"
            :index="index"
            :rubric="rubric"
          >
          </rubric-summary>
        </li>
      </ul>
      <div class="p-5 flex-1 flex flex-col justify-end">
        <div class="bg-white rounded">
          <div class="flex border-b p-4 items-center">
            <span class="flex-1 text-base font-bold">Survey Responses</span>
            <span class="w-10 justify-center flex"
              ><span class="circle-number bg-red text-white font-bold">{{
                evaluations.length
              }}</span></span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import ConfirmModal from "../modals/ConfirmModal.vue";
import RubricSummary from "./RubricSummary.vue";

export default {
  name: "TeamCard",
  components: {
    "confirm-modal": ConfirmModal,
    "rubric-summary": RubricSummary
  },
  props: ["participant", "rubrics", "evaluation", "answers"],
  data: function() {
    return {
      confirmDeregister: false
    };
  },
  computed: {
    evaluations() {
      return _.filter(this.answers, {
        form_id: this.evaluation.id
      });
    }
  },
  methods: {
    answer(id) {
      return _.find(this.answers, { form_id: id });
    },
    consentIcon(consent) {
      const table = {
        view: "icon-eye",
        publish: "icon-data",
        none: "icon-lock"
      };

      return table[consent];
    }
  }
};
</script>
