<template>
  <div class="relative">
    <div class="container mx-auto pb-24">
      <spinner v-if="!user"></spinner>
      <div v-else-if="!user.is_facilitator">
        <h2 class="text-center">Not authorized</h2>
      </div>
      <div v-else>
        <h1 class="mb-5">{{ title }}</h1>
        <div class="flex">
          <p>
            This page will generate the public event description. If you wish to
            communicate any private information about the event with CSforALL
            please email us at
            <a id="email-copy" v-on:click="copyToClipboard()"
              >script@csforall.org</a
            >.
          </p>
          <div class="image-container">
            <img
              v-show="user.member && user.member.logo_url"
              v-bind:src="user.member.logo_url"
              alt="logo"
            />
          </div>
        </div>
        <div class="mt-12 pt-5 border-t workshop-title">
          <div class="w-full pb-4 mt-6 flex flex-wrap">
            <label class="w-full text-h3 font-bold pl-0"
              >Workshop Title <span class="text-red">*</span></label
            >
            <p class="w-full leading-loose text-h4">
              Please use the following naming convention:
            </p>
            <p class="w-full leading-loose text-h4 mt-1">
              [Sponsor/Host/or Single District Participant] [State] “SCRIPT
              Workshop” - [Starting Month & Year]
            </p>
          </div>

          <div class="w-full pb-4 mt-6 flex flex-wrap">
            <div class="w-full lg:w-1/5 lg:pr-5 mb-3 lg:mb-0">
              <label class="text-h3 font-bold pl-0" for="funder-type">
                Funder Type
              </label>
              <input
                type="text"
                name="funder-type"
                v-model="formData.title_funder"
              />
            </div>

            <div class="w-full lg:w-1/5 lg:pr-5 mb-3 lg:mb-0">
              <label
                class="text-h3 font-bold pl-0"
                for="states-territories-title"
              >
                State or Territory<span class="text-red">*</span>
              </label>
              <v-select
                id="states-territories-title"
                name="Workshop title state or territory"
                label="name"
                :options="statesAndTerritories"
                :reduce="state => state.name"
                v-model="formData.title_state"
                v-validate="'required'"
              >
              </v-select>

              <span
                v-show="errors.has('Workshop title state or territory')"
                class="text-red text-xs italic mt-3 block"
              >
                State or territory is required
              </span>
            </div>

            <div class="w-full lg:w-1/5 lg:pr-5 mb-3 lg:mb-0">
              <label for="start_month" class="pl-0">
                Month:<span class="text-red">*</span>
              </label>
              <select
                class="border-2 bg-white rounded h-12 w-full"
                name="Workshop title month"
                id="start_month"
                v-validate="'required'"
                v-model="formData.title_month"
              >
                <option value=""></option>
                <option value="January">January</option>
                <option value="February">February</option>
                <option value="March">March</option>
                <option value="April">April</option>
                <option value="May">May</option>
                <option value="June">June</option>
                <option value="July">July</option>
                <option value="August">August</option>
                <option value="September">September</option>
                <option value="October">October</option>
                <option value="November">November</option>
                <option value="December">December</option>
              </select>
              <span
                v-show="errors.has('Workshop title month')"
                class="text-red text-xs italic mt-3 block"
              >
                Month is required
              </span>
            </div>

            <div class="w-full lg:w-1/5 lg:pr-5 mb-3 lg:mb-0">
              <label for="start_year" class="pl-0">
                Year:<span class="text-red">*</span>
              </label>
              <select
                class="border-2 bg-white rounded h-12 w-full"
                name="Workshop title year"
                id="start_year"
                v-validate="'required'"
                v-model="formData.title_year"
              >
                <option value=""></option>
                <option :value="getYear(0)">{{ getYear(0) }}</option>
                <option :value="getYear(1)">{{ getYear(1) }}</option>
                <option :value="getYear(2)">{{ getYear(2) }}</option>
              </select>
              <span
                v-show="errors.has('Workshop title year')"
                class="text-red text-xs italic mt-3 block"
              >
                Year is required
              </span>
            </div>
          </div>

          <div class="w-full lg:w-3/4 pb-4 mt-6">
            <label class="text-h3 font-bold mb-4 pl-0"
              >Workshop Description <span class="text-red">*</span></label
            >
            <p class="leading-loose text-h4 mb-3">
              This description will be shown to registered districts. Please
              include any relevant information about your SCRIPT Workshop that
              districts should be aware of upon participating. This can be the
              Workshop’s location (full address) or other relevant details.
            </p>
            <vue-editor
              v-model="formData.description"
              :editorToolbar="descriptionToolbar"
              data-vv-name="Workshop description"
              v-validate="'required'"
            ></vue-editor>
            <span
              v-show="errors.has('Workshop description')"
              class="text-red text-xs italic mt-3 block"
            >
              Description is required
            </span>
          </div>

          <div class="w-full lg:w-3/4 pb-4 mt-6">
            <p class="text-h3 font-bold leading-tight">
              When you are ready to have workshop participants work on their
              forms, please turn the workshop on.
            </p>
            <div class="select-box">
              <label for="active" class="pl-0">
                <input
                  name="active"
                  id="active"
                  type="checkbox"
                  v-model="formData.active"
                />
                <span class="check inline-block mr-2"></span>
                Active?
              </label>
            </div>
          </div>

          <div class="w-full lg:w-3/4 pb-4 mt-6">
            <label class="text-h3 font-bold pl-0"
              >Timezone <span class="text-red">*</span></label
            >

            <select
              type="text"
              class="border-2 bg-white rounded h-12 w-full"
              name="Timezone"
              v-validate="'required'"
              v-model="formData.timezone"
            >
              <option value="">--Please choose the timezone--</option>
              <option value="UTC-11">UTC-11: Samoa Standard Time</option>
              <option value="UTC-10">UTC-10: Hawaii-Aleutian Time</option>
              <option value="UTC-9">UTC-9: Alaska Time</option>
              <option value="UTC-8">UTC−8: Pacific Time</option>
              <option value="UTC-7">UTC−7: Mountain Time</option>
              <option value="UTC-6">UTC−6: Central Time</option>
              <option value="UTC-5">UTC−5: Eastern Time</option>
              <option value="UTC-4">UTC−4: Atlantic Time </option>
              <option value="UTC+10">UTC+10: Chamorro Standard Time</option>
              <option value="UTC+12">UTC+12: Wake Island Time Zone</option>
            </select>
            <span
              v-show="errors.has('Timezone')"
              class="text-red text-xs italic mt-3 block"
            >
              Timezone required
            </span>
          </div>

          <div class="w-full mt-6">
            <label class="text-h3 font-bold pl-0"
              >Start Date <span class="text-red">*</span></label
            >
          </div>

          <div class="w-full pb-4 mt-6 flex flex-wrap">
            <div class="w-full lg:w-1/5 lg:pr-5 mb-3 lg:mb-0">
              <label for="start_month" class="pl-0">Month:</label>
              <select
                class="border-2 bg-white rounded h-12 w-full"
                name="Start date - Month"
                id="start_month"
                v-validate="'required'"
                v-model="start.month"
                :disabled="!timezoneSelected"
              >
                <option value="">--Please choose the month--</option>
                <option value="0">January</option>
                <option value="1">February</option>
                <option value="2">March</option>
                <option value="3">April</option>
                <option value="4">May</option>
                <option value="5">June</option>
                <option value="6">July</option>
                <option value="7">August</option>
                <option value="8">September</option>
                <option value="9">October</option>
                <option value="10">November</option>
                <option value="11">December</option>
              </select>
              <span
                v-show="errors.has('Start date - Month')"
                class="text-red text-xs italic mt-3 block"
              >
                Month is required
              </span>
            </div>

            <div class="w-full lg:w-1/5 lg:pr-5 mb-3 lg:mb-0">
              <label for="start_month" class="pl-0">Day:</label>
              <select
                class="border-2 bg-white rounded h-12 w-full"
                name="Start date - Day"
                id="start_day"
                v-validate="'required'"
                v-model="start.day"
                :disabled="!timezoneSelected"
              >
                <option value="">--Please choose the day--</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
                <option value="13">13</option>
                <option value="14">14</option>
                <option value="15">15</option>
                <option value="16">16</option>
                <option value="17">17</option>
                <option value="18">18</option>
                <option value="19">19</option>
                <option value="20">20</option>
                <option value="21">21</option>
                <option value="22">22</option>
                <option value="23">23</option>
                <option value="24">24</option>
                <option value="25">25</option>
                <option value="26">26</option>
                <option value="27">27</option>
                <option value="28">28</option>
                <option value="29">29</option>
                <option value="30">30</option>
                <option value="31">31</option>
              </select>
              <span
                v-show="errors.has('Start date - Day')"
                class="text-red text-xs italic mt-3 block"
              >
                Day is required
              </span>
            </div>

            <div class="w-full lg:w-1/5 lg:pr-5 mb-3 lg:mb-0">
              <label for="start_year" class="pl-0">Year:</label>
              <select
                class="border-2 bg-white rounded h-12 w-full"
                name="Start Date - Year"
                id="start_year"
                v-validate="'required'"
                v-model="start.year"
                :disabled="!timezoneSelected"
              >
                <option value="">--Please choose the year--</option>
                <option :value="getYear(0)">{{ getYear(0) }}</option>
                <option :value="getYear(1)">{{ getYear(1) }}</option>
                <option :value="getYear(2)">{{ getYear(2) }}</option>
              </select>
              <span
                v-show="errors.has('Start Date - Year')"
                class="text-red text-xs italic mt-3 block"
              >
                Year is required
              </span>
            </div>

            <div class="w-full lg:w-1/5 lg:pr-5 mb-3 lg:mb-0">
              <label for="start_hour" class="pl-0">Hour:</label>
              <select
                class="border-2 bg-white rounded h-12 w-full"
                name="Start Date - Hour"
                id="start_hour"
                v-validate="'required'"
                v-model="start.hour"
                :disabled="!timezoneSelected"
              >
                <option value="">--Please choose the hour--</option>
                <option value="6">6 AM {{ formData.timezone }}</option>
                <option value="7">7 AM {{ formData.timezone }}</option>
                <option value="8">8 AM {{ formData.timezone }}</option>
                <option value="9">9 AM {{ formData.timezone }}</option>
                <option value="10">10 AM {{ formData.timezone }}</option>
                <option value="11">11 AM {{ formData.timezone }}</option>
                <option value="12">12 PM {{ formData.timezone }}</option>
                <option value="13">1 PM {{ formData.timezone }}</option>
                <option value="14">2 PM {{ formData.timezone }}</option>
                <option value="15">3 PM {{ formData.timezone }}</option>
                <option value="16">4 PM {{ formData.timezone }}</option>
                <option value="17">5 PM {{ formData.timezone }}</option>
                <option value="18">6 PM {{ formData.timezone }}</option>
                <option value="19">7 PM {{ formData.timezone }}</option>
                <option value="20">8 PM {{ formData.timezone }}</option>
                <option value="21">9 PM {{ formData.timezone }}</option>
              </select>
              <span
                v-show="errors.has('Start Date - Hour')"
                class="text-red text-xs italic mt-3 block"
              >
                Hour is required
              </span>
            </div>
            <div class="w-full lg:w-1/5 lg:pr-5 mb-3 lg:mb-0">
              <label for="start_minute" class="pl-0">Minute:</label>
              <select
                class="border-2 bg-white rounded h-12 w-full"
                name="Start Date - Minute"
                id="start_minute"
                v-validate="'required'"
                v-model="start.minute"
                :disabled="!timezoneSelected"
              >
                <option value="0">00</option>
                <option value="30">30</option>
              </select>
              <span
                v-show="errors.has('Start Date - Minute')"
                class="text-red text-xs italic mt-3 block"
              >
                Minutes are required
              </span>
            </div>
          </div>

          <div class="w-full mt-6">
            <label class="text-h3 font-bold pl-0"
              >End Date <span class="text-red">*</span></label
            >
          </div>

          <div class="w-full pb-4 mt-6 flex flex-wrap">
            <div class="w-full lg:w-1/5 lg:pr-5 mb-3 lg:mb-0">
              <label for="end_month" class="pl-0">Month:</label>
              <select
                class="border-2 bg-white rounded h-12 w-full"
                name="End date - Month"
                id="end_month"
                v-validate="'required'"
                v-model="end.month"
                :disabled="!timezoneSelected"
              >
                <option value="">--Please choose the month--</option>
                <option value="0">January</option>
                <option value="1">February</option>
                <option value="2">March</option>
                <option value="3">April</option>
                <option value="4">May</option>
                <option value="5">June</option>
                <option value="6">July</option>
                <option value="7">August</option>
                <option value="8">September</option>
                <option value="9">October</option>
                <option value="10">November</option>
                <option value="11">December</option>
              </select>
              <span
                v-show="errors.has('End date - Month')"
                class="text-red text-xs italic mt-3 block"
              >
                Month is required
              </span>
            </div>

            <div class="w-full lg:w-1/5 lg:pr-5 mb-3 lg:mb-0">
              <label for="end_day" class="pl-0">Day:</label>
              <select
                class="border-2 bg-white rounded h-12 w-full"
                name="End Date - Day"
                id="end_day"
                v-validate="'required'"
                v-model="end.day"
                :disabled="!timezoneSelected"
              >
                <option value="">--Please choose the day--</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
                <option value="13">13</option>
                <option value="14">14</option>
                <option value="15">15</option>
                <option value="16">16</option>
                <option value="17">17</option>
                <option value="18">18</option>
                <option value="19">19</option>
                <option value="20">20</option>
                <option value="21">21</option>
                <option value="22">22</option>
                <option value="23">23</option>
                <option value="24">24</option>
                <option value="25">25</option>
                <option value="26">26</option>
                <option value="27">27</option>
                <option value="28">28</option>
                <option value="29">29</option>
                <option value="30">30</option>
                <option value="31">31</option>
              </select>
              <span
                v-show="errors.has('End Date - Day')"
                class="text-red text-xs italic mt-3 block"
              >
                Day is required
              </span>
            </div>

            <div class="w-full lg:w-1/5 lg:pr-5 mb-3 lg:mb-0">
              <label for="end_year" class="pl-0">Year:</label>
              <select
                class="border-2 bg-white rounded h-12 w-full"
                name="End Date - Year"
                id="end_year"
                v-validate="'required'"
                v-model="end.year"
                :disabled="!timezoneSelected"
              >
                <option value="">--Please choose the year--</option>
                <option :value="getYear(0)">{{ getYear(0) }}</option>
                <option :value="getYear(1)">{{ getYear(1) }}</option>
                <option :value="getYear(2)">{{ getYear(2) }}</option>
              </select>
              <span
                v-show="errors.has('End Date - Year')"
                class="text-red text-xs italic mt-3 block"
              >
                Year is required
              </span>
            </div>

            <div class="w-full lg:w-1/5 lg:pr-5 mb-3 lg:mb-0">
              <label for="end_time" class="pl-0">Hour:</label>
              <select
                class="border-2 bg-white rounded h-12 w-full"
                name="End Date - Hour"
                id="end_time"
                v-validate="'required'"
                v-model="end.hour"
                :disabled="!timezoneSelected"
              >
                <option value="">--Please choose the hour--</option>
                <option value="6">6 AM {{ formData.timezone }}</option>
                <option value="7">7 AM {{ formData.timezone }}</option>
                <option value="8">8 AM {{ formData.timezone }}</option>
                <option value="9">9 AM {{ formData.timezone }}</option>
                <option value="10">10 AM {{ formData.timezone }}</option>
                <option value="11">11 AM {{ formData.timezone }}</option>
                <option value="12">12 PM {{ formData.timezone }}</option>
                <option value="13">1 PM {{ formData.timezone }}</option>
                <option value="14">2 PM {{ formData.timezone }}</option>
                <option value="15">3 PM {{ formData.timezone }}</option>
                <option value="16">4 PM {{ formData.timezone }}</option>
                <option value="17">5 PM {{ formData.timezone }}</option>
                <option value="18">6 PM {{ formData.timezone }}</option>
                <option value="19">7 PM {{ formData.timezone }}</option>
                <option value="20">8 PM {{ formData.timezone }}</option>
                <option value="21">9 PM {{ formData.timezone }}</option>
              </select>
              <span
                v-show="errors.has('End Date - Hour')"
                class="text-red text-xs italic mt-3 block"
              >
                Time is required
              </span>
            </div>
            <div class="w-full lg:w-1/5 lg:pr-5 mb-3 lg:mb-0">
              <label for="end_minute" class="pl-0">Minute:</label>
              <select
                class="border-2 bg-white rounded h-12 w-full"
                name="End Date - Minute"
                id="end_minute"
                v-validate="'required'"
                v-model="end.minute"
                :disabled="!timezoneSelected"
              >
                <option value="0">00</option>
                <option value="30">30</option>
              </select>
              <span
                v-show="errors.has('End Date - Minute')"
                class="text-red text-xs italic mt-3 block"
              >
                Minutes are required
              </span>
            </div>
          </div>

          <div class="w-full lg:w-3/4 pb-4 mt-6">
            <label class="text-h3 font-bold pl-0" for="states-territories"
              >State or Territory<span class="text-red">*</span></label
            >
            <v-select
              id="states-territories"
              name="State or territory is required"
              label="name"
              :options="statesAndTerritories"
              :reduce="state => state.abbreviation"
              v-model="formData.state"
              v-validate="'required'"
            >
            </v-select>
            <span
              v-show="errors.has('State or territory is required')"
              class="text-red text-xs italic mt-3 block"
            >
              State or territory is required
            </span>
          </div>

          <div class="w-full lg:w-3/4 pb-4 mt-6">
            <p class="text-h3 font-bold leading-tight">
              Check the box if the workshop is Virtual
            </p>
            <div class="select-box">
              <label for="virtual" class="pl-0">
                <input
                  name="virtual"
                  id="virtual"
                  type="checkbox"
                  v-model="formData.virtual"
                />
                <span class="check inline-block mr-2"></span>
                Virtual
              </label>
            </div>
            <p class="leading-loose text-sm mb-3">
              By default (unchecked) the workshop meeting is:'<b>In-Person</b>'.
            </p>
          </div>

          <div class="w-full lg:w-3/4 pb-4 mt-6">
            <label class="text-h3 font-bold pl-0"
              >City <span class="text-red">*</span></label
            >
            <input
              type="text"
              class="my-4"
              name="City"
              v-validate="'required'"
              v-model="formData.city"
            />
            <!-- <p class="leading-loose text-sm mb-3">
              Please write in 'Virtual' if the setting is virtual and serves
              LEAs from multiple locations
            </p> -->
            <span v-show="errors.has('City')" class="text-red text-xs italic">
              City is required
            </span>
          </div>

          <div class="w-full lg:w-3/4 pb-4 mt-6">
            <label class="text-h3 font-bold pl-0"
              >Workshop Sponsor/Host <span class="text-red">*</span></label
            >
            <input
              type="text"
              class="my-4"
              name="Event sponsor/host"
              v-validate="'required'"
              v-model="formData.event_sponsor_host"
            />
            <span
              v-show="errors.has('Event sponsor/host')"
              class="text-red text-xs italic"
            >
              Event Sponsor/Host is required
            </span>
          </div>

          <div class="w-full lg:w-3/4 pb-4 mt-6">
            <label class="text-h3 font-bold pl-0"
              >Facilitator First Name <span class="text-red">*</span></label
            >
            <input
              type="text"
              class="my-4"
              name="Facilitator first name"
              v-validate="'required'"
              v-model="formData.presenter_first_name"
            />
            <span
              v-show="errors.has('Facilitator first name')"
              class="text-red text-xs italic"
            >
              Facilitator first name is required
            </span>
          </div>

          <div class="w-full lg:w-3/4 pb-4 mt-6">
            <label class="text-h3 font-bold pl-0"
              >Facilitator Last Name <span class="text-red">*</span></label
            >
            <input
              type="text"
              class="my-4"
              name="Facilitator last name"
              v-validate="'required'"
              v-model="formData.presenter_last_name"
            />
            <span
              v-show="errors.has('Facilitator last name')"
              class="text-red text-xs italic"
            >
              Facilitator last name is required
            </span>
          </div>

          <div class="w-full lg:w-3/4 pb-4 mt-6 create-workshop">
            <div class="horizontal-break"></div>
            <h3 class="text-h3 font-bold">Add Users</h3>
            <br />

            <!-- hiding lead facilitators since we aren't ready to roll that out yet -->
            <!-- <h3 class="h3-no-bold">Add Lead Facilitator</h3>
            <p>
              Lead Facilitators are facilitators that coordinate a group of
              facilitators in a region or state. Please note that not every
              region or state has an affiliated lead facilitator, so this
              section is optional. Adding a lead facilitator to your workshop
              will allow them to view data submitted by LEAs for this workshop.
            </p>
            <div class="mb-6">
              <label for="type" class="mb-4"></label>
              <div class="flex mb-2">
                <div class="items-center w-full">
                  <label for="organization">
                    <v-select
                      multiple
                      label="name"
                      :options="leadFacilitators"
                      v-model="formData.leadFacilitators"
                      :create-option="org => ({ name: org })"
                      id="newOrg"
                    >
                    </v-select>
                  </label>
                </div>
              </div>
            </div>
            <div class="horizontal-break"></div> -->

            <h3 class="h3-no-bold">Add Facilitator</h3>
            <p>
              Some SCRIPT Workshops are co-facilitated by multiple facilitators.
              Adding a facilitator to your workshop will allow them to access
              the workshop page and view data submitted by LEAs for this
              workshop. Please note that facilitators need to create an account
              in the CSforALL member portal in order to appear as an option in
              the drop down menu.
            </p>
            <div class="mb-6">
              <div class="flex mb-2">
                <div class="items-center w-full">
                  <label for="newFacilitator" class="pl-0">
                    <v-select
                      multiple
                      label="name"
                      :options="facilitators"
                      v-model="formData.facilitators"
                      :create-option="user => ({ name: user })"
                      id="newFacilitator"
                    >
                    </v-select>
                  </label>
                </div>
              </div>
            </div>

            <div v-show="isEditing">
              <h3 class="h3-no-bold">Add LEA Points of Contact (POCs)</h3>
              <p>
                Extend an invitation to one individual from each Local Education
                Association (LEA) team to participate in this workshop. This
                individual will serve as the main contact for their team and
                will have the capability to invite additional team members to
                partake in the workshop. After the team has been established,
                the role of LEA point of contact can be transferred within the
                existing members.
              </p>
              <div class="mb-6">
                <div class="flex mb-2">
                  <button @click="showLEAInviteModal" class="btn btn-blue mt-4">
                    Invite LEA Admins
                  </button>
                  <lea-invite-modal
                    v-if="inviteLEAModalOpen == true"
                    v-model="inviteLEAModalOpen"
                    v-bind:workshop="this.workshop"
                    @reload="reloadWorkshops"
                  >
                  </lea-invite-modal>
                </div>
              </div>
            </div>

            <div class="horizontal-break"></div>

            <h3 class="text-h3 font-bold">Workshop Materials</h3>
            <p>
              Add workshop materials that you want to display for all teams that
              join your workshop. These will show as links when they are viewing
              the workshop. If you have materials per individual team, you can
              add them in the workshop page once these teams register to the
              workshop.
            </p>
            <div v-if="materialBox">
              <div
                class="workshop-forms-group"
                v-for="(formRow, index) in formData.materials"
                :key="index"
              >
                <i
                  title="Remove link"
                  class="far fa-minus-square cursor-pointer text-blue-dark float-right minus-placement"
                  @click="removeLink(formData.materials[index])"
                ></i>
                <input
                  type="text"
                  placeholder="Material Name"
                  v-model="formData.materials[index].form_name"
                  :name="`material name ${index + 1}`"
                  v-validate="'required'"
                />
                <div class="my-4">
                  <span
                    v-show="errors.has(`material name ${index + 1}`)"
                    class="text-xs italic text-red"
                    >{{ errors.first(`material name ${index + 1}`) }}</span
                  >
                </div>
                <input
                  type="text"
                  placeholder="http://example.com"
                  v-model="formData.materials[index].form_url"
                  :name="`material URL ${index + 1}`"
                  v-validate="{
                    required: false,
                    url: {
                      require_protocol: true,
                      protocols: ['http', 'https']
                    }
                  }"
                />
                <div class="my-4">
                  <span
                    v-show="errors.has(`material URL ${index + 1}`)"
                    class="text-xs italic text-red"
                    >{{ errors.first(`material URL ${index + 1}`) }}</span
                  >
                </div>
              </div>
            </div>

            <button @click="addMoreForms" class="btn btn-blue mt-4">
              + Add More Materials
            </button>

            <div class="horizontal-break"></div>
          </div>

          <div class="flex flex-wrap -mx-2 mb-8">
            <div class="w-full md:w-1/2 lg:w-1/4 px-2 mb-4">
              <div class="h-12 flex items-center justify-start">
                <button @click.stop.prevent="cancel" class="btn btn-grey mt-4">
                  Cancel
                </button>
              </div>
            </div>
            <div class="w-full md:w-1/2 lg:w-1/4 px-2 mb-4">
              <div class=" h-12 flex items-center justify-center">
                <!-- TODO: For LEA invite modal popup on create 
                    <button
                      @click.stop.prevent="ceateAndInvite"
                      class="btn btn-green mt-4"
                    >
                      Create and Invite LEAs
                    </button> -->
              </div>
            </div>
            <div class="w-full md:w-1/2 lg:w-1/4 px-2 mb-4">
              <div class="h-12 flex items-center justify-end">
                <button
                  @click.stop.prevent="createOrUpdate"
                  class="btn btn-green mt-4"
                >
                  {{ this.actionButtonText }}
                </button>
              </div>
            </div>
          </div>
          <spinner v-show="loading ? true : false"></spinner>
        </div>
      </div>
    </div>
    <div class="blue-bar"></div>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import { mapGetters } from "vuex";
import { ROLES } from "@/const";
import moment from "moment";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import LEAInviteModal from "../modals/LEAInviteModal.vue";
import _ from "lodash";
import { STATE_AND_TERRITORIES } from "@/util/statesAndTerritories";

export default {
  name: "WorkshopCreateEdit",
  props: {},
  components: {
    VueEditor,
    "v-select": vSelect,
    "lea-invite-modal": LEAInviteModal
  },
  data: () => ({
    inviteLEAModalOpen: false,
    descriptionToolbar: [
      ["bold", "italic", "link"],
      [{ list: "ordered" }, { list: "bullet" }]
    ],
    formData: {
      active: false,
      title: "",
      description: "",
      start_time: "",
      end_time: "",
      timezone: "",
      city: "",
      state: "",
      event_sponsor_host: "",
      presenter_first_name: "",
      presenter_last_name: "",
      leadFacilitators: [],
      facilitators: [],
      leaInvites: [],
      materials: [],
      title_funder: "",
      title_state: "",
      title_month: "",
      title_year: "",
      virtual: false
    },
    leadFacilitators: [],
    facilitators: [],
    workshoptitle: [],
    start: {
      month: "",
      day: "",
      year: "",
      hour: "",
      minute: "0"
    },
    end: {
      month: "",
      day: "",
      year: "",
      hour: "",
      minute: "0"
    },
    loading: false,
    materialBox: false,
    statesAndTerritories: STATE_AND_TERRITORIES
  }),
  computed: {
    ...mapGetters({
      user: "user",
      workshop: "facilitatorWorkshop"
    }),
    timezoneSelected() {
      return this.formData.timezone;
    },
    actionButtonText() {
      return this.isEditing ? "Save and Return" : "Create";
    },
    title() {
      return this.isEditing ? "Edit Workshop" : "Create Workshop";
    },
    id() {
      return this.$route.params.id;
    },
    isEditing() {
      return !!this.id;
    }
  },
  watch: {
    workshop: function() {
      this.load();
    },
    $route: "load"
  },
  methods: {
    cancel() {
      this.$router.push("/workshops/");
      return;
    },
    createOrUpdate() {
      const dateStarts = this.getDateAsString(this.start);
      const dateEnds = this.getDateAsString(this.end);
      const convertedDates = Math.floor(
        (Date.parse(dateEnds) - Date.parse(dateStarts)) / (1000 * 60 * 60 * 24)
      );

      this.$validator.validateAll().then(result => {
        if (!result) {
          let errFieldArr = this.$validator.errors.items;
          let parsedArr = [...errFieldArr];
          if (convertedDates > 21) {
            let errTimeHandlerMsg = [
              {
                timewrong: true,
                msg: "Workshop dates should not exceed 3 weeks."
              }
            ];
            parsedArr = errFieldArr.concat(errTimeHandlerMsg);
          } else if (convertedDates < 0) {
            let errTimeHandlerMsg = [
              {
                timewrong: true,
                msg: "Workshop end date should be later than start date."
              }
            ];
            parsedArr = errFieldArr.concat(errTimeHandlerMsg);
          } else {
            parsedArr.filter(e => e !== e.timewrong);
          }

          this.$toasted.show(parsedArr.map(user => user.msg).join("<br />"), {
            position: "top-center",
            type: "error",
            action: {
              text: "x",
              onClick: (e, toastObject) => {
                toastObject.goAway(0);
              }
            }
          });
          return;
        }

        if (this.isEditing) {
          this.update();
        } else {
          this.create();
        }
      });
    },
    create() {
      this.loading = true;
      this.formData.form_id = this.$route.params.id;
      this.setFormDates();
      let data = this.gatherStaffIDsandMaterials();

      window.axios
        .post("/api/workshops", data)
        .then(() => {
          // TODO: for create and invite modal popup in a createandinvite function
          // console.log(resp.data)
          // this.workshop=resp.data
          // this.$router.push({name:"workshops-list", params: { modalOpen: true, workshop: this.workshop }})
          this.loading = false;
          this.$toasted.show("Workshop created");
          this.$router.push({
            name: "workshops-list",
            params: { created: true }
          });
        })
        .catch(error => {
          console.log(error);
          this.loading = false;
        });
    },
    showLEAInviteModal() {
      this.inviteLEAModalOpen = !this.inviteLEAModalOpen;
    },
    gatherStaffIDsandMaterials() {
      let facilitatorIds = this.formData.facilitators.map(user => user.id);
      this.workshoptitle.push(
        this.formData.title_funder,
        this.formData.title_state,
        "-",
        this.formData.title_month,
        this.formData.title_year
      );
      this.formData.title = this.workshoptitle.join(" ");
      let leadFacilitatorIds = this.formData.leadFacilitators.map(
        user => user.id
      );

      this.formData.materials = this.formData.materials.filter(
        obj => obj.form_name !== "" && obj.form_url !== ""
      );

      let data = {
        leadFacilitatorIds,
        facilitatorIds,
        ...this.formData
      };
      return data;
    },
    addMoreForms() {
      this.materialBox = true;
      this.formData.materials.push({
        form_id: "",
        form_name: "",
        form_url: "",
        temp_id: (+new Date()).toString(36)
      });
    },
    removeLink(material) {
      this.formData.materials = _.filter(this.formData.materials, function(m) {
        return (
          m?.form_id !== material?.form_id || m?.temp_id !== material?.temp_id
        );
      });
    },
    getDateAsString(dateTime) {
      let date = moment()
        .local()
        .minute(0)
        .second(0)
        .millisecond(0);

      date.year(dateTime.year);
      date.month(dateTime.month);
      date.date(dateTime.day);
      date.hour(dateTime.hour);
      date.minute(dateTime.minute);

      return date.format("YYYY-MM-DD HH:mm");
    },
    getDateFromString(dateString) {
      let m = moment(dateString);
      let date = {};

      date.year = m.year();
      date.month = m.month();
      date.day = m.date();
      date.hour = m.hour();
      date.minute = m.minute();

      return date;
    },
    getYear(increment) {
      return moment().year() + increment;
    },
    setFormDates() {
      this.formData.start_time = this.getDateAsString(this.start);
      this.formData.end_time = this.getDateAsString(this.end);
    },
    getUsers: function() {
      // this only loads facilitators currently. When lead facilitators are re-introduced to the app, will need to load them as well.
      let params = {
        "filter[roles.name]": ROLES.FACILITATOR
      };

      window.axios.get("/api/users", { params: params }).then(res => {
        res.data.data.forEach(user => {
          if (!this.facilitators.includes(user)) {
            this.facilitators.push(user);
          }
        });
        this.loadStaff();
      });
    },
    update() {
      this.loading = true;
      this.setFormDates();
      let data = this.gatherStaffIDsandMaterials();

      window.axios
        .patch(`/api/workshops/${this.workshop.id}`, data)
        .then(() => {
          this.loading = false;
          this.$toasted.show("Workshop updated");
          this.$store.dispatch("getFacilitatorWorkshop", this.workshop.id);
          this.$router.push({
            name: "facilitator-workshop-show",
            params: { id: this.id }
          });
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    },
    load() {
      // only load if we're editing
      if (this.isEditing) {
        this.formData.active = this.workshop.active;
        this.formData.title = this.workshop.title;
        this.formData.description = this.workshop.description;
        this.formData.timezone = this.workshop.timezone;
        this.formData.city = this.workshop.city;
        this.formData.state = this.workshop.state;
        this.formData.event_sponsor_host = this.workshop.event_sponsor_host;
        this.formData.presenter_first_name = this.workshop.presenter_first_name;
        this.formData.presenter_last_name = this.workshop.presenter_last_name;
        this.formData.title_funder = this.workshop.title_funder;
        this.formData.title_state = this.workshop.title_state;
        this.formData.title_month = this.workshop.title_month;
        this.formData.title_year = this.workshop.title_year;
        this.formData.virtual = this.workshop.virtual;

        this.start = this.getDateFromString(this.workshop.start_time);
        this.end = this.getDateFromString(this.workshop.end_time);

        if (this.workshop.materials !== undefined) {
          this.formData.materials = this.workshop.materials.map(obj => {
            if (obj.form_url !== null && obj.form_name !== null) {
              return {
                form_id: obj.id,
                form_name: obj.form_name,
                form_url: obj.form_url
              };
            }
          });
        }
      }
    },
    loadStaff() {
      if (this.isEditing) {
        if (this.workshop.staff) {
          this.workshop.staff.forEach(staff => {
            staff.roles.forEach(role => {
              if (role.name === ROLES.FACILITATOR) {
                this.facilitators.forEach(facilitator => {
                  if (
                    facilitator.id === staff.id &&
                    !this.formData.facilitators.includes(facilitator)
                  ) {
                    this.formData.facilitators.push(facilitator);
                  }
                });
              }
              if (role.name === ROLES.LEAD_FACILITATOR) {
                this.leadFacilitators.forEach(leadFacilitator => {
                  if (
                    leadFacilitator.id === staff.id &&
                    !this.formData.leadFacilitators.includes(leadFacilitator)
                  ) {
                    this.formData.leadFacilitators.push(leadFacilitator);
                  }
                });
              }
            });
          });
        }

        if (
          this.workshop.member_invitations &&
          this.workshop.member_invitations.length > 0
        ) {
          this.workshop.member_invitations.forEach(member => {
            if (!this.formData.leaInvites.includes(member)) {
              this.formData.leaInvites.push(member);
            }
          });
        }

        if (
          this.workshop.email_invitations &&
          this.workshop.email_invitations.length > 0
        ) {
          this.workshop.email_invitations.forEach(invite => {
            if (!this.formData.leaInvites.includes(invite.email)) {
              this.formData.leaInvites.push(invite.email);
            }
          });
        }
      }
    },

    copyToClipboard() {
      const email = document.getElementById("email-copy");
      email.addEventListener("click", () => {
        const selection = window.getSelection();
        const range = document.createRange();
        range.selectNodeContents(email);

        selection.addRange(range);
        document.execCommand("copy");
        selection.removeAllRanges();
      });
      this.$toasted.show("Email Copied").goAway(1000);
    },
    reloadWorkshops(updated) {
      if (updated) {
        this.$emit("reload");
      }
    }
  },
  mounted() {
    this.getUsers();
    if (!this.workshop.id && this.id) {
      this.$store.dispatch("getFacilitatorWorkshop", this.$route.params.id);
    }
    this.load();
  }
};
</script>

<style>
/* logo image container */
.image-container {
  width: 200px;
  min-height: 200px;
  margin-left: 10px;
}

.image-container img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
}

.horizontal-break {
  width: 100%;
  height: 2px;
  background-color: lightgrey;
  margin-bottom: 30px;
  margin-top: 30px;
}

.h3-no-bold {
  font-weight: 400;
}

.create-workshop p {
  font-family: Raleway;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3061ac;
  margin-top: 9px;
  margin-bottom: 17px;
}

.create-workshop label {
  margin-bottom: 0;
}

/* workshop materials */
.workshop-forms-group {
  height: 100%;
  padding: 30px;
  margin-bottom: 20px;
  border-radius: 20px;
  border: solid 1px #979797;
}

.workshop-forms-group input {
  height: 100%;
  padding: 10px;
  border-radius: 8px;
  border: solid 1px lightgray;
}

.workshop-forms-group input:last-child {
  margin-top: 20px;
}

.minus-placement {
  margin-top: -15px !important;
  margin-bottom: 7px;
}

.lead-invites {
  display: flex;
  justify-content: space-between;
}

.lead-invites .dropdown {
  display: flex;
  justify-content: space-between;
}

.lead-invites .v-select {
  width: 400px;
}

.lead-invites textarea,
.lead-invites label {
  height: 60px;
  width: 400px;
  padding: 20px;
}

.lead-invites label {
  height: 60px;
  width: 400px;
  padding: 0;
}

.lead-invites button {
  padding-left: 20px;
  padding-bottom: 10px;
  font-size: 20px;
  font-weight: 500;
}

.email-lea {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.new-lea {
  margin-top: 50px;
}

.invite-list {
  margin-left: 30px;
  min-width: 400px;
}

.invite-list .blue-li-box {
  display: inline-block;
  height: 34px;
  margin-bottom: 8px;
  margin-right: 8px;
  border-radius: 6px;
  background-color: #3061ac;
  text-align: center;
}

.invite-list ul {
  padding-left: 0;
}

.invite-list li {
  color: white;
  display: inline-block;
  font-weight: 400;
  padding: 5px 12.5px 5px 9.5px;
}

.invite-list span {
  margin-left: 8px;
  font-weight: bold;
  cursor: pointer;
}

.workshop-title input[name="funder-type"] {
  height: 48px;
}

.workshop-title .vs__dropdown-toggle {
  border: 2px solid #c7ceda;
  height: 48px;
}

.workshop-title .vs__selected {
  height: 40px;
}
.toasted-container .toasted {
  align-items: flex-start;
  padding-top: 20px;
  padding-bottom: 15px;
}
.toasted .action.ripple {
  padding: 0;
  color: white;
  font-weight: bold;
  margin: -10px -7px 7px 20px;
}
</style>
