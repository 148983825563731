<template>
  <div class="relative">
    <div class="container mx-auto pb-24">
      <breadcrumbs :breadcrumbs="crumbs"></breadcrumbs>

      <h1>{{ curriculum.name }} Alignment</h1>

      <div class="w-full bg-grey-lighter p-8 mt-10">
        <div class="flex flex-wrap mb-5">
          <div class="w-full mb-5 md:mb-0 md:flex-1">
            <h3>K12 CS Framework Components</h3>
          </div>
          <div class="w-full md:w-auto" v-if="!user.is_reviewer">
            <button
              v-bind:class="{
                'opacity-50 cursor-not-allowed': this.isSaving ? true : false,
                'btn btn-blue btn-sm md:ml-4 mb-2 md:mb-0': true
              }"
              @click="onSave"
            >
              {{ this.isCertificationMode ? "Update" : "Save" }}
            </button>
            <button
              v-show="!this.isCertificationMode"
              v-bind:class="{
                'opacity-50 cursor-not-allowed': this.isSaving ? true : false,
                'btn btn-blue btn-sm ml-4': true
              }"
              @click="showSubmitModal"
            >
              Submit for Review
            </button>
          </div>
        </div>

        <div class="hidden lg:flex text-white bg-blue-primary rounded-t">
          <div class="w-full lg:w-32 p-4 font-bold">
            Grade Band
          </div>
          <div class="w-full lg:w-64 p-4 font-bold">
            Concept
          </div>
          <div class="w-full lg:w-48 p-4 font-bold">
            Sub-Concept
          </div>
          <div class="w-full lg:flex-1 p-4 font-bold">
            Statement
          </div>
        </div>
        <div
          v-for="(alignment, index) in alignments"
          v-bind:key="alignment.id"
          v-bind:class="{
            'block lg:flex lg:border-b-2 lg:border-grey-lighter': true,
            'lg:bg-grey-lightest': !(index % 2)
          }"
          class="bg-white mb-8 lg:mb-0 border-2 border-gray-light"
        >
          <div
            class="w-full lg:w-32 p-4 font-bold select select-box bg-grey-lighter lg:bg-transparent border-b-2 lg:border-none"
          >
            <label>
              <input
                type="checkbox"
                :value="alignment.id"
                v-model="selected_alignments"
              />
              <span class="check inline-block mr-2"></span>
              <span class="hidden lg:inline">{{ alignment.grade_band }}</span>
              <span class="lg:hidden text-blue-primary font-bold"
                >Select this standard</span
              >
            </label>
          </div>
          <div class="w-full lg:w-64 p-4 text-blue-primary lg:hidden">
            <div class="lg:hidden text-blue-primary font-bold">Grade Band:</div>
            {{ alignment.grade_band }}
          </div>
          <div class="w-full lg:w-64 p-4 lg:text-blue-primary">
            <div class="lg:hidden text-blue-primary font-bold">Concept:</div>
            {{ alignment.concept }}
          </div>
          <div class="w-full lg:w-48 p-4">
            <div class="lg:hidden text-blue-primary font-bold">
              Sub-Concept:
            </div>
            {{ alignment.subconcept }}
          </div>
          <div class="w-full lg:flex-1 p-4 leading-loose">
            <div class="lg:hidden text-blue-primary font-bold">Statement:</div>
            {{ alignment.statement }}
          </div>
        </div>
        <div
          class="w-full md:w-auto mt-10 flex justify-end"
          v-if="!user.is_reviewer"
        >
          <button
            v-bind:class="{
              'opacity-50 cursor-not-allowed': this.isSaving ? true : false,
              'btn btn-blue btn-sm md:ml-4 mb-2 md:mb-0': true
            }"
            @click="onSave"
          >
            {{ this.isCertificationMode ? "Update" : "Save" }}
          </button>
          <button
            v-show="!this.isCertificationMode"
            v-bind:class="{
              'opacity-50 cursor-not-allowed': this.isSaving ? true : false,
              'btn btn-blue btn-sm ml-4': true
            }"
            @click="showSubmitModal"
          >
            Submit for Review
          </button>
        </div>
      </div>

      <div
        v-if="showModal"
        class="fixed inset-0 w-full h-screen flex items-center justify-center"
        style="top:0;left:0;background:rgba(0,0,0,0.2);"
      >
        <div class="relative w-full max-w-md bg-white shadow-lg rounded-lg p-8">
          <input
            class="rounded w-full border-2 mb-2 p-4 leading-loose"
            type="text"
            name="username"
            placeholder="Username (optional)"
            v-model="username"
          />
          <input
            class="rounded w-full border-2 mb-2 p-4 leading-loose"
            type="text"
            name="password"
            placeholder="Password (optional)"
            v-model="password"
          />
          <textarea
            rows="4"
            placeholder="Notes for certification"
            class="rounded w-full border-2 mb-2 p-4 leading-loose"
            v-model="certification_notes"
            maxlength="140"
          ></textarea>
          <p class="mb-2">
            Upon submitting we will have experienced CS teachers review the
            content of your curriculum or program to verify that the indicated
            alignment with the framework is indeed there. Upon verifying your
            alignment, we will display it on our curriculum directory, and
            enable your curriculum to be found in dropdown category searches.
          </p>
          <p>
            We kindly ask you to provide us with access instructions (along with
            a login if necessary) to your content, which will be strictly used
            for review purposes. You will be notified by email once the review
            is complete.
          </p>
          <div class="w-full flex justify-between pt-3">
            <button class="btn btn-sm bg-grey-light" @click="hideSubmitModal">
              Cancel
            </button>
            <button class="btn btn-sm btn-blue" @click="onSaveAndSubmit">
              Submit
            </button>
          </div>
        </div>
      </div>

      <spinner v-show="isSaving ? true : false"></spinner>
    </div>
    <div class="blue-bar"></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  data() {
    return {
      isSaving: false,
      curriculum: {},
      selected_alignments: [],
      certification_notes: "",
      showModal: false,
      username: "",
      password: ""
    };
  },
  methods: {
    onSave() {
      this.isSaving = true;

      const data = {
        alignments: this.selected_alignments,
        updating: true
      };

      if (this.isCertificationMode) {
        return window.axios
          .put(
            "/api/user/curriculum/" + this.$route.params.token + "/align",
            data
          )
          .then(() => {
            this.isSaving = false;
            this.$toasted.show("Alignment Updated");
            this.loadCurriculum();
          });
      } else {
        return window.axios
          .put(
            "/api/me/curriculum/" + this.$route.params.token + "/align",
            data
          )
          .then(resp => {
            this.isSaving = false;
            this.setCurriculum(resp.data.data[0]);
            this.$toasted.show("Aligment draft saved");
            this.$router.push("/home/curriculum/" + resp.data.data[0].uuid);
          });
      }
    },
    setCurriculum(item) {
      this.curriculum = item;

      if (this.curriculum.submitted && !this.isCertificationMode) {
        // if the alignments are pending, don't allow them to edit
        this.$toasted.show("Aligments are pending certification");
        this.$router.push("/home/curriculum/" + this.curriculum.uuid);
        return;
      }

      let useAlignments = this.curriculum.draftAlignments.data;
      if (
        !useAlignments.length &&
        this.curriculum.submittedAlignments.data.length
      ) {
        useAlignments = this.curriculum.submittedAlignments.data;
      }
      if (
        !useAlignments.length &&
        this.curriculum.publishedAlignments.data.length
      ) {
        useAlignments = this.curriculum.publishedAlignments.data;
      }
      if (useAlignments.length) {
        let selected = [];
        useAlignments.forEach(item => {
          selected.push(item.id);
        });
        this.selected_alignments = selected;
      }
    },
    showSubmitModal() {
      this.showModal = true;
    },
    hideSubmitModal() {
      this.showModal = false;
    },
    onSaveAndSubmit() {
      this.isSaving = true;

      const data = {
        alignments: this.selected_alignments,
        submit: true,
        notes: this.certification_notes,
        username: this.username,
        password: this.password
      };

      return window.axios
        .put("/api/me/curriculum/" + this.$route.params.token + "/align", data)
        .then(resp => {
          this.isSaving = false;
          this.showModal = false;
          this.$toasted.show("Alignment submitted");
          this.$router.push("/home/curriculum/" + resp.data.data[0].uuid);
        });
    },
    loadCurriculum() {
      if (!this.$route.params.token) {
        return;
      }

      if (this.isCertificationMode) {
        window.axios
          .get("/api/user/curriculum/" + this.$route.params.token)
          .then(resp => {
            this.setCurriculum(resp.data.data[0]);
          });
      } else {
        window.axios
          .get("/api/me/curriculum/" + this.$route.params.token)
          .then(resp => {
            this.setCurriculum(resp.data.data[0]);
          });
      }
    }
  },
  computed: {
    ...mapGetters({
      user: "user",
      isLoggedIn: "isLoggedIn",
      alignments: "alignments"
    }),
    returnTo: function() {
      if (this.isAdminMode) {
        return "/admin/profile/" + this.$route.params.token;
      }
      return "/home";
    },
    isCertificationMode: function() {
      return this.$route.name == "curricula-cert-edit";
    },
    isAdminMode: function() {
      return this.$route.name == "curricula-cert-edit" && this.user.is_admin;
    },
    crumbs: function() {
      if (this.isAdminMode) {
        return [
          {
            link:
              "/admin/profile/" +
              (this.curriculum.member ? this.curriculum.member.uuid : ""),
            label: this.curriculum.member
              ? this.curriculum.member.data.name
              : ""
          },
          {
            link:
              "/home/certify/" + (this.curriculum ? this.curriculum.uuid : ""),
            label: this.curriculum.name ? this.curriculum.name : "Curriculum"
          },
          {
            link: false,
            label: "Alignment"
          }
        ];
      } else {
        return [
          {
            link: "/home",
            label: this.curriculum.member
              ? this.curriculum.member.data.name
              : ""
          },
          {
            link:
              "/home/curriculum/" +
              (this.curriculum ? this.curriculum.uuid : ""),
            label: this.curriculum.name ? this.curriculum.name : "Curriculum"
          },
          {
            link: false,
            label: "Alignment"
          }
        ];
      }
    }
  },
  created() {
    if (this.isCertificationMode) {
      this.$store.dispatch("setActiveNav", "certify");
    } else {
      this.$store.dispatch("setActiveNav", "profile");
    }
  },
  mounted() {
    window.axios.get("/api/alignment").then(resp => {
      this.$store.dispatch("setAlignments", resp.data.data);
      this.loadCurriculum();
    });
  }
};
</script>
