<template>
  <div>
    <h3 class="text-h3 text-blue-primary font-bold mb-2">
      {{ input.attributes.title }}
      <span v-if="required" class="text-red">*</span>
    </h3>
    <p class="leading-loose text-h4 mb-2">{{ input.description }}</p>
    <div class="flex flex-wrap pt-3">
      <template v-for="(option, index) in input.options">
        <label class="flex w-full mb-4" :key="index">
          <input
            type="radio"
            :id="option.id"
            :name="input.attributes.name"
            :value="option.value"
            :checked="choice == option.value"
            :disabled="disabled"
            @click="handleClick(option.value)"
          />
          <span
            class="radio-label md:pr-20 ml-2 font-normal cursor-pointer text-h4 leading-20"
            >{{ option.label }}</span
          >
        </label>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "RadioInput",
  $_veeValidate: {
    // must not be arrow functions.
    // the name getter
    name() {
      return this.input.attributes.name;
    },
    // the value getter
    value() {
      return this.choice; // `this` is the component instance, `value` is the prop we added.
    }
  },
  props: {
    answer: String,
    disabled: Boolean,
    input: Object
  },
  watch: {
    answer(answer) {
      this.choice = answer;
      this.$emit("input", answer);
    }
  },
  data() {
    return {
      choice: this.answer ? this.answer : null
    };
  },
  computed: {
    required() {
      return (
        this.input.validation && this.input.validation.includes("required")
      );
    }
  },
  methods: {
    handleClick(value) {
      if (this.choice === value) {
        this.choice = null;
      } else {
        this.choice = value;
      }

      this.$emit("input", this.choice);
    }
  }
};
</script>
