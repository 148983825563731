<template>
  <div :class="{ 'mb-10 w-full': true, 'lg:w-1/2': !withDescriptions }">
    <div class="w-full">
      <label class="text-h3 pl-0">{{ label }}</label>
      <p v-if="description" class="mt-2 mb-4 pl-0">
        {{ description }}
      </p>
    </div>
    <div class="select-box" v-for="option in options" v-bind:key="option.id">
      <label class="pl-0" :for="option.option">
        <input
          :id="option.option"
          type="checkbox"
          :checked="selected.indexOf(option.id) !== -1"
          @change="onCheck(option.id, $event)"
        /><span class="check inline-block mr-2"></span>
        {{ option.option }}
        <span v-show="option.description && withDescriptions" class="pl-2">
          {{ option.description }}
        </span>
      </label>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";

export default {
  props: [
    "label",
    "options",
    "values",
    "option",
    "withDescriptions",
    "description"
  ],
  data: () => ({
    selected: []
  }),
  computed: {
    ...mapGetters({
      user: "user"
    })
  },
  methods: {
    onCheck: function(id, e) {
      if (e.target.checked) {
        this.selected.push(id);
      } else {
        this.selected = _.reject(this.selected, item => {
          return item == id;
        });
      }

      this.$emit("optionUpdate", {
        property: this.option,
        value: this.selected
      });
    },
    onChange: function() {
      this.$emit("optionUpdate", {
        property: this.option,
        value: this.selected
      });
    },
    setSelected: function() {
      if (!this.values || !this.values.data) {
        return;
      }
      _.each(this.values.data, item => {
        this.selected.push(item.id);
      });
    }
  },
  watch: {
    values: function() {
      this.setSelected();
    }
  },
  created() {
    this.setSelected();
  },
  mounted() {
    this.setSelected();
  }
};
</script>
