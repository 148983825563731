<template>
  <label class="md:pr-20 flex">
    <input
      type="checkbox"
      :checked="checked"
      @input="onChange($event)"
      :disabled="disabled"
    />
    <span class="check inline-block mr-2"></span>
    <span class="flex-1 font-normal leading-20">{{ option.label }}</span>
  </label>
</template>
<style scoped>
[type="checkbox"] {
  display: none;
  border: none;
  /* padding-right: 20px;
width: 100%; */
  /* box-sizing: border-box; */
}
.checkbox-toolbar input[type="checkbox"]:checked ~ * {
  background-color: #e6ecf5 !important;
}
</style>
<script>
export default {
  name: "Checkbox",
  props: {
    checked: Boolean,
    option: Object,
    disabled: Boolean
  },
  methods: {
    onChange(event) {
      let answer = {
        id: this.option.id,
        value: event.target.checked
      };

      this.$emit("input", answer);
    }
  }
};
</script>
