export const USA_STATES = [
  { number: "1", value: "AL", name: "Alabama" },
  { number: "4", value: "AK", name: "Alaska" },
  { number: "5", value: "AZ", name: "Arizona" },
  { number: "6", value: "AR", name: "Arkansas" },
  { number: "7", value: "CA", name: "California" },
  { number: "8", value: "CO", name: "Colorado" },
  { number: "9", value: "CT", name: "Connecticut" },
  { number: "10", value: "DE", name: "Delaware" },
  { number: "11", value: "FL", name: "Florida" },
  { number: "12", value: "GA", name: "Georgia" },
  { number: "13", value: "HI", name: "Hawaii" },
  { number: "14", value: "ID", name: "Idaho" },
  { number: "15", value: "IL", name: "Illinois" },
  { number: "16", value: "IN", name: "Indiana" },
  { number: "17", value: "IA", name: "Iowa" },
  { number: "18", value: "KS", name: "Kansas" },
  { number: "19", value: "KY", name: "Kentucky" },
  { number: "20", value: "LA", name: "Louisiana" },
  { number: "21", value: "ME", name: "Maine" },
  { number: "22", value: "MD", name: "Maryland" },
  { number: "23", value: "MA", name: "Massachusetts" },
  { number: "24", value: "MI", name: "Michigan" },
  { number: "25", value: "MN", name: "Minnesota" },
  { number: "26", value: "MS", name: "Mississippi" },
  { number: "27", value: "MO", name: "Missouri" },
  { number: "28", value: "MT", name: "Montana" },
  { number: "29", value: "NE", name: "Nebraska" },
  { number: "30", value: "NV", name: "Nevada" },
  { number: "31", value: "NH", name: "New Hampshire" },
  { number: "32", value: "NJ", name: "New Jersey" },
  { number: "33", value: "NM", name: "New Mexico" },
  { number: "34", value: "NY", name: "New York" },
  { number: "35", value: "NC", name: "North Carolina" },
  { number: "36", value: "ND", name: "North Dakota" },
  { number: "37", value: "OH", name: "Ohio" },
  { number: "38", value: "OK", name: "Oklahoma" },
  { number: "39", value: "OR", name: "Oregon" },
  { number: "40", value: "PA", name: "Pennsylvania" },
  { number: "41", value: "RI", name: "Rhode Island" },
  { number: "42", value: "SC", name: "South Carolina" },
  { number: "43", value: "SD", name: "SouthDakota" },
  { number: "44", value: "TN", name: "Tennessee" },
  { number: "45", value: "TX", name: "Texas" },
  { number: "46", value: "UT", name: "Utah" },
  { number: "47", value: "VT", name: "Vermont" },
  { number: "48", value: "VA", name: "Virginia" },
  { number: "49", value: "WA", name: "Washington" },
  { number: "50", value: "WV", name: "West Virginia" },
  { number: "51", value: "WI", name: "Wisconsin" },
  { number: "52", value: "WY", name: "Wyoming" },
  { number: "53", value: "other", name: "Other please specify" }
];
