export const REGIONS_SET = "REGIONS_SET";

const state = {
  regions: {}
};

const getters = {
  regions: state => state.regions
};

const actions = {
  setRegions: ({ commit }, regions) => {
    commit(REGIONS_SET, {
      regions: regions
    });
  }
};

const mutations = {
  REGIONS_SET: (state, payload) => {
    state.regions = Object.assign({}, state.regions, payload.regions);
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
