import { Auth } from "aws-amplify";

// source taken from...
// https://objectpartners.com/2019/12/05/refreshing-aws-access-token-with-amplify-and-axios/
export const refreshTokenAxiosInterceptor = async config => {
  // This method will automatically refresh the accessToken and idToken if tokens are expired and a valid refreshToken presented.
  // https://docs.amplify.aws/lib/auth/manageusers/q/platform/js#retrieve-current-session
  let session;
  try {
    session = await Auth.currentSession();
  } catch (e) {
    // No current user session found for request
  }

  // Set auth header on all requests
  let idToken = session && session.getIdToken().getJwtToken();

  if (idToken) {
    config.headers.Authorization = "Bearer " + idToken;
  }

  return config;
};
