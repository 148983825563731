<template>
  <!-- GOALS -->
  <div v-if="page === 'goals'">
    <div>
      <p class="leading-loose text-h4 mb-2">{{ input.description }}</p>
      <input
        :name="input.attributes.name"
        :type="input.type"
        :disabled="disabled"
        v-model="value"
      />
    </div>
  </div>

  <!-- ALL ELSE -->
  <div v-else>
    <div class="mb-8">
      <label class="text-h3 font-bold"
        >{{ input.attributes.title }}
        <span v-if="required" class="text-red">*</span></label
      >
      <p class="leading-loose text-h4 mb-2">{{ input.description }}</p>
      <input
        :name="input.attributes.name"
        :type="input.type"
        :disabled="disabled"
        v-model="value"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "TextInput",
  props: {
    answer: String,
    page: String,
    disabled: Boolean,
    input: Object
  },
  data() {
    return {
      value: this.answer ? this.answer : ""
    };
  },
  computed: {
    required() {
      return (
        this.input.validation && this.input.validation.includes("required")
      );
    }
  },
  watch: {
    value() {
      this.$emit("input", this.value);
    },
    answer() {
      this.value = this.answer;
    }
  },
  mounted() {
    this.value = this.answer;
  }
};
</script>
