<template>
  <div v-if="loaded" class="w-full h-screen">
    <verify-email-confirmation-code-modal
      v-show="showConfirmEmailModal"
      :email="email"
      :message="
        `We've sent a confirmation code to your email address. Please
              re-enter it here to confirm your account.`
      "
      @confirmationComplete="emailConfirmationComplete"
    ></verify-email-confirmation-code-modal>
    <div class="relative h-full">
      <div
        class="overflow-auto block bg-blue-primary w-full min-h-full p-10 flex flex-col items-center text-black z-10"
      >
        <div v-if="loading">
          <spinner></spinner>
        </div>
        <div
          v-else-if="loaded"
          class="container mx-auto flex flex-col lg:flex-row rounded p-10 bg-white justify-evenly"
        >
          <div class="w-3/4">
            <h1 class="text-blue-primary mb-2">
              {{ organization }} is inviting you to join them on CSforALL
            </h1>

            <span class="mb-10 text-blue-primary mb-10">
              {{ headerInfo }}
            </span>
            <form
              method="POST"
              aria-label="Register"
              @submit.prevent="validateBeforeSubmit"
              class="form flex flex-col mt-5"
            >
              <div
                v-bind:class="{
                  'mb-3 w-100': true,
                  'has-error': errors.has('first name')
                }"
              >
                <label for="first-name">Your First Name</label>

                <input
                  id="first-name"
                  type="text"
                  name="first name"
                  v-model="firstName"
                  v-validate="'required'"
                  class="w-100"
                />
                <div class="mt-4">
                  <span
                    v-show="errors.has('first name')"
                    class="text-xs italic text-red"
                    >{{ errors.first("first name") }}</span
                  >
                </div>
              </div>

              <div
                v-bind:class="{
                  'mb-3 w-100': true,
                  'has-error': errors.has('last name')
                }"
              >
                <label for="last-name">Your Last Name</label>

                <input
                  id="last-name"
                  type="text"
                  name="last name"
                  v-model="lastName"
                  v-validate="'required'"
                  class="w-100"
                />
                <div class="mt-4">
                  <span
                    v-show="errors.has('last name')"
                    class="text-xs italic text-red"
                    >{{ errors.first("last name") }}</span
                  >
                </div>
              </div>

              <div
                v-bind:class="{
                  'mb-3 w-100': true,
                  'has-error': errors.has('email')
                }"
              >
                <label for="email">Your Email</label>

                <input
                  id="email"
                  type="email"
                  name="email"
                  v-model="email"
                  v-validate="'required'"
                  class="w-100"
                  disabled
                />
                <div class="mt-4">
                  <span
                    v-show="errors.has('email')"
                    class="text-xs italic text-red"
                    >{{ errors.first("email") }}</span
                  >
                </div>
              </div>

              <div
                v-bind:class="{
                  'mb-3 w-100': true,
                  'has-error': errors.has('password')
                }"
              >
                <label for="password">Password</label>
                <input
                  id="password"
                  v-model="password"
                  type="password"
                  v-validate="`required|min:${VALIDATION.MIN_PW_LEN}`"
                  class="form-control w-100"
                  name="password"
                  ref="password"
                />
                <div class="mt-4">
                  <span
                    v-show="errors.has('password')"
                    class="text-xs italic text-red"
                    >{{ errors.first("password") }}</span
                  >
                </div>
              </div>

              <div
                v-bind:class="{
                  'mb-3 w-100': true,
                  'has-error': errors.has('password confirmation')
                }"
              >
                <label for="password-confirm">Confirm Password</label>

                <input
                  id="password-confirm"
                  v-validate="
                    `required|confirmed:password|min:${VALIDATION.MIN_PW_LEN}`
                  "
                  type="password"
                  class="form-control"
                  name="password confirmation"
                />
                <div class="mt-4">
                  <span
                    v-show="errors.has('password confirmation')"
                    class="text-xs italic text-red"
                    >{{ errors.first("password confirmation") }}</span
                  >
                </div>
              </div>

              <div v-if="!isSchool" class="mb-3 w-100">
                <label for="schoolName">Your School (If Applicable)</label>
                <input id="schoolName" v-model.trim="schoolName" />
                <div class="mt-4"></div>
              </div>

              <div v-if="!isDistrict" class="mb-3 w-100">
                <label for="schoolDistrict"
                  >Your School District (If Applicable)</label
                >
                <input id="schoolDistrict" v-model.trim="schoolDistrict" />
                <div class="mt-4"></div>
              </div>

              <div
                v-bind:class="{
                  'mb-3 w-100': true,
                  'has-error': errors.has('title')
                }"
              >
                <label for="title">Your Title</label>

                <input
                  id="title"
                  type="title"
                  name="title"
                  v-model="title"
                  v-validate="'required'"
                  class="w-100"
                />
                <div class="mt-4">
                  <span
                    v-show="errors.has('title')"
                    class="text-xs italic text-red"
                    >{{ errors.first("title") }}</span
                  >
                </div>
              </div>

              <div
                v-bind:class="{
                  'mb-3 w-100': true,
                  'has-error': errors.has('your role')
                }"
              >
                <label for="your-role">Institutional Role</label>

                <div class="flex mb-2 w-100">
                  <div class="items-center w-full font-bold">
                    <v-select
                      label="name"
                      :options="roles"
                      v-model="role"
                      name="institutional role"
                      v-validate="'required'"
                      taggable
                      id="your-role"
                    >
                    </v-select>
                  </div>
                </div>

                <div class="mt-4">
                  <span
                    v-show="errors.has('institutional role')"
                    class="text-xs italic text-red"
                    >{{ errors.first("institutional role") }}</span
                  >
                </div>
              </div>

              <div class="flex my-5">
                <button type="submit" class="btn btn-blue">
                  Create Your Account
                </button>
              </div>
            </form>
          </div>
          <div class="w-2/4 flex items-center h-full ml-2">
            <div
              class="bg-blue-panel w-full rounded bg-grey-light p-5 flex flex-col"
            >
              <h2 class="text-blue-primary mt-10">
                Workshop: {{ workshop.title }}
              </h2>
              <h4 class="text-blue-primary mt-5">
                {{ formatDate(workshop.start_time) }} -
                {{ formatDate(workshop.end_time) }}
                {{ workshop.timezone }}
              </h4>
              <p class="text-blue-primary mt-5">
                Presenter Name: {{ workshop.presenter_first_name }}
                {{ workshop.presenter_last_name }}
              </p>
              <p class="text-blue-primary mt-10">
                Workshop Sponsored By: {{ workshop.event_sponsor_host }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.container {
  width: 70%;
}

.vertical-middle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.form-entry {
  margin-top: 10px;
}
.workshop {
  min-height: 75%;
  background-color: #e6ecf5;
}
.form {
  width: 80%;
}
</style>

<script>
import { mapGetters } from "vuex";
import vSelect from "vue-select";
import VerifyEmailConfirmationCode from "../modals/VerifyEmailConfirmationCode";
import {
  ROLES,
  PARTICIPANT_ROLES,
  ORG_TYPES,
  VALIDATION,
  LEA_TYPES
} from "../../const";
import _ from "lodash";
import { Auth } from "aws-amplify";
import { getDataOnSignIn } from "../../util/getDataOnSignIn";
import Spinner from "../Spinner.vue";
import { formatWorkshopDate } from "../../util/formatDate";

export default {
  components: {
    "verify-email-confirmation-code-modal": VerifyEmailConfirmationCode,
    "v-select": vSelect,
    spinner: Spinner
  },
  data() {
    return {
      firstName: undefined,
      lastName: undefined,
      organization: undefined,
      email: undefined,
      password: undefined,
      confirmPassword: undefined,
      role: undefined,
      title: undefined,
      schoolName: undefined,
      schoolDistrict: undefined,
      roles: Object.values(PARTICIPANT_ROLES),
      headerInfo: null,
      leaType: null,

      showConfirmEmailModal: false,
      VALIDATION: VALIDATION,
      loading: false,
      loaded: false,

      //invite info
      invitee: {},
      workshop: {},
      inviteInvalid: false,
      inviteStale: false
    };
  },
  computed: {
    ...mapGetters(["questionnaire", "user"]),
    isDistrict() {
      return this.leaType === LEA_TYPES.SCHOOL_DISTRICT;
    },
    isSchool() {
      return this.leaType === LEA_TYPES.INDIVIDUAL_SCHOOL;
    }
  },
  methods: {
    formatDate(date) {
      return formatWorkshopDate(date);
    },
    emailConfirmationComplete: async function() {
      this.showConfirmEmailModal = false;
      this.isSyncing = true;
      const user = await Auth.signIn(this.email, this.password);
      await getDataOnSignIn(this.$store, user);
      this.$router.push("/home");
    },
    loadValues: function() {
      this.email = this.invitee.email;
    },
    validateBeforeSubmit: function() {
      this.$validator.validateAll().then(result => {
        if (!result) {
          return;
        }
        let data = {
          first_name: this.firstName,
          last_name: this.lastName,
          email: this.email,
          password: this.password,
          password_confirmation: this.password,
          participant_role: this.role,
          title: this.title,
          school_name: this.schoolName,
          school_district: this.schoolDistrict,
          role: ROLES.LEA_PARTICIPANT,
          type: "member",
          org_types: [ORG_TYPES.EDUCATION_ASSOCIATION],
          organization: this.organization,
          member_id: this.invitee.member_id,
          invite: this.invitee.id
        };
        this.loading = true;
        console.log("loading - ", this.loading);
        window.axios
          .post("/api/register", data)
          .then(resp => {
            const field = this.$validator.fields.find({
              name: "email",
              scope: this.$options.scope
            });
            if (resp && resp.data.message != "ok") {
              this.$validator.errors.add({
                id: field.id,
                field: "email",
                msg: "Failed to register user",
                scope: this.$options.scope
              });
            } else {
              this.cognitoRegistration(data);
            }
            this.loading = false;
          })
          .catch(e => {
            if (e.response.data.errors) {
              _.forEach(e.response.data.errors, (message, id) => {
                const field = this.$validator.fields.find({
                  name: id,
                  scope: this.$options.scope
                });
                this.$validator.errors.add({
                  id: field.id,
                  field: id,
                  msg: message[0],
                  scope: this.$options.scope
                });
              });
            }
            this.loading = false;
          });
      });
    },
    cognitoRegistration: async function(data) {
      try {
        const { email, password } = data;
        await Auth.signUp({
          username: email,
          password: password
        });
        this.showConfirmEmailModal = true;
      } catch (error) {
        console.error(error);
      }
    }
  },
  async mounted() {
    this.loading = true;
    await window.axios
      .get(`/api/me/member/invite/${this.$route.params.invitationUuid}`)
      .then(res => {
        if (res.data.notFound) {
          this.inviteInvalid = true;
        } else if (res.data.stale) {
          this.inviteStale = true;
        } else {
          this.invitee = res.data;
          this.workshop = res.data.workshop;
          this.organization = res.data.member.name;
          this.leaType = res.data.member.leaType || null;
          this.headerInfo = `${this.organization} has invited you to join their Organization
            on the CSforALL Member Portal. To register a new user please fill 
            in the information below. You will join ${this.organization}'s Organization 
            once registered and have access to any workshops they've joined.`;
          this.loadValues();
        }

        if (this.inviteStale) {
          this.$router.push({ name: "TeamInviteUsed" });
        } else if (this.inviteInvalid) {
          this.$router.push({ name: "TeamInviteNotFound" });
        }

        this.loading = false;
        this.loaded = true;
      })
      .catch(error => {
        console.log("error getting invite info");
        console.log(error);
      });
  }
};
</script>
