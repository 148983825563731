<template>
  <div class="container p-20">
    Hold tight, logging you out...
  </div>
</template>

<script>
import { Auth } from "aws-amplify";
export default {
  data: () => ({}),
  computed: {
    isMasquerading: function() {
      return localStorage.getItem("Masquerade") ? true : false;
    }
  },
  methods: {
    handleLogout: function() {
      if (this.isMasquerading) {
        localStorage.removeItem("Masquerade");
        this.$store.dispatch("masqueradeOff");
        window.axios.get("/api/me").then(resp => {
          this.$store.dispatch("setUser", resp.data.data[0]);
          this.$router.push({ name: "dashboard" });
        });
      } else {
        this.cognitoSignOut();
        this.$store
          .dispatch("setTokens", { idToken: { jwtToken: false } })
          .then(() => {
            window.location.href = window.location.origin;
          });
      }
    },
    cognitoSignOut: async function() {
      try {
        await Auth.signOut();
      } catch (error) {
        console.log("error signing out: ", error);
      }
    }
  },
  mounted() {
    this.handleLogout();
  }
};
</script>
