<template>
  <div v-if="isLoading">
    <spinner></spinner>
  </div>
  <div v-else>
    <header class="fixed top-0 w-full z-10 py-5 bg-white border-b-2">
      <div class="flex container mx-auto px-5 items-end">
        <div class="w-1/3">
          <router-link
            v-if="form"
            :to="{
              name: 'public.workshops-show',
              params: { id: $route.query.workshop }
            }"
          >
            <p class="text-blue-primary font-bold container m-auto">
              &lt; Back to {{ workshop.title }}
            </p>
          </router-link>
        </div>
        <div class="w-1/3 text-center">
          <img class="w-40" src="@/assets/img/scriptlogo.png" alt="script" />
        </div>
      </div>
    </header>
    <!-- start -->
    <div class="bg-blue-panel pt-34">
      <div class="container mx-auto">
        <!-- if goals -->
        <div v-if="form.title === 'Goal Setting'">
          <GoalsSection
            :form="form"
            :answers="answers"
            :formData="formData"
            :user="user"
          >
          </GoalsSection>
        </div>
        <!-- end groups -->

        <!-- else for the others -->
        <div v-else>
          <RubricSection
            :form="form"
            :answers="answers"
            :formData="formData"
            :user="user"
          >
          </RubricSection>
          <!-- end if else -->
        </div>
      </div>
      <div class="pb-34"></div>
    </div>
    <!-- end -->
  </div>
</template>

<style scoped>
.bg-btn-goals {
  background-color: #d8dee8;
  height: 6vh;
  width: 100%;
}
.buttons-panel {
  width: 100%;
  display: grid;
  grid-template-columns: auto auto auto auto auto;
}
.center-div {
  margin: 0 auto;
}
</style>

<script>
import Spinner from "../../components/Spinner";

import GoalsSection from "../forms/FormAnswersGoals.vue";
import RubricSection from "../forms/FormAnswersRubric.vue";

import { mapGetters } from "vuex";

export default {
  name: "PublicFormAnswers",
  components: {
    Spinner,
    GoalsSection,
    RubricSection
  },
  data: () => ({
    answered: false,
    answers: {},
    form: {},
    formData: {},
    isLoading: true,
    registration: {},
    workshop: {}
  }),
  computed: {
    ...mapGetters({
      user: "user"
    })
  },
  methods: {
    getAnswers() {
      return window.axios
        .get(`/api/answers/${this.$route.params.id}`)
        .then(response => {
          if (response.data) {
            this.workshop = response.data.workshop;
            this.registration = response.data.registration;
            this.answers = response.data.answers;
            this.form = response.data.form.schema;
            if (response.data.answers && Object.keys(this.answers).length) {
              this.formData = Object.assign({}, this.answers);
            }
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  async mounted() {
    await this.getAnswers();
    this.isLoading = false;
  }
};
</script>
