<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "WorkshopModule",
  props: {},
  components: {},
  data: () => ({}),
  computed: {},
  watch: {},
  methods: {},
  created() {
    this.$store.dispatch("setActiveNav", "script");
  }
};
</script>
