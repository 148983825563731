export const WORKSHOP_SET = "WORKSHOP_SET";
export const WORKSHOPS_SET = "WORKSHOPS_SET";

const state = {
  list: {},
  active: {}
};

const getters = {
  active: state => (state.active.length ? true : false),
  workshop: state => state.active,
  workshops: state => state.list
};

const actions = {
  setWorkshop: ({ commit }, workshop) => {
    commit(WORKSHOP_SET, {
      workshop: workshop
    });
  },
  setWorkshops: ({ commit }, workshops) => {
    commit(WORKSHOPS_SET, {
      workshops: workshops
    });
  },
  getWorkshop: ({ commit, state }, workshop_id) => {
    if (!getters.active(state)) {
      return window.axios
        .get(`/api/workshops/${workshop_id}`)
        .then(response => {
          commit(WORKSHOP_SET, {
            workshop: response.data
          });
        });
    }
  }
};

const mutations = {
  WORKSHOP_SET: (state, payload) => {
    state.active = Object.assign({}, state.active, payload.workshop);
  },
  WORKSHOPS_SET: (state, payload) => {
    state.list = Object.assign({}, state.list, payload.workshops);
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
