var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoggedIn),expression:"isLoggedIn"}],staticClass:"w-full border-b mb-10"},[_c('alert-modal',{directives:[{name:"show",rawName:"v-show",value:(_vm.alertModal),expression:"alertModal"}],attrs:{"title":"Contact Membership","body":_vm.alertModalBody,"closeButton":"Close","closeButtonColor":"btn-blue"},on:{"close":function($event){_vm.alertModal = false}}}),_c('img',{staticClass:"hidden md:block md:absolute pin-t nav-circuits",attrs:{"src":require("@/assets/img/circuits-nav.svg"),"alt":"Circuits"}}),_c('div',{staticClass:"container mx-auto block text-center md:flex flex-row justify-between items-center my-6 relative"},[_c('router-link',{staticClass:"mr-3",attrs:{"to":"/home"}},[_c('img',{attrs:{"src":require("@/assets/img/csforall-logo.svg"),"alt":"CSforAll","width":"170"}})]),_c('ul',{staticClass:"list-reset mt-2 md:mt-0 flex flex-wrap justify-center md:justify-start"},[_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isMasquerading),expression:"isMasquerading"}],staticClass:"py-4 lg:py-0 mr-12 text-h4"},[_c('div',{staticClass:"text-red-dark font-medium"},[_vm._v(" Masquerading ")])]),(_vm.user && _vm.user.id)?_c('li',{directives:[{name:"show",rawName:"v-show",value:(!_vm.user.is_admin),expression:"!user.is_admin"}],staticClass:"py-4 lg:py-0 mr-6 text-h4"},[_c('img',{staticStyle:{"vertical-align":"middle"},attrs:{"src":require("@/assets/img/icon-user.svg"),"alt":"Profile icon torso in a circle","width":"22"}}),_vm._v("   "),_c('router-link',{class:{
            'text-blue-primary hover:text-blue-light font-medium hover:font-bold': true,
            active: _vm.activeNav == 'account'
          },attrs:{"to":"/home"}},[_vm._v("Portal")])],1):_vm._e(),(_vm.user && _vm.user.id)?_c('li',{directives:[{name:"show",rawName:"v-show",value:(!_vm.user.is_admin),expression:"!user.is_admin"}],staticClass:"py-4 lg:py-0 mr-6 text-h4"},[_c('img',{staticStyle:{"vertical-align":"middle"},attrs:{"src":require("@/assets/img/icon-gear-line.svg"),"alt":"settings icon line drawing of a gear","width":"22"}}),_vm._v("   "),_c('router-link',{class:{
            'text-blue-primary hover:text-blue-light font-medium hover:font-bold': true,
            active: _vm.activeNav == 'account'
          },attrs:{"to":"/home/account"}},[_vm._v("Settings")])],1):_vm._e(),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.user.is_admin),expression:"user.is_admin"}],staticClass:"py-4 lg:py-0 mr-6 text-h4"},[_c('router-link',{class:{
            'text-grey-dark hover:text-blue-primary font-medium': true,
            active: _vm.activeNav == 'admin'
          },attrs:{"to":"/admin"}},[_vm._v("Admin")])],1),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.user.is_admin),expression:"user.is_admin"}],staticClass:"py-4 lg:py-0 mr-6 text-h4"},[_c('router-link',{class:{
            'text-grey-dark hover:text-blue-primary font-medium': true,
            active: _vm.activeNav == 'curricula'
          },attrs:{"to":"/admin/curricula"}},[_vm._v("Curricula List")])],1),_c('li',{staticClass:"mr-6 py-4 lg:py-0 text-grey-light hidden md:block"},[_vm._v("|")]),(_vm.user && _vm.user.id)?_c('li',{directives:[{name:"show",rawName:"v-show",value:(!_vm.user.is_admin),expression:"!user.is_admin"}],staticClass:"py-4 lg:py-0 mr-6 text-h4"},[_c('img',{staticStyle:{"vertical-align":"middle"},attrs:{"src":require("@/assets/img/contact-icon-3.png"),"alt":"contact icon of an outline of an envelope","width":"22"}}),_vm._v("   "),_c('a',{staticClass:"text-blue-primary hover:text-blue-light font-light font-normal",attrs:{"id":"email-copy"},on:{"click":function($event){return _vm.copyToClipboard(_vm.memberEmail)}}},[_vm._v("Contact")])]):_vm._e(),_c('li',{staticClass:"py-4 lg:py-0 text-h4"},[_c('router-link',{class:{ 'text-grey-dark hover:text-blue-primary': true },attrs:{"to":"/auth/logout"}},[_vm._v("Logout")])],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }