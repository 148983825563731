<template>
  <div v-if="page === 'goals'">
    <div class="border-goals mb-10">
      <h3 class="text-h3 font-bold mb-2">
        {{ input.attributes.title }}
        <span v-if="required" class="text-red">*</span>
      </h3>
      <p class="leading-loose text-h4 mb-2">{{ input.description }}</p>
      <div class="flex flex-wrap">
        <div
          v-for="(option, index) in input.options"
          v-bind:key="index"
          class="select-box w-full md:w-1/2 checkbox-toolbar"
        >
          <Checkbox
            :name="input.attributes.name"
            :option="option"
            :checked="checkboxAnswer(option.id)"
            @input="onChange($event)"
            :disabled="disabled"
            class="checkbox-check"
          >
          </Checkbox>
        </div>
      </div>
    </div>
  </div>

  <div v-else>
    <div class="mb-10">
      <h3 class="text-h3 font-bold mb-2">
        {{ input.attributes.title }}
        <span v-if="required" class="text-red">*</span>
      </h3>
      <p class="leading-loose text-h4 mb-2">{{ input.description }}</p>
      <div class="flex flex-wrap">
        <div
          v-for="(option, index) in input.options"
          v-bind:key="index"
          class="select-box w-full md:w-1/2 checkbox-toolbar"
        >
          <Checkbox
            :name="input.attributes.name"
            :option="option"
            :checked="checkboxAnswer(option.id)"
            @input="onChange($event)"
            :disabled="disabled"
            class="checkbox-check"
          >
          </Checkbox>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.border-goals {
  border: 1px solid grey;
  border-radius: 0px 0px 8px 8px;
  border-style: none solid solid solid;
  padding-left: 30px;
  padding-right: 10px;
  padding-bottom: 30px;
  padding-top: 40px;
}

.checkbox-toolbar input[type="checkbox"]:checked ~ * {
  background-color: #e6ecf5 !important;
}
</style>

<script>
import Checkbox from "./Checkbox.vue";
import _ from "lodash";

export default {
  name: "CheckboxInput",
  props: {
    input: Object,
    page: String,
    answer: Array,
    disabled: Boolean
  },
  components: {
    Checkbox
  },
  $_veeValidate: {
    // must not be arrow functions.
    // the name getter
    name() {
      return this.input.attributes.name;
    },
    // the value getter
    value() {
      return this.checkboxes; // `this` is the component instance, `value` is the prop we added.
    }
  },
  data: () => ({
    checkboxes: []
  }),

  watch: {
    answer(answer) {
      this.updateFromAnswer(answer);
    }
  },
  computed: {
    required() {
      return (
        this.input.validation &&
        (this.input.validation.includes("required") ||
          this.input.validation.includes("atLeastOne"))
      );
    }
  },
  methods: {
    checkboxAnswer(id) {
      let a = _.find(this.answer, function(a) {
        return a.id == id;
      });

      return a ? a.value : false;
    },
    updateFromAnswer(answer) {
      if (answer) {
        if (this.checkboxes == null) {
          this.checkboxes = [];
        }
        this.checkboxes = JSON.parse(JSON.stringify(this.answer));
        this.$emit("input", this.checkboxes);
      }
    },
    onChange(answer) {
      console.log("registered a change!");
      let i = _.findIndex(this.checkboxes, function(a) {
        return a.id == answer.id;
      });

      if (i != -1) {
        this.checkboxes[i].value = answer.value;
      } else {
        this.checkboxes.push(answer);
      }

      // if they're all false, send null
      if (
        _.find(this.checkboxes, function(a) {
          return a.value == true;
        })
      ) {
        this.$emit("input", this.checkboxes);
      } else {
        this.checkboxes = null;
        this.$emit("input", null);
      }
    }
  },
  mounted() {
    this.updateFromAnswer(this.answer);
  }
};
</script>
