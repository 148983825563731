<template>
  <div class="relative pb-32">
    <div class="container mx-auto pb-20 h-screen w-full: lg:w-2/5">
      <div class="text-center">
        <img
          src="@/assets/img/circuits-grey.svg"
          alt="Circuits"
          class="relative pin-t margin-auto"
        />
      </div>

      <div class="text-center mt-6">
        <a href="/home"
          ><img
            src="@/assets/img/csforall-logo.svg"
            alt="CSforAll"
            width="260"
            class="mb-10 main-logo"
        /></a>
        <h1 class="text-center">Password Reset</h1>
        <p v-show="required" class="mt-3 mb-10 text-center">
          A password reset is required for your account.
        </p>
        <p class="mt-3 mb-10 text-center">
          A code has been sent to your email address to confirm your password
          reset. Please enter it below along with your new password.
        </p>
        <form
          method="POST"
          aria-label="Forgot Password"
          @submit.prevent="validateBeforeSubmit"
        >
          <div
            v-bind:class="{
              'mb-8 text-left': true,
              'has-error': errors.has('code')
            }"
          >
            <label for="email">Verification Code</label>

            <input
              id="code"
              type="text"
              name="code"
              v-model="code"
              v-validate="'required|numeric|length:6'"
            />
            <span v-show="errors.has('code')" class="text-red text-xs italic">{{
              errors.first("code")
            }}</span>
          </div>

          <div
            v-bind:class="{
              'mb-8 text-left': true,
              'has-error': errors.has('password')
            }"
          >
            <label for="password">Password</label>

            <input
              id="password"
              v-model="password"
              type="password"
              v-validate="`required|min:${VALIDATION.MIN_PW_LEN}`"
              class="form-control"
              name="password"
              ref="password"
            />
            <span
              v-show="errors.has('password')"
              class="text-red text-xs italic"
              >{{ errors.first("password") }}</span
            >
          </div>

          <div
            v-bind:class="{
              'mb-8 text-left': true,
              'has-error': errors.has('password_confirmation')
            }"
          >
            <label for="password-confirm">Confirm Password</label>

            <input
              id="password-confirm"
              v-validate="
                `required|confirmed:password|min:${VALIDATION.MIN_PW_LEN}`
              "
              type="password"
              class="form-control"
              name="password_confirmation"
            />
            <span
              v-show="errors.has('password_confirmation')"
              class="text-red text-xs italic"
              >{{ errors.first("password_confirmation") }}</span
            >
          </div>
          <a href="mailto:script@csforall.org" class="text-sm flex float-right">
            Having trouble? <b class="ml-1"> Contact Us</b>
          </a>
          <div class="text-left">
            <button type="submit" class="btn btn-blue mb-10">
              Reset my password
            </button>
          </div>
        </form>
      </div>
    </div>
    <div class="blue-bar"></div>
  </div>
</template>

<script>
import { Auth } from "aws-amplify";
import { VALIDATION } from "../../const";

export default {
  data() {
    return {
      VALIDATION: VALIDATION,
      email: null,
      password: null,
      code: null,
      required: false
    };
  },
  methods: {
    validateBeforeSubmit: function() {
      this.$validator.validateAll().then(result => {
        if (!result) {
          return;
        }

        Auth.forgotPasswordSubmit(
          this.$route.query.email,
          this.code,
          this.password
        )
          .then(() => {
            this.$toasted.global.success("Password Reset, please log in");
            this.$router.push("/auth/login");
          })
          .catch(err => console.log(err));
      });
    }
  },
  mounted() {
    if (this.$route.query.required) {
      this.required = this.$route.query.required;
    }
  }
};
</script>
