export const getDataOnSignIn = async (store, user) => {
  store.dispatch("setTokens", user.signInUserSession);
  await Promise.all([
    window.axios.get("/api/me").then(resp => {
      store.dispatch("setUser", resp.data.data[0]);
    }),
    window.axios.get("/api/category/option").then(resp => {
      store.dispatch("setAudiences", resp.data.data[0].audiences.data);
      store.dispatch("setLevels", resp.data.data[0].levels.data);
      store.dispatch("setOthers", resp.data.data[0].others.data);
      store.dispatch("setRegions", resp.data.data[0].regions.data);
      store.dispatch("setResearchTypes", resp.data.data[0].researchTypes.data);
      store.dispatch("setSettings", resp.data.data[0].settings.data);
      store.dispatch("setStudents", resp.data.data[0].students.data);
      store.dispatch("setTeachers", resp.data.data[0].teachers.data);
      store.dispatch("setTypes", resp.data.data[0].types.data);
    })
  ]);
};
