<template>
  <div class="relative pb-32">
    <div class="container mx-auto">
      <div
        class="mb-2 block lg:flex justify-between items-center mb-10 lg:mb-15"
      >
        <h1>
          {{ isAdminMode ? "Curricula" : "Pending Certification" }}
          <span>Total submitted: {{ curricula.length }}</span>
          <span>Total reviews: {{ totalReviews }}</span>
        </h1>
        <div class="no-flex md:flex items-center" v-show="isAdminMode">
          <div class="my-2 md:my-0">
            <span
              :disabled="!canExport"
              :class="{
                'opacity-50 cursor-not-allowed': !canExport,
                'text-blue-primary hover:text-blue-light pr-6 flex cursor-pointer': true
              }"
              @click="handleExport"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="16px"
                height="16px"
                viewBox="0 0 16 16"
              >
                <g transform="translate(0, 0)">
                  <polygon
                    data-color="color-2"
                    fill="#3061ac"
                    points="16,0 10,0 12.293,2.293 6.022,8.564 7.436,9.978 13.707,3.707 16,6 "
                  ></polygon>
                  <path
                    fill="#3061ac"
                    d="M13,16H1c-0.552,0-1-0.448-1-1V3c0-0.552,0.448-1,1-1h6v2H2v10h10V9h2v6C14,15.552,13.552,16,13,16z"
                  ></path>
                </g>
              </svg>
              <span class="ml-2">Export All</span>
            </span>
          </div>
        </div>
      </div>

      <div v-show="!isSyncing">
        <div v-if="curricula.length">
          <div class="hidden lg:flex items-center">
            <div class="w-full lg:w-1/3 p-4 lg:pl-10">
              <h4
                class="font-bold pr-6 flex text-blue-primary hover:text-blue-light cursor-pointer"
                @click="handleSort('name')"
              >
                Name
              </h4>
            </div>
            <div class="w-full lg:w-1/3 p-4 lg:pl-10">
              <h4
                class="font-bold pr-6 flex text-blue-primary hover:text-blue-light cursor-pointer"
                @click="handleSort('claimed')"
              >
                Claimed
              </h4>
            </div>
            <div v-show="isAdminMode" class="w-full lg:w-1/3 p-4 lg:pl-10">
              <h4
                class="font-bold pr-6 flex text-blue-primary hover:text-blue-light cursor-pointer"
                @click="handleSort('status')"
              >
                Status
              </h4>
            </div>
            <div class="w-full lg:w-1/3 p-4 lg:pl-10">
              <h4
                class="font-bold pr-6 flex text-blue-primary hover:text-blue-light cursor-pointer"
                @click="handleSort('updated')"
              >
                Aligned
              </h4>
            </div>
            <div class="w-full lg:w-1/3 p-4 lg:pl-10">
              <h4
                class="font-bold  pr-6 flex text-blue-primary hover:text-blue-light cursor-pointer"
                @click="handleSort('updated')"
              >
                BPC
              </h4>
            </div>
            <div class="w-full lg:w-1/3 p-4 lg:pl-10">
              <h4
                class="font-bold pr-6 flex text-blue-primary hover:text-blue-light cursor-pointer"
                @click="handleSort('updated')"
              >
                Updated
              </h4>
            </div>
          </div>

          <div
            v-for="(item, index) in curricula"
            v-bind:key="'curriculum' + item.id"
            v-bind:class="{
              'block lg:flex items-center p-4 mb-2': true,
              'bg-grey-lightest': !(index % 2)
            }"
            class="content-center"
          >
            <div
              class="w-full lg:w-1/3 p-4 border-b lg:p-0 lg:border-0 font-bold"
            >
              <router-link
                :to="'/home/certify/' + item.uuid"
                :class="{ 'disabled text-grey': !item.name }"
                >{{ item.name ? item.name : "No name" }}</router-link
              >
            </div>
            <div class="w-full lg:w-1/3 p-4 border-b lg:p-0 lg:border-0">
              <span v-if="item.claimedBy && item.claimedBy.data.uuid">
                {{ item.claimedBy.data.first_name }}
                {{ item.claimedBy.data.last_name }}
              </span>
              <span v-else>
                <button
                  @click="setClaim(item.uuid)"
                  class="ml-1 btn btn-green btn-sm"
                >
                  Claim
                </button>
              </span>
            </div>
            <div
              v-show="isAdminMode"
              class="w-full lg:w-1/3 p-4 border-b lg:p-0 lg:border-0"
            >
              {{ item.status_string }}
            </div>
            <div class="w-full lg:w-1/3 p-4 border-b lg:p-0 lg:border-0">
              <label class="pl-0 font-normal" :for="index">
                <input
                  type="checkbox"
                  :id="index"
                  v-model="item.aligned"
                  @change="setIsAligned(item.uuid, item.aligned)"
                />
                Is Aligned
              </label>
            </div>
            <div class="w-full lg:w-1/3 p-4 border-b lg:p-0 lg:border-0">
              <label class="pl-0 font-normal" :for="`${index}` + 1">
                <input
                  type="checkbox"
                  :id="`${index}` + 1"
                  v-model="item.BPC_Curriculum"
                  @change="setIsBPC(item.uuid, item.BPC_Curriculum)"
                />
                Is BPC
              </label>
            </div>
            <div class="w-full lg:w-1/3 p-4 border-b lg:p-0 lg:border-0">
              <span v-if="item.last_updated">
                {{ formatDate(item.last_updated.date) }}
              </span>
            </div>
          </div>
          <!-- pagination -->
          <div class="text-right mt-6">
            <span
              class="text-blue underline cursor-pointer"
              v-if="pagination.links && pagination.links.previous"
              @click="handlePaginate(pagination.links.previous)"
            >
              &#171; Previous
            </span>
            <span class="text-grey cursor-not-allowed mr-2" v-else>
              &#171; Previous
            </span>
            <span
              class="text-blue underline cursor-pointer"
              v-if="pagination.links && pagination.links.next"
              @click="handlePaginate(pagination.links.next)"
            >
              Next &#187;
            </span>
            <span class="text-grey cursor-not-allowed" v-else>
              Next &#187;
            </span>
          </div>
        </div>
        <div v-else>No Curricula Match Your Results</div>
      </div>
      <spinner v-show="isSyncing"></spinner>
    </div>
    <div v-show="!isSyncing" class="blue-bar"></div>
  </div>
</template>
<style scoped>
h1 span {
  font-size: 1rem;
  font-weight: normal;
  display: block;
}
label.font-normal {
  font-size: 16px;
}
</style>
<script>
import { mapGetters } from "vuex";
import UrlParse from "url-parse";
import moment from "moment";

export default {
  data: () => ({
    curricula: [],
    pagination: {},
    currentPage: 1,
    search: "",
    isSyncing: true
  }),
  computed: {
    ...mapGetters({
      user: "user",
      isLoggedIn: "isLoggedIn",
      activeFilter: "activeFilter",
      activeSort: "activeSort",
      activeSortDir: "activeSortDir"
    }),
    isAdminMode: function() {
      return this.$route.name == "curricula-admin";
    },
    canExport: function() {
      return !this.isSyncing && this.curricula?.length;
    },
    totalReviews: function() {
      return this.curricula.filter(m => !m.publish_to_directory).length;
    }
  },
  watch: {
    $route() {
      window.axios.get("/api/user/curriculum").then(resp => {
        this.curricula = resp.data.data;
        this.pagination = resp.data.meta.pagination;
      });
    }
  },
  methods: {
    formatDate(date) {
      return moment(date).format("M/D/YYYY, h:mm:ss A");
    },
    handlePaginate: function(url) {
      const parsed = new UrlParse(url, null, true);
      this.isSyncing = true;
      this.currentPage = parsed.query.page;

      let query = { page: parsed.query.page };

      window.axios.get("/api/user/curriculum", { params: query }).then(resp => {
        this.curricula = resp.data.data;
        this.pagination = resp.data.meta.pagination;

        this.isSyncing = false;
      });
    },
    handleExport: function() {
      if (!this.canExport) {
        return;
      }

      this.isSyncing = true;
      window
        .axios({
          url: "/api/user/curriculum/export",
          method: "GET",
          responseType: "blob"
        })
        .then(resp => {
          this.isSyncing = false;
          const url = window.URL.createObjectURL(new Blob([resp.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "csforall-curricula.csv");
          document.body.appendChild(link);
          link.click();
        });
    },
    checkAccess: function() {
      if (!this.user.id) {
        // defer
        return;
      }
      if (!this.user.is_admin && !this.user.can_approve_alignments) {
        this.$router.push("/home");
      }
    },
    setIsAligned: function(curriculum, data) {
      const payloadData = {
        aligned: data
      };

      window.axios
        .put("/api/user/curriculum/" + curriculum + "/aligned", payloadData)
        .then(() => {
          this.$toasted.show("Curricula updated");
          let query = { page: this.currentPage };

          window.axios
            .get("/api/user/curriculum", { params: query })
            .then(resp => {
              this.curricula = resp.data.data;
              this.pagination = resp.data.meta.pagination;
            });
        });
    },
    setIsBPC: function(curriculum, data) {
      const payloadData = {
        BPC_Curriculum: data
      };

      window.axios
        .put("/api/user/curriculum/" + curriculum + "/bpc", payloadData)
        .then(() => {
          this.$toasted.show("Curricula updated");
          let query = { page: this.currentPage };

          window.axios
            .get("/api/user/curriculum", { params: query })
            .then(resp => {
              this.curricula = resp.data.data;
              this.pagination = resp.data.meta.pagination;
            });
        });
    },
    setCertify: function(curriculum) {
      this.isSyncing = true;
      const data = {
        certify: 1
      };

      window.axios.put("/api/user/curriculum/" + curriculum, data).then(() => {
        this.$toasted.show("Curriculum certified");
        let query = { page: this.currentPage };

        window.axios
          .get("/api/user/curriculum", { params: query })
          .then(resp => {
            this.curricula = resp.data.data;
            this.pagination = resp.data.meta.pagination;
            this.isSyncing = false;
          });
      });
    },
    setClaim: function(curriculum) {
      this.isSyncing = true;
      const data = {
        claim: 1
      };

      window.axios
        .put("/api/user/curriculum/" + curriculum + "/claim", data)
        .then(() => {
          this.$toasted.show("Curriculum claimed");

          const query = { page: this.currentPage };

          window.axios
            .get("/api/user/curriculum", { params: query })
            .then(resp => {
              this.curricula = resp.data.data;
              this.pagination = resp.data.meta.pagination;
              this.isSyncing = false;
            });
        });
    },
    handleSort: function(col) {
      const newSort = col;
      let newDir = this.activeSortDir;
      if (col == this.activeSort) {
        newDir = this.activeSortDir == "asc" ? "desc" : "asc";
      }

      let query = [];
      query.push("sort=" + newSort);
      query.push("dir=" + newDir);
      if (this.activeFilter == "pending") {
        query.push("filter=pending");
      }
      this.isSyncing = true;

      this.$store.dispatch("setActiveSort", newSort);
      this.$store.dispatch("setActiveSortDir", newDir);

      console.log("query", query);

      window.axios.get("/api/user/curriculum?" + query.join("&")).then(resp => {
        console.log("resp", resp);
        this.isSyncing = false;
        this.curricula = resp.data.data;
        this.pagination = resp.data.meta.pagination;
      });
    }
  },
  created() {
    if (this.isAdminMode) {
      this.$store.dispatch("setActiveNav", "curricula");
    } else {
      this.$store.dispatch("setActiveNav", "certify");
    }

    this.$store.subscribe(mutation => {
      if (mutation.type != "USER_SET") {
        return;
      }
      this.checkAccess();
    });
  },
  async mounted() {
    this.checkAccess();

    const resp = await window.axios.get("/api/user/curriculum");

    this.curricula = resp.data.data;
    this.pagination = resp.data.meta.pagination;

    this.isSyncing = false;
  }
};
</script>
