<template>
  <div class="w-full h-full">
    <div class="relative flex flex-col">
      <img
        src="@/assets/img/circuits-white.svg"
        alt="Circuits"
        class="md:block absolute pin-t pin-r pin-l ml-8 opacity-25 lg:opacity-100"
      />
      <div
        class="block items-center justify-center text-center w-full h-screen p-5 bg-blue-primary lg:p-10 md:mb-0"
      >
        <div class="text-white">
          <p class="mb-10 text-h1 font-bold">
            These are not the Droids you're looking for
          </p>
        </div>

        <div
          class="box w-3/4  bg-blue-panel  z-2 rounded text-blue-primary p-10  mx-auto"
        >
          <h1 class="text-h1 font-bold  mb-3">
            {{ headerMessage }}
          </h1>
          <p class="text-h3 font-medium mt-10 mb-6">
            Please contact your facilitator for more information and to request
            a new invite.
          </p>
          <router-link class="btn btn-blue" to="/auth/login"
            >Go to Login</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BAD_INVITES } from "@/const";

export default {
  name: "WorkshopBadInvite",
  props: {
    inviteType: {
      type: String,
      required: true
    }
  },
  computed: {
    headerMessage() {
      if (
        this.inviteType === BAD_INVITES.WORKSHOP_STALE ||
        this.inviteType === BAD_INVITES.TEAM_STALE
      ) {
        return "This invite was already used";
      } else if (this.inviteType === BAD_INVITES.WORKSHOP_NOT_FOUND) {
        return "Workshop invite not found";
      } else if (this.inviteType === BAD_INVITES.TEAM_NOT_FOUND) {
        return "Team invite not found";
      } else {
        return "";
      }
    },
    bodyMessage() {
      if (this.inviteType === BAD_INVITES.WORKSHOP_STALE) {
        return "Please request a new invite from your facilitator.";
      } else if (this.inviteType === BAD_INVITES.WORKSHOP_NOT_FOUND) {
        return "Please contact your facilitator for more information and to request a new invite.";
      } else if (
        this.inviteType === BAD_INVITES.TEAM_STALE ||
        this.inviteType === BAD_INVITES.TEAM_NOT_FOUND
      ) {
        return "Please request a new invite from your Organzation's Admin user.";
      } else {
        return "";
      }
    }
  }
};
</script>

<style scoped></style>
