<template>
  <transition name="modal" v-show="value">
    <div class="modal-mask h-full flex justicy-center items-center">
      <div v-if="loading">
        <spinner></spinner>
      </div>
      <confirm-modal
        v-show="confirmResend"
        title=""
        :body="confirmResendBody"
        actionButton="Resend"
        cancelButton="Cancel"
        actionButtonColor="btn-blue"
        cancelButtonColor="btn-grey-light-2"
        @cancel="confirmResend = false"
        @confirm="resendInvite"
      ></confirm-modal>

      <remove-LEA-modal
        v-show="confirmLEARemove"
        title=""
        :body="confirmLEARemoveBody"
        actionButton="Remove"
        cancelButton="Cancel"
        actionButtonColor="btn-blue"
        cancelButtonColor="btn-grey-light-2"
        @cancel="
          confirmLEARemove = false;
          activeMenu = null;
        "
        @confirm="removeLEAParticipant"
      ></remove-LEA-modal>

      <remove-LEA-pending-modal
        v-show="confirmLEAPendingRemove"
        title=""
        :body="confirmLEAPendingRemoveBody"
        actionButton="Remove"
        cancelButton="Cancel"
        actionButtonColor="btn-blue"
        cancelButtonColor="btn-grey-light-2"
        @cancel="
          confirmLEAPendingRemove = false;
          activeMenu = null;
        "
        @confirm="removeLEAParticipantPending"
      ></remove-LEA-pending-modal>

      <switch-LEA-modal
        v-show="confirmLEASwitch"
        title=""
        :body="confirmLEASwitchBody"
        actionButton="Switch"
        cancelButton="Cancel"
        actionButtonColor="btn-blue"
        cancelButtonColor="btn-grey-light-2"
        @cancel="confirmLEASwitch = false"
        @confirm="switchLEAParticipant"
      ></switch-LEA-modal>

      <switch-LEA-modal
        v-show="confirmLEASwitch"
        title=""
        :body="confirmLEASwitchBody"
        actionButton="Switch"
        cancelButton="Cancel"
        actionButtonColor="btn-blue"
        cancelButtonColor="btn-grey-light-2"
        @cancel="confirmLEASwitch = false"
        @confirm="switchLEAParticipant"
      ></switch-LEA-modal>

      <div class="modal-wrapper flex h-full justify-center items-center w-full">
        <div
          class="modal-box flex flex-col p-2 modal-container border-2 bg-white rounded shadow m-auto w-1/2 md:w-4/4 lg:w-3/4 pt-6 pb-10 lg:pb-24"
        >
          <!-- lea admin -->
          <div v-if="isLEAAdmin">
            <div class="flex justify-between items-center">
              <h2 class="text-blue-primary mb-5">Team Management</h2>
              <button @click.prevent="close">
                <h1 class="text-blue-primary mr-5">X</h1>
              </button>
            </div>
            <h4 class="text-black font-bold mb-2">
              Invite participants through email
            </h4>

            <div v-bind:class="{ 'mb-6 ': true }">
              <div class="flex">
                <input
                  ref="emailInput"
                  class="w-4/6"
                  id="participant"
                  type="text"
                  name="participant"
                  v-model="currentParticipant"
                  v-validate.continues="'required|email'"
                  placeholder="address@example.com"
                />
                <button
                  :disabled="btnTimer"
                  class="w-1/6 ml-2 btn btn-blue"
                  @click="sendInvite"
                >
                  Invite
                </button>
              </div>
              <div v-if="emailError == true" class="text-red">
                Email must be in valid format
              </div>
            </div>

            <h4 class="text-black font-bold mb-5">Team Participants</h4>

            <div
              class="table-container align-middle inline-block rounded-bl-lg rounded-br-lg w-full h-full"
            >
              <table
                class="table overflow-scroll overflow-y-scroll flex flex-col flex-no-wrap w-full h-full"
                id="table"
                @scroll="updateScroll()"
              >
                <tr class="text-blue-primary flex w-full">
                  <th
                    class="w-1/4 px-6 py-4 border-b-2 border-gray-300 text-left leading-4 text-blue-500 tracking-wider"
                  >
                    Name
                  </th>
                  <th
                    class="w-1/4 px-6 py-4 border-b-2 border-gray-300 text-left leading-4 text-blue-500 tracking-wider"
                  >
                    Email
                  </th>
                  <th
                    class="w-1/4 px-6 py-4 border-b-2 border-gray-300 text-left leading-4 text-blue-500 tracking-wider"
                  >
                    Institutional Role
                  </th>
                  <th
                    class="w-1/4 px-6 py-4 border-b-2 border-gray-300 text-center leading-4 text-blue-500 tracking-wider"
                  ></th>
                  <th class=" border-b-2 border-gray-300"></th>
                </tr>

                <tbody class="bg-white flex-1 sm:flex-none w-full">
                  <tr
                    v-for="participant in confirmedParticipants"
                    :key="participant.id"
                    class="flex w-full"
                  >
                    <td
                      class="w-1/4 px-6 py-4 whitespace-no-wrap overflow-scroll border-b border-gray-500"
                    >
                      {{ participant.first_name }}
                      {{ participant.last_name }}
                    </td>
                    <td
                      class="w-1/4 px-6 py-4 whitespace-no-wrap overflow-scroll border-b border-gray-500"
                    >
                      {{ participant.email }}
                    </td>
                    <td
                      class="w-1/4 px-6 py-4 whitespace-no-wrap overflow-scroll border-b text-blue-900 border-gray-500 leading-5"
                    >
                      {{ participant.participantRole.data.name }}
                    </td>
                    <td
                      class="w-1/4 text-center px-6 py-4 whitespace-no-wrap overflow-scroll border-b text-blue-900 border-gray-500 leading-5"
                    >
                      <div
                        v-show="!participant.roles.includes('LEAAdmin')"
                        class="position-relative inline-block"
                      >
                        <button
                          type="button"
                          class="dropdown inline-block relative"
                          :id="`active-${participant.id}`"
                          @click="menuClick($event)"
                          aria-haspopup="true"
                          aria-expanded="true"
                        >
                          <span
                            class="btn btn-white border-solid border-2 border-black"
                          >
                            Actions
                            <img
                              class="w-4"
                              src="@/assets/img/dropdown_arrow.svg"
                            />
                          </span>
                        </button>
                        <span
                          class="dropdown position-absolute"
                          v-show="activeMenu == `active-${participant.id}`"
                        >
                          <div
                            class="display-block dropdown-menu flex flex-col absolute text-grey-dark bg-white pt-1 ml-2 border-2 border-gray-300 z-10"
                            :style="scrollStyles"
                          >
                            <div
                              class="display-block rounded-none hover:bg-grey-light py-4 px-6 block whitespace-no-wrap border-b-2 border-gray-300 cursor-pointer"
                              @click="
                                confirmLEASwitchModal(
                                  participant.id,
                                  participant.first_name
                                )
                              "
                            >
                              <button type="button">
                                Convert to LEA POC
                              </button>
                            </div>
                            <div
                              class="display-block rounded-none hover:bg-grey-light py-4 px-6 block whitespace-no-wrap cursor-pointer"
                              @click="
                                removeLEAParticipantModal(
                                  participant.uuid,
                                  participant.email
                                )
                              "
                            >
                              <button type="button">
                                Remove
                              </button>
                            </div>
                          </div>
                        </span>
                      </div>
                    </td>
                  </tr>
                  <tr
                    v-for="participant in pendingParticipants"
                    :key="'pending' + participant.id"
                    class="flex w-full"
                  >
                    <td
                      class="w-1/4 px-6 py-4 whitespace-no-wrap overflow-scroll border-b border-gray-500"
                    >
                      Invite Pending
                    </td>
                    <td
                      class="w-1/4 px-6 py-4 whitespace-no-wrap overflow-scroll border-b border-gray-500"
                    >
                      {{ participant.email }}
                    </td>
                    <td
                      class="w-1/4 px-6 py-4 whitespace-no-wrap overflow-scroll border-b text-blue-900 border-gray-500 leading-5 bold"
                    >
                      - -
                    </td>
                    <td
                      class="w-1/4 text-center px-6 py-4 whitespace-no-wrap overflow-scroll border-b text-blue-900 border-gray-500 leading-5"
                    >
                      <!-- start -->
                      <div class="position-relative inline-block">
                        <button
                          type="button"
                          class="dropdown inline-block relative"
                          :id="`pending-${participant.id}`"
                          @click="menuClick($event)"
                          aria-haspopup="true"
                          aria-expanded="true"
                        >
                          <span
                            class="btn btn-white border-solid border-2 border-black"
                          >
                            Actions
                            <img
                              class="w-4"
                              src="@/assets/img/dropdown_arrow.svg"
                            />
                          </span>
                        </button>
                        <span
                          class="dropdown position-absolute"
                          v-show="activeMenu == `pending-${participant.id}`"
                        >
                          <div
                            class="display-block dropdown-menu flex flex-col absolute text-grey-dark bg-white pt-1 ml-2 border-2 border-gray-300 z-10"
                            :style="scrollStyles"
                          >
                            <div
                              class="display-block rounded-none hover:bg-grey-light py-4 px-6 block whitespace-no-wrap border-b-2 border-gray-300 cursor-pointer"
                              @click="
                                removeLEAParticipantPendingModal(
                                  participant.email,
                                  participant.id
                                )
                              "
                            >
                              <button type="button">
                                Remove
                              </button>
                            </div>
                            <div
                              class="display-block rounded-none hover:bg-grey-light py-4 px-6 block whitespace-no-wrap cursor-pointer"
                              @click="
                                resendInviteByParams({
                                  id: participant.id,
                                  email: participant.email
                                })
                              "
                            >
                              <button type="button">
                                Resend
                              </button>
                            </div>
                          </div>
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- end lea admin -->
          <!-- lea participant -->
          <div v-else-if="isLEAParticipant">
            <div class="flex justify-between items-center">
              <h2 class="text-blue-primary mb-5">View Team</h2>
              <button @click.prevent="close">
                <h1 class="text-blue-primary mr-5">X</h1>
              </button>
            </div>
            <h4 class="text-black font-bold mb-5 text-center">
              Team Participants
            </h4>
            <h6 class="pl-6">
              The user in bold is your LEA Point of Contact (POC)
            </h6>
            <div
              class="table-container-participant align-middle inline-block rounded-bl-lg rounded-br-lg w-full h-full"
            >
              <table
                class="table overflow-scroll overflow-y-scroll flex flex-col flex-no-wrap w-full h-full"
                id="table"
                @scroll="updateScroll()"
              >
                <tr class="text-blue-primary flex w-full">
                  <th
                    class="w-1/3 px-6 py-4 border-b-2 border-gray-300 text-left leading-4 text-blue-500 tracking-wider"
                  >
                    Name
                  </th>
                  <th
                    class="w-1/3 px-6 py-4 border-b-2 border-gray-300 text-left leading-4 text-blue-500 tracking-wider"
                  >
                    Email
                  </th>
                  <th
                    class="w-1/3 px-6 py-4 border-b-2 border-gray-300 text-left leading-4 text-blue-500 tracking-wider"
                  >
                    Participant Role
                  </th>
                  <th class=" border-b-2 border-gray-300"></th>
                </tr>
                <tbody class="bg-white flex-1 sm:flex-none w-full">
                  <tr
                    v-for="participant in confirmedParticipants"
                    :key="participant.id"
                    class="flex w-full"
                  >
                    <td
                      class="w-1/3 px-6 py-4 whitespace-no-wrap overflow-scroll border-b border-gray-500"
                      :class="[boldIfLEAAdmin(participant)]"
                    >
                      {{ participant.first_name }}
                      {{ participant.last_name }}
                    </td>
                    <td
                      class="w-1/3 px-6 py-4 whitespace-no-wrap overflow-scroll border-b border-gray-500"
                      :class="[boldIfLEAAdmin(participant)]"
                    >
                      {{ participant.email }}
                    </td>
                    <td
                      class="w-1/3 px-6 py-4 whitespace-no-wrap overflow-scroll border-b text-blue-900 border-gray-500 leading-5"
                      :class="[boldIfLEAAdmin(participant)]"
                    >
                      {{ participant.participantRole.data.name }}
                    </td>
                  </tr>
                  <tr
                    v-for="participant in pendingParticipants"
                    :key="'pending' + participant.id"
                    class="flex w-full"
                  >
                    <td
                      class="w-1/3 px-6 py-4 whitespace-no-wrap overflow-scroll border-b border-gray-500 sm"
                    >
                      Invite Pending
                    </td>
                    <td
                      class="w-1/3 px-6 py-4 whitespace-no-wrap overflow-scroll border-b border-gray-500 sm"
                    >
                      {{ participant.email }}
                    </td>
                    <td
                      class="w-1/3 px-6 py-4 whitespace-no-wrap overflow-scroll border-b text-blue-900 border-gray-500 leading-5 extrabold"
                    >
                      - -
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- end lea participant -->
        </div>
      </div>
    </div>
  </transition>
</template>

<style lang="css" scoped>
.bold {
  font-weight: 900;
}
.not-bold {
  font-weight: 400;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.85);
  transition: opacity 0.3s ease;
}

.modal-container {
  transition: all 0.3s ease;
}

.modal-box {
  height: 63%;
}

label {
  padding-left: 22px;
  text-indent: -22px;
}

.table {
  height: 100%;
}

.table-container {
  height: 30vh;
}

.table-container-participant {
  height: 45vh;
}
/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>

<script>
import ConfirmModal from "./ConfirmModal";
import _ from "lodash";
import { mapGetters } from "vuex";
import { ROLES } from "../../const";

export default {
  components: {
    "confirm-modal": ConfirmModal,
    "remove-LEA-modal": ConfirmModal,
    "switch-LEA-modal": ConfirmModal,
    "remove-LEA-pending-modal": ConfirmModal
  },
  name: "LEATeamModal",
  props: {
    value: {
      required: true
    }
  },
  data: () => ({
    btnTimer: false,
    confirmResendBody: null,
    confirmResend: false,

    confirmLEARemoveBody: null,
    confirmLEAPendingRemoveBody: null,

    removeLEAParticipantUUID: null,
    removeLEAParticipantPendingId: null,

    confirmLEARemove: false,
    confirmLEAPendingRemove: false,

    confirmLEASwitchBody: null,
    switchLEAId: null,
    confirmLEASwitch: false,

    currentInvitee: {},
    pendingParticipants: [],
    confirmedParticipants: [],
    allInvitees: [],
    currentParticipant: null,
    participants: [],
    emailError: false,

    activeMenu: null,
    scrollPosition: null,
    loading: false,
    toolTipY: null,
    reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
  }),
  computed: {
    ...mapGetters({
      user: "user"
    }),
    isFacilitator() {
      return this.user && this.user.is_facilitator;
    },
    isLEAAdmin() {
      return this.user && this.user.roles.includes(ROLES.LEA_ADMIN);
    },
    isLEAParticipant: function() {
      return this.user.roles.includes(ROLES.LEA_PARTICIPANT);
    },
    scrollStyles() {
      return {
        top: `${this.toolTipY}px`
      };
    }
  },
  methods: {
    updateScroll() {
      var div = document.getElementById("table").scrollTop;
      this.scrollPosition = div;
      this.activeMenu = null;
    },
    boldIfLEAAdmin(participant) {
      return participant.roles.includes("LEAAdmin") ? "bold" : "not-bold";
    },
    // switch current lea with selected (functions with Confirm modal)
    confirmLEASwitchModal(id, name) {
      this.confirmLEASwitchBody = `Are you sure you want to convert ${name} to LEA POC? You will transfer the management of your team and Organization Profile over to them.`;
      this.switchLEAId = id;
      this.confirmLEASwitch = true;
    },
    switchLEAParticipant() {
      this.switchLEA(this.switchLEAId);
    },
    switchLEA(id) {
      this.activeMenu = null;
      window.axios
        .post("/api/me/member/switch", { id: id })
        .then(() => {
          this.$toasted.show("Switched LEA POC");
        })
        .catch(error => {
          console.log(error);
        });

      this.$router.go(0);
    },
    // remove lea (functions with Confirm modal)
    removeLEAParticipantModal(uuid, email) {
      this.confirmLEARemoveBody = `Are you sure you want to remove ${email} from your LEA? This will delete their account.`;
      this.removeLEAParticipantUUID = uuid;
      this.confirmLEARemove = true;
    },
    removeLEAParticipant() {
      this.removeParticipant(this.removeLEAParticipantUUID);
    },
    removeParticipant(uuid) {
      this.activeMenu = null;
      this.loading = true;
      window.axios
        .delete(`/api/me/member/remove/${uuid}`)
        .then(() => {
          this.confirmedParticipants = this.confirmedParticipants.filter(
            e => e.uuid !== uuid
          );
          this.confirmedParticipants = _.orderBy(this.confirmedParticipants, [
            "roles"
          ]);
          this.loading = false;
          this.$toasted.show("removed participant from your team");
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
      this.confirmLEARemove = false;
    },
    // remove lea if theyre pending
    removeLEAParticipantPendingModal(email, id) {
      this.confirmLEAPendingRemoveBody = `Are you sure you want to remove the pending invitation to ${email}?`;
      this.removeLEAParticipantPendingId = id;
      this.confirmLEAPendingRemove = true;
    },
    removeLEAParticipantPending() {
      this.removeLEAPending(this.removeLEAParticipantPendingId);
    },
    removeLEAPending(id) {
      this.activeMenu = null;
      this.loading = true;
      window.axios
        .delete("/api/me/member/invite/" + id)
        .then(() => {
          this.pendingParticipants = this.pendingParticipants.filter(
            e => e.id !== id
          );
          this.loading = false;
          this.$toasted.show(`Removed pending participant from invites`);
        })
        .catch(error => {
          console.log(error);
        });
      this.confirmLEAPendingRemove = false;
    },
    menuClick(event) {
      this.toolTipY = event.currentTarget.parentNode.getBoundingClientRect().bottom;
      if (this.activeMenu == event.currentTarget.id) {
        this.activeMenu = null;
        window.removeEventListener("click", this.closeMenuOnOutsideClick);
      } else {
        this.activeMenu = event.currentTarget.id;
        window.addEventListener("click", this.closeMenuOnOutsideClick);
      }
    },
    closeMenuOnOutsideClick(event) {
      const el = document.getElementById(this.activeMenu);
      if (!el.contains(event.target)) {
        this.activeMenu = null;
      }
    },
    close() {
      this.$toasted.clear();
      this.$emit("input", !this.value);
    },
    sendInvite: function() {
      this.btnTimer = true;
      this.emailError = false;
      if (this.emailCheck()) {
        const dupeEmailCheck = this.emailDupe();
        if (dupeEmailCheck === null) {
          this.loading = true;
          const email = this.currentParticipant;
          window.axios
            .post("/api/me/member/invite", { email })
            .then(() => {
              this.$toasted.global.success(`Invitation sent to: ${email}`);
              this.resetInput();
              this.loading = true;
            })
            .catch(error => {
              this.loading = false;
              console.log(error);
            });
        } else if (dupeEmailCheck === "pending") {
          this.confirmResendBody = `An invitation has already been sent to ${this.currentParticipant}, would you like to resend the invitation?`;
          this.confirmResend = true;
        } else if (dupeEmailCheck === "confirmed") {
          alert(`${this.currentParticipant} has already been registered`);
        }
        this.currentParticipant = "";
      } else {
        this.emailError = true;
      }
      setTimeout(() => {
        this.btnTimer = false;
      }, 500);
    },
    isEmailValid: function() {
      if (
        this.currentParticipant === null ||
        this.currentParticipant.length <= 0
      ) {
        return false;
      } else {
        return this.reg.test(this.currentParticipant) ? true : false;
      }
    },
    emailCheck() {
      var emailValid = this.isEmailValid();
      if (this.currentParticipant != null && emailValid === true) {
        return true;
      }
      return false;
    },
    emailDupe() {
      var foundParticipant = [];

      var emailC = this.confirmedParticipants;
      var emailP = this.pendingParticipants;

      var compareConfirmed = compare(
        emailC,
        foundParticipant,
        this.currentParticipant
      );
      var comparePending = compare(
        emailP,
        foundParticipant,
        this.currentParticipant
      );

      function compare(email, foundParticipant, currentParticipant) {
        for (let i = 0; i < email.length; i++) {
          if (email[i].email === currentParticipant) {
            foundParticipant = email[i];
          }
        }
        return foundParticipant;
      }

      if (compareConfirmed.email === this.currentParticipant) {
        this.currentInvitee = compareConfirmed;
        return "confirmed";
      } else if (comparePending.email === this.currentParticipant) {
        this.currentInvitee = comparePending;
        return "pending";
      } else {
        return null;
      }
    },
    resetInput() {
      let params = {
        include: "users.participantRole,invitations"
      };
      this.loading = true;
      window.axios.get("/api/me/member", { params: params }).then(res => {
        this.pendingParticipants = res.data.data[0].invitations.data.filter(
          e => e.status == "pending"
        );
        this.confirmedParticipants = res.data.data[0].users.data.filter(
          e => e.id
        );
        this.confirmedParticipants = _.orderBy(this.confirmedParticipants, [
          "roles"
        ]);
        this.loading = false;
      });
    },
    resendInvite() {
      this.loading = true;
      window.axios
        .put("/api/me/member/invite", { id: this.currentInvitee.id })
        .then(() => {
          this.$toasted.global.success(
            `Invitation re-sent to: ${this.currentInvitee.email}`
          );
          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
      this.confirmResend = false;
    },
    resendInviteByParams({ id, email }) {
      this.loading = true;
      window.axios
        .put("/api/me/member/invite", { id })
        .then(() => {
          this.$toasted.global.success(`Invitation re-sent to: ${email}`);
          this.activeMenu = null;
          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
          this.activeMenu = null;
          console.log(error);
        });
    }
  },
  watch: {
    currentParticipant() {
      this.emailCheck();
    }
  },
  mounted() {
    this.resetInput();
  }
};
</script>
