<template>
  <nav v-show="isLoggedIn" class="w-full border-b mb-10">
    <alert-modal
      v-show="alertModal"
      title="Contact Membership"
      :body="alertModalBody"
      closeButton="Close"
      closeButtonColor="btn-blue"
      @close="alertModal = false"
    ></alert-modal>
    <img
      src="@/assets/img/circuits-nav.svg"
      alt="Circuits"
      class="hidden md:block md:absolute pin-t nav-circuits"
    />
    <div
      class="container mx-auto block text-center md:flex flex-row justify-between items-center my-6 relative"
    >
      <router-link to="/home" class="mr-3"
        ><img src="@/assets/img/csforall-logo.svg" alt="CSforAll" width="170"
      /></router-link>
      <ul
        class="list-reset mt-2 md:mt-0 flex flex-wrap justify-center md:justify-start"
      >
        <li v-show="isMasquerading" class="py-4 lg:py-0 mr-12 text-h4">
          <div class="text-red-dark font-medium">
            Masquerading
          </div>
        </li>
        <li
          v-if="user && user.id"
          v-show="!user.is_admin"
          class="py-4 lg:py-0 mr-6 text-h4"
        >
          <img
            src="@/assets/img/icon-user.svg"
            alt="Profile icon torso in a circle"
            width="22"
            style="vertical-align:middle"
          />
          &nbsp;
          <router-link
            :class="{
              'text-blue-primary hover:text-blue-light font-medium hover:font-bold': true,
              active: activeNav == 'account'
            }"
            to="/home"
            >Portal</router-link
          >
        </li>
        <li
          v-if="user && user.id"
          v-show="!user.is_admin"
          class="py-4 lg:py-0 mr-6 text-h4"
        >
          <img
            src="@/assets/img/icon-gear-line.svg"
            alt="settings icon line drawing of a gear"
            width="22"
            style="vertical-align:middle"
          />
          &nbsp;
          <router-link
            :class="{
              'text-blue-primary hover:text-blue-light font-medium hover:font-bold': true,
              active: activeNav == 'account'
            }"
            to="/home/account"
            >Settings</router-link
          >
        </li>
        <li v-show="user.is_admin" class="py-4 lg:py-0 mr-6 text-h4">
          <router-link
            :class="{
              'text-grey-dark hover:text-blue-primary font-medium': true,
              active: activeNav == 'admin'
            }"
            to="/admin"
            >Admin</router-link
          >
        </li>
        <li v-show="user.is_admin" class="py-4 lg:py-0 mr-6 text-h4">
          <router-link
            :class="{
              'text-grey-dark hover:text-blue-primary font-medium': true,
              active: activeNav == 'curricula'
            }"
            to="/admin/curricula"
            >Curricula List</router-link
          >
        </li>
        <li class="mr-6 py-4 lg:py-0 text-grey-light hidden md:block">|</li>

        <li
          v-if="user && user.id"
          v-show="!user.is_admin"
          class="py-4 lg:py-0 mr-6 text-h4"
        >
          <img
            src="@/assets/img/contact-icon-3.png"
            alt="contact icon of an outline of an envelope"
            width="22"
            style="vertical-align:middle"
          />
          &nbsp;
          <a
            id="email-copy"
            v-on:click="copyToClipboard(memberEmail)"
            class="text-blue-primary hover:text-blue-light font-light font-normal"
            >Contact</a
          >
        </li>
        <!-- <li class="mr-6 py-4 lg:py-0 text-grey-light hidden md:block">|</li> -->
        <li class="py-4 lg:py-0 text-h4">
          <router-link
            :class="{ 'text-grey-dark hover:text-blue-primary': true }"
            to="/auth/logout"
            >Logout</router-link
          >
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from "vuex";
import AlertModal from "./modals/AlertModal.vue";

export default {
  name: "MainNav",
  components: {
    "alert-modal": AlertModal
  },
  data: () => ({
    memberEmail: "script@csforall.org",
    alertModal: false,
    alertModalBody: null
  }),
  computed: {
    ...mapGetters({
      isLoggedIn: "isLoggedIn",
      user: "user",
      activeNav: "activeNav",
      isMasquerading: "isMasquerading"
    }),
    showProfile: function() {
      return this.user.is_member_owner;
    },
    showScript: function() {
      return this.user.has_workshops || this.user.is_facilitator;
    }
  },
  methods: {
    copyToClipboard(memberEmail) {
      this.alertModalBody = `Our contact email ${this.memberEmail}, has been copied to your clipboard. You can email the membership team with any profile issues.`;
      var copyThis = document.createElement("textarea");
      document.body.appendChild(copyThis);
      copyThis.value = memberEmail;
      copyThis.select();
      try {
        document.execCommand("copy");
      } catch (e) {
        console.log("error");
      }
      document.body.removeChild(copyThis);

      this.alertModal = true;
    }
  },
  mounted() {
    if (localStorage.getItem("Masquerade")) {
      this.$store.dispatch("masqueradeOn");
    }
  }
};
</script>
