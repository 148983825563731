<template>
  <div class="relative">
    <div class="container mx-auto w-full lg:w-2/5 pb-24 pt-24">
      <h1 class="text-center">Password Reset</h1>
      <p v-if="passwordReset" class="mt-3 mb-10 text-center">
        Your account requires a password reset. Please reset your password to
        continue.
      </p>
      <p v-else class="mt-3 mb-10 text-center">
        Enter your new password below.
      </p>
      <form
        method="POST"
        aria-label="Forgot Password"
        @submit.prevent="validateBeforeSubmit"
      >
        <div
          v-bind:class="{
            'mb-10': true,
            'has-error': errors.has('oldpassword')
          }"
        >
          <label for="oldpassword">Old Password</label>

          <input
            id="oldpassword"
            v-model="oldpassword"
            type="password"
            v-validate="`min:${VALIDATION.MIN_PW_LEN}`"
            class="form-control"
            name="oldpassword"
            ref="oldpassword"
          />
          <div class="mt-4">
            <span
              v-show="errors.has('oldpassword')"
              class="text-red text-xs italic"
              >{{ errors.first("oldpassword") }}</span
            >
          </div>
        </div>

        <div
          v-bind:class="{
            'mb-10': true,
            'has-error': errors.has('password')
          }"
        >
          <label for="password">Password</label>

          <input
            id="password"
            v-model="password"
            type="password"
            v-validate="`min:${VALIDATION.MIN_PW_LEN}`"
            class="form-control"
            name="password"
            ref="password"
          />
          <div class="mt-4">
            <span
              v-show="errors.has('password')"
              class="text-red text-xs italic"
              >{{ errors.first("password") }}</span
            >
          </div>
        </div>

        <div
          v-bind:class="{
            'mb-10': true,
            'has-error': errors.has('password_confirmation')
          }"
        >
          <label for="password-confirm">Confirm Password</label>

          <input
            id="password-confirm"
            v-validate="`confirmed:password|min:${VALIDATION.MIN_PW_LEN}`"
            type="password"
            class="form-control"
            name="password_confirmation"
          />
          <div class="mt-4">
            <span
              v-show="errors.has('password_confirmation')"
              class="text-red text-xs italic"
              >{{ errors.first("password_confirmation") }}</span
            >
          </div>
        </div>

        <div class="text-left">
          <button type="submit" class="btn btn-blue">
            Reset my password
          </button>
        </div>
      </form>
    </div>
    <div class="blue-bar"></div>
  </div>
</template>

<script>
import { Auth } from "aws-amplify";
import { VALIDATION } from "../../const";

export default {
  data() {
    return {
      VALIDATION: VALIDATION,
      password: null,
      oldpassword: null,
      passwordReset: false
    };
  },
  methods: {
    validateBeforeSubmit: function() {
      this.$validator.validateAll().then(async result => {
        if (!result) {
          return;
        }

        Auth.currentAuthenticatedUser()
          .then(user => {
            return Auth.changePassword(user, this.oldpassword, this.password);
          })
          .then(() => {
            this.$router.push("/home");
          })
          .catch(err => {
            console.log(err);
            this.authFromUserPoolUser();
          });
      });
    },
    authFromUserPoolUser: async function() {
      try {
        await Auth.signIn(this.$route.query.email, this.oldpassword)
          .then(user => {
            return Auth.completeNewPassword(user, this.password);
          })
          .then(() => {
            this.$router.push("/home");
            this.$router.go(this.$router.currentRoute);
          })
          .catch(err => {
            console.log(err);
          });
      } catch (err) {
        console.log(err);
      }
    }
  },
  created() {
    this.passwordReset = this.$route.query.passreset;
  }
};
</script>
