export const TEACHERS_SET = "TEACHERS_SET";

const state = {
  teachers: {}
};

const getters = {
  teachers: state => state.teachers
};

const actions = {
  setTeachers: ({ commit }, teachers) => {
    commit(TEACHERS_SET, {
      teachers: teachers
    });
  }
};

const mutations = {
  TEACHERS_SET: (state, payload) => {
    state.teachers = Object.assign({}, state.teachers, payload.teachers);
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
