<template>
  <div class="p-16">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "FormModule",
  props: {},
  components: {},
  data: () => ({}),
  computed: {},
  watch: {},
  methods: {},
  mounted() {}
};
</script>
