export const USER_SET = "USER_SET";
export const USER_UNSET = "USER_UNSET";

export const USERS_FETCH = "USERS_FETCH";
export const USERS_SET_USER = "USERS_SET_USER";
export const USERS_FETCHING = "USERS_FETCHING";

export const USERS_SET_TOKEN = "USERS_SET_TOKEN";

const state = {
  user: {
    member: {
      types: false,
      audiences: false,
      levels: false,
      regions: false,
      researchTopics: false,
      researchTypes: false,
      settings: false,
      student: false,
      teacher: false,
      name: false,
      uuid: false,
      memberImages: []
    },
    roles: []
  },
  tokens: {
    idToken: { jwtToken: false }
  }
};

const getters = {
  user: state => state.user,
  tokens: state => state.tokens,
  isLoggedIn: state => {
    return ((state.tokens || {}).idToken || {}).jwtToken !== false;
  }
};

const actions = {
  setUser: ({ commit }, user) => {
    commit(USER_SET, {
      user: user,
      audiences: false,
      levels: false,
      regions: false,
      researchTopics: false,
      researchTypes: false,
      settings: false,
      student: false,
      teacher: false,
      teaser: false,
      description: false
    });
  },
  getUser: ({ commit }) => {
    return window.axios.get("/api/me").then(resp => {
      commit(USER_SET, {
        user: resp.data.data[0]
      });
    });
  },

  clearUser: ({ commit }) => {
    commit(USER_SET, {
      user: {
        member: {
          types: false
        }
      }
    });
  },

  setTokens: ({ commit }, tokens) => {
    commit(USERS_SET_TOKEN, {
      tokens: tokens
    });
  }
};

const mutations = {
  USER_SET: (state, payload) => {
    state.user = Object.assign({}, state.user, payload.user);
    state.user.member = Object.assign(
      {},
      state.user.member,
      payload.user.member.data
    );
  },
  USERS_FETCHING: () => {},
  USERS_SET_USER: (state, payload = {}) => {
    let u = payload.user;
    state.user = Object.assign({}, state.user, u);
  },
  USERS_SET_TOKEN: (state, payload) => {
    state.tokens = payload.tokens;
    if (payload.tokens) {
      window.axios.defaults.headers.common["Authorization"] =
        "Bearer " + payload.tokens.idToken.jwtToken;
    }
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
