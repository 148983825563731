<template>
  <div class="relative pb-32">
    <div class="container mx-auto pb-20 h-screen w-full: lg:w-2/5">
      <div class="text-center">
        <img
          src="@/assets/img/circuits-grey.svg"
          alt="Circuits"
          class="relative pin-t margin-auto"
        />
      </div>

      <div class="text-center mt-6">
        <a href="/home"
          ><img
            src="@/assets/img/csforall-logo.svg"
            alt="CSforAll"
            width="260"
            class="mb-10 text-center main-logo"
        /></a>
        <h1 class="text-center">Forgot Password</h1>
        <div v-if="resetPws">
          <p class="mt-3 mb-10 text-center">
            Enter your email address below and we'll send you a password reset
            link.
          </p>
        </div>
        <div v-else>
          <p class="mt-3 mb-10 text-center">
            Your account password needs to be reset.
            <br />
            <b>
              Please first <u>check your email</u> for a temporary password.
            </b>
            <br />
            <span v-if="!resetPws">Redirecting in {{ counter }}</span>
          </p>
        </div>
      </div>

      <form
        method="POST"
        aria-label="Forgot Password"
        @submit.prevent="validateBeforeSubmit"
      >
        <div v-bind:class="{ 'mb-4': true, 'has-error': errors.has('email') }">
          <label for="email" class="text-h3 font-bold">Email Address:</label>

          <input
            id="email"
            type="email"
            name="email"
            v-model="email"
            v-validate="'required'"
          />
          <div class="mt-4">
            <span
              v-show="errors.has('email')"
              class="text-red text-xs italic"
              >{{ errors.first("email") }}</span
            >
          </div>
        </div>
        <a href="mailto:script@csforall.org" class="text-sm flex float-right">
          Having trouble? <b class="ml-1"> Contact Us</b>
        </a>
        <button type="submit" class="btn btn-blue w-full mt-6">
          Send reset code
        </button>
      </form>
    </div>
    <div class="blue-bar"></div>
  </div>
</template>

<script>
import _ from "lodash";
import { Auth } from "aws-amplify";

export default {
  data() {
    return {
      email: null,
      required: false,
      resetPws: true,
      counter: 15
    };
  },
  methods: {
    countDown() {
      if (this.counter > 0) {
        setTimeout(() => {
          this.counter--;
          this.countDown();
        }, 1000);
      } else {
        this.$router.push("/auth/reset");
      }
    },
    validateBeforeSubmit: function() {
      this.$validator.validateAll().then(result => {
        if (!result) {
          return;
        }

        Auth.forgotPassword(this.email)
          .then(() => {
            this.$toasted.show("Reset code sent");
            this.$router.push({
              path: "/auth/forgot/reset",
              query: { email: this.email }
            });
          })
          .catch(e => {
            console.log(e);
            Auth.signIn(this.email, "123")
              .then(res => {
                console.log(res);
                return false;
              })
              .catch(e => {
                if (
                  e.message ===
                    "Temporary password has expired and must be reset by an administrator." &&
                  e.code === "NotAuthorizedException"
                ) {
                  this.resetPws = false;
                  this.countDown();
                } else if (e.code === "UserNotFoundException") {
                  this.$toasted.show("User not found.");
                } else {
                  _.forEach(e.response.data.errors, (message, id) => {
                    const field = this.$validator.fields.find({
                      name: id,
                      scope: this.$options.scope
                    });
                    this.$validator.errors.add({
                      id: field.id,
                      field: id,
                      msg: message[0],
                      scope: this.$options.scope
                    });
                  });
                }
              });
          });
      });
    }
  }
};
</script>
