<template>
  <div>
    <h1 class="mb-8 text-center">Report</h1>
    <!-- group 1 -->
    <div class="bg-white p-10 rounded">
      <div class="pl-16 pr-16">
        <h2 class="mb-2 text-blue-primary">{{ form.title }}</h2>
        <h4 class="mb-12">{{ form.description }}</h4>
      </div>
      <div v-for="input in goalsGroup1.inputs" :key="input.id">
        <div v-if="input.type === 'section'">
          <component
            :is="`Section-input`"
            :input="input"
            :name="getInputName(input)"
            v-model="formData[input.id]"
            :page="`goals`"
          >
          </component>
        </div>
        <div v-else>
          <component
            :is="`Text-input`"
            :input="input"
            :name="getInputName(input)"
            :answer="answers[input.id]"
            v-model="formData[input.id]"
            :disabled="true"
            :class="getGoalsClass(input.type)"
            :page="`goals`"
          >
          </component>
        </div>
      </div>
    </div>

    <div class="pb-20"></div>
    <!-- BUTTONS FOR PANEL SWITCHING -->
    <!-- <div class="col-xs-12 col-sm-12 col-md-5 col-lg-4"> -->
    <div id="btn-container" class="buttons-panel">
      <button
        ref="two"
        class=" bg-btn-goals text-grey-medium rounded-tr-xl px-0 mx-0 outline-none shadow-sm shadow-inner hover:bg-blue-lighter focus:bg-white focus:text-black focus:outline-none"
        v-on:click="setGoalSection(rubricSections.MATERIALS)"
      >
        {{ rubricSections.BTN_MATERIALS }}
      </button>
      <button
        ref="three"
        class=" bg-btn-goals text-grey-medium rounded-tr-xl px-4 py-2 mx-0 outline-none shadow-sm shadow-inner hover:bg-blue-lighter focus:bg-white focus:text-black focus:outline-none"
        v-on:click="setGoalSection(rubricSections.LEADERSHIP)"
      >
        {{ rubricSections.BTN_LEADERSHIP }}
      </button>
      <button
        ref="four"
        class=" bg-btn-goals text-grey-medium rounded-tr-xl px-4 py-2 mx-0 outline-none shadow-sm shadow-inner hover:bg-blue-lighter focus:bg-white focus:text-black focus:outline-none"
        v-on:click="setGoalSection(rubricSections.CAPACITY)"
      >
        {{ rubricSections.BTN_CAPACITY }}
      </button>
      <button
        ref="five"
        class=" bg-btn-goals text-grey-medium rounded-tr-xl px-4 py-2 mx-0 outline-none shadow-sm shadow-inner hover:bg-blue-lighter focus:bg-white focus:text-black focus:outline-none"
        v-on:click="setGoalSection(rubricSections.PARTNERS)"
      >
        {{ rubricSections.BTN_PARTNERS }}
      </button>
      <button
        ref="six"
        class=" bg-btn-goals text-grey-medium rounded-tr-xl px-4 py-2 mx-0 outline-none shadow-sm shadow-inner hover:bg-blue-lighter focus:bg-white focus:text-black focus:outline-none"
        v-on:click="setGoalSection(rubricSections.COMMUNITY)"
      >
        {{ rubricSections.BTN_COMMUNITY }}
      </button>
      <!-- </div> -->
    </div>
    <div class="bg-white p-10 rounded">
      <div id="content-container">
        <div v-for="input in activeInput.inputs" :key="input.id">
          <component
            :is="`${input.type}-input`"
            :input="input"
            :name="getInputName(input)"
            :answer="answers[input.id]"
            v-model="formData[input.id]"
            :disabled="true"
            :class="getGoalsClass(input.type)"
            :page="`goals`"
          >
          </component>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.buttons-panel {
  width: 100%;
  display: grid;
  grid-template-columns: auto auto auto auto auto;
}
.bg-btn-goals {
  background-color: #d8dee8;
  height: 6vh;
  width: 100%;
}
</style>

<script>
import CheckboxInput from "../inputsResults/CheckboxInput.vue";
import SectionInput from "../inputsResults/SectionInput.vue";
import DescriptionInput from "../inputsResults/DescriptionInput.vue";
import TextInput from "../inputsResults/TextInput.vue";
import TextHeaderInput from "../inputsResults/TextHeaderInput.vue";
import TextareaInput from "../inputsResults/TextareaInput.vue";

// import { mapGetters } from "vuex";
import { RUBRIC_SECTIONS } from "../../const";

export default {
  name: "FormAnswersGoals",
  props: {
    form: Object,
    answers: Object,
    formData: Object,
    user: Object
  },
  components: {
    CheckboxInput,
    DescriptionInput,
    SectionInput,
    TextInput,
    TextHeaderInput,
    TextareaInput
  },
  data: () => ({
    goalsSeparated: [],
    registration: {},
    workshop: {},
    activeInput: {},
    rubricSections: { ...RUBRIC_SECTIONS }
  }),
  computed: {
    // for goals
    goalsGroup1() {
      var getGroup = this.goalsSeparated.filter(
        x => x["sectionName"] === "Visions for CS Education Statement"
      );
      var finalGroup = getGroup[0];
      return finalGroup;
    }
  },
  methods: {
    setGoalSection(section) {
      var getGroup = this.goalsSeparated.filter(
        x => x["sectionName"] === section
      );
      this.activeInput = getGroup[0];
      return this.activeInput;
    },
    goals() {
      var group = [];
      var sectionName;
      for (const [k, v] of Object.entries(this.form.inputs)) {
        for (const [l, w] of Object.entries(v)) {
          if (l === "header") {
            sectionName = w;
          }
        }
        if (sectionName === undefined) {
          console.log("undefined");
          continue;
        } else {
          if (group[sectionName]) {
            group[sectionName][k] = v;
          } else {
            group[sectionName] = { [k]: v };
          }
        }
      }

      var groupsTest = Object.entries(group);
      var groups = groupsTest.map(function(group) {
        group["sectionName"] = group[0];
        group["inputs"] = group[1];

        return { sectionName: group[0], inputs: group[1] };
      });

      this.goalsSeparated = groups;
    },
    getGoalsClass(type) {
      if (type === "text" || type === "checkbox") return "pb-6";
      if (type === "section") return "pb-2";
      return "";
    },
    getInputName(input) {
      if (input.type == "section") {
        return input.header;
      }
      return input.attributes.name;
    },
    focusInput() {
      this.$refs.two.focus();
    }
  },
  async mounted() {
    this.goals();
    this.setGoalSection(RUBRIC_SECTIONS.MATERIALS);

    setTimeout(() => {
      this.focusInput();
    }, 100);
  }
};
</script>
