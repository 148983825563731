<template>
  <signup-layout>
    <template #pageTitle>
      Account Creation
    </template>

    <form
      aria-label="Register"
      @submit.prevent="submit"
      class="w-full h-full flex flex-col justify-center vertical-align"
    >
      <p class="mx-auto text-center max-w-lg text-h3 font-bold">
        Select Your K12 Local Education Association (LEA) Type From the List
        <span class="text-h1 font-bold text-red">
          *
        </span>
      </p>

      <p class="mx-6 mt-4 mb-3 text-h4">
        By signing up here, you will become your organization's Point of Contact
        (POC), managing your organization’s profile.
        <span class="font-bold">Please select your LEA type adequately.</span>
        For example, if this account is on behalf of your School District,
        please select School District, even if you personally work at a School.
      </p>
      <div class="h-full flex flex-col justify-between">
        <div class="flex flex-col self-center">
          <div
            class="items-center my-1"
            v-for="(typeInfo, typeKey) in leaTypes"
            :key="typeKey"
          >
            <label
              :for="typeKey"
              class="inline-flex items-center justify-center"
            >
              <input
                class="inline-flex justify-center "
                :id="typeKey"
                type="radio"
                :value="typeInfo.name"
                v-model="questionnaire.leaType"
              />
              <span class="ml-5 mt-1 -mb-1">
                {{ typeInfo.name }}
                <span
                  v-if="typeInfo.description"
                  class="tooltip right"
                  data-toggle="tooltip"
                  :data-text="typeInfo.description"
                >
                  <span
                    class="tooltip-icon bg-grey-medium text-white items-center"
                    >?</span
                  >
                </span>
              </span>
            </label>
            <div>
              <input
                v-if="
                  typeKey === 'otherSchoolNetwork' &&
                    schoolNetworkRadioValidation
                "
                placeholder="Specify a school network"
                id="school-network"
                name="school network"
                type="text"
                v-model="questionnaire.schoolNetwork"
                v-validate="'required'"
              />
              <div v-if="typeKey === 'otherSchoolNetwork'">
                <span
                  v-show="errors.has('school network')"
                  class="text-xs italic text-red"
                  >{{ errors.first("school network") }}</span
                >
              </div>
            </div>
          </div>
        </div>
        <p class="mx-6 mt-4 text-h4">
          <span class="text-h1 font-bold text-red">
            *
          </span>
          <span class="font-bold"
            >For current & future attendees of SCRIPT workshops</span
          >: If you enroll in a SCRIPT workshop, you will also invite your team
          members and could even transfer this role over to them once they have
          an account. If you feel this isn’t your role, please request someone
          else from your organization sign up.
        </p>
        <div class="h-4 w-full"></div>
        <p
          :class="{ invisible: !errorNoLeaType }"
          class="mb-5 text-red text-h5 text-center"
        >
          Please select at least one option before proceeding.
        </p>
        <div class="flex w-1/2 self-center justify-between text-center mb-5">
          <router-link type="button" class="btn btn-blue" :to="hitBack">
            Back
          </router-link>
          <button type="submit" class="btn btn-blue">
            Next
          </button>
        </div>
      </div>
    </form>
  </signup-layout>
</template>
<style scoped>
.vertical-align {
  align-self: center;
}
</style>

<script>
import "vue-select/dist/vue-select.css";
import SignupLayout from "@/components/signup/SignupLayout";
import signupMixin from "@/components/signup/mixins/signupMixin";
import { LEA_TYPES, ORG_TYPES } from "@/const";

export default {
  name: "SelectLeaType",
  components: {
    SignupLayout
  },
  mixins: [signupMixin],
  data() {
    return {
      leaTypes: {
        individualSchool: {
          name: LEA_TYPES.INDIVIDUAL_SCHOOL
        },
        schoolDistrict: {
          name: LEA_TYPES.SCHOOL_DISTRICT
        },
        regionalEducationAgency: {
          name: LEA_TYPES.REGIONAL_EDUCATION_AGENCY,
          description:
            "A regional education agency is an educational service agency that is authorized by a regional or state level to develop and provide services and programs to local groups, such as school districts."
        },
        otherSchoolNetwork: {
          name: "Other School Networks",
          description:
            "Other school networks include after or out-of-school learning time providers, local education services and organizations, and other informal education providers.",
          schoolNetwork: ""
        },
        noneOfThese: {
          name: "None of these"
        }
      },
      errorNoLeaType: false
    };
  },
  computed: {
    schoolNetworkRadioValidation() {
      return this.questionnaire.leaType === "Other School Networks";
    },
    queryUrlExist() {
      return Object.keys(this.$route.query).length !== 0;
    },
    hitBack() {
      if (this.queryUrlExist) {
        return {
          name: "LeaSelectOrganization",
          query: this.$route.query
        };
      } else {
        return {
          name: "LeaSelectOrganization"
        };
      }
    }
  },
  methods: {
    async submit() {
      if (
        this.schoolNetworkRadioValidation &&
        this.leaTypes.otherSchoolNetwork.schoolNetwork.length <= 0
      ) {
        const validateField = await this.$validator.validate("school network");
        if (!validateField) {
          return;
        }
      }

      const leaType = this.questionnaire.leaType;

      this.errorNoLeaType = !leaType;

      if (this.errorNoLeaType) {
        return;
      }

      if (leaType === this.leaTypes.noneOfThese.name) {
        this.$router.push({ name: "LearnMore" });
        return;
      }

      this.questionnaire.organizationTypes = [ORG_TYPES.EDUCATION_ASSOCIATION];
      this.questionnaire.org_id = this.$route.query
        ? parseInt(this.$route.query.orgGroup)
        : "";

      this.setQuestionnaire(this.questionnaire);

      const routeConfig =
        Object.keys(this.$route.query).length !== 0
          ? { name: "LeaRegisterOrganization", query: this.$route.query }
          : { name: "LeaRegisterOrganization" };

      this.$router.push(routeConfig);
    }
  }
};
</script>
