<template>
  <!-- <div>
    <h3 class="text-h3 text-blue-primary font-bold mb-2">
      {{ input.attributes.title }}
      <span v-if="required" class="text-red">*</span>
    </h3>
    <p class="leading-loose text-h4 mb-2">{{ input.description }}</p>
    <div class="flex flex-wrap pt-3">
      <template v-for="(option, index) in input.options">
        <label class="flex w-full mb-4" :key="index">
          <input
            type="radio"
            :id="option.id"
            :name="input.attributes.name"
            :value="option.value"
            :checked="choice == option.value"
            :disabled="disabled"
            @click="handleClick(option.value)"
          />
          <span
            class="radio-label md:pr-20 ml-2 font-normal cursor-pointer text-h4 leading-20"
            >{{ option.label }}</span
          >
        </label>
      </template>
    </div>
  </div> -->

  <div class="pb-4 text-center w-full">
    <h3 class="text-h3 text-blue-primary font-bold mb-4 mt-2">
      {{ input.attributes.title }}
      <span v-if="required" class="text-red">*</span>
    </h3>

    <p class="leading-loose text-h4 mb-2">{{ input.description }}</p>

    <div class="grid">
      <div class="col-start-1 col-end-3">{{ input.high }}</div>
      <div class="col-end-5 col-span-2">{{ input.low }}</div>
    </div>

    <div>
      <ul class="likert">
        <template v-for="(option, index) in input.options">
          <li :key="index">
            <input
              type="radio"
              :id="option.id"
              :name="input.attributes.name"
              :value="option.value"
              :checked="choice == option.value"
              :disabled="disabled"
              @click="handleClick(option.value)"
            />
            <label>
              {{ option.label }}
            </label>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>

<style scoped>
.grid {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto;
  grid-gap: 10px;
}
.col-start-1 {
  grid-column-start: 1;
}
.col-end-3 {
  grid-column-end: 3;
}
.col-end-5 {
  grid-column-end: 5;
}
.col-span-2 {
  column-span: 2;
}

label {
  float: left;
  clear: none;
  display: block;
  padding: 0px 0px 0px 0px;
}

.likert {
  list-style: none;
  width: 100%;
  margin: 0;
  padding: 0 0 35px;
  display: block;
  border-bottom: 2px solid #efefef;
}
.likert li {
  display: inline-block;
  width: 16%;
  text-align: center;
  vertical-align: top;
}

.likert li label {
  width: 100%;
}

.likert li input[type="radio"] {
  display: block;
  position: relative;
  top: 0;
  left: 50%;
  margin-left: -6px;
}

.likert:last-of-type {
  border-bottom: 0;
}

.likert:before {
  content: "";
  position: relative;
  top: 16px;
  left: -8%;
  margin: 0 auto;
  display: block;
  background-color: #dce0ef;
  height: 4px;
  width: 65%;
}

input[type="radio"] {
  transform: scale(1.3);
}
</style>

<script>
export default {
  name: "LikertInput",
  $_veeValidate: {
    // must not be arrow functions.
    // the name getter
    name() {
      return this.input.attributes.name;
    },
    // the value getter
    value() {
      return this.choice; // `this` is the component instance, `value` is the prop we added.
    }
  },
  props: {
    answer: String,
    disabled: Boolean,
    input: Object
  },
  watch: {
    answer(answer) {
      this.choice = answer;
      this.$emit("input", answer);
    }
  },
  data() {
    return {
      choice: this.answer ? this.answer : null
    };
  },
  computed: {
    required() {
      return (
        this.input.validation && this.input.validation.includes("required")
      );
    }
  },
  methods: {
    handleClick(value) {
      if (this.choice === value) {
        this.choice = null;
      } else {
        this.choice = value;
      }

      this.$emit("input", this.choice);
    }
  }
};
</script>
