<template>
  <div class="mt-32 mb-32 container mx-auto w-4/5">
    <p>
      These are not the droids you are looking for.<br /><br />
      Woops! seems we can't find this page. If you think this is in error reach
      out to
      <a href="mailto:info@csforall.org" target="_blank">info@csforall.org</a>
    </p>
    <br />
    <p>
      <router-link :to="{ name: 'dashboard' }" class="home-link">
        Visit Home
      </router-link>
    </p>
  </div>
</template>

<script>
export default {
  name: "NotFound"
};
</script>

<style scoped>
p {
  font-size: 1.4rem;
}
.home-link {
  font-size: 2rem;
  font-weight: bold;
}
</style>
