<template>
  <div class="relative pb-32">
    <div class="container">
      <!-- menu -->
      <div
        class="mb-2 block lg:flex justify-between items-center mb-10 lg:mb-10"
      >
        <h1>Members</h1>
        <div class="my-6 lg:my-0 w-full lg:w-2/5">
          <input
            type="text"
            placeholder="Search..."
            v-model="search"
            @keydown="handleSearchKey"
          />
        </div>

        <div class="no-flex md:flex items-center">
          <div class="my-2 md:my-0">
            <select
              :class="{
                'text-blue-primary hover:text-blue-light pr-6 flex cursor-pointer': true
              }"
              @change="handleFilter"
            >
              <option value="all">Select a Filter</option>
              <option value="all">View All Members</option>
              <option value="pending">View Unpublished</option>
              <option value="nonpublic">All Non-Public</option>
              <option value="publicUnpublished">Public Unpublished</option>
              <option value="publicPublished">Public Published</option>
              <option value="researchersUnpublished"
                >Researchers Unpublished</option
              >
              <option value="membersUnpublished">Members Unpublished</option>
              <option value="reviewStatusNew">Review New</option>
              <option value="reviewStatusPending">Review Pending</option>
              <option value="reviewStatusFinished">Review Finished</option>
              <option value="reviewStatusRejected">Review Rejected</option>
            </select>
          </div>
          <div class="my-2 md:my-0">
            <span
              :class="{
                'opacity-50 cursor-not-allowed': this.isSyncing ? true : false,
                'text-blue-primary hover:text-blue-light pr-6 flex cursor-pointer': true
              }"
              @click="handleExport"
            >
              <span class="ml-2">Export All</span>
            </span>
          </div>
          <div class="my-2 md:my-0">
            <span
              :class="{
                'opacity-50 cursor-not-allowed': this.isSyncing ? true : false,
                'text-blue-primary hover:text-blue-light flex cursor-pointer': true
              }"
              @click="handleResync"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="16px"
                height="16px"
                viewBox="0 0 16 16"
              >
                <g transform="translate(0, 0)">
                  <path
                    fill="#3061ac"
                    d="M7.5,14C4.5,14,2,11.5,2,8.5S4.5,3,7.5,3c1.2,0,2.2,0.4,3.2,1H8v2h6V0h-2v2.5C10.7,1.5,9.2,1,7.5,1 C3.4,1,0,4.4,0,8.5S3.4,16,7.5,16S15,12.6,15,8.5h-2C13,11.5,10.5,14,7.5,14z"
                  ></path>
                </g>
              </svg>
              <span class="ml-2">Resync All</span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <!-- pagination -->
    <div class="text-right mt-6 mb-6 pl-8 pr-8 lg:pl-10 lg:pr-10">
      <span
        class="text-blue underline cursor-pointer"
        v-if="pagination.links && pagination.links.previous"
        @click="handlePaginate(pagination.links.previous)"
      >
        &#171; Previous
      </span>
      <span class="text-grey cursor-not-allowed mr-2" v-else>
        &#171; Previous
      </span>
      <span
        class="text-blue underline cursor-pointer"
        v-if="pagination.links && pagination.links.next"
        @click="handlePaginate(pagination.links.next)"
      >
        Next &#187;
      </span>
      <span class="text-grey cursor-not-allowed" v-else>
        Next &#187;
      </span>
    </div>

    <!-- member list -->
    <div class="hidden lg:flex items-center">
      <div class="w-full lg:w-1/4 lg:pl-10">
        <h4
          class="font-bold text-blue-primary hover:text-blue-light pr-6 flex cursor-pointer text-left"
          @click="handleSort('name')"
        >
          Name
        </h4>
      </div>
      <div class="w-full lg:w-1/4 ">
        <h4
          class="font-bold text-blue-primary hover:text-blue-light pr-6 flex cursor-pointer text-left"
          @click="handleSort('email')"
        >
          Email
        </h4>
      </div>
      <div class="w-full lg:w-1/6 ">
        <h4
          class="font-bold text-blue-primary hover:text-blue-light pr-6 flex cursor-pointer text-left"
          @click="handleSort('recordType')"
        >
          Record Type
        </h4>
      </div>
      <div class="w-full lg:w-1/5 ">
        <h4 class="font-bold text-blue-primary pr-6 flex text-left">
          Role(s)
        </h4>
      </div>
      <div class="w-full lg:w-1/4">
        <h4
          class="font-bold text-blue-primary hover:text-blue-light pr-6 flex cursor-pointer text-left"
          @click="handleSort('problem')"
        >
          Problem
        </h4>
      </div>
      <div class="w-full lg:w-1/4">
        <h4
          class="font-bold text-blue-primary hover:text-blue-light pr-6 flex cursor-pointer text-left"
          @click="handleSort('emailStatus')"
        >
          Email Status
        </h4>
      </div>
      <div class="w-full lg:w-1/4">
        <h4
          class="font-bold text-blue-primary hover:text-blue-light pr-6 flex cursor-pointer text-left"
          @click="handleSort('reviewStatus')"
        >
          Review Status
        </h4>
      </div>
      <div class="w-full lg:w-1/4">
        <h4
          class="font-bold text-blue-primary hover:text-blue-light pr-6 flex cursor-pointer text-left"
          @click="handleSort('valid')"
        >
          Valid
        </h4>
      </div>
      <div class="w-full lg:w-1/5 ">
        <h4 class="font-bold">Actions</h4>
      </div>
    </div>

    <div class="pl-8 pr-8 lg:pl-10 lg:pr-10" v-if="users.length">
      <div
        v-for="(item, index) in users"
        v-bind:key="'user' + item.id"
        v-bind:class="{
          'block lg:flex items-center p-2 mb-2': true,
          'bg-grey-lightest': !(index % 2)
        }"
        class="content-center"
      >
        <div
          class="w-full lg:w-1/4 py-4 border-b lg:p-0 lg:border-0 font-bold text-left"
        >
          <!-- <i @click="setAdmin(item.id, item.is_admin)" :class="{'far fa-user-circle px-0 lg:px-4 text-lg cursor-pointer': true, 'text-grey-light': !item.is_admin, 'text-blue-dark': item.is_admin}" :title="item.is_admin ? 'Unset admin' : 'Set admin'"></i> -->
          <router-link
            :to="'/admin/profile/' + item.uuid"
            :class="{ 'disabled text-grey': !item.name }"
            >{{ item.name ? item.name : "No name" }}</router-link
          >
        </div>
        <div class="w-full lg:w-1/4 py-4 border-b lg:p-0 lg:border-0 text-left">
          {{ item.email }}
        </div>
        <div class="w-full lg:w-1/6 py-4 border-b lg:p-0 lg:border-0 text-left">
          {{ item.member.data.type }}
        </div>
        <div class="w-full lg:w-1/5 py-4 border-b lg:p-0 lg:border-0 text-left">
          {{ getRoles(item) }}
        </div>
        <div class="w-full lg:w-1/4 py-1 border-b lg:p-0 lg:border-0 text-left">
          <!-- @keydown.shift.exact="setProblems(item.member.data.missing_info, item.id)" -->
          <textarea
            class="textarea-users p-2 block"
            v-model="item.member.data.missing_info"
            @change="setProblems(item.member.data.missing_info, item.id)"
            placeholder="Enter profile errors"
          >
          </textarea>
        </div>
        <div class="w-full lg:w-1/4 py-1 border-b lg:p-0 lg:border-0 text-left">
          <select
            class="select-users border-2 bg-white rounded h-8"
            v-model="item.member.data.emailing"
            @change="setEmailing(item.member.data.emailing, item.id)"
          >
            <option value="null"></option>
            <option value="email1">First Email</option>
            <option value="email2">Second Email</option>
            <option value="email3">Final Email</option>
            <option value="reject">REJECT</option>
          </select>
        </div>
        <div class="w-full lg:w-1/4 py-1 border-b lg:p-0 lg:border-0 text-left">
          <select
            class="select-users border-2 bg-white rounded h-8 w-32"
            :class="item.member.data.review_status"
            v-model="item.member.data.review_status"
            @change="setReview(item.member.data.review_status, item.id)"
          >
            <option value="null"></option>
            <option value="new">New</option>
            <option value="pending">Pending</option>
            <option value="finished">Finished</option>
            <option value="rejected">Rejected</option>
          </select>
        </div>
        <div class="w-full lg:w-1/4 py-4 border-b lg:p-0 lg:border-0 text-left">
          <!-- <div v-show="!item.member.data.profile_validated" class="ml-1">
              No
            </div>
            <div v-show="item.member.data.profile_validated" class="ml-1">
              Yes
            </div> -->
          {{ item.member.data.profile_validated }}
        </div>

        <div class="w-full lg:w-1/5 py-4 lg:p-0 text-left">
          <div
            v-if="
              (item.member.data.profile_validated &&
                item.member.data.type != 'nonPublic') ||
                item.member.data.status === 'published'
            "
          >
            <!-- <button v-show="item.member.data.status != 'hidden'" @click="setStatus('hidden', item.id)" class="ml-1 btn btn-blue text-xs">Hide</button> -->
            <button
              v-show="item.member.data.status != 'draft'"
              @click="setStatus('draft', item.id)"
              class="ml-1 btn bg-grey-light btn-sm"
            >
              Unpublish
            </button>
            <button
              v-show="item.member.data.status != 'published'"
              @click="setStatus('published', item.id)"
              class="ml-1 btn btn-green btn-sm"
            >
              Publish
            </button>
          </div>
        </div>
      </div>
    </div>

    <spinner v-show="isSyncing ? true : false"></spinner>

    <div class="text-right mt-6 pl-8 pr-8 lg:pl-10 lg:pr-10">
      <span
        class="text-blue underline cursor-pointer"
        v-if="pagination.links && pagination.links.previous"
        @click="handlePaginate(pagination.links.previous)"
      >
        &#171; Previous
      </span>
      <span class="text-grey cursor-not-allowed mr-2" v-else>
        &#171; Previous
      </span>
      <span
        class="text-blue underline cursor-pointer"
        v-if="pagination.links && pagination.links.next"
        @click="handlePaginate(pagination.links.next)"
      >
        Next &#187;
      </span>
      <span class="text-grey cursor-not-allowed" v-else>
        Next &#187;
      </span>
    </div>
    <div class="blue-bar"></div>
  </div>
</template>

<style scoped>
.textarea-users {
  height: 2em;
  width: 80%;
  line-height: 1;
}
.select-users.new {
  background-color: #fff;
}
.select-users.pending {
  background-color: #ecebb8;
}
.select-users.finished {
  background-color: #d1edd3;
}
.select-users.rejected {
  background-color: #edd3d1;
}
</style>

<script>
import { mapGetters } from "vuex";
import UrlParse from "url-parse";
import _ from "lodash";

let SEARCHTIMER = null;

export default {
  data: () => ({
    users: [],
    pagination: {},
    search: "",
    isSyncing: false
  }),
  computed: {
    ...mapGetters({
      user: "user",
      isLoggedIn: "isLoggedIn",
      activeFilter: "activeFilter",
      activeSort: "activeSort",
      activeSortDir: "activeSortDir"
    })
  },
  methods: {
    getRoles: function(user) {
      if (user.roles.length > 0) {
        return user.roles.join(",\n");
      }
      return "no role";
    },
    handlePaginate: function(url) {
      console.log(url);
      const parsed = new UrlParse(url, null, true);
      this.isSyncing = true;

      let query = ["page=" + parsed.query.page];
      if (this.search) {
        query.push("search=" + this.search);
      }
      if (this.activeFilter) {
        query.push("filter=" + this.activeFilter);
      }
      if (this.activeSort) {
        query.push("sort=" + this.activeSort);
      }
      if (this.activeSortDir) {
        query.push("dir=" + this.activeSortDir);
      }
      window.axios.get("/api/user/list?" + query.join("&")).then(resp => {
        this.users = resp.data.data;
        this.pagination = resp.data.meta.pagination;
        this.isSyncing = false;
      });
    },
    handleSearchKey: function() {
      if (this.isSyncing) {
        return;
      }
      if (SEARCHTIMER) {
        clearTimeout(SEARCHTIMER);
      }

      SEARCHTIMER = setTimeout(() => {
        this.isSyncing = true;
        let query = [];
        query.push("search=" + this.search);
        if (this.activeFilter) {
          query.push("filter=" + this.activeFilter);
        }
        window.axios.get("/api/user/list?" + query.join("&")).then(resp => {
          this.users = resp.data.data;
          this.pagination = resp.data.meta.pagination;
          this.isSyncing = false;
        });
      }, 400);
    },
    handleExport: function() {
      this.isSyncing = true;
      window
        .axios({
          url: "/api/user/export",
          method: "GET",
          responseType: "blob"
        })
        .then(resp => {
          this.isSyncing = false;
          const url = window.URL.createObjectURL(new Blob([resp.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "csforall-members.csv");
          document.body.appendChild(link);
          link.click();
        });
    },
    handleResync: function() {
      if (
        !confirm(
          "This will force a resync of both Algolia and the members area of the website. Are you sure you want to continue?"
        )
      ) {
        return;
      }
      this.isSyncing = true;
      window.axios.put("/api/user/resync").then(() => {
        this.$toasted.show("Members resynced");
        this.isSyncing = false;
      });
    },
    handleFilter: function(filterVar) {
      this.isSyncing = true;
      let filter = filterVar.target.value;
      // console.log(filter);
      this.$store.dispatch("setActiveFilter", filter);

      window.axios.get("/api/user/list?filter=" + filter).then(resp => {
        this.isSyncing = false;
        this.users = resp.data.data;
        this.pagination = resp.data.meta.pagination;
      });
    },
    handleSort: function(col) {
      const newSort = col;
      let newDir = this.activeSortDir;
      if (col == this.activeSort) {
        newDir = this.activeSortDir == "asc" ? "desc" : "asc";
      }

      let query = [];
      query.push("sort=" + newSort);
      query.push("dir=" + newDir);
      if (this.activeFilter == "pending") {
        // console.log(this.activeFilter)
        query.push("filter=pending");
      } else if (this.activeFilter == "publicUnpublished") {
        query.push("filter=publicUnpublished");
      } else if (this.activeFilter == "publicPublished") {
        query.push("filter=publicPublished");
      } else if (this.activeFilter == "nonpublic") {
        query.push("filter=nonpublic");
      } else if (this.activeFilter == "researchersUnpublished") {
        query.push("filter=researchersUnpublished");
      } else if (this.activeFilter == "membersUnpublished") {
        query.push("filter=membersUnpublished");
      } else if (this.activeFilter == "reviewStatusNew") {
        query.push("filter=reviewStatusNew");
      } else if (this.activeFilter == "reviewStatusPending") {
        query.push("filter=reviewStatusPending");
      } else if (this.activeFilter == "reviewStatusFinished") {
        query.push("filter=reviewStatusFinished");
      } else if (this.activeFilter == "reviewStatusRejected") {
        query.push("filter=reviewStatusRejected");
      }
      this.isSyncing = true;

      this.$store.dispatch("setActiveSort", newSort);
      this.$store.dispatch("setActiveSortDir", newDir);

      window.axios.get("/api/user/list?" + query.join("&")).then(resp => {
        this.isSyncing = false;
        this.users = resp.data.data;
        this.pagination = resp.data.meta.pagination;
      });
    },
    checkAdmin: function() {
      if (!this.user.is_admin) {
        this.$router.push("/home");
      }
    },

    setStatus: function(status, id) {
      this.isSyncing = true;
      const data = {
        id: id,
        status: status
      };
      window.axios.put("/api/user/status", data).then(resp => {
        const existing = _.findIndex(this.users, user => {
          return user.id == id;
        });
        const changed = _.findIndex(resp.data.data, user => {
          return user.id == id;
        });

        if (changed !== -1) {
          this.users[existing] = resp.data.data[changed];
        } else {
          // couldn't find it, fake it
          this.users[existing].member.data.status = status;
        }
        // this.users = resp.data.data;
        // this.pagination = resp.data.meta.pagination;
        this.$toasted.show("User updated");
        this.isSyncing = false;
      });
    },
    setProblems: function(problems, id) {
      this.isSyncing = true;
      const data = {
        id: id,
        missing_info: problems
      };
      window.axios.put("/api/user/problems", data).then(resp => {
        const existing = _.findIndex(this.users, user => {
          return user.id == id;
        });
        const changed = _.findIndex(resp.data.data, user => {
          return user.id == id;
        });

        if (changed !== -1) {
          this.users[existing] = resp.data.data[changed];
        } else {
          // couldn't find it, fake it
          this.users[existing].member.data.missing_info = problems;
        }
        this.$toasted.show("User updated");
        this.isSyncing = false;
      });
    },
    setEmailing: function(emailStatus, id) {
      this.isSyncing = true;
      // console.log("in set emailing");
      const data = {
        id: id,
        emailing: emailStatus
      };
      window.axios.put("/api/user/emailing", data).then(resp => {
        const existing = _.findIndex(this.users, user => {
          return user.id == id;
        });
        const changed = _.findIndex(resp.data.data, user => {
          return user.id == id;
        });

        if (changed !== -1) {
          this.users[existing] = resp.data.data[changed];
        } else {
          // couldn't find it, fake it
          this.users[existing].member.data.emailing = emailStatus;
        }
        this.$toasted.show("User updated");
        this.isSyncing = false;
      });
    },
    setReview: function(reviewstatus, id) {
      this.isSyncing = true;
      const data = {
        id: id,
        review_status: reviewstatus
      };
      window.axios.put("/api/user/review_status", data).then(resp => {
        const existing = _.findIndex(this.users, user => {
          return user.id == id;
        });
        const changed = _.findIndex(resp.data.data, user => {
          return user.id == id;
        });

        if (changed !== -1) {
          this.users[existing] = resp.data.data[changed];
        } else {
          // couldn't find it, fake it
          this.users[existing].member.data.review_status = reviewstatus;
        }
        this.$toasted.show("User updated");
        this.isSyncing = false;
      });
    }
    // setAdmin: function(id, currently) { REMOVED FROM INDEX, CAN UPDATE IN PROFILE
    //   const data = {
    //     id: id,
    //     flag: !currently
    //   };

    //   window.axios.put("/api/user/admin", data).then(resp => {
    //     const existing = _.findIndex(this.users, user => {
    //       return user.id == id;
    //     });
    //     const changed = _.findIndex(resp.data.data, user => {
    //       return user.id == id;
    //     });

    //     if (changed !== -1) {
    //       this.users[existing] = resp.data.data[changed];
    //     } else {
    //       // couldn't find it, fake it
    //       this.users[existing].is_admin = !currently;
    //     }
    //     // this.users = resp.data.data;
    //     // this.pagination = resp.data.meta.pagination;
    //     this.$toasted.show("User updated");
    //   });
    // }
  },
  created() {
    this.$store.dispatch("setActiveNav", "admin");
    this.$store.subscribe(mutation => {
      if (mutation.type != "USER_SET") {
        return;
      }
      this.checkAdmin();
    });
  },
  mounted() {
    this.checkAdmin();
    let query = [];
    if (this.activeFilter == "pending") {
      // console.log(this.activeFilter)
      query.push("filter=pending");
    } else if (this.activeFilter == "publicUnpublished") {
      query.push("filter=publicUnpublished");
    } else if (this.activeFilter == "publicPublished") {
      query.push("filter=publicPublished");
    } else if (this.activeFilter == "nonpublic") {
      query.push("filter=nonpublic");
    } else if (this.activeFilter == "researchersUnpublished") {
      query.push("filter=researchersUnpublished");
    } else if (this.activeFilter == "membersUnpublished") {
      query.push("filter=membersUnpublished");
    } else if (this.activeFilter == "reviewStatusNew") {
      query.push("filter=reviewStatusNew");
    } else if (this.activeFilter == "reviewStatusPending") {
      query.push("filter=reviewStatusPending");
    } else if (this.activeFilter == "reviewStatusFinished") {
      query.push("filter=reviewStatusFinished");
    } else if (this.activeFilter == "reviewStatusRejected") {
      query.push("filter=reviewStatusRejected");
    }
    if (this.activeSort) {
      query.push("sort=" + this.activeSort);
    }
    if (this.activeSortDir) {
      query.push("dir=" + this.activeSortDir);
    }
    this.isSyncing = true;
    window.axios.get("/api/user/list?" + query.join("&")).then(resp => {
      this.users = resp.data.data;
      this.pagination = resp.data.meta.pagination;
      this.isSyncing = false;
    });
  }
};
</script>
