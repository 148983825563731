<template>
  <div class="relative">
    <div class="container mx-auto w-full lg:w-2/5 pb-24">
      <verify-email-confirmation-code
        v-if="showConfirmEmailModal"
        :is-sign-up="false"
        :email="email"
        :message="confirmationModalMessage"
        @confirmationComplete="emailConfirmationComplete"
      />

      <h1 class="text-center">Member Account</h1>
      <p class="mt-3 mb-10 text-center">Edit your account</p>

      <form aria-label="Register" @submit.prevent="validateBeforeSubmit">
        <div
          v-bind:class="{
            'mb-10': true,
            'has-error': errors.has('first_name')
          }"
        >
          <label for="first_name">First Name</label>

          <input
            id="first_name"
            type="text"
            name="first_name"
            v-model.trim="first_name"
            v-validate="'required'"
          />
          <div class="mt-4">
            <span
              v-show="errors.has('first_name')"
              class="text-red text-xs italic"
              >{{ errors.first("first_name") }}</span
            >
          </div>
        </div>

        <div v-bind:class="{ 'mb-10': true, 'has-error': errors.has('name') }">
          <label for="last_name">Last Name</label>

          <input
            id="last_name"
            type="text"
            name="last_name"
            v-model.trim="last_name"
            v-validate="'required'"
          />
          <div class="mt-4">
            <span
              v-show="errors.has('last_name')"
              class="text-red text-xs italic"
              >{{ errors.first("last_name") }}</span
            >
          </div>
        </div>

        <div v-bind:class="{ 'mb-10': true, 'has-error': errors.has('email') }">
          <label for="email">Email</label>

          <input
            id="email"
            type="email"
            name="email"
            v-model.trim="email"
            v-validate="'required'"
            disabled
          />
          <div class="mt-4">
            <span
              v-show="errors.has('email')"
              class="text-red text-xs italic"
              >{{ errors.first("email") }}</span
            >
          </div>
        </div>

        <div class="mb-6">
          <a href="mailto:script@csforall.org" class="text-sm">
            Transfer account? <b class="ml-1">Contact Us</b>
          </a>
        </div>

        <div>
          <button
            @click="handleCancel"
            type="button"
            class="btn bg-grey-lighter"
          >
            Cancel
          </button>
          <button type="submit" :disabled="!canSave" :class="submitButtonClass">
            Save
          </button>
          <button
            @click="handlePasswordReset"
            class="btn btn-blue mr-auto ml-4"
          >
            Reset Password
          </button>
        </div>
      </form>

      <spinner v-show="isSaving"></spinner>
    </div>
    <div class="blue-bar"></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VerifyEmailConfirmationCode from "../modals/VerifyEmailConfirmationCode";
import { Auth } from "aws-amplify";

export default {
  components: {
    VerifyEmailConfirmationCode
  },
  data() {
    return {
      first_name: null,
      last_name: null,
      email: null,
      isSaving: false,
      showConfirmEmailModal: false,
      newUserData: {}
    };
  },
  computed: {
    ...mapGetters({
      user: "user",
      isLoggedIn: "isLoggedIn"
    }),
    hasFormChanged() {
      return Object.keys(this.fields).some(key => this.fields[key].changed);
    },
    canSave() {
      return this.hasFormChanged && !this.isSaving;
    },
    userData() {
      return {
        email: this.email,
        first_name: this.first_name,
        last_name: this.last_name
      };
    },
    hasEmailChanged() {
      return this.fields.email.changed;
    },
    confirmationModalMessage() {
      return `We've sent a confirmation code to ${this.email}. Please
              re-enter it here to confirm your account.`;
    },
    submitButtonClass() {
      return {
        "btn btn-blue ml-4": true,
        "opacity-50 cursor-not-allowed": !this.canSave
      };
    }
  },
  methods: {
    setProfile: function() {
      this.email = this.user.email;
      this.first_name = this.user.first_name;
      this.last_name = this.user.last_name;
      this.$validator.reset();
    },
    handleCancel: function() {
      this.$router.push("/home");
      return;
    },
    handlePasswordReset: function() {
      this.$router.push("/auth/reset");
      return;
    },
    emailConfirmationComplete: async function() {
      await this.$store.dispatch("setUser", this.newUserData);
      this.showConfirmEmailModal = false;
      this.$toasted.show("Account updated");
      this.$router.push("/home");
    },
    emailChangePrompt: async function() {
      const changeEmailQuestion = `Are you sure you want to change your email to:\n "${this.email}" \nThis will change your login credentials and where you recieve notifications`;

      return new Promise(function(resolve) {
        const confirmed = confirm(changeEmailQuestion);

        return resolve(confirmed);
      });
    },
    validateBeforeSubmit: async function() {
      const isAllValid = await this.$validator.validateAll();

      if (!isAllValid) {
        return;
      }

      if (this.hasEmailChanged) {
        this.updateDataWithEmail();
      } else {
        this.updateData();
      }
    },
    updateDataWithEmail: async function() {
      const isEmailChangeConfirmed = await this.emailChangePrompt();

      if (!isEmailChangeConfirmed) {
        return;
      }

      const self = this;

      const afterPutHandler = async function() {
        const user = await Auth.currentAuthenticatedUser();
        await Auth.updateUserAttributes(user, { email: self.email });

        self.isSaving = false;
        self.showConfirmEmailModal = true;
      };

      await this.putDataUpdates(afterPutHandler);
    },
    updateData: async function() {
      const self = this;

      const afterPutHandler = async function() {
        await self.$store.dispatch("setUser", self.newUserData);

        self.isSaving = false;

        self.$toasted.show("Account updated");
        await self.$router.push("/home");
      };

      await this.putDataUpdates(afterPutHandler);
    },
    putDataUpdates: async function(afterPutHandler) {
      try {
        this.isSaving = true;

        const resp = await window.axios.put("/api/me", this.userData);
        this.newUserData = resp.data.data[0];

        await afterPutHandler();
      } catch (e) {
        this.isSaving = false;
        this.$toasted.show("There was an error saving your account data");
        console.log(e);
      }
    }
  },
  created() {
    this.$store.dispatch("setActiveNav", "account");
    this.$store.subscribe(mutation => {
      if (mutation.type != "USER_SET") {
        return;
      }
      this.setProfile();
    });
  },
  mounted() {
    this.setProfile();
  }
};
</script>
