export const RESEARCH_TYPES_SET = "RESEARCH_TYPES_SET";

const state = {
  researchTypes: {}
};

const getters = {
  researchTypes: state => state.researchTypes
};

const actions = {
  setResearchTypes: ({ commit }, researchTypes) => {
    commit(RESEARCH_TYPES_SET, {
      researchTypes: researchTypes
    });
  }
};

const mutations = {
  RESEARCH_TYPES_SET: (state, payload) => {
    state.researchTypes = Object.assign(
      {},
      state.researchTypes,
      payload.researchTypes
    );
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
