<template>
  <transition name="modal" v-show="value">
    <div class="modal-mask h-full flex justify-center items-center">
      <div
        class="modal-wrapper overflow-scroll flex h-full justify-center items-center w-full"
      >
        <div
          class="modal-box flex flex-col modal-container bg-white rounded shadow m-auto w-3/4 md:w-3/4 lg:w-3/4 pt-6 pb-10 lg:pb-24"
          id="lea-invite-modal"
        >
          <div class="ml-10 mr-10">
            <div class="p-2 flex justify-between items-center">
              <h2 class="text-blue-primary mb-5">
                {{ participant.member.name }} Materials
              </h2>
              <button @click.prevent="close">
                <h1 class="text-blue-primary mr-5">X</h1>
              </button>
            </div>

            <h3 class="text-h3 font-bold">{{ workshop.title }}</h3>
            <p>
              Add materials that you want to display for this individual team.
              These will show as links when the team is viewing the workshop.
            </p>

            <div
              v-for="(team_material, index) in formData.team_materials"
              :key="index"
            >
              <div
                v-if="participant.member_id === team_material.form_member"
                class="workshop-forms-group"
              >
                <i
                  title="Remove link"
                  class="far fa-minus-square cursor-pointer text-blue-dark float-right minus-placement"
                  @click="removeLink(team_material)"
                ></i>
                <input
                  type="text"
                  placeholder="Material Name"
                  v-model="team_material.form_name"
                  :name="`material name ${index + 1}`"
                  v-validate="'required'"
                />
                <div class="my-4">
                  <span
                    v-show="errors.has(`material name ${index + 1}`)"
                    class="text-xs italic text-red"
                    >{{ errors.first(`material name ${index + 1}`) }}</span
                  >
                </div>
                <input
                  type="text"
                  placeholder="http://example.com"
                  v-model="team_material.form_url"
                  :name="`material URL ${index + 1}`"
                  v-validate="{
                    required: true,
                    url: {
                      require_protocol: true,
                      protocols: ['http', 'https']
                    }
                  }"
                />
                <div class="my-4">
                  <span
                    v-show="errors.has(`material URL ${index + 1}`)"
                    class="text-xs italic text-red"
                    >{{ errors.first(`material URL ${index + 1}`) }}</span
                  >
                </div>
              </div>
              <div v-else></div>
            </div>

            <button @click="addMoreForms" class="btn btn-blue-light mt-4 ">
              + Add More Materials
            </button>
            <br />
            <button @click="update" class="btn btn-blue mt-4 float-right">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<style lang="css" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.85);
  transition: opacity 0.3s ease;
}

.modal-container {
  transition: all 0.3s ease;
}

label {
  padding-left: 22px;
}

.export {
  margin-right: 5px;
}

/*
    * The following styles are auto-applied to elements with
    * transition="modal" when their visibility is toggled
    * by Vue.js.
    *
    * You can easily play with the modal transition by editing
    * these styles.
    */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

/* workshop materials */
.workshop-forms-group {
  height: 100%;
  padding: 30px;
  margin-bottom: 20px;
  border-radius: 20px;
  border: solid 1px #979797;
}

.workshop-forms-group input {
  height: 100%;
  padding: 10px;
  border-radius: 8px;
  border: solid 1px lightgray;
}

.workshop-forms-group input:last-child {
  margin-top: 20px;
}
.minus-placement {
  margin-top: -15px !important;
  margin-bottom: 7px;
}
</style>

<script>
import _ from "lodash";

export default {
  name: "TeamMaterialsModal",
  props: {
    value: {
      required: true
    },
    workshop: Object,
    participant: Object
  },
  components: {},
  data: () => ({
    formData: {
      team_materials: [
        {
          form_id: "",
          form_name: "",
          form_url: "",
          form_member: ""
        }
      ]
    },
    loading: false
  }),

  methods: {
    close() {
      this.$emit("input", !this.value);
    },
    addMoreForms() {
      this.formData.team_materials.push({
        form_id: "",
        form_name: "",
        form_url: "",
        form_member: this.participant.member_id,
        temp_id: (+new Date()).toString(36)
      });
    },
    load() {
      if (this.workshop.team_materials !== undefined) {
        this.formData.team_materials = this.workshop.team_materials.map(obj => {
          if (obj.form_url !== null && obj.form_name !== null) {
            return {
              form_id: obj.id,
              form_name: obj.form_name,
              form_url: obj.form_url,
              form_member: obj.member_id
            };
          }
        });
      }
    },
    removeLink(material) {
      this.formData.team_materials = _.filter(
        this.formData.team_materials,
        function(m) {
          return (
            m?.form_id !== material?.form_id || m?.temp_id !== material?.temp_id
          );
        }
      );
    },
    gatherTeamMaterials() {
      this.formData.team_materials = this.formData.team_materials.filter(
        obj =>
          obj.form_name !== "" && obj.form_url !== "" && obj.form_member !== ""
      );

      let data = {
        ...this.formData
      };
      return data;
    },
    async update() {
      const validatorResult = await this.$validator.validateAll();

      if (!validatorResult) return;

      this.loading = true;
      let data = this.gatherTeamMaterials();
      window.axios
        .patch(`/api/workshops/${this.workshop.id}`, data)
        .then(() => {
          this.loading = false;
          this.$toasted.show("Workshop updated");
          this.$store.dispatch("getFacilitatorWorkshop", this.workshop.id);
          this.close();
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    }
  },
  mounted() {
    this.load();
  }
};
</script>
