import { MEMBER_TYPES, ROLES } from "@/const";
import { Auth } from "aws-amplify";
import { getDataOnSignIn } from "@/util/getDataOnSignIn";
import _ from "lodash";

export default {
  data() {
    return {
      isSyncing: false,
      showConfirmEmailModal: false,
      failedToRegisterUserMsg: "Failed to register user",
      hasQueryParamVal: false
    };
  },
  computed: {
    isWorkshop() {
      return this.inviteId;
    },
    roleName() {
      return this.questionnaire.role?.displayName;
    }
  },
  methods: {
    normalize(data) {
      return {
        first_name: data.firstName,
        last_name: data.lastName,
        email: data.email,
        password: data.password,
        password_confirmation: data.password,
        org_types: data.organizationTypes ?? [],
        type: data.memberType,
        role: data.role?.type,
        organization: this.getOrganization(data),
        participant_role: data.institutionalRole,
        lea_type: data.leaType,
        school_name: data.schoolName,
        school_district: data.schoolDistrict,
        other_gender: data.other_gender ?? "",
        gender: data.gender,
        other_ethnicity_cultural_background:
          data.other_ethnicity_cultural_background ?? "",
        ethnicity_cultural_background: data.ethnicity_cultural_background,
        org_id: data.org_id ?? ""
      };
    },
    async submit() {
      const isAllValid = await this.$validator.validateAll();

      if (!isAllValid) {
        return;
      }

      this.isSyncing = true;

      const normalData = this.normalize(this.questionnaire);

      try {
        const response = await window.axios.post("/api/register", normalData);
        if (response && response.data.message != "ok") {
          this.showEmailFieldError(this.failedToRegisterUserMsg);
        } else {
          await this.cognitoRegistration();
        }
      } catch (error) {
        this.isSyncing = false;
        console.log("failed to register");
        let errors = error.response.data.errors;
        if (errors) {
          // attach an erros message for all fields validated on backend, mainly used for email
          _.forEach(errors, (message, id) => {
            const field = this.$validator.fields.find({
              name: id,
              scope: this.$options.scope
            });
            this.$validator.errors.add({
              id: field.id,
              field: id,
              msg: message[0],
              scope: this.$options.scope
            });
          });
        }
      }
    },
    cognitoRegistration: async function() {
      try {
        const { email, password } = this.questionnaire;

        await Auth.signUp({
          username: email,
          password: password
        });

        this.showConfirmEmailModal = true;
      } catch (error) {
        let errorMessage;

        if (error.code === "UsernameExistsException") {
          errorMessage = error.message;
        } else {
          errorMessage = this.failedToRegisterUserMsg;
        }

        this.showEmailFieldError(errorMessage);

        console.error(error);
      } finally {
        this.isSyncing = false;
      }
    },
    getOrganization(data) {
      if (data.memberType === MEMBER_TYPES.MEMBER) {
        return data.organization;
      } else {
        return `${data.firstName} ${data.lastName}`;
      }
    },
    emailConfirmationComplete: async function() {
      this.showConfirmEmailModal = false;
      this.isSyncing = true;

      const { email, password } = this.questionnaire;

      const user = await Auth.signIn(email, password);

      await getDataOnSignIn(this.$store, user);

      this.clearQuestionnaire();

      if (this.isWorkshop) {
        await this.registerWorkshop();
      } else {
        this.redirectAfterVerification();
      }
    },
    async registerWorkshop() {
      this.user = this.$store.getters.user;
      const data = {
        user_id: this.user.id,
        workshop_id: this.workshop.id,
        member_id: this.user.member.id,
        workshopEmailInvitationUuid: this.inviteId
      };

      try {
        await window.axios.post("/api/registration", data);

        this.$toasted.show("You have been registered to the workshop");

        await this.$store.dispatch("getUser", this.user.id);

        this.redirectAfterVerification();
      } catch (error) {
        console.log(error);
        alert("Error, please try again.");
        this.$router.push("/home");
      }
    },
    async getWorkshopFromInviteId() {
      // TODO: Remove when implement fetching and storing workshop in store.
      if (this.workshop) {
        return;
      }
      try {
        const response = await window.axios.get(
          `/api/me/member/workshop/email-invite/${this.inviteId}`
        );

        const { data } = response;

        if (data.notFound) {
          this.$router.push({ name: "WorkshopNotFound" });
        } else if (data.stale) {
          this.$router.push({ name: "WorkshopInviteUsed" });
        } else {
          this.workshop = data.workshop; // TODO: Only need workshop ID returned, not whole object
        }
      } catch (error) {
        console.log("error getting workshop info");
        console.log(error);
      }
    },
    showEmailFieldError(message) {
      const field = this.$validator.fields.find({
        name: "email",
        scope: this.$options.scope
      });

      this.$validator.errors.add({
        id: field.id,
        field: "email",
        msg: message,
        scope: this.$options.scope
      });
    },
    confirmRefresh(event) {
      event.preventDefault();
      event.returnValue = "";
    },
    hasQueryParam(value) {
      this.hasQueryParamVal = value;
    },
    redirectAfterVerification: function() {
      let url;

      if (this.questionnaire.role?.type === ROLES.PENDING_REVIEWER) {
        url = "/home";
      } else if (
        this.isWorkshop &&
        this.questionnaire.role?.type === ROLES.LEA_ADMIN
      ) {
        url = `/auth/create-profile/${this.workshop.id}`;
      } else {
        if (this.hasQueryParamVal) {
          url = `/auth/create-profile?orgGroup=${this.$route.query.orgGroup}`;
        } else {
          url = "/auth/create-profile";
        }
      }

      this.$router.push(url);
    }
  },
  beforeMount() {
    window.addEventListener("beforeunload", this.confirmRefresh);
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.confirmRefresh);
  }
};
