<template>
  <div class="w-full">
    <verify-email-confirmation-code-modal
      v-show="showConfirmEmailModal"
      :email="email"
      :message="
        `Your account has not yet been verified. Please confirm your account with the code that was previously sent, or resend a new code to confirm`
      "
      @confirmationComplete="emailConfirmationComplete"
    ></verify-email-confirmation-code-modal>
    <div v-if="loading">
      <spinner></spinner>
    </div>
    <div
      class="md:h-screen flex flex-col-reverse flex-col md:flex-row relative"
    >
      <div
        class="w-full lg:w-1/2 bg-blue-primary lg:h-screen flex flex-col items-center text-white justify-center p-2 lg:p-10 text-center relative"
      >
        <img
          src="@/assets/img/circuits-white.svg"
          alt="Circuits"
          class="hidden md:block absolute pin-t pin-r pin-l mx-auto opacity-25 lg:opacity-100"
        />
        <div v-if="workshopId && loaded && !workshopInviteStale">
          <h1 class="text-center mb-10 text-white">
            You're invited to a Workshop
          </h1>
          <p class="text-center mb-2 text-white">
            To register simply login to your member profile
          </p>
          <div class="text-left text-blue-primary rounded bg-blue-panel p-10">
            <h2>Workshop: {{ workshop.title }}</h2>
            <br />
            <h3>Time: {{ workshop.start_time }} - {{ workshop.end_time }}</h3>
            <br />
            <p>
              Presenter Name: {{ workshop.presenter_first_name }}
              {{ workshop.presenter_last_name }}
            </p>
            <p>Workshop Sponsored by: {{ workshop.event_sponsor_host }}</p>
          </div>
        </div>
        <!-- workshop invite invalid -->
        <div v-else-if="workshopInviteInvalid && loaded">
          <div class="text-left text-blue-primary rounded bg-blue-panel p-10">
            <h1>
              Workshop Invite not found
            </h1>
            <br />
            <p class="leading-loose font-semibold center">
              Please contact your facilitator for more information and to
              request a new invite.
            </p>
          </div>
        </div>
        <div v-else-if="workshopInviteStale && loaded">
          <div class="text-left text-blue-primary rounded bg-blue-panel p-10">
            <h1>
              This invite was already used
            </h1>
            <br />
            <p class="leading-loose font-semibold center">
              Looks like someone already signed up with this invite. Please
              login to view your workshops or request a new invite from your
              facilitator.
              <br />
              <router-link to="/auth/login" class="text-blue underline">
                Go to login
              </router-link>
            </p>
          </div>
        </div>
        <div v-else-if="loaded">
          <div v-if="queryParam">
            <p>
              <b>Welcome CS100 Award Winning Schools!</b>
            </p>
            <h2 class="my-4 lg:mt-20 text-h1 md:text-large cs100logintitle">
              Get Your Free CSforAll Membership
            </h2>
          </div>

          <div v-else>
            <h2 class="my-4 lg:mt-20 text-h1 md:text-large">
              Don’t have an account?
            </h2>
            <p class="leading-loose">
              Register as a member or researcher to create a profile for the
              CSforALL website.
            </p>
          </div>
          <button @click="registerNow" class="btn btn-white text-h4 mt-6">
            Register Now
          </button>

          <div v-if="queryParam">
            <br /><br />
            <p class="leading-loose">
              Complete your CSforALL profile to register as a member and
              <br />
              receive all member benefits.
            </p>
          </div>
        </div>
      </div>

      <div
        class="w-full lg:w-1/2 flex flex-col items-center h-auto md:h-screen justify-center p-10 lg:p-32 mb-10 md:mb-0"
      >
        <img
          src="@/assets/img/csforall-logo.svg"
          alt="CSforAll"
          class="mb-10 main-logo"
        />
        <div v-if="(workshopInviteStale || workshopInviteInvalid) && loaded">
          <h2 v-if="workshopInviteInvalid" class="mb-4 text-grey-dark">
            These are not the droids you're looking for
          </h2>
          <div class="flex items-center justify-center">
            <router-link to="/auth/login" class="btn btn-blue text-center mt-6">
              Go to Login
            </router-link>
          </div>
        </div>
        <spinner v-else-if="!loaded"></spinner>
        <h2 v-else class="mb-4 text-grey-dark">Login</h2>
        <form
          v-if="!(workshopInviteStale || workshopInviteInvalid) && loaded"
          method="POST"
          aria-label="Login"
          @submit.prevent="validateBeforeSubmit"
          class="w-full"
        >
          <div
            v-bind:class="{ 'mb-4': true, 'has-error': errors.has('email') }"
          >
            <input
              id="email"
              placeholder="Email Address"
              type="email"
              name="email"
              class="mb-2"
              v-model="email"
              v-validate="'required|email'"
              required
              autofocus
            />
            <span
              v-show="errors.has('email')"
              class="text-red text-xs italic"
              >{{ errors.first("email") }}</span
            >
          </div>

          <div
            v-bind:class="{ 'mb-4': true, 'has-error': errors.has('password') }"
          >
            <input
              id="password"
              placeholder="Password"
              type="password"
              name="password"
              class="text-h5 mb-2"
              v-model="password"
              v-validate="`required|min:${VALIDATION.MIN_PW_LEN}`"
              required
            />
            <span
              v-show="errors.has('password')"
              class="text-red text-xs italic"
              >{{ errors.first("password") }}</span
            >
          </div>
          <router-link to="/auth/forgot" class="text-sm flex float-left "
            >Forgot your password?</router-link
          >
          <a href="mailto:script@csforall.org" class="text-sm flex float-right">
            Transfer account? <b class="ml-1">Contact Us</b>
          </a>
          <div class="flex items-center justify-between">
            <button type="submit" class="btn btn-blue w-full mt-6">
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<style scoped>
.cs100logintitle {
  margin-top: 10px;
  color: black;
}
</style>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";
import { Auth } from "aws-amplify";
import { getDataOnSignIn } from "../../util/getDataOnSignIn";
import { COGNITO_ERRORS, VALIDATION } from "../../const";
import VerifyEmailConfirmationCode from "../modals/VerifyEmailConfirmationCode";
import Spinner from "../Spinner.vue";

export default {
  components: {
    "verify-email-confirmation-code-modal": VerifyEmailConfirmationCode,
    spinner: Spinner
  },
  data: () => ({
    email: null,
    password: null,
    remember: false,
    showConfirmEmailModal: false,
    VALIDATION: VALIDATION,
    loaded: false,
    loading: false,
    // add in workshop info
    workshopInviteInvalid: false,
    workshopInviteStale: false,
    inviteUsers: {},
    workshopId: undefined,
    workshop: {}
  }),
  computed: {
    ...mapGetters(["user", "isLoggedIn"]),
    queryParam() {
      return Object.keys(this.$route.query).length !== 0;
    }
  },
  watch: {
    $route: "handleRefresh"
  },
  methods: {
    registerNow() {
      let routeConfig = this.queryParam
        ? {
            path: "/auth/pre-req",
            query: this.$route.query
          }
        : {
            path: "/auth/pre-req"
          };
      this.$router.push(routeConfig);
    },
    validateBeforeSubmit: function() {
      this.$validator.validateAll().then(async result => {
        if (!result) {
          return;
        }
        this.message = false;
        this.cognitoSignIn();
      });
    },
    handleRefresh() {
      //reset all variables that were loaded from the invitation path to reset the page to a normal login with no invitation.
      this.workshopInviteInvalid = false;
      this.workshopInviteStale = false;
      this.inviteUsers = {};
      this.workshopId = undefined;
      this.workshop = {};
      this.load();
    },
    cognitoSignIn: async function() {
      try {
        // Check for anyone who isn't a user on the invited member (LEA)
        this.loading = true;
        if (this.workshopId) {
          if (this.inviteUsers.includes(this.email)) {
            const user = await Auth.signIn(this.email, this.password);
            await getDataOnSignIn(this.$store, user);
            await this.registerWorkshop();
          } else {
            const field = this.$validator.fields.find({
              name: "email",
              scope: this.$options.scope
            });
            this.$validator.errors.add({
              id: field.id,
              field: "email",
              msg:
                "Sorry, these credentials don't correspond to the organization this inviation is for. Please enter an email that corresponds to your workshop invitation.",
              scope: this.$options.scope
            });
          }
        } else {
          const user = await Auth.signIn(this.email, this.password);
          // Conditional when user is created via CognitoPool with 'FORCE_CHANGE_PASSWORD' account status.
          if (
            user.challengeName === "NEW_PASSWORD_REQUIRED" ||
            user.challengeName === "RESET_REQUIRED"
          ) {
            this.$router.push({
              path: "/auth/reset",
              query: {
                email: this.email,
                required: true,
                passreset: true
              }
            });
          } else {
            await getDataOnSignIn(this.$store, user);
            this.ForwardLoggedInUser();
          }
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        if (error.code === COGNITO_ERRORS.USER_NOT_CONFIRMED_EXCEPTION) {
          this.showConfirmEmailModal = true;
        } else if (
          error.code === COGNITO_ERRORS.PASSWORD_RESET_REQUIRED_EXCEPTION
        ) {
          // Cognito migration forced password reset...
          Auth.forgotPassword(this.email)
            .then(() => {
              this.$router.push({
                path: "/auth/forgot/reset",
                query: { email: this.email, required: true }
              });
            })
            .catch(() => {
              console.log("Error sending password reset code");
            });
        } else {
          const field = this.$validator.fields.find({
            name: "email",
            scope: this.$options.scope
          });
          this.$validator.errors.add({
            id: field.id,
            field: "email",
            msg: "Sorry, those credentials don't match our records",
            scope: this.$options.scope
          });
        }
      }
    },
    isRegisteredToWorkshop(workshopId) {
      const registration = _.find(
        this.user.member.registrations.data,
        registration => registration.workshop_id == workshopId
      );

      return Boolean(registration);
    },
    async registerWorkshop() {
      if (this.isRegisteredToWorkshop(this.workshopId)) {
        this.$router.push(`/workshops/${this.workshopId}`);
        return;
      }

      try {
        const registration = await window.axios.post("/api/registration", {
          user_id: this.user.id,
          workshop_id: this.workshopId,
          member_id: this.user.member.id,
          workshopMemberInvitationUuid: this.$route.params.memInvitationUuid
        });

        // refresh workshop and user based on new data
        await this.$store.dispatch("setUser", registration.data.user.data[0]);
        await this.$store.dispatch("getWorkshop", this.workshopId);

        await this.$toasted.show("You have been registered to the workshop");

        this.$router.push(`/workshops/${this.workshopId}`);
      } catch (error) {
        console.log(error);
        alert("error registering to workshop, please try again");
        this.$router.push("/home");
      }
    },
    emailConfirmationComplete: async function() {
      this.showConfirmEmailModal = false;
      this.cognitoSignIn();
    },
    async getWorkshopFromInvitationUuid() {
      const response = await window.axios.get(
        `/api/me/member/workshop/mem-invite/${this.$route.params.memInvitationUuid}`
      );

      try {
        let users;
        if (response.data.notFound) {
          // handle invite not found - deleted.
          this.workshopInviteInvalid = true;
        } else if (response.data.stale) {
          // handle invite already used.
          this.workshopInviteStale = true;
          this.workshopId = response.data.invite.workshop.id;
          this.workshop = response.data.invite;
          users = response.data.invite.member.users;
          this.inviteUsers = users.map(user => user.email);
        } else {
          this.workshopId = response.data.workshop.id;
          this.workshop = response.data.workshop;
          users = response.data.member.users;
          this.inviteUsers = users.map(user => user.email);
        }
        this.loaded = true;
      } catch (error) {
        console.log("error getting workshop info");
        console.log(error);
      }
    },
    ForwardLoggedInUser() {
      if (this.isLoggedIn) {
        console.log("forwarding user to regular bc logged in");
        const to = window._redirectedFrom ? window._redirectedFrom : "/home";
        this.$router.push(to);
      } else {
        console.log("is not logged in");
      }
    },
    async load() {
      if (this.$route.params.memInvitationUuid) {
        await this.processInvite();
      } else {
        this.loaded = true;
        if (this.isLoggedIn) {
          this.ForwardLoggedInUser();
        }
      }
    },
    async processInvite() {
      await this.getWorkshopFromInvitationUuid();

      if (!this.isLoggedIn || this.workshopInviteInvalid) {
        return;
      }

      try {
        const user = await Auth.currentAuthenticatedUser();

        await getDataOnSignIn(this.$store, user);

        if (this.inviteUsers.includes(this.user.email)) {
          await this.registerWorkshop();
        }
      } catch (error) {
        console.log(error);
      }
    }
  },

  async mounted() {
    await this.load();
  }
};
</script>
