<template>
  <div class="-mt-5" v-show="breadcrumbs.length">
    <div
      class="breadcrumbs mb-10 inline-flex justify-center md:justify-start text-md"
      v-for="(crumb, index) in breadcrumbs"
      v-bind:key="index"
    >
      <div class="flex-1 mr-2">
        <router-link class="text-black" v-if="crumb.link" :to="crumb.link">
          {{ crumb.label || "" }}
        </router-link>
        <span class="font-bold" v-else>{{ crumb.label || "" }}</span>
        <span class="ml-1" v-show="index < breadcrumbs.length - 1">&gt;</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      isLoggedIn: "isLoggedIn",
      user: "user",
      activeNav: "activeNav"
    })
  },
  props: {
    breadcrumbs: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  mounted() {}
};
</script>
