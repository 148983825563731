export const FACILITATOR_WORKSHOP_SET = "FACILITATOR_WORKSHOP_SET";
export const FACILITATOR_WORKSHOPS_SET = "FACILITATOR_WORKSHOPS_SET";

const state = {
  list: {},
  active: {}
};

const getters = {
  activeFacilitatorWorkshop: state => (state.active.length ? true : false),
  facilitatorWorkshop: state => state.active,
  facilitatorWorkshops: state => state.list
};

const actions = {
  setFacilitatorWorkshop: ({ commit }, workshop) => {
    commit(FACILITATOR_WORKSHOP_SET, {
      workshop: workshop
    });
  },
  setFacilitatorWorkshops: ({ commit }, workshops) => {
    commit(FACILITATOR_WORKSHOPS_SET, {
      workshops: workshops
    });
  },
  getFacilitatorWorkshop: ({ commit }, workshop_id) => {
    window.axios
      .get(`/api/facilitator/workshops/${workshop_id}`)
      .then(response => {
        commit(FACILITATOR_WORKSHOP_SET, {
          workshop: response.data
        });
      })
      .catch(error => {
        console.log("error:" + error);
      });
  }
};

const mutations = {
  FACILITATOR_WORKSHOP_SET: (state, payload) => {
    state.active = Object.assign({}, state.active, payload.workshop);
  },
  FACILITATOR_WORKSHOPS_SET: (state, payload) => {
    state.list = Object.assign({}, state.list, payload.workshops);
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
