<template>
  <div class="rounded-lg mb-8 items-center bg-blue-panel ">
    <consent-show
      v-if="showConsent == true"
      v-model="showConsent"
    ></consent-show>

    <!-- col 1 -->
    <div class="md:flex">
      <div
        class="md:flex-initial md:w-3/6
      flex flex-col w-full p-5 "
      >
        <div class="p-5">
          <div class="flex lg:w-full justify-between">
            <button class="text-left" @click.stop.prevent="getWorkshop">
              <router-link
                class="text-blue-primary text-21 leading-large font-bold mb-5 block"
                :to="{
                  name: this.workshopRouteName,
                  params: { id: this.workshop.id }
                }"
                >{{ workshop.title }}</router-link
              >
            </button>
            <div
              v-if="!workshop.active"
              class="text-white bg-grey-darker flex justify-center items-center rounded text-center w-32 h-5 text-h5 leading-loose"
            >
              <span> Closed </span>
            </div>
          </div>
          <div class="font-bold text-blue-primary mb-2 text-h5 leading-loose">
            {{ formatDate(workshop.start_time) }} -
            {{ formatDate(workshop.end_time) }} {{ workshop.timezone }}
          </div>
          <div class="flex justify-start mb-5">
            <div
              class="text-blue-primary font-bold text-sm bg-grey-light flex justify-center items-center rounded w-32 h-5 leading-loose"
            >
              <span>
                {{ workshop.virtual ? "Virtual" : "In-person" }} meeting
              </span>
            </div>
            <div
              class="ml-2 validated-workshop font-bold text-sm bg-grey-light flex justify-center items-center rounded h-5 leading-loose"
              :class="{
                rejected: activeWorkshopOpt == 'rejected',
                approved: activeWorkshopOpt == 'approved'
              }"
            >
              <span style="text-transform: capitalize;">
                {{ workshop.validated }} Workshop
              </span>
            </div>
          </div>
          <p class="text-blue-primary leading-loose text-h5">
            {{ workshop.presenter_first_name }}
            {{ workshop.presenter_last_name }}
          </p>
          <p class="text-blue-primary mb-3 text-h4 leading-loose">
            {{ workshop.virtual ? "Virtual" : workshop.city }}
            {{ workshop.state }}
          </p>
          <p class="text-blue-primary text-14 leading-loosish">
            Workshop Sponsored by: {{ workshop.event_sponsor_host }}
          </p>
        </div>
      </div>
      <!-- col 2 -->
      <div
        v-if="isFacilitator"
        class="md:flex-initial md:w-2/5 md:border-r md:border-l border-white md:border-b-0 md:border-t-0
      flex flex-col w-full border-b border-t p-5"
      >
        <h3 class="text-blue-primary">Evaluation</h3>
        <div class="flex w-full items-evenly justify-between mt-5"></div>
        <div class="relative pt-1">
          <!-- <div class="overflow-hidden h-3 mb-4 text-xs flex rounded bg-grey	">
          <div
            class="w-1/2 shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-primary"
          ></div>
        </div> -->
        </div>

        <button class="text-left " @click.stop.prevent="getWorkshop">
          <router-link
            v-if="isFacilitator"
            class="btn btn-blue"
            :to="{
              name: 'facilitator.evaluations.show',
              params: { workshop: this.workshop.id }
            }"
            >View Post-Survey</router-link
          >
          <router-link
            v-if="!isFacilitator"
            class="btn btn-blue"
            :to="{
              name: this.workshopRouteName,
              params: { id: this.workshop.id }
            }"
            >Go to Post-Survey</router-link
          >
        </button>
      </div>
      <div
        v-else
        class="md:border-r md:border-l border-white md:border-b-0 md:border-t-0 border-b border-t"
      ></div>
      <!-- col 3 -->
      <div
        class="md:flex-initial md:w-2/5
      flex flex-col w-full p-5"
      >
        <div class="flex-col">
          <!-- go to workshop btn -->
          <div v-if="!isFacilitator" class="p-5"></div>
          <button class="mb-1 w-full">
            <router-link
              class="btn bg-blue-light text-white w-full"
              :to="{
                name: this.workshopRouteName,
                params: { id: this.workshop.id }
              }"
              >Go to Workshop</router-link
            >
          </button>
          <!-- Edit workshop btn -->
          <button
            v-show="isFacilitator"
            class="mb-1 w-full"
            @click.stop.prevent="getWorkshop"
          >
            <router-link
              class="btn btn-blue w-full"
              :to="{
                name: 'workshop-edit',
                params: { id: this.workshop.id }
              }"
              >Edit Workshop</router-link
            >
          </button>

          <button v-show="!isFacilitator" class="w-full mt-2">
            <a
              href="#"
              @click="showConsentModal"
              class="btn btn-blue w-full mb-1"
              >Data Permissions</a
            >
          </button>
          <!-- if fac show invite leas -->
          <button
            v-show="isFacilitator"
            @click="showLEAInviteModal"
            class="btn btn-blue w-full mb-1"
          >
            Invite LEA Admins
          </button>
          <lea-invite-modal
            v-if="inviteLEAModalOpen == true"
            v-model="inviteLEAModalOpen"
            v-bind:workshop="this.workshop"
            @reload="reloadWorkshops"
          >
          </lea-invite-modal>
          <!-- if fac show  workshop participants -->
          <button
            v-show="isFacilitator"
            @click="showWorkshopParticipantsModal"
            class="btn btn-blue w-full btn-font-sm mb-1"
          >
            View Participants
          </button>
          <view-participants-modal
            v-if="workshopParticipantsModalOpen == true"
            v-model="workshopParticipantsModalOpen"
            v-bind:workshop="this.workshop"
            @reload="reloadWorkshops"
          >
          </view-participants-modal>
          <!-- TODO: remove workshops -->
          <!-- <button
            v-show="isFacilitator"
            @click="removeWorkshop(workshop.id)"
            class="btn btn-blue w-full btn-font-sm"
          >
            Remove Workshop
          </button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.btn-font-sm {
  font-size: 0.95rem;
}
.validated-workshop {
  text-transform: capitalize;
  color: #3061ac;
  padding: 0 10px;
}
.validated-workshop.rejected {
  background-color: #ef5753;
  color: #fff;
}
.validated-workshop.approved {
  background-color: #33bf8e;
  color: #fff;
}
</style>

<script>
import { ROLES } from "../../const";
import LEAInviteModal from "../modals/LEAInviteModal.vue";
import ViewParticipantsModal from "../modals/ViewParticipantsModal.vue";
import ConsentShow from "../consent/ConsentShow.vue";
import { formatWorkshopDate } from "../../util/formatDate";

export default {
  name: "WorkshopItem",
  props: {
    workshop: Object,
    user: Object
  },
  data: () => ({
    inviteLEAModalOpen: false,
    workshopParticipantsModalOpen: false,
    showConsent: false
  }),
  components: {
    "lea-invite-modal": LEAInviteModal,
    "view-participants-modal": ViewParticipantsModal,
    "consent-show": ConsentShow
  },
  computed: {
    postSurveyRouteName() {
      if (this.user.is_facilitator) {
        console.log("");
        return "facilitator.evaluations.show";
      }
      return "workshop-show";
    },
    workshopRouteName() {
      if (this.user.is_facilitator) {
        return "facilitator-workshop-show";
      }
      return "workshop-show";
    },
    isFacilitator() {
      return this.user && this.user.is_facilitator;
    },
    isLEAAdmin() {
      return this.user && this.user.roles.includes(ROLES.LEA_ADMIN);
    },
    activeWorkshopOpt() {
      return this.workshop.validated;
    }
  },
  methods: {
    showConsentModal() {
      this.showConsent = !this.showConsent;
    },
    showLEAInviteModal() {
      this.inviteLEAModalOpen = !this.inviteLEAModalOpen;
    },
    showWorkshopParticipantsModal() {
      this.workshopParticipantsModalOpen = !this.workshopParticipantsModalOpen;
    },
    getWorkshop() {
      this.setWorkshop();
      this.$router.push({
        name: this.workshopUrl,
        params: { id: this.workshop.id }
      });
    },
    setWorkshop() {
      if (this.user.is_facilitator) {
        this.$store.dispatch("setFacilitatorWorkshop", this.workshop);
      } else {
        this.$store.dispatch("setWorkshop", this.workshop);
      }
    },
    formatDate(date) {
      return formatWorkshopDate(date);
    },
    reloadWorkshops(updated) {
      if (updated) {
        this.$emit("reload");
      }
    }
    // TODO: remove workshops
    // removeWorkshop(workshopId) {
    //   this.$emit("sendRemovedWorkshopId", workshopId);
    // }
  }
};
</script>
