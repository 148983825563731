export const toastedGlobalOptions = {
  position: "top-center",
  duration: 5000,
  singleton: true
};

export const toastedSuccessOptions = {
  action: {
    text: "Close",
    onClick: (e, toastObject) => {
      toastObject.goAway(0);
    }
  },
  className: ["bg-green", "font-bold", "text-white"],
  theme: "none",
  duration: Infinity
};

export const toastedMessageHandler = message => {
  if (message && typeof message === "string") {
    return message;
  } else {
    return "Success!"; // default message
  }
};
