<template>
  <!-- EVALUATION -->
  <div v-if="page === 'evaluation-left-radio'">
    <div class="pb-4 text-left w-full">
      <h3 class="text-h3 text-blue-primary font-bold mb-4 mt-2">
        {{ input.attributes.title }}
      </h3>
      <div class="radio-toolbar">
        <template v-for="(option, index) in input.options">
          <label class="pb-4 font-bold" :key="index">
            <input
              type="radio"
              :id="option.id"
              :name="input.attributes.name"
              :value="option.value"
              :checked="choice == option.value"
              :disabled="disabled"
              @click="handleClick(option.value)"
            />
            <span class="font-normal cursor-pointer text-h4 font-medium mx-4">{{
              option.label
            }}</span>
          </label>
        </template>
      </div>
      <br />
    </div>
  </div>

  <div v-else-if="page === 'evaluation-center-radio'">
    <div class="pb-4 text-center w-full">
      <h3 class="text-h3 text-black font-bold mb-4 mt-2">
        {{ input.attributes.title }}
      </h3>
      <div class="radio-toolbar flex container items-center">
        <template v-for="(option, index) in input.options">
          <label class="pb-4 font-bold" :key="index">
            <input
              type="radio"
              :id="option.id"
              :name="input.attributes.name"
              :value="option.value"
              :checked="choice == option.value"
              :disabled="disabled"
              @click="handleClick(option.value)"
            />
            <span class="font-normal cursor-pointer text-h4 font-medium mx-4">{{
              option.label
            }}</span>
          </label>
        </template>
      </div>
      <br />
    </div>
  </div>

  <!-- RUBRIC -->
  <div v-else-if="page === 'rubric'">
    <div class="text-left w-full">
      <h3 class="text-h3 text-blue-primary font-bold mb-4 mt-8 text-center">
        {{ input.attributes.title }}
      </h3>
      <div class="pt-12 border">
        <template v-for="(option, index) in input.options">
          <label
            class="flex radio-fill-rubric radio-label-width mb-6"
            :key="index"
          >
            <input
              type="radio"
              :id="option.id"
              :name="input.attributes.name"
              :value="option.value"
              :checked="choice == option.value"
              :disabled="disabled"
              @click="handleClick(option.value)"
            />
            <span class="font-normal cursor-pointer text-h4">{{
              option.label
            }}</span>
          </label>
        </template>
      </div>
    </div>
  </div>

  <!-- all others -->
  <div v-else>
    <div class="pb-4 w-full">
      <h3 class="text-h3 text-black font-bold mb-4 mt-2">
        {{ input.attributes.title }}
      </h3>
      <div class="radio-toolbar">
        <template v-for="(option, index) in input.options">
          <label class="pb-4 font-bold" :key="index">
            <input
              type="radio"
              :id="option.id"
              :name="input.attributes.name"
              :value="option.value"
              :checked="choice == option.value"
              :disabled="disabled"
              @click="handleClick(option.value)"
            />
            <span class="font-normal cursor-pointer text-h4 font-medium mx-4">{{
              option.label
            }}</span>
          </label>
        </template>
      </div>
      <br />
    </div>
  </div>
</template>

<style scoped>
.border {
  border: 1px solid grey;
  border-radius: 8px 8px 0px 0px;
  border-style: solid solid none solid;
  padding-bottom: 2vh;
}

input[type="radio"] + span::before {
  width: 0;
  height: 0px;
  opacity: 0;
  padding-right: 10px;
}

.radio-toolbar input[type="radio"]:checked ~ * {
  background-color: #e6ecf5 !important;
  padding-right: 25px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.radio-toolbar {
  margin: 0 auto;
}
label {
  float: left;
  clear: none;
  display: block;
  padding: 0px 0px 0px 0px;
}

input[type="radio"],
input.radio {
  float: left;
  clear: none;
  margin: 2px 0 0 2px;
}

/* rubric */
.radio-fill-rubric input[type="radio"]:checked ~ * {
  background-color: #e6ecf5 !important;
  width: 100%;
  height: 100%;
  padding: 4px 11px;
  display: inline-block;
}

.radio-label-width {
  width: 100%;
  padding-left: 7vh;
  padding-right: 7vh;
}
</style>

<script>
export default {
  name: "RadioInput",
  $_veeValidate: {
    // must not be arrow functions.
    // the name getter
    name() {
      return this.input.attributes.name;
    },
    // the value getter
    value() {
      return this.choice; // `this` is the component instance, `value` is the prop we added.
    }
  },
  props: {
    answer: String,
    page: String,
    disabled: Boolean,
    input: Object
  },
  watch: {
    answer(answer) {
      this.choice = answer;
      this.$emit("input", answer);
    }
  },
  data() {
    return {
      choice: this.answer ? this.answer : null
    };
  }
};

// export default {
//   name: "RadioInput",
//   $_veeValidate: {
//     // must not be arrow functions.
//     // the name getter
//     name() {
//       return this.input.attributes.name;
//     },
//     // the value getter
//     value() {
//       return this.choice; // `this` is the component instance, `value` is the prop we added.
//     }
//   },
//   props: {
//     answer: String,
//     disabled: Boolean,
//     input: Object
//   },
//   watch: {
//     answer(answer) {
//       this.choice = answer;
//       this.$emit("input", answer);
//     }
//   },
//   data() {
//     return {
//       choice: this.answer ? this.answer : null
//     };
//   },
//   computed: {
//     required() {
//       return (
//         this.input.validation && this.input.validation.includes("required")
//       );
//     }
//   },
//   methods: {
//     handleClick(value) {
//       if (this.choice === value) {
//         this.choice = null;
//       } else {
//         this.choice = value;
//       }

//       this.$emit("input", this.choice);
//     }
//   }
// };
</script>
