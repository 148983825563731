<template>
  <div class="relative">
    <div class="container m-auto pb-24">
      <!-- LEA Team Modal -->
      <lea-team-modal v-if="modalOpen == true" v-model="modalOpen">
      </lea-team-modal>

      <survey-modal
        v-if="modalOpenSurvey == true"
        :registration="registration"
        v-model="modalOpenSurvey"
      >
      </survey-modal>

      <div v-if="!registration" class="text-center">
        <h2>
          You don't have access because you aren't registered to this workshop.
          Please register through your invitation link or contact your
          facilitator for one.
        </h2>
      </div>
      <div v-else>
        <div class="flex flex-wrap border-b border-gray mb-10 pb-10">
          <div class="w-full md:w-3/4 md:pr-20">
            <div>
              <breadcrumbs :breadcrumbs="crumbs"></breadcrumbs>
              <consent-show
                v-if="needConsent"
                :registration="registration"
              ></consent-show>
            </div>
            <div class="mb-6">
              <div class="mb-5" v-if="!workshop.active">
                <div
                  class="text-white bg-red-bright rounded text-center w-32 text-h4 leading-loose"
                >
                  Closed
                </div>
                <p>
                  This workshop is not active. You can view forms but you cannot
                  edit them
                </p>
              </div>
              <h1 class="mb-3">{{ workshop.title }}</h1>
              <div class="text-blue-primary text-h3 mb-10 font-bold">
                {{ formatDate(workshop.start_time) }} -
                {{ formatDate(workshop.end_time) }} {{ workshop.timezone }}
              </div>
              <div class="mb-3" v-html="workshop.description"></div>
            </div>
          </div>
          <div class="w-full md:w-1/4">
            <img
              class="hidden md:block mb-10"
              src="@/assets/img/scriptlogo.png"
              alt="script"
            />
            <p class="text-blue-primary font-bold mb-3">
              {{ workshop.presenter_first_name }}
              {{ workshop.presenter_last_name }}
            </p>
            <p class="text-blue-primary mb-3">
              {{ workshop.city }} {{ workshop.state }}
            </p>
            <p class="text-blue-primary text-sm">
              Workshop Sponsored by:<br />{{ workshop.event_sponsor_host }}
            </p>
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full md:w-3/4 md:pr-20">
            <div class="flex flex-wrap -mx-5">
              <div
                v-for="rubric in workshop.rubrics"
                :key="rubric.id"
                class="text-blue-primary rounded-lg mb-5 w-full md:w-1/2 px-5"
              >
                <div
                  class="flex flex-col rounded-lg h-full workshop-card border-2 border-blue-panel"
                  :class="answerStatus(rubric)"
                >
                  <div
                    class="border-b-2 border-blue-panel p-5 content-separator"
                  >
                    <span
                      class="flex items-center font-bold"
                      v-if="answerStatus(rubric) == 'Started'"
                      ><img
                        src="@/assets/img/status-started.svg"
                        class="mr-2"
                        alt="started"
                      /><span>{{ answerStatus(rubric) }}</span></span
                    >
                    <span
                      class="flex items-center font-bold"
                      v-else-if="answerStatus(rubric) == 'Completed'"
                      ><img
                        class="mr-2"
                        src="@/assets/img/status-complete.svg"
                        alt="completed"
                      /><span>{{ answerStatus(rubric) }}</span></span
                    >
                    <span class="flex items-center font-bold" v-else
                      ><img
                        class="mr-2"
                        src="@/assets/img/status-not-started.svg"
                        alt="not started"
                      /><span>{{ answerStatus(rubric) }}</span></span
                    >
                  </div>
                  <div class="p-5 flex-1 flex flex-col">
                    <h3 class="mb-3">{{ rubric.title }}</h3>
                    <div
                      v-if="
                        isLEAAdmin &&
                          answerStatus(rubric) == 'Not Started' &&
                          workshop.active
                      "
                      class="flex-1 flex items-end"
                    >
                      <router-link
                        class="btn btn-md btn-blue text-center w-full mt-10"
                        :to="{
                          name: 'workshop-form-create',
                          params: { workshop: id, form: rubric.id }
                        }"
                        >Open Form</router-link
                      >
                    </div>
                    <div
                      v-else-if="isLEAAdmin && workshop.active"
                      class="flex-1 flex items-end"
                    >
                      <div class="flex-col">
                        <router-link
                          class="btn btn-md btn-blue-light text-center w-full mt-10"
                          :to="{
                            name: 'workshop-form-create',
                            params: {
                              workshop: id,
                              form: rubric.id,
                              mode: editMode
                            }
                          }"
                          >Edit Responses</router-link
                        >
                        <router-link
                          class="btn btn-md btn-blue text-center w-full mt-3"
                          :to="{
                            name: 'workshop-form-create',
                            params: {
                              workshop: id,
                              form: rubric.id,
                              mode: viewMode
                            }
                          }"
                          >View Responses</router-link
                        >
                      </div>
                    </div>
                    <div
                      v-else-if="isLEAAdmin && !workshop.active"
                      class="flex-1 flex items-end"
                    >
                      <router-link
                        v-if="answerStatus(rubric) == 'Not Started'"
                        class="btn btn-md btn-blue text-center w-full mt-10"
                        :to="{
                          name: 'workshop-form-create',
                          params: { workshop: id, form: rubric.id }
                        }"
                        >View Form</router-link
                      >
                      <router-link
                        v-else
                        class="btn btn-md btn-blue text-center w-full mt-10"
                        :to="{
                          name: 'workshop-form-create',
                          params: { workshop: id, form: rubric.id }
                        }"
                        >View Responses</router-link
                      >
                    </div>

                    <div v-else class="flex-1 flex items-end">
                      <!-- is an LEA participant -->
                      <router-link
                        v-if="answerStatus(rubric) != 'Not Started'"
                        class="btn btn-md btn-blue text-center w-full mt-10"
                        :to="{
                          name: 'workshop-form-create',
                          params: { workshop: id, form: rubric.id }
                        }"
                        >View Responses</router-link
                      >
                      <router-link
                        v-else
                        class="btn btn-md btn-blue text-center w-full mt-10"
                        :to="{
                          name: 'workshop-form-create',
                          params: { workshop: id, form: rubric.id }
                        }"
                        >View Form</router-link
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-col w-full md:w-1/4">
            <div class="w-full mb-10">
              <button class="w-full" v-if="isLEAAdmin">
                <a
                  href="#"
                  v-show="isLEAAdmin"
                  @click="showModal"
                  class="btn btn-green w-full"
                  >Team Management</a
                >
              </button>
              <button class="w-full" v-if="isLEAParticipant">
                <a
                  href="#"
                  v-show="isLEAParticipant"
                  @click="showModal"
                  class="btn btn-green w-full"
                  >View Team</a
                >
              </button>
            </div>
            <div class="bg-red-bright text-white mb-5 rounded-lg p-5">
              <h3 class="mb-2">Evaluation</h3>
              <div class="bg-white rounded-lg p-3">
                <h3 class="text-blue-primary mb-5">Workshop Post-Survey</h3>
                <button
                  class="btn btn-sm btn-blue text-center w-full"
                  @click="showModalSurvey"
                >
                  Open Survey
                </button>
              </div>
            </div>
            <div class="bg-red-bright text-white rounded-lg p-5 mb-5">
              <h3 class="mb-2">Report</h3>
              <p class="mb-3 text-md leading-tight">
                Click here to get a link to your completed rubrics
              </p>
              <button
                class="btn btn-md btn-white w-full"
                @click.prevent="copyToClipboard(completedRubricsLink)"
              >
                Share
              </button>
            </div>
            <div
              class="bg-red-bright text-white mb-5 rounded-lg pr-5 pl-5 pt-5"
            >
              <h3 class="mb-2">Workshop Materials</h3>
              <div v-if="materialsCount === 0">
                <div class=" mb-5">
                  <p class="mb-3 text-md leading-tight">
                    Any workshop Materials will appear here
                  </p>
                </div>
              </div>
              <div v-else>
                <div
                  class=" mb-5"
                  v-for="material in workshop.materials"
                  :key="material.id"
                >
                  <div class="bg-white rounded-lg p-3">
                    <a
                      class="text-blue-primary mb-5 break-words"
                      :href="material.form_url"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <h3>
                        {{ material.form_name }}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          x="0px"
                          y="0px"
                          width="16px"
                          height="16px"
                          viewBox="0 0 16 16"
                        >
                          <g transform="translate(0, 0)">
                            <polygon
                              data-color="color-2"
                              fill="#3061ac"
                              points="16,0 10,0 12.293,2.293 6.022,8.564 7.436,9.978 13.707,3.707 16,6 "
                            ></polygon>
                            <path
                              fill="#3061ac"
                              d="M13,16H1c-0.552,0-1-0.448-1-1V3c0-0.552,0.448-1,1-1h6v2H2v10h10V9h2v6C14,15.552,13.552,16,13,16z"
                            ></path>
                          </g>
                        </svg>
                      </h3>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="bg-red-bright text-white mb-5 rounded-lg pr-5 pl-5 pt-5"
            >
              <h3 class="mb-2">Team Materials</h3>
              <div v-if="teamMaterialsCount === 0">
                <div class=" mb-5">
                  <p class="mb-3 text-md leading-tight">
                    Any Team Materials will appear here
                  </p>
                </div>
              </div>
              <div v-else>
                <div
                  class=" mb-5"
                  v-for="material in teamMaterials"
                  :key="material.id"
                >
                  <div class="bg-white rounded-lg p-3">
                    <a
                      class="text-blue-primary mb-5 break-words"
                      :href="material.form_url"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <h3>
                        {{ material.form_name }}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          x="0px"
                          y="0px"
                          width="16px"
                          height="16px"
                          viewBox="0 0 16 16"
                        >
                          <g transform="translate(0, 0)">
                            <polygon
                              data-color="color-2"
                              fill="#3061ac"
                              points="16,0 10,0 12.293,2.293 6.022,8.564 7.436,9.978 13.707,3.707 16,6 "
                            ></polygon>
                            <path
                              fill="#3061ac"
                              d="M13,16H1c-0.552,0-1-0.448-1-1V3c0-0.552,0.448-1,1-1h6v2H2v10h10V9h2v6C14,15.552,13.552,16,13,16z"
                            ></path>
                          </g>
                        </svg>
                      </h3>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="blue-bar"></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";
import ConsentShow from "../consent/ConsentConfirm.vue";
import Breadcrumbs from "../Breadcrumbs.vue";
import { formatWorkshopDate } from "../../util/formatDate";
import { ROLES, FORMMODE } from "../../const";
import LEATeamModal from "../modals/LEATeamModal.vue";
import SurveyModal from "../modals/SurveyModal.vue";

export default {
  name: "WorkshopShow",
  components: {
    Breadcrumbs,
    "consent-show": ConsentShow,
    "lea-team-modal": LEATeamModal,
    "survey-modal": SurveyModal
  },
  data: () => ({
    registration: null,
    workshopId: null,
    editMode: FORMMODE.EDIT,
    viewMode: FORMMODE.VIEW,
    modalOpen: false,
    modalOpenSurvey: false
  }),
  computed: {
    ...mapGetters({
      user: "user",
      workshop: "workshop"
    }),
    isLEAAdmin: function() {
      return this.user.roles.includes(ROLES.LEA_ADMIN);
    },
    isLEAParticipant: function() {
      return this.user.roles.includes(ROLES.LEA_PARTICIPANT);
    },
    answers() {
      return this.workshop.answers;
    },
    crumbs: function() {
      return [
        {
          link: "/workshops",
          label: "Workshops"
        }
      ];
    },
    materialsCount() {
      return this.workshop.materials ? this.workshop.materials.length : 0;
    },
    teamMaterials() {
      return this.workshop.team_materials
        ? this.workshop.team_materials.filter(
            mat => mat.member_id == this.user.member.id
          )
        : null;
    },
    teamMaterialsCount() {
      return this.teamMaterials ? this.teamMaterials.length : 0;
    },
    evaluationCount() {
      return this.registration ? this.registration.evaluations_count : 0;
    },

    evaluationId() {
      return this.workshop.evaluation ? this.workshop.evaluation.id : null;
    },
    evaluationLink() {
      if (this.registration) {
        let props = this.$router.resolve({
          name: "public.evaluation",
          params: { id: this.registration.uuid }
        });
        return window.location.origin + props.href;
      }

      return "#";
    },
    completedRubricsLink() {
      if (this.registration) {
        let props = this.$router.resolve({
          name: "public.workshops-show",
          params: { id: this.registration.uuid }
        });

        return window.location.origin + props.href;
      }

      return "#";
    },
    id() {
      return this.$route.params.id;
    },
    needConsent() {
      return !this.registration.consent;
    }
  },
  watch: {
    user: function() {
      this.load();
    }
  },
  methods: {
    showModal() {
      this.modalOpen = !this.modalOpen;
    },
    showModalSurvey() {
      this.modalOpenSurvey = !this.modalOpenSurvey;
    },
    answerStatus(form) {
      let answer = _.find(this.answers, { form_id: form.id });
      return answer && answer.status
        ? _.startCase(answer.status)
        : "Not Started";
    },
    copyToClipboard(text) {
      function fallbackCopyTextToClipboard(text) {
        var textArea = document.createElement("textarea");
        textArea.value = text;

        // Avoid scrolling to bottom
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.position = "fixed";

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        document.execCommand("copy");

        document.body.removeChild(textArea);
      }

      if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text);
        this.$toasted.show("URL Copied");
        return;
      }
      navigator.clipboard.writeText(text);
      this.$toasted.show("URL Copied");
    },
    formatDate(date) {
      return formatWorkshopDate(date);
    },
    load() {
      if (this.user && this.user.member && this.user.member.registrations) {
        this.registration = _.find(
          this.user.member.registrations.data,
          registration => registration.workshop_id == this.workshopId
        );
        // getWorkshop if member is registered and the loaded workshop is not the one on the route
        if (this.registration && this.workshopId != this.workshop.id) {
          this.$store.dispatch("getWorkshop", this.workshopId);
        }
      }
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.workshopId = this.$route.params.id;
    } else {
      console.log("workshop not found");
    }
    // need to do this here to handle it if you "click" your way here instead of coming directly
    // for direct visits, can't do this here, so also use a `watch` on `user`.
    this.load();
  }
};
</script>
