<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div
          class="modal-container rounded shadow m-auto w-5/6 md:w-3/4 lg:w-1/2 pt-6 pb-10 lg:pb-24 shadow-lg bg-white"
        >
          <div class="modal-header border-b border-white pb-6 px-10 mt-10">
            <slot name="header">
              <h1 class="text-h1 text-blue-primary">Data Permissions Notice</h1>
            </slot>
          </div>

          <div class="modal-body px-10 py-6 text-blue-primary">
            <consent-content></consent-content>
          </div>

          <div class="modal-footer items-center mb-16 px-10">
            <slot name="footer">
              <div class="text-md text-black mb-3 w-full ">
                By clicking 'Acknowledge' I acknowledge that I understand the
                data sharing consent statements above.
                <a
                  href="https://www.csforall.org/privacy/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Learn More
                </a>
              </div>

              <div class="w-full text-right">
                <button
                  class="modal-default-button btn btn-blue items-right"
                  @click.prevent="save()"
                >
                  Acknowledge
                </button>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<!-- Using the `scoped` attribute -->
<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.85);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  transition: all 0.3s ease;
  max-height: 80vh;
  overflow: auto;
}

label {
  padding-left: 22px;
  text-indent: -22px;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>

<script>
import ConsentContent from "./ConsentContent.vue";
export default {
  props: ["registration"],
  components: {
    "consent-content": ConsentContent
  },
  data: function() {
    return {
      consent: null
    };
  },
  methods: {
    save() {
      window.axios
        .patch(`/api/me/${this.registration.id}`, {
          consent: "publish"
        })
        .then(() => {
          this.$store.dispatch("getUser");
        })
        .catch(error => {
          console.log(error);
          alert("error please try again");
        });
    }
  }
};
</script>
