export const STUDENTS_SET = "STUDENTS_SET";

const state = {
  students: {}
};

const getters = {
  students: state => state.students
};

const actions = {
  setStudents: ({ commit }, students) => {
    commit(STUDENTS_SET, {
      students: students
    });
  }
};

const mutations = {
  STUDENTS_SET: (state, payload) => {
    state.students = Object.assign({}, state.students, payload.students);
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
