import Vue from "vue";
import Vuex from "vuex";

import user from "./modules/user";
import app from "./modules/app";
import audience from "./modules/audience";
import level from "./modules/level";
import other from "./modules/other";
import region from "./modules/region";
import researchTopic from "./modules/researchTopic";
import researchType from "./modules/researchType";
import setting from "./modules/setting";
import student from "./modules/student";
import teacher from "./modules/teacher";
import type from "./modules/type";
import alignment from "./modules/alignment";
import facilitatorWorkshops from "./modules/facilitatorWorkshops";
import workshops from "./modules/workshops";
import registration from "./modules/registration";
import masquerading from "./modules/masquerading";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    user,
    app,
    audience,
    level,
    other,
    region,
    researchTopic,
    researchType,
    setting,
    student,
    teacher,
    type,
    alignment,
    facilitatorWorkshops,
    workshops,
    registration,
    masquerading
  },
  strict: false
});
