var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative"},[_c('div',{staticClass:"container mx-auto w-full lg:w-2/5 pb-24 pt-24"},[_c('h1',{staticClass:"text-center"},[_vm._v("Password Reset")]),(_vm.passwordReset)?_c('p',{staticClass:"mt-3 mb-10 text-center"},[_vm._v(" Your account requires a password reset. Please reset your password to continue. ")]):_c('p',{staticClass:"mt-3 mb-10 text-center"},[_vm._v(" Enter your new password below. ")]),_c('form',{attrs:{"method":"POST","aria-label":"Forgot Password"},on:{"submit":function($event){$event.preventDefault();return _vm.validateBeforeSubmit.apply(null, arguments)}}},[_c('div',{class:{
          'mb-10': true,
          'has-error': _vm.errors.has('oldpassword')
        }},[_c('label',{attrs:{"for":"oldpassword"}},[_vm._v("Old Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.oldpassword),expression:"oldpassword"},{name:"validate",rawName:"v-validate",value:(`min:${_vm.VALIDATION.MIN_PW_LEN}`),expression:"`min:${VALIDATION.MIN_PW_LEN}`"}],ref:"oldpassword",staticClass:"form-control",attrs:{"id":"oldpassword","type":"password","name":"oldpassword"},domProps:{"value":(_vm.oldpassword)},on:{"input":function($event){if($event.target.composing)return;_vm.oldpassword=$event.target.value}}}),_c('div',{staticClass:"mt-4"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('oldpassword')),expression:"errors.has('oldpassword')"}],staticClass:"text-red text-xs italic"},[_vm._v(_vm._s(_vm.errors.first("oldpassword")))])])]),_c('div',{class:{
          'mb-10': true,
          'has-error': _vm.errors.has('password')
        }},[_c('label',{attrs:{"for":"password"}},[_vm._v("Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"},{name:"validate",rawName:"v-validate",value:(`min:${_vm.VALIDATION.MIN_PW_LEN}`),expression:"`min:${VALIDATION.MIN_PW_LEN}`"}],ref:"password",staticClass:"form-control",attrs:{"id":"password","type":"password","name":"password"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing)return;_vm.password=$event.target.value}}}),_c('div',{staticClass:"mt-4"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('password')),expression:"errors.has('password')"}],staticClass:"text-red text-xs italic"},[_vm._v(_vm._s(_vm.errors.first("password")))])])]),_c('div',{class:{
          'mb-10': true,
          'has-error': _vm.errors.has('password_confirmation')
        }},[_c('label',{attrs:{"for":"password-confirm"}},[_vm._v("Confirm Password")]),_c('input',{directives:[{name:"validate",rawName:"v-validate",value:(`confirmed:password|min:${_vm.VALIDATION.MIN_PW_LEN}`),expression:"`confirmed:password|min:${VALIDATION.MIN_PW_LEN}`"}],staticClass:"form-control",attrs:{"id":"password-confirm","type":"password","name":"password_confirmation"}}),_c('div',{staticClass:"mt-4"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('password_confirmation')),expression:"errors.has('password_confirmation')"}],staticClass:"text-red text-xs italic"},[_vm._v(_vm._s(_vm.errors.first("password_confirmation")))])])]),_vm._m(0)])]),_c('div',{staticClass:"blue-bar"})])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-left"},[_c('button',{staticClass:"btn btn-blue",attrs:{"type":"submit"}},[_vm._v(" Reset my password ")])])
}]

export { render, staticRenderFns }