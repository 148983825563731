export const ROLES = {
  // make sure these match with db roles table. I beleive they are compared to impelement role-specific logic.
  SUPER_ADMIN: "superAdmin",
  PENDING_REVIEWER: "pendingReviewer",
  REVIEWER: "reviewer",
  GENERAL: "general",
  FACILITATOR: "facilitator",
  PENDING_FACILITATOR: "pendingFacilitator",
  LEAD_FACILITATOR: "leadFacilitator",
  LEA_ADMIN: "LEAAdmin",
  LEA_PARTICIPANT: "LEAParticipant",
  GUEST: "guest"
};

export const ORG_TYPES = {
  CONTENT_PROVIDER: "Content Provider",
  OUT_OF_SCHOOL_PROVIDER: "Out of School Provider",
  SERVICE_PROVIDER: "Service Provider",
  TEACHER_PREPARATION_PROGRAMS: "Teacher Preparation Programs",
  FUNDER: "Funder",
  EDUCATION_ASSOCIATION: "Education Association",
  NATIONAL_NETWORK_AND_ALLIANCES: "National Networks and Alliances"
};

// LEA type strings must match server-side values.
// See: RegisterController.php
export const LEA_TYPES = {
  INDIVIDUAL_SCHOOL: "Individual School",
  SCHOOL_DISTRICT: "School District",
  REGIONAL_EDUCATION_AGENCY: "Regional Education Agency",
  OTHER_SCHOOL_NETWORKS: "Other School Networks"
};

export const MEMBER_TYPES = {
  MEMBER: "member",
  RESEARCHER: "researcher",
  NON_PUBLIC: "nonPublic"
};

export const MEMBER_STATUS = {
  DRAFT: "draft",
  PUBLISHED: "published",
  HIDDEN: "hidden"
};

export const PARTICIPANT_ROLES = {
  COUNSELOR: "Counselor",
  DEPARTMENT_HEAD: "Department Head",
  DISTRICT_ADMINISTRATOR: "District Administrator",
  ENGLISH_LANGUAGE_LEARNER_TEACHER: "English Language Learner Teacher",
  INSTRUCTIONAL_COACH: "Instructional Coach",
  SCHOOL_ADMINISTRATOR: "School Administrator",
  SCHOOL_PRINCIPAL: "School Principal",
  SPECIAL_EDUCATION_TEACHER: "Special Education Teacher",
  SUPERINTENDENT: "Superintendent",
  TEACHER: "Teacher",
  OTHER: "Other"
};

export const COGNITO_ERRORS = {
  USER_NOT_CONFIRMED_EXCEPTION: "UserNotConfirmedException",
  PASSWORD_RESET_REQUIRED_EXCEPTION: "PasswordResetRequiredException"
};

export const VALIDATION = {
  MIN_PW_LEN: 8,
  MIN_DESC_LEN: 250
};

export const FORMMODE = {
  EDIT: "edit",
  VIEW: "view"
};

export const RUBRIC_SECTIONS = {
  VISIONS: "Visions for CS Education Statement",
  MATERIALS: "Materials and Curriculum Selection & Content Refinement",
  LEADERSHIP: "Leadership",
  CAPACITY: "Teacher Capacity and Development",
  PARTNERS: "Partners",
  COMMUNITY: "Community",

  BTN_MATERIALS: "Materials and Curriculum",
  BTN_LEADERSHIP: "Leadership",
  BTN_CAPACITY: "Teacher Capacity",
  BTN_PARTNERS: "Partners",
  BTN_COMMUNITY: "Community"
};

export const BAD_INVITES = {
  WORKSHOP_STALE: "workshop, stale",
  WORKSHOP_NOT_FOUND: "workshop, not found",
  TEAM_STALE: "team, stale",
  TEAM_NOT_FOUND: "team, not found"
};

export const GENDER = {
  FEMALE: "Female",
  MALE: "Male",
  NON_BINARY: "Non-binary",
  TRANSGENDER: "Transgender",
  OTHER: "Other",
  PREFER_NOT_TO_SAY: "Prefer not to say"
};

export const ETHNICITY_CULTURAL_BACKGROUNDS = {
  WHITE_CAUCASIAN: "White/Caucasian",
  BLACK_AFRICANAMERICAN: "Black/African American",
  ASIAN: "Asian",
  HISPANIC_LATINX: "Hispanic/Latinx",
  NATIVE_AMERICAN_INDIGENOUS: "Native American/Indigenous",
  MIDDLE_EASTERN: "Middle Eastern",
  PACIFIC_ISLADER: "Pacific Islander",
  MULTIRACIAL: "Mixed/Multiracial",
  OTHER: "Other",
  PREFER_NOT_TO_SAY: "Prefer not to say"
};

export const LINKEDIN_LABEL = "linkedIn";
