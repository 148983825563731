<template>
  <div class="mb-10 w-full">
    <div class="w-full flex">
      <div class="w-1/3">
        <label class="text-h3">{{ label }}</label>
      </div>
      <div class="w-2/3 pl-5">
        <div class="text-blue-primary font-bold mb-2">
          Additional Profile Opportunities
        </div>
      </div>
    </div>
    <div
      class="w-full flex select-box border-b pb-4 pt-4"
      v-for="option in options"
      v-bind:key="option.id"
    >
      <div class="w-1/3">
        <label :for="option.option">
          <input
            :id="option.option"
            type="checkbox"
            :checked="selected.indexOf(option.id) !== -1"
            @change="onCheck(option.id, $event)"
          /><span class="check inline-block mr-2"></span>
          {{ option.option }}
        </label>
      </div>
      <div class="w-2/3 pl-5">
        <span v-show="option.description" class="text-blue-primary">
          {{ option.description }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";

export default {
  props: ["label", "options", "values", "option"],
  data: () => ({
    selected: []
  }),
  computed: {
    ...mapGetters({
      user: "user"
    })
  },
  methods: {
    onCheck: function(id, e) {
      if (e.target.checked) {
        this.selected.push(id);
      } else {
        this.selected = _.reject(this.selected, item => {
          return item == id;
        });
      }

      this.$emit("optionUpdate", {
        property: this.option,
        value: this.selected
      });
    },
    onChange: function() {
      this.$emit("optionUpdate", {
        property: this.option,
        value: this.selected
      });
    },
    setSelected: function() {
      if (!this.values || !this.values.data) {
        return;
      }
      _.each(this.values.data, item => {
        this.selected.push(item.id);
      });
    }
  },
  watch: {
    values: function() {
      this.setSelected();
    }
  },
  created() {
    this.setSelected();
  },
  mounted() {
    this.setSelected();
  }
};
</script>
