export const REDIRECT_SET = "REDIRECT_SET";
export const ACTIVE_NAV_SET = "ACTIVE_NAV_SET";
export const ACTIVE_FILTER_SET = "ACTIVE_FILTER_SET";
export const ACTIVE_SORT_SET = "ACTIVE_SORT_SET";
export const ACTIVE_SORT_DIR_SET = "ACTIVE_SORT_DIR_SET";

const state = {
  application: {
    redirect: "",
    active: ""
  },
  activeFilter: "all",
  activeSort: "name",
  activeSortDir: "asc"
};

const getters = {
  redirect: state => state.application.redirect,
  activeNav: state => state.application.active,
  activeFilter: state => state.activeFilter,
  activeSort: state => state.activeSort,
  activeSortDir: state => state.activeSortDir
};

const actions = {
  setRedirect: ({ commit }, redirect) => {
    if (!redirect || redirect == "" || redirect == "false") {
      redirect = false;
    }
    commit(REDIRECT_SET, {
      redirect: redirect
    });
  },
  setActiveNav: ({ commit }, active) => {
    if (!active || active == "" || active == "false") {
      active = false;
    }
    commit(ACTIVE_NAV_SET, {
      active: active
    });
  },
  setActiveFilter: ({ commit }, filter) => {
    commit(ACTIVE_FILTER_SET, {
      filter: filter
    });
  },
  setActiveSort: ({ commit }, sort) => {
    commit(ACTIVE_SORT_SET, {
      sort: sort
    });
  },
  setActiveSortDir: ({ commit }, direction) => {
    commit(ACTIVE_SORT_DIR_SET, {
      direction: direction
    });
  }
};

const mutations = {
  REDIRECT_SET: (state, payload) => {
    state.application = Object.assign({}, state, payload);
  },
  ACTIVE_NAV_SET: (state, payload) => {
    state.application = Object.assign({}, state, payload);
  },
  ACTIVE_FILTER_SET: (state, payload) => {
    state.activeFilter = payload.filter;
  },
  ACTIVE_SORT_SET: (state, payload) => {
    state.activeSort = payload.sort;
  },
  ACTIVE_SORT_DIR_SET: (state, payload) => {
    state.activeSortDir = payload.direction;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
