<template>
  <signup-layout :main-form-width="'w-11/12'">
    <template #pageTitle>
      What's Your Account Type?
    </template>

    <form
      aria-label="Select Your Member Type"
      @submit.prevent="submit"
      class="w-full h-full flex flex-col  justify-center vertical-align"
    >
      <div class="h-full flex flex-col justify-between">
        <div class="mx-auto text-center max-w-lg text-h3 font-bold mt-8">
          Please select an account type based on the nature of your work.
        </div>
        <div class="signup-types mt-10">
          <div v-for="(typeInfo, typeKey) in signupTypes" :key="typeKey">
            <label :for="typeKey">
              <input
                :id="typeKey"
                type="radio"
                name="typeInfo"
                :value="typeInfo"
                v-model.lazy="signupTypeInfo"
              />
              <span class="my-1">
                {{ typeInfo.name }}
              </span>
              <span v-if="typeInfo.isPublic" class="text-red text-h1 font-bold">
                *
              </span>
            </label>
            <p v-html="typeInfo.description" class="mx-6 text-h4"></p>
          </div>
        </div>
        <p
          :class="{
            invisible: !errorNoSignupType
          }"
          class="mb-5 text-red text-h2 text-center"
        >
          Please select at least one option before proceeding.
        </p>
        <p class="text-center">
          <span class="text-h1 font-bold text-red">
            *
          </span>
          <span class="text-blue-primary">
            Your Profile will be reviewed by administrators and published into
            the public
          </span>
          <a
            class="text-blue-light"
            href="https://www.csforall.org/projects_and_programs/member_directory"
            >CSforALL Member Directory</a
          >.
        </p>
        <div class="flex self-center justify-between my-5">
          <button type="submit" class="btn btn-blue">
            Next
          </button>
        </div>
      </div>
    </form>
  </signup-layout>
</template>
<style scoped>
.vertical-align {
  align-self: center;
}

.signup-types {
  width: 100%;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(2, 50%);
}

@media (max-width: 800px) {
  .signup-types {
    grid-template-columns: 1fr;
  }
}
</style>

<script>
import "vue-select/dist/vue-select.css";
import SignupLayout from "@/components/signup/SignupLayout";
import signupMixin from "@/components/signup/mixins/signupMixin";
import { ROLES, MEMBER_TYPES } from "@/const";

export default {
  components: {
    SignupLayout
  },
  mixins: [signupMixin],
  data() {
    return {
      errorNoSignupType: false,
      signupTypeInfo: null,
      signupTypes: {
        organization: {
          name: "Organization",
          nextStep: "OrgSelectOrganization",
          role: {
            type: ROLES.GENERAL,
            displayName: "General"
          },
          memberType: MEMBER_TYPES.MEMBER,
          isPublic: true,
          description:
            "You are an organization that works directly or indirectly in CS education. You are not a school, school district, school network, or regional education agency. E.g., you should select this if you are a content provider, a University, a CS Ed funder, etc. To verify your eligibility to join as a CSforALL member, please visit our <a href='https://www.csforall.org/become_a_member/requirements/'>member requirements page</a>."
        },
        reviewer: {
          name: "AlignCS Reviewer",
          nextStep: "ReviewerRegister",
          role: {
            type: ROLES.PENDING_REVIEWER,
            displayName: "Reviewer"
          },
          memberType: MEMBER_TYPES.NON_PUBLIC,
          description:
            "You are an educator that reviews curricula submitted to CSforALL as part of the AlignCS program. Learn more about <a href='https://www.csforall.org/projects_and_programs/aligncs/'>AlignCS</a>."
        },
        lea: {
          name: "K-12 Education Association",
          nextStep: "LeaSelectOrganization",
          role: {
            type: ROLES.LEA_ADMIN,
            displayName: "LEA Administrator"
          },
          memberType: MEMBER_TYPES.MEMBER,
          isPublic: true,
          description:
            "You are an education association, including an individual school, school district, regional education agency, or a school network. If you haven’t already undergone a SCRIPT workshop, email <a href='mailto:script@csforall.org'>script@csforall.org</a>. Visit our <a href='https://www.csforall.org/projects_and_programs/script/'>SCRIPT page</a>  to learn more about the program."
        },
        facilitator: {
          name: "SCRIPT Facilitator",
          nextStep: "FacilitatorRegister",
          role: {
            type: ROLES.PENDING_FACILITATOR,
            displayName: "Facilitator"
          },
          memberType: MEMBER_TYPES.NON_PUBLIC,
          description:
            "You are a trained CSforALL <a href='https://www.csforall.org/projects_and_programs/script/'>SCRIPT</a> facilitator, or you are planning to attend a SCRIPT facilitator training within six months."
        },
        researcher: {
          name: "Researcher",
          nextStep: "ResearcherRegister",
          role: {
            type: ROLES.GENERAL,
            displayName: "General"
          },
          memberType: MEMBER_TYPES.RESEARCHER,
          isPublic: true,
          description:
            "You are either an independent researcher or affiliated with an organization or educational institution that focuses on K-12 CS education research. You publish research under your individual name and can provide examples of your published work."
        },
        none: {
          name: "None of These",
          nextStep: "LearnMore",
          role: null,
          memberType: null,
          description:
            "At the moment, you do not fall under any of the categories listed above."
        }
      }
    };
  },
  computed: {
    queryParam() {
      return Object.keys(this.$route.query).length !== 0;
    }
  },
  methods: {
    async submit() {
      this.errorNoSignupType = this.signupTypeInfo === null;

      if (this.errorNoSignupType) {
        return;
      }

      this.clearQuestionnaire();

      const { role, nextStep, memberType } = this.signupTypeInfo;

      this.setQuestionnaire({ role, memberType });

      const routeConfig =
        this.signupTypeInfo.nextStep == "LeaSelectOrganization"
          ? { name: nextStep, query: this.$route.query }
          : { name: nextStep };
      this.$router.push(routeConfig);
    }
  }
};
</script>
