var render = function render(){var _vm=this,_c=_vm._self._c;return _c('signup-layout',{scopedSlots:_vm._u([{key:"pageTitle",fn:function(){return [_vm._v(" Register ")]},proxy:true},{key:"pageSubtitle",fn:function(){return [_c('p',[_vm._v(" To register a new account with CSforALL please fill in the information below. ")]),_c('p',[_vm._v("You will be able to create a profile once registered.")])]},proxy:true}])},[_c('verify-email-confirmation-code-modal',{directives:[{name:"show",rawName:"v-show",value:(_vm.showConfirmEmailModal),expression:"showConfirmEmailModal"}],attrs:{"email":_vm.questionnaire.email,"message":`We've sent a confirmation code to ${_vm.questionnaire.email}. Please
            re-enter it here to confirm your account.`},on:{"confirmationComplete":_vm.emailConfirmationComplete}}),_c('form',{staticClass:"lg:w-3/5 sm:w-4/5 mx-auto flex flex-col text-left justify-center vertical-align",attrs:{"aria-label":"Register"},on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('spinner',{directives:[{name:"show",rawName:"v-show",value:(_vm.isSyncing),expression:"isSyncing"}]}),(_vm.isNotGeneralRole)?_c('div',{staticClass:"mb-6"},[_c('label',{attrs:{"for":"role"}},[_vm._v("Role")]),_c('input',{attrs:{"id":"role","type":"text","disabled":""},domProps:{"value":_vm.roleName}}),_c('div',{staticClass:"mt-4"})]):_vm._e(),_c('div',{class:{
        'my-6': true,
        'has-error': _vm.errors.has('first name')
      }},[_c('label',{attrs:{"for":"first-name"}},[_vm._v("First Name")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.questionnaire.firstName),expression:"questionnaire.firstName",modifiers:{"trim":true}},{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"id":"first-name","type":"text","name":"first name"},domProps:{"value":(_vm.questionnaire.firstName)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.questionnaire, "firstName", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"mt-4"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('first name')),expression:"errors.has('first name')"}],staticClass:"text-xs italic text-red"},[_vm._v(_vm._s(_vm.errors.first("first name")))])])]),_c('div',{class:{
        'mb-6': true,
        'has-error': _vm.errors.has('last name')
      }},[_c('label',{attrs:{"for":"last-name"}},[_vm._v("Last Name")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.questionnaire.lastName),expression:"questionnaire.lastName",modifiers:{"trim":true}},{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"id":"last-name","type":"text","name":"last name"},domProps:{"value":(_vm.questionnaire.lastName)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.questionnaire, "lastName", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"mt-4"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('last name')),expression:"errors.has('last name')"}],staticClass:"text-xs italic text-red"},[_vm._v(_vm._s(_vm.errors.first("last name")))])])]),_c('div',{class:{
        'mb-6': true,
        'has-error': _vm.errors.has('email')
      }},[_c('label',{attrs:{"for":"email"}},[_vm._v("Email")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.questionnaire.email),expression:"questionnaire.email",modifiers:{"trim":true}},{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"id":"email","type":"email","name":"email"},domProps:{"value":(_vm.questionnaire.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.questionnaire, "email", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"mt-4"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('email')),expression:"errors.has('email')"}],staticClass:"text-xs italic text-red"},[_vm._v(_vm._s(_vm.errors.first("email")))])])]),_c('div',{class:{
        'mb-6': true,
        'has-error': _vm.errors.has('password')
      }},[_c('label',{attrs:{"for":"password"}},[_vm._v("Password")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.questionnaire.password),expression:"questionnaire.password",modifiers:{"trim":true}},{name:"validate",rawName:"v-validate",value:(`required|min:${_vm.VALIDATION.MIN_PW_LEN}`),expression:"`required|min:${VALIDATION.MIN_PW_LEN}`"}],ref:"password",staticClass:"form-control",attrs:{"id":"password","type":"password","name":"password"},domProps:{"value":(_vm.questionnaire.password)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.questionnaire, "password", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"mt-4"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('password')),expression:"errors.has('password')"}],staticClass:"text-xs italic text-red"},[_vm._v(_vm._s(_vm.errors.first("password")))])])]),_c('div',{class:{
        'mb-6': true,
        'has-error': _vm.errors.has('password confirmation')
      }},[_c('label',{attrs:{"for":"password-confirm"}},[_vm._v("Confirm Password")]),_c('input',{directives:[{name:"validate",rawName:"v-validate",value:(
          `required|confirmed:password|min:${_vm.VALIDATION.MIN_PW_LEN}`
        ),expression:"\n          `required|confirmed:password|min:${VALIDATION.MIN_PW_LEN}`\n        "}],staticClass:"form-control",attrs:{"id":"password-confirm","type":"password","name":"password confirmation"}}),_c('div',{staticClass:"mt-4"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('password confirmation')),expression:"errors.has('password confirmation')"}],staticClass:"text-xs italic text-red"},[_vm._v(_vm._s(_vm.errors.first("password confirmation")))])])]),_c('div',{staticClass:"my-5 flex text-center w-1/2 self-center justify-between"},[_c('router-link',{staticClass:"btn btn-blue",attrs:{"type":"button","to":{ name: 'user-pre-req' }}},[_vm._v(" Back ")]),_c('button',{staticClass:"btn btn-blue",attrs:{"type":"submit"}},[_vm._v(" Submit ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }