<template>
  <transition name="modal" v-show="value">
    <div class="modal-mask h-full flex justify-center items-center">
      <div
        class="modal-wrapper overflow-scroll flex h-full justify-center items-center w-full"
      >
        <div
          class="modal-box flex flex-col modal-container bg-white rounded shadow m-auto w-3/4 md:w-3/4 lg:w-3/4 pt-6 pb-10 lg:pb-24"
        >
          <div class="flex flex-wrap-reverse mb-4">
            <div class="flex-1 sm:flex">
              <div class=" h-12 flex items-center justify-start pl-6">
                <h2 class="text-blue-primary itmes-center">All Participants</h2>
              </div>
            </div>

            <div class="flex-grow xs:flex">
              <div
                class=" h-12 flex items-center justify-end xs:justify-center"
              >
                <button @click="getParticipantsExport" class="btn btn-blue">
                  Export Participant List
                </button>
              </div>
            </div>

            <div class="flex-none pl-8 pr-6 xs:flex">
              <div class=" h-12 flex items-center justify-end">
                <button @click.prevent="close">
                  <h1 class="text-blue-primary">X</h1>
                </button>
              </div>
            </div>
          </div>

          <div
            class="flex justify-between participant-table items-center"
          ></div>
          <div
            class="align-middle inline-block rounded-bl-lg rounded-br-lg w-full h-full"
          >
            <table class="modal-box flex flex-col flex-no-wrap w-full h-1/2">
              <thead class="text-blue-primary w-full">
                <tr class="flex w-full">
                  <th
                    class="w-1/5 px-6 py-4 text-center leading-4 text-blue-500 tracking-wider"
                  >
                    Name
                  </th>
                  <th
                    class="w-1/5 px-6 py-4 text-center leading-4 text-blue-500 tracking-wider"
                  >
                    Organization
                  </th>
                  <th
                    class="w-1/5 px-6 py-4 text-center leading-4 text-blue-500 tracking-wider"
                  >
                    Email
                  </th>
                  <th
                    class="w-1/5 px-6 py-4 text-center leading-4 text-blue-500 tracking-wider"
                  >
                    Workshop Role
                  </th>
                  <th
                    class="w-1/4 px-6 py-4 text-center leading-4 text-blue-500 tracking-wider"
                  >
                    Institutional Role
                  </th>
                </tr>
              </thead>
              <tbody
                class="bg-white flex-1 sm:flex-none w-full overflow-scroll tbody"
              >
                <tr
                  v-for="(participants, index) in allParticipants"
                  v-bind:key="'existingParticipants' + participants.email"
                  v-bind:class="{
                    'block lg:flex items-center p-2': true,
                    'bg-blue-lightest': !(index % 2)
                  }"
                  class="flex w-full justify-start	"
                >
                  <td
                    class="justify-start w-1/5 px-6 py-4 whitespace-no-wrap overflow-scroll "
                  >
                    {{ participants.name }}
                  </td>
                  <td
                    class="justify-start w-1/5 px-6 py-4 whitespace-no-wrap overflow-scroll "
                  >
                    {{ participants.member_name }}
                  </td>
                  <td
                    class="justify-start w-1/5 px-6 py-4 whitespace-no-wrap overflow-scroll "
                  >
                    {{ participants.email }}
                  </td>
                  <td
                    class="justify-start w-1/5 px-6 py-4 whitespace-no-wrap overflow-scroll text-blue-900 leading-5"
                  >
                    {{ participants.role }}
                  </td>
                  <td
                    class="justify-end w-1/4 text-center px-2 py-4 whitespace-no-wrap overflow-scroll text-blue-900 leading-5"
                  >
                    {{ participants.inst_role }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<style lang="css">
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.85);
  transition: opacity 0.3s ease;
}

.modal-container {
  transition: all 0.3s ease;
}
/*
    * The following styles are auto-applied to elements with
    * transition="modal" when their visibility is toggled
    * by Vue.js.
    *
    * You can easily play with the modal transition by editing
    * these styles.
    */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

/* custom styles */
.participant-table {
  margin-top: 20px;
}
.tbody {
  max-height: 500px;
}
</style>

<script>
import "babel-core";
import * as _ from "lodash";

export default {
  name: "ViewParticipantsModal",
  props: {
    value: {
      required: true
    },
    workshop: Object
  },
  data: () => ({
    existingParticipants: [],
    workshop_id: null,
    staffArray: [],
    partArray: [],
    participants: [],
    allParticipants: []
  }),
  methods: {
    close() {
      this.$emit("input", !this.value);
      this.$emit("reload", this.updated);
    },
    getParticipants() {
      this.workshop_id = this.workshop.id;
      window.axios
        .get(`/api/workshops/${this.workshop_id}/participant-list`)
        .then(res => {
          this.staffArray = res.data.staff;
          this.partArray = res.data.participants;

          // add ws staff first then participant
          this.staffArray.forEach(staff => {
            staff.role = staff.role[0];
            this.allParticipants.push(staff);
          });

          this.partArray.forEach(participants => {
            // sort out roles options
            participants.role.forEach(roles => {
              if (roles.includes("facilitator")) {
                participants.role = "Facilitator";
              } else if (roles.includes("LEAAdmin")) {
                participants.role = "LEA Admin";
              } else {
                participants.role = "LEA Participant";
              }
            });
            this.participants.push(participants);
          });

          // order participants add them to all participants
          this.participants = _.orderBy(
            this.participants,
            ["member_name"],
            ["asc"]
          );
          this.participants.forEach(part => {
            this.allParticipants.push(part);
          });
        });
    },
    getParticipantsExport() {
      this.workshop_id = this.workshop.id;
      window
        .axios({
          url: `/api/workshops/${this.workshop_id}/export-participants`,
          method: "GET",
          responseType: "blob"
        })
        .then(res => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `${this.workshop.title}-participants-export.csv`
          );
          document.body.appendChild(link);
          link.click();
        });
    }
  },
  mounted() {
    this.loading = true;
    this.getParticipants();

    this.loading = false;
  }
};
</script>
