<template>
  <div>
    <h1 class="mb-8 text-black text-center">Rubric Results</h1>
    <div class="bg-white p-10 rounded mb-8">
      <div v-for="input in form.inputs" :key="input.id">
        <!-- section head -->
        <div v-if="input.type === 'section'" class="pb-24 center-div">
          <h2 class="mb-8 font-semibold text-blue-primary">{{ form.title }}</h2>
          <component
            :is="`description-input`"
            :input="input"
            :name="getInputName(input)"
            v-model="formData[input.id]"
            class="pb-8"
          >
          </component>
          <div class="blue-spacer pl-10 pr-10 container">
            <div class="edge-a"></div>
            <div class="edge-b"></div>
            <div class="edge-c"></div>
            <div class="edge-d"></div>
          </div>
        </div>
        <!-- other inputs -->
        <div v-else>
          <div
            v-if="input.attributes.title.includes('Rubric Reflection')"
            class="pb-2"
          >
            <component
              :is="`textarea-input`"
              :input="input"
              :name="getInputName(input)"
              :answer="answers[input.id]"
              v-model="formData[input.id]"
              :disabled="true"
              :page="`rubric-end`"
            >
            </component>
          </div>
          <div v-else-if="input.type === 'textarea'" class="pb-2">
            <component
              :is="`textarea-input`"
              :input="input"
              :name="getInputName(input)"
              :answer="answers[input.id]"
              v-model="formData[input.id]"
              :disabled="true"
              :page="`rubric`"
            >
            </component>
          </div>
          <div v-else>
            <component
              :is="`${input.type}-input`"
              :input="input"
              :name="getInputName(input)"
              :answer="answers[input.id]"
              v-model="formData[input.id]"
              :disabled="true"
              :page="`rubric`"
            >
            </component>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.center-div {
  margin: 0 auto;
}
.blue-spacer {
  background-color: #e6ecf5;
  width: 100%;
  height: 7vh;
  position: absolute;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  left: 0;
  right: 0;
}

.edge-a {
  position: relative;
  width: 20px;
  height: 15px;
  top: -15px;
  background: radial-gradient(
    180% 180% at right -35% top -35%,
    transparent 99%,
    #e6ecf5
  );
}

.edge-b {
  position: relative;
  width: 20px;
  height: 15px;
  top: -30px;
  float: right;
  background: radial-gradient(
    180% 180% at left -35% top -35%,
    transparent 99%,
    #e6ecf5
  );
}

.edge-c {
  position: relative;
  width: 20px;
  height: 15px;
  bottom: calc(-7vh + 15px);
  background: radial-gradient(
    180% 180% at right -35% bottom -35%,
    transparent 99%,
    #e6ecf5
  );
}

.edge-d {
  position: relative;
  width: 20px;
  height: 15px;
  bottom: calc(-7vh + 30px);
  float: right;
  background: radial-gradient(
    180% 180% at left -35% bottom -35%,
    transparent 99%,
    #e6ecf5
  );
}
</style>

<script>
import CheckboxInput from "../inputsResults/CheckboxInput.vue";
import RadioInput from "../inputsResults/RadioInput.vue";
import Radio from "../inputsResults/Radio.vue";
import SectionInput from "../inputsResults/SectionInput.vue";
import DescriptionInput from "../inputsResults/DescriptionInput.vue";
import TextInput from "../inputsResults/TextInput.vue";
import TextareaInput from "../inputsResults/TextareaInput.vue";

import _ from "lodash";

export default {
  name: "FormAnswersGoals",
  props: {
    form: Object,
    answers: Object,
    formData: Object,
    user: Object
  },
  components: {
    CheckboxInput,
    RadioInput,
    Radio,
    DescriptionInput,
    SectionInput,
    // SectionHeaderInput,
    TextInput,
    // TextHeaderInput,
    TextareaInput
    // TextareaRubricInput,
    // TextareaRubricEndInput
  },
  data: () => ({
    isLoading: true,
    registration: {}
  }),
  computed: {
    allowedAccess() {
      return (
        _.map(this.workshop.staff, "id").includes(this.user.id) &&
        this.registration.consent != "none"
      );
    }
  },
  methods: {
    getInputName(input) {
      if (input.type == "section") {
        return input.header;
      }
      return input.attributes.name;
    }
  }
};
</script>
