export const MASQUERADE_ON = "MASQUERADE_ON";
export const MASQUERADE_OFF = "MASQUERADE_OFF";

const state = {
  isMasquerading: false
};

const getters = {
  isMasquerading: state => state.isMasquerading
};

const actions = {
  masqueradeOn: ({ commit }) => {
    commit(MASQUERADE_ON);
  },
  masqueradeOff: ({ commit }) => {
    commit(MASQUERADE_OFF);
  }
};

const mutations = {
  MASQUERADE_ON: state => {
    state.isMasquerading = true;
  },
  MASQUERADE_OFF: state => {
    state.isMasquerading = false;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
