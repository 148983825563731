<template>
  <div class="relative">
    <div class="container mx-auto pb-24">
      <lea-team-modal v-if="modalOpen == true" v-model="modalOpen">
      </lea-team-modal>

      <lea-invite-info
        v-show="inviteLEAPopup"
        :title="inviteLEAPopupTitle"
        :body="inviteLEAPopupBody"
        actionButton="Okay"
        actionButtonColor="btn-blue"
        @confirm="inviteLEAPopup = false"
      ></lea-invite-info>

      <div class="flex flex-wrap mb-2">
        <div class="w-full md:w-2/3 md:pr-10">
          <h1 class="mb-10">All Workshops</h1>
          <div v-if="!isFacilitator" class="text-blue-primary mb-2">
            <ul>
              <li>
                Below you can find all workshops your LEA has registered for,
                listed by date created.
              </li>
              <li>
                For closed completed workshops listed below, you can access LEA
                rubric self-assessment and goal setting data submitted to the
                portal.
              </li>
              <li>
                Team Management - use the “Team Management” button to invite
                individuals to join your LEA team. You can also remove
                individuals, or assign another LEA team member to be the LEA
                team admin.
              </li>
            </ul>
          </div>
          <div v-if="isFacilitator" class="text-blue-primary mb-2">
            <p>
              Below you can find all of your created workshops, listed by date
              created
            </p>
            <ul>
              <li>
                As a facilitator you can set a workshop to be active or closed.
              </li>
              <li>
                Set a workshop to active when you want to enable LEA teams to
                edit workshop forms.
              </li>
              <li>
                When a workshop is active, you will be able to track an LEA
                team’s completion of the workshop rubric and goal setting forms.
              </li>
              <li>
                Set a workshop to closed either before a workshop has started or
                after a workshop has ended to disable LEA teams’ ability to edit
                forms. Please note that the post-survey form will remain
                editable even after a workshop has been set to closed.
              </li>
              <li>
                For closed completed workshops listed below, you can access LEA
                rubric self-assessment and goal setting data submitted to the
                portal.”
              </li>
            </ul>
          </div>
        </div>
        <div class="w-full md:w-1/3 md:justify-end justify-center">
          <img
            v-show="user.member && user.member.logo_url"
            class="mb-6 pt-5"
            v-bind:src="user.member.logo_url"
            alt="logo"
          />
        </div>
      </div>
      <div class="border-b mt-10 mb-16 pb-8">
        <div class="flex float-right ">
          <img
            class="w-4 mr-1"
            src="@/assets/img/halp-tri.png"
            alt="blue triangle with white exclamation point indside it"
          />
          <a
            href="https://forms.gle/iPxAJf66mxhCwixh9"
            class="text-sm"
            target="_blank"
            >Having workshop trouble? <b> Contact SCRIPT</b></a
          >
        </div>
      </div>
      <div class="w-full md:flex-1">
        <div class="flex w-full justify-between items-center align-baseline">
          <div class="flex">
            <h3 class="text-24 mb-10 mr-4">Your Workshops</h3>

            <select
              v-if="isReviewer"
              v-model="filter"
              class="border-2 bg-white rounded h-8 w-32"
            >
              <option value="all">All</option>
              <option value="pending">Pending</option>
              <option value="approved">Approved</option>
              <option value="rejected">Rejected</option>
            </select>
          </div>

          <div class="flex justify-end">
            <button class="md:w-64" v-if="isFacilitator">
              <router-link
                :to="{ name: 'workshop-create' }"
                class="btn btn-green mb-10 md:w-64"
                >Create Workshop</router-link
              >
            </button>
            <button class="md:w-64" v-if="isLEAAdmin">
              <a
                href="#"
                v-show="isLEAAdmin"
                @click="showModal"
                class="btn btn-green mb-10 md:w-64"
                >Team Management</a
              >
            </button>
            <button class="md:w-64" v-if="isLEAParticipant">
              <a
                href="#"
                v-show="isLEAParticipant"
                @click="showModal"
                class="btn btn-green mb-10 md:w-64"
                >View Team</a
              >
            </button>
          </div>
        </div>

        <!-- <div>
            <label>
              <input type="radio" name="filter" id="all" v-model="filter" value="all" />
              <span
                class="radio-label md:pr-20 ml-2 font-normal cursor-pointer text-h4 leading-20"
                >All</span
              >
            </label>
            <label>
              <input type="radio" name="filter" id="pending" v-model="filter" value="pending" />
              <span
                class="radio-label md:pr-20 ml-2 font-normal cursor-pointer text-h4 leading-20"
                >Pending</span
              >
            </label>
            <label>
              <input type="radio" name="filter" id="completed" v-model="filter" value="approved" />
              <span
                class="radio-label md:pr-20 ml-2 font-normal cursor-pointer text-h4 leading-20"
                >Approved</span
              >
            </label>
            <label>
              <input type="radio" name="filter" id="rejected" v-model="filter" value="rejected" />
              <span
                class="radio-label md:pr-20 ml-2 font-normal cursor-pointer text-h4 leading-20"
                >Rejected</span
              >
            </label>
          </div> -->

        <workshop-item
          v-for="workshop in filteredWorkshops"
          :key="workshop.id"
          :workshop="workshop"
          :user="user"
          @reload="loadWorkshops"
        >
        </workshop-item>
        <!-- TODO: remove workshops -->
        <!-- <workshop-item
          v-for="workshop in workshops"
          :key="workshop.id"
          :workshop="workshop"
          :user="user"
          @sendRemovedWorkshopId="getRemovedWorkshopId"
          @reload="loadWorkshops"
        >
        </workshop-item> -->
        <div class="border-t border-grey pt-5 mt-10 hidden">
          <h2 class="text-blue-primary text-24 mb-5">
            Past Workshops
          </h2>
          <ul class="list-reset">
            <li><a href="#">Past Workshop Here ></a></li>
            <li><a href="#">Another Past Workshop Here ></a></li>
            <li><a href="#">Past Workshop Here ></a></li>
          </ul>
        </div>
      </div>
    </div>
    <div class="blue-bar"></div>
  </div>
</template>

<script>
import WorkshopItem from "./WorkshopItem.vue";
import { mapGetters } from "vuex";
import { ROLES } from "../../const";
import LEATeamModal from "../modals/LEATeamModal.vue";
import InfoModal from "../modals/InfoModal";

export default {
  name: "WorkshopList",
  components: {
    WorkshopItem,
    "lea-team-modal": LEATeamModal,
    "lea-invite-info": InfoModal
  },
  data: () => ({
    workshops: [],
    filteredWorkshops: [],
    modalOpen: false,
    inviteLEAPopup: false,
    inviteLEAPopupBody: "",
    inviteLEAPopupTitle: "",
    filter: "all"
  }),
  computed: {
    ...mapGetters({
      user: "user"
    }),
    isFacilitator() {
      return this.user && this.user.is_facilitator;
    },
    isLEAAdmin() {
      return this.user && this.user.roles.includes(ROLES.LEA_ADMIN);
    },
    isLEAParticipant: function() {
      return this.user.roles.includes(ROLES.LEA_PARTICIPANT);
    },
    isReviewer() {
      return this.user.is_reviewer || this.user.is_admin;
    }
  },
  watch: {
    $route: "loadWorkshops",
    user: function() {
      this.loadWorkshops();
    },
    filter(value) {
      console.log(value);
      if (value === "all") {
        this.filteredWorkshops = this.workshops;
      } else {
        this.filteredWorkshops = this.workshops.filter(
          e => e.validated === value
        );
      }
    }
  },
  methods: {
    showModal() {
      this.modalOpen = !this.modalOpen;
    },
    loadWorkshops() {
      if (this.user && this.user.id) {
        if (this.isFacilitator) {
          this.getFacilitatorWorkshops();
        } else {
          this.getWorkshops();
        }
      }
    },
    // TODO: remove workshops
    // getRemovedWorkshopId(id) {
    //   console.log('removed', id)
    //   this.removeWorkshop(id);
    // },
    // removeWorkshop(id) {
    //   // this.loading = true;
    //   console.log(id);
    //   // window.axios
    //   //   .delete("/api/facilitator/workshops", config)
    //   //   .then(() => {
    //   //     this.$toasted.show("Invite Removed");
    //   //     this.loading = false;
    //   //   })
    //   //   .catch(error => {
    //   //     this.loading = false;
    //   //     console.log(error);
    //   //   });
    // },
    getWorkshops() {
      window.axios
        .get("/api/workshops")
        .then(response => {
          this.workshops = response.data;
          this.filteredWorkshops = this.workshops;
          this.$store.dispatch("setWorkshops", this.workshops);
        })
        .catch(error => {
          console.log(error);
        });
    },
    getFacilitatorWorkshops() {
      window.axios
        .get("/api/facilitator/workshops")
        .then(response => {
          this.workshops = response.data;
          this.filteredWorkshops = this.workshops;
          this.$store.dispatch("setFacilitatorWorkshops", this.workshops);
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  created() {
    this.loadWorkshops();
  },
  mounted() {
    if (this.$route.params.created === true) {
      this.inviteLEAPopupTitle = `You've now created a workshop!`;
      this.inviteLEAPopupBody = `It's time to invite people to attend. Find your workshop card in the list and click "invite LEAs" to do so.`;
      this.inviteLEAPopup = true;
    }
  }
};
</script>

<style scoped>
h5 {
  color: #3061ac;
}
</style>
