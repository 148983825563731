<template>
  <div class="mb-4 section-heading">
    <h2 class="text-24 font-bold text-blue-primary">{{ input.header }}</h2>
    <p class="text-black">{{ this.input.description }}</p>
  </div>
</template>

<script>
export default {
  name: "SectionInput",
  props: {
    input: Object
  }
};
</script>
