<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div
          class="modal-container bg-blue-panel rounded shadow m-auto lg:w-3/5 md:w-3/4 w-5/6 pt-6 pb-10 lg:pb-24"
        >
          <div class="modal-header pb-6 px-10">
            <slot name="header">
              <h1 class="text-h1 text-blue-primary">{{ title }}</h1>
            </slot>
          </div>

          <div
            class="modal-body px-10 py-6 font-bold text-h3 text-blue-primary leading-loose"
          >
            <slot name="body">
              {{ body }}
            </slot>
          </div>

          <div class="modal-footer flex flex-wrap justify-center px-10">
            <slot name="footer">
              <button
                class="modal-default-button btn w-full md:w-auto mb-2"
                :class="{
                  [closeButtonColor]: closeButtonColor ? true : false
                }"
                @click.prevent="$emit('close')"
              >
                {{ closeButton }}
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: ["title", "body", "closeButton", "closeButtonColor"],
  data: function() {
    return {};
  },
  computed: {},
  methods: {}
};
</script>

<!-- Using the `scoped` attribute -->
<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.85);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  transition: all 0.3s ease;
}

label {
  padding-left: 22px;
  text-indent: -22px;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
