<template>
  <div class="mb-8 description-heading">
    <p class="text-black">{{ this.input.description }}</p>
  </div>
</template>

<script>
export default {
  name: "DescriptionInput",
  props: {
    input: Object
  }
};
</script>
