export const LEVELS_SET = "LEVELS_SET";

const state = {
  levels: {}
};

const getters = {
  levels: state => state.levels
};

const actions = {
  setLevels: ({ commit }, levels) => {
    commit(LEVELS_SET, {
      levels: levels
    });
  }
};

const mutations = {
  LEVELS_SET: (state, payload) => {
    state.levels = Object.assign({}, state.levels, payload.levels);
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
