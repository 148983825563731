<template>
  <label>
    <input
      type="radio"
      :id="option.id"
      :name="name"
      :value="option.value"
      :checked="option.value == answer"
      :disabled="disable"
      @input="onChange($event)"
    />
    <span class="ml-2 font-normal cursor-pointer">{{ option.label }}</span>
  </label>
</template>

<script>
export default {
  name: "Radio",
  props: {
    answer: String,
    disable: Boolean,
    name: String,
    option: Object
  },
  methods: {
    onChange(event) {
      this.$emit("change", event.target.value);
    }
  }
};
</script>
