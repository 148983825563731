<template>
  <div class="w-full h-screen">
    <confirm-modal
      v-show="showConfirmationModel"
      title=""
      body="Your profile is missing some fields required for activation."
      actionButton="Finish Later"
      cancelButton="Finish Profile"
      actionButtonColor="btn-grey-light-2"
      cancelButtonColor="btn-blue"
      @cancel="showConfirmationModel = false"
      @confirm="proceedToConfirmation"
    ></confirm-modal>

    <div class="relative h-full">
      <Header
        v-bind:stages="['1', '2', '3']"
        v-bind:memberType="profile.member.type"
      />
      <div
        class="w-full p-10 flex flex-col items-center bg-blue-primary text-black z-10"
      >
        <h1 class="text-center mb-10 text-white">Complete Your Profile</h1>

        <div class="rounded form-container bg-white">
          <div class="mt-8 divider">
            <div class="w-full pb-8">
              <label class="text-h3 font-bold mb-4 pl-0">Profile Name</label>
              <p v-if="isOrgMember" class="leading-loose mb-6 text-h4">
                This is the name of your company or organization.
              </p>
              <input type="text" v-model="name" autocomplete="nope" />
            </div>
            <div v-if="isFacilitatorProfile" class="w-full pb-8">
              <label class="text-h3 font-bold">Professional Title</label>
              <input
                type="text"
                v-model="title"
                name="title"
                autocomplete="nope"
                v-validate="'required'"
              />
              <div class="my-4">
                <span
                  v-show="errors.has('title')"
                  class="text-xs italic text-red"
                  >{{ errors.first("title") }}</span
                >
              </div>
            </div>
            <div v-if="isFacilitatorProfile" class="w-full pb-8">
              <label class="w-4/5 text-h3 font-bold"
                >If your SCRIPT Facilitator Training was sponsored, please write
                in the name of the sponsoring organization.</label
              >
              <input
                type="text"
                v-model="training_sponsor"
                name="training_sponsor"
                autocomplete="nope"
              />
            </div>
          </div>

          <div class="mt-8 divider">
            <div class="w-full lg:w-3/4 pb-8">
              <label v-if="isOrgMember" class="text-h3 font-bold mb-4 pl-0"
                >Logo</label
              >
              <label v-else class="text-h3 font-bold mb-4">Profile Photo</label>
              <p class="leading-loose mb-6 text-h4">
                Accepted file formats include: JPG, JPEG, GIF and PNG. File size
                may not exceed 500K.
              </p>
              <span
                v-show="profile.member.logo_url && !isSaving && !memberLogo"
              >
                <a :href="profile.member.logo_url" target="_blank">{{
                  profile.member.logo_url
                }}</a>
              </span>
              <span v-show="memberLogo">
                Selected File: {{ memberLogo ? memberLogo.logo.name : "None" }}
              </span>
              <div class="mt-8">
                <span
                  :class="{
                    'btn btn-blue btn-sm cursor-pointer inline-block': true,
                    'opacity-50 cursor-not-allowed': isSaving
                  }"
                  @click="handleSelectFile"
                  >{{
                    profile.member.logo_url ? "Replace" : "Upload"
                  }}
                  File</span
                >
              </div>
              <div
                class="my-4"
                v-if="profile.member.logo_url ? memberLogo : true"
              >
                <span
                  v-show="
                    profile.member.logo_url === null || memberLogo === false
                  "
                  class="text-xs italic text-red"
                  >This image is required</span
                >
              </div>
            </div>
          </div>

          <div v-if="isPublicMember" class="mt-8 divider">
            <div class="w-full lg:w-3/4 pb-8">
              <label class="text-h3 font-bold mb-4 pl-0">Photos</label>
              <p class="leading-loose mb-6 text-h4">
                Accepted file formats include: JPG, JPEG, GIF and PNG. File size
                may not exceed 500K. Up to 3 photos may be uploaded.
              </p>
              <div class="mb-2 flex">
                <div
                  v-for="item in existingImages"
                  v-bind:key="item"
                  class="w-1/3"
                >
                  <div class="text-center mx-2">
                    <a :href="item"><img :src="item" :alt="item"/></a>
                    <button
                      class="bg-red-light text-white py-1 px-2 leading-none rounded-full mr-2"
                      @click="handleRemoveMemberImage(item)"
                    >
                      x
                    </button>
                  </div>
                </div>
                <div
                  v-for="item in memberImages"
                  v-bind:key="item.name"
                  class="w-1/3"
                >
                  <div class="text-center mx-2">
                    <img
                      alt="member image"
                      :src="
                        'data:image/' +
                          item.name.substr(item.name.lastIndexOf('.') + 1) +
                          ';base64,' +
                          item.contents
                      "
                    />
                    <button
                      class="bg-red-light text-white py-1 px-2 leading-none rounded-full mr-2"
                      @click="handleRemoveSelectedImage(item.name)"
                    >
                      x
                    </button>
                  </div>
                </div>
              </div>
              <span
                :class="{
                  'inline-block mt-4 btn btn-blue btn-sm cursor-pointer': true,
                  'opacity-50 cursor-not-allowed':
                    isSaving || selectedImages >= 3
                }"
                @click="handleAddImage"
                >Add Image</span
              >
            </div>
          </div>

          <div class="mt-8 divider">
            <div class="w-full lg:w-3/4 pb-8">
              <label class="text-h3 font-bold mb-4 pl-0">Teaser</label>
              <p v-if="isPublicMember" class="leading-loose mb-6 text-h4">
                This will show up on your member card in the search directory.
              </p>
              <textarea
                rows="4"
                class="rounded w-full border-2 mb-2 p-4 leading-loose"
                v-model="teaserText"
                maxlength="140"
                name="teaser"
                v-validate="'required'"
              ></textarea>
              {{ teaserText ? teaserText.length : "" }} of 140 characters
              <div class="my-4">
                <span
                  v-show="errors.has('teaser')"
                  class="text-xs italic text-red"
                  >{{ errors.first("teaser") }}</span
                >
              </div>
            </div>
          </div>

          <div class="mt-8 divider">
            <div class="w-full lg:w-3/4 pb-8">
              <label class="text-h3 font-bold mb-4 pl-0">{{
                descriptionLabel
              }}</label>
              <p v-if="isPublicMember" class="leading-loose mb-6 text-h4">
                This will show up on your member profile page.
              </p>
              <vue-editor
                v-model="descriptionText"
                :editorToolbar="descriptionToolbar"
                data-vv-name="description"
                v-validate="`required|min:${VALIDATION.MIN_DESC_LEN}`"
              ></vue-editor>
              {{ descriptionText ? descriptionText.length : "" }} of
              {{ VALIDATION.MIN_DESC_LEN }} characters
              <div class="my-4">
                <span
                  v-show="errors.has('description')"
                  class="text-xs italic text-red"
                  >{{ errors.first("description") }}</span
                >
              </div>
            </div>

            <div v-if="hasQueryParam" class="w-full lg:w-1/2 mb-10 mapimage">
              <p><b>CS100</b> Institution. <br />Click image to expand map.</p>
              <br />
              <a
                href="https://www.csiselementary.org/s/cs100-awards-2023"
                target="_blank"
              >
                <img
                  class="w-full"
                  src="@/assets/img/cs100map.png"
                  alt="cs100 map"
                />
              </a>
            </div>
          </div>

          <div v-if="isPublicMember">
            <h3 class="mt-8 mb-4 pl-0">Profile Links:</h3>
            <p class="leading-loose mb-6 text-h4">
              Add links to your website or social media profiles that will help
              people connect with your organization.
            </p>
            <div v-for="(link, index) in profileLinks" v-bind:key="link.uuid">
              <div class="mt-8">
                <div class="w-full lg:w-3/4">
                  <label class="mr-2 inline-block mb-4 pl-0 font-medium">{{
                    link.label ? link.label : "New Link"
                  }}</label>
                  <span v-show="index > 0">
                    <i
                      title="Remove link"
                      class="far fa-minus-square cursor-pointer text-blue-dark inline-block"
                      @click="removeProfileLink(link.uuid)"
                    ></i>
                  </span>
                </div>
                <div class="w-full lg:w-3/4">
                  <input
                    type="text"
                    class="mb-4"
                    placeholder="Link Label"
                    autocomplete="nope"
                    v-model.trim="link.label"
                    :name="`label ${index + 1}`"
                    v-validate="'required'"
                  />
                  <div class="my-4">
                    <span
                      v-show="errors.has(`label ${index + 1}`)"
                      class="text-xs italic text-red"
                      >{{ errors.first(`label ${index + 1}`) }}</span
                    >
                  </div>
                  <input
                    type="text"
                    class="mb-4"
                    placeholder="http://example.com"
                    autocomplete="nope"
                    v-model.trim="link.link"
                    :name="`url ${index + 1}`"
                    v-validate="{
                      required: true,
                      url: {
                        require_protocol: true,
                        protocols: ['http', 'https']
                      }
                    }"
                  />
                  <div class="my-4">
                    <span
                      v-show="errors.has(`url ${index + 1}`)"
                      class="text-xs italic text-red"
                      >{{ errors.first(`url ${index + 1}`) }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full lg:w-3/4 mt-4">
              <span
                class="btn btn-blue btn-sm inline-block"
                @click="addProfileLink"
                >Add Profile Link</span
              >
            </div>
          </div>
          <div v-else>
            <h3 class="mt-8 mb-4">LinkedIn URL</h3>
            <div class="mt-8">
              <div class="w-full lg:w-3/4">
                <input
                  type="text"
                  class="mb-4"
                  placeholder="http://www.linkedin.com/in/your-profile/"
                  autocomplete="nope"
                  v-model="profileLinks[0].link"
                  :name="'linkedin_url'"
                  v-validate="{
                    url: {
                      require_protocol: true,
                      protocols: ['http', 'https']
                    }
                  }"
                />
                <div class="my-4">
                  <span
                    v-show="errors.has('linkedin_url')"
                    class="text-xs italic text-red"
                    >{{ errors.first("linkedin_url") }}</span
                  >
                </div>
              </div>
            </div>
          </div>

          <div v-if="isPublicMember" class="mt-8 mb-10 border-t pt-8">
            <h3>Categories:</h3>
            <p class="mt-2 mb-10">
              You may select as many options that apply to your organization.
            </p>
            <div class="flex flex-wrap">
              <multi-select-field-wide
                v-if="profile.member.type === 'member'"
                :options="types"
                :values="profile.member.types"
                option="types"
                label="Organization Type"
                v-on:optionUpdate="onSelectUpdate"
              >
              </multi-select-field-wide>
              <multi-select-field
                v-if="profile.member.type === 'researcher'"
                :options="researchTypes"
                :values="profile.member.researchTypes"
                option="researchTypes"
                label="Research Types"
                v-on:optionUpdate="onSelectUpdate"
              >
              </multi-select-field>
              <multi-select-field
                :options="typeAudiences"
                :values="profile.member.audiences"
                option="audiences"
                label="Audience"
                v-on:optionUpdate="onSelectUpdate"
              >
              </multi-select-field>
              <multi-select-field
                :options="typeLevels"
                :values="profile.member.levels"
                option="levels"
                label="Level"
                v-on:optionUpdate="onSelectUpdate"
              >
              </multi-select-field>
              <multi-select-field
                v-if="user.member.type === 'member'"
                :options="settings"
                :values="profile.member.settings"
                option="settings"
                label="Setting"
                v-on:optionUpdate="onSelectUpdate"
              >
              </multi-select-field>
              <select-field
                v-if="profile.member.type === 'member'"
                :options="students"
                :value="profile.member.student"
                option="student"
                label="Students Served"
                v-on:optionUpdate="onSelectUpdate"
              >
              </select-field>
              <select-field
                v-if="profile.member.type === 'member'"
                :options="teachers"
                :value="profile.member.teacher"
                option="teacher"
                label="Teachers Served"
                v-on:optionUpdate="onSelectUpdate"
              >
              </select-field>
              <multi-select-field
                v-if="user.member.type === 'researcher'"
                :options="others"
                :values="profile.member.others"
                option="others"
                label="Other"
                v-on:optionUpdate="onSelectUpdate"
              >
              </multi-select-field>
            </div>
          </div>

          <div class="mt-8 mb-10 border-t pt-8">
            <div class="flex flex-wrap">
              <multi-select-field
                v-if="isPublicMember"
                :options="regions"
                :values="profile.member.regions"
                option="regions"
                label="Region"
                v-on:optionUpdate="onSelectUpdate"
              >
              </multi-select-field>

              <div class="mb-4 pl-0 w-full lg:w-1/2">
                <h3>Location</h3>
                <p class="mt-2 mb-10">
                  Select your service area.
                </p>

                <div class="w-full mb-5">
                  <label for="countries" class="text-h3 mb-4 pl-0">
                    Country
                  </label>
                  <v-select
                    id="countries"
                    label="name"
                    name="country"
                    autocomplete="nope"
                    :options="countries"
                    :reduce="country => country.code3"
                    v-model="country"
                    v-validate="'required'"
                  >
                  </v-select>
                  <div class="my-4">
                    <span
                      v-show="errors.has('country')"
                      class="text-xs italic text-red"
                      >{{ errors.first("country") }}</span
                    >
                  </div>
                </div>

                <div v-show="isUsa" class="w-full mb-5">
                  <label for="state" class="text-h3">
                    State or Territory
                  </label>
                  <v-select
                    id="state"
                    name="state"
                    label="name"
                    autocomplete="nope"
                    :options="statesAndTerritories"
                    :reduce="state => state.abbreviation"
                    v-model.trim="state"
                    v-validate="{ required: this.isUsa }"
                  >
                  </v-select>
                  <div class="my-4">
                    <span
                      v-show="errors.has('state')"
                      class="text-xs italic text-red"
                      >{{ errors.first("state") }}</span
                    >
                  </div>
                </div>

                <div class="w-full mb-5">
                  <label for="city" class="text-h3 mb-4 pl-0">
                    City
                  </label>
                  <input
                    id="city"
                    name="city"
                    autocomplete="nope"
                    v-model.trim="city"
                    v-validate="'required'"
                  />
                  <div class="my-4">
                    <span
                      v-show="errors.has('city')"
                      class="text-xs italic text-red"
                      >{{ errors.first("city") }}</span
                    >
                  </div>
                </div>

                <div v-show="isUsa" class="w-full mb-5">
                  <label for="zip" class="text-h3">
                    Zip Code
                  </label>
                  <input
                    id="zip"
                    name="zip code"
                    autocomplete="nope"
                    v-model.trim="zipCode"
                    v-validate="{
                      required: this.isUsa,
                      regex: /^\d{5}(-\d{4})?$/
                    }"
                  />
                  <div class="my-4">
                    <span
                      v-show="errors.has('zip code')"
                      class="text-xs italic text-red"
                      >{{ errors.first("zip code") }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="w-full mt-4 flex justify-center">
            <button
              v-bind:class="{
                'opacity-50 cursor-not-allowed': this.isSaving,
                'btn bg-blue-light-2 text-white ml-4 m-1': true
              }"
              @click="onSave"
            >
              Save
            </button>
          </div>

          <spinner v-show="isSaving"></spinner>
          <input
            type="file"
            id="logo-file"
            @change="handleProcessFile"
            class="hidden"
          />
          <input
            type="file"
            id="member-file"
            @change="handleProcessMemberFile"
            class="hidden"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.form-container {
  background-color: #fff;
  padding: 42px 142px;
  margin-top: 26px;
  margin-bottom: 376px;
}
.mapimage img {
  border: 1px solid #c8c8c8;
  box-shadow: 0px 1px 5px 1px #00000017;
  transition: all 0.2s ease;
}
.mapimage img:hover {
  box-shadow: 0px 2px 5px 2px #00000023;
}
</style>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";
import "vue-select/dist/vue-select.css";
import { VueEditor } from "vue2-editor";
import Header from "../header/Header.vue";
import uuidv4 from "uuid/v4";
import ConfirmModal from "../modals/ConfirmModal";

import { MEMBER_TYPES, VALIDATION, ROLES, LINKEDIN_LABEL } from "@/const";
import { COUNTRIES } from "@/util/countries";
import { STATE_AND_TERRITORIES } from "@/util/statesAndTerritories";
import vSelect from "vue-select";

export default {
  components: {
    VueEditor,
    Header,
    "confirm-modal": ConfirmModal,
    "v-select": vSelect
  },
  data() {
    return {
      MEMBER_TYPES: MEMBER_TYPES,
      VALIDATION: VALIDATION,
      teaserToolbar: [["bold", "italic"]],
      descriptionToolbar: [
        ["bold", "italic", "link"],
        [{ list: "ordered" }, { list: "bullet" }]
      ],
      changedUser: {},
      teaserText: null,
      descriptionText: null,
      name: "",
      title: null,
      training_sponsor: null,
      isSaving: false,
      profileLinks: [],
      memberLogo: false,
      memberImages: [],
      existingImages: [],
      selectedImages: 0,
      can_approve_alignments: false,
      is_facilitator: false,
      showConfirmationModel: false,
      workshopRegister: false,
      workshopID: null,
      profile: {
        member: {
          types: false,
          audiences: false,
          levels: false,
          regions: false,
          researchTypes: false,
          settings: false,
          student: false,
          teacher: false,
          teaser: false,
          description: false,
          logo_url: false,
          profileLinks: [],
          memberImages: []
        }
      },
      city: "",
      state: null,
      country: null,
      zipCode: "",
      countries: COUNTRIES,
      statesAndTerritories: STATE_AND_TERRITORIES,
      linkedInLabel: LINKEDIN_LABEL
    };
  },
  methods: {
    handleProcessMemberFile(e) {
      let reader = new FileReader();
      let contents;
      if (!e.target.files.length) {
        return;
      }
      const file = e.target.files[0];
      const fileTypes = [".jpg", ".png", ".gif", ".jpeg"];
      const urlType = fileTypes.filter(type => file.name.search(type) > 0)[0];
      let newUrl = urlType
        ? file.name.replace(urlType, file.lastModified + urlType)
        : null;

      reader.onloadend = e => {
        contents = e.target.result;
        const name = newUrl;

        if (file.size > 500000) {
          // 500000 bytes = 500 kb
          this.$toasted.show("Oops! Images must be less than 500KB");
          return;
        }
        if (name.toLowerCase().search(/(jpe?g|gif|png)/g) === -1) {
          this.$toasted.show("Oops! Images must be a JPG, JPEG, GIF or PNG.");
          return;
        }

        const fileData = {
          name: name,
          contents: btoa(contents)
        };

        this.selectedImages++;
        this.memberImages.push(fileData);
      };
      reader.readAsBinaryString(file);
    },
    handleProcessFile(e) {
      let reader = new FileReader();
      let contents;
      if (!e.target.files.length) {
        return;
      }
      const file = e.target.files[0];
      const fileTypes = [".jpg", ".png", ".gif", ".jpeg"];
      const urlType = fileTypes.filter(type => file.name.search(type) > 0)[0];
      let newUrl = urlType
        ? file.name.replace(urlType, file.lastModified + urlType)
        : null;

      reader.onloadend = e => {
        contents = e.target.result;
        const name = newUrl;

        if (file.size > 500000) {
          // 500000 bytes = 500 kb
          this.$toasted.show("Oops! Logo files must be less than 500KB");

          return;
        }
        if (name.toLowerCase().search(/(jpe?g|gif|png)/g) === -1) {
          this.$toasted.show(
            "Oops! Logo files must be a JPG, JPEG, GIF or PNG."
          );
          return;
        }

        this.memberLogo = {
          logo: {
            name: name,
            contents: btoa(contents)
          }
        };
      };

      reader.readAsBinaryString(file);
    },
    handleRemoveSelectedImage(name) {
      this.selectedImages--;
      this.memberImages = _.reject(this.memberImages, item => {
        return item.name === name;
      });
    },
    handleRemoveMemberImage(name) {
      this.selectedImages--;
      this.existingImages = _.reject(this.existingImages, item => {
        return item === name;
      });
    },
    handleSelectFile() {
      document.getElementById("logo-file").click(); // this function uses the id on the hidden input field to trigger the @change function
    },
    handleAddImage() {
      document.getElementById("member-file").click(); // this function uses the id on the hidden input field to trigger the @change function
    },
    removeProfileLink(uuid) {
      this.profileLinks = _.filter(this.profileLinks, function(link) {
        return link.uuid != uuid;
      });
    },
    addProfileLink() {
      this.profileLinks.push({
        uuid: uuidv4(),
        label: "",
        link: ""
      });
    },
    setProfile() {
      this.profile = this.user;
      this.name = this.profile.member?.name || "";
      this.title = this.profile.title || "";
      this.training_sponsor = this.profile.training_sponsor || "";
      this.teaserText = this.getTeaserText(this.profile);
      this.descriptionText = this.profile.member?.description || "";
      this.city = this.profile.member?.city || "";
      this.state = this.profile.member?.state || null;
      this.country = this.profile.member?.country || null;
      this.zipCode = this.profile.member?.zipCode || "";

      if (this.profile?.member?.profileLinks?.length) {
        this.profileLinks = this.profile.member.profileLinks;
      } else if (this.profileLinks.length < 1) {
        this.addProfileLink();
      }

      if (this.profile.member?.memberImages?.length) {
        this.existingImages = this.profile.member.memberImages;
        this.selectedImages = this.profile.member.memberImages.length;
      }
    },
    getTeaserText(profile) {
      const teaserHtml = profile?.member?.teaser;

      if (!teaserHtml) {
        return "";
      }

      const tmp = document.createElement("div");
      tmp.innerHTML = teaserHtml;

      return tmp.innerText;
    },
    onSelectUpdate(e) {
      let update = {};
      update[e.property] = e.value;
      this.changedUser = Object.assign({}, this.changedUser, update);
    },
    async onSave() {
      this.isSaving = true;

      if (this.teaserText) {
        this.changedUser.teaser = this.teaserText;
      }
      if (this.descriptionText && this.descriptionText.length >= 250) {
        this.changedUser.description = this.descriptionText;
      }
      if (this.name) {
        this.changedUser.name = this.name;
      }
      if (this.title) {
        this.changedUser.title = this.title;
      }
      if (this.training_sponsor) {
        this.changedUser.training_sponsor = this.training_sponsor;
      }
      if (this.profileLinks && this.profileLinks[0].link) {
        if (this.isFacilitatorProfile) {
          const linkedInValid = await this.$validator.validate("linkedin_url");
          if (linkedInValid) {
            this.profileLinks[0].label = this.linkedInLabel;
            this.changedUser.profileLinks = this.profileLinks;
          }
        } else {
          this.changedUser.profileLinks = this.profileLinks;
        }
      }
      if (this.memberLogo) {
        this.changedUser.logo = this.memberLogo.logo;
      }
      if (this.memberImages) {
        this.changedUser.newImages = this.memberImages;
      }
      if (this.city) {
        this.changedUser.city = this.city;
      }
      if (this.country) {
        this.changedUser.country = this.country;
      }
      if (this.state && this.isUsa) {
        this.changedUser.state = this.state;
      } else {
        this.changedUser.state = null;
      }
      if (this.isInternational) {
        this.changedUser.state = "International";
      }
      if (this.zipCode && this.isUsa) {
        this.changedUser.zipCode = this.zipCode;
      } else {
        this.changedUser.zipCode = null;
      }
      this.changedUser.memberImages = this.existingImages;

      try {
        const response = await window.axios.put("/api/me", this.changedUser);

        this.$store.dispatch("setUser", response.data.data[0]);
        this.$toasted.show("Profile updated");
      } catch (error) {
        console.log(error);
      } finally {
        this.isSaving = false;
      }

      const isAllValid = await this.validateProfileFields();

      if (isAllValid) {
        this.proceedToConfirmation();
      } else {
        this.showConfirmationModel = true;
      }
    },
    async validateProfileFields() {
      const validator = await this.$validator.validateAll();
      return validator && this.memberLogo;
    },
    proceedToConfirmation() {
      if (this.workshopRegister == true) {
        this.$router.push(`/auth/profile-confirm/${this.workshopID}`);
      } else if (this.isFacilitatorProfile) {
        this.$router.push({ name: "dashboard" });
      } else {
        this.$router.push(`/auth/profile-confirm`);
      }
    }
  },
  computed: {
    ...mapGetters({
      user: "user",
      isLoggedIn: "isLoggedIn",
      types: "types",
      researchTypes: "researchTypes",
      audiences: "audiences",
      levels: "levels",
      regions: "regions",
      settings: "settings",
      teachers: "teachers",
      students: "students",
      others: "others"
    }),
    hasQueryParam() {
      return Object.keys(this.$route.query).length !== 0;
    },
    isOrgMember() {
      const memberType = this.profile?.member?.type;
      return memberType === MEMBER_TYPES.MEMBER;
    },
    isPublicMember() {
      const memberType = this.profile?.member?.type;
      return memberType === MEMBER_TYPES.RESEARCHER || this.isOrgMember;
    },
    isFacilitatorProfile() {
      const facRole = this.profile?.roles.find(
        role => role === ROLES.FACILITATOR || role === ROLES.PENDING_FACILITATOR
      );
      return facRole;
    },
    isUsa() {
      return this.country === "USA";
    },
    isInternational() {
      return this.country !== "USA";
    },
    descriptionLabel() {
      if (this.isFacilitatorProfile) {
        return "Personal Bio";
      } else {
        return "Description";
      }
    },
    typeAudiences: function() {
      if (!this.audiences) {
        return [];
      }
      return _.filter(this.audiences, audience => {
        return (
          audience.for === this.profile.member.type || audience.for === "both"
        );
      });
    },
    typeLevels: function() {
      if (!this.levels) {
        return [];
      }
      return _.filter(this.levels, level => {
        return level.for === this.profile.member.type || level.for === "both";
      });
    }
  },
  created() {
    this.setProfile();
    this.$store.subscribe(mutation => {
      if (mutation.type != "USER_SET") {
        return;
      }
      this.setProfile();
    });
  },
  mounted() {
    this.$validator.validateAll();
    if (this.$route.params.workshop) {
      this.workshopRegister = true;
      this.workshopID = this.$route.params.workshop;
    }
  }
};
</script>
