<template>
  <div class="relative">
    <div class="container m-auto">
      <div v-if="!registration">Loading</div>
      <div v-else>
        <div class="flex container mx-auto px-5 py-5 items-end">
          <img class="w-40" src="@/assets/img/scriptlogo.png" alt="script" />
        </div>
        <div class="pb-12 px-4 pt-5">
          <div class="flex flex-wrap border-b border-gray mb-10 pb-10">
            <div class="w-full md:w-3/4 md:pr-20">
              <div class="mb-6">
                <h1 class="mb-3">{{ workshop.title }}</h1>
                <div class="text-blue-primary text-h3 mb-10 font-bold">
                  {{ formatDate(workshop.start_time) }} -
                  {{ formatDate(workshop.end_time) }} {{ workshop.timezone }}
                </div>
                <div class="mb-3" v-html="workshop.description"></div>
              </div>
            </div>
            <div class="w-full md:w-1/4">
              <img
                class="hidden md:block mb-10"
                src="@/assets/img/scriptlogo.png"
                alt="script"
              />
              <p class="text-blue-primary font-bold mb-3">
                {{ workshop.presenter_first_name }}
                {{ workshop.presenter_last_name }}
              </p>
              <p class="text-blue-primary mb-3">
                {{ workshop.city }} {{ workshop.state }}
              </p>
              <p class="text-blue-primary text-sm">
                Workshop Sponsored by:<br />{{ workshop.event_sponsor_host }}
              </p>
            </div>
          </div>
          <div class="flex flex-wrap">
            <div class="w-full md:w-3/4 md:pr-20">
              <div class="flex flex-wrap -mx-5">
                <div
                  v-for="rubric in completedRubrics"
                  :key="rubric.id"
                  class="text-blue-primary rounded mb-5 w-full md:w-1/2 px-5"
                >
                  <div class="flex flex-col bg-blue-panel rounded-lg">
                    <div class="border-b border-white p-5">
                      <p>Status: {{ answerStatus(rubric) }}</p>
                    </div>
                    <div class="p-5">
                      <h3 class="mb-3">{{ rubric.title }}</h3>
                      <router-link
                        v-if="answer(rubric.id)"
                        class="btn btn-blue text-center w-full"
                        :to="{
                          name: 'public.answer',
                          params: { id: answer(rubric.id).id, workshop: id },
                          query: { workshop: id }
                        }"
                        >Open Rubric</router-link
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="blue-bar"></div>
  </div>
</template>

<script>
import _ from "lodash";
import { formatWorkshopDate } from "../../util/formatDate";

export default {
  name: "PublicWorkshopShow",
  components: {},
  data: function() {
    return {
      loading: true,
      registration: null
    };
  },
  computed: {
    answers() {
      return this.workshop.answers;
    },
    completedRubrics() {
      return _.filter(
        this.rubrics,
        rubric => this.answerStatus(rubric) == "Completed"
      );
    },
    rubrics() {
      return this.workshop.rubrics;
    },
    workshop() {
      return this.registration.workshop;
    },
    id() {
      return this.$route.params.id;
    }
  },
  watch: {
    $route: "load"
  },
  methods: {
    answer(id) {
      return _.find(this.answers, { form_id: id });
    },
    answerStatus(form) {
      let answer = this.answer(form.id);
      return answer && answer.status
        ? _.upperFirst(answer.status)
        : "Not Started";
    },
    load() {
      window.axios.get(`/public/api/workshop/${this.id}`).then(resp => {
        this.registration = resp.data;
        this.loading = false;
      });
    },
    formatDate(date) {
      return formatWorkshopDate(date);
    }
  },
  created() {
    this.load();
  }
};
</script>
