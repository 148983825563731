<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div
          class="modal-container bg-blue-panel rounded shadow m-auto w-5/6 md:w-3/4 lg:w-modal pt-6 pb-10 lg:pb-24"
        >
          <div class="modal-header pb-6 px-10">
            <slot name="header">
              <h1 class="text-h1 text-blue-primary">Email Verification</h1>
            </slot>
          </div>

          <div
            class="modal-body px-10 py-6 font-bold text-h3 text-blue-primary leading-loose"
          >
            <slot name="body">
              {{ message }}
            </slot>

            <div class="mb-4 mt-4 has-error" v-show="error">
              <span class="text-red text-xs italic font-normal"
                >There was a problem with your verification code. Please try
                submitting again or resending a new verification code.</span
              >
            </div>

            <form
              method="POST"
              aria-label="Verify Email"
              @submit.prevent="validateBeforeSubmit"
            >
              <div
                v-bind:class="{
                  'mb-4': true,
                  'mt-4': true,
                  'has-error': errors.has('code')
                }"
              >
                <input
                  id="code"
                  type="text"
                  name="code"
                  v-model="code"
                  v-validate="'required|numeric|length:6'"
                />
                <div class="mt-4">
                  <span
                    v-show="errors.has('code')"
                    class="text-red text-xs italic font-normal"
                    >{{ errors.first("code") }}</span
                  >
                </div>
              </div>

              <div class="modal-footer flex flex-wrap justify-center px-10">
                <slot name="footer">
                  <button
                    type="button"
                    @click="handleResend"
                    class="btn btn-blue"
                  >
                    Resend
                  </button>
                  <button type="submit" class="btn btn-blue-light mr-auto ml-4">
                    Verify
                  </button>
                </slot>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { Auth } from "aws-amplify";
export default {
  props: {
    email: String,
    message: String,
    isSignUp: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      code: null,
      error: null
    };
  },
  methods: {
    confirmEmailOwnership: function() {
      if (this.isSignUp) {
        return Auth.confirmSignUp(this.email, this.code);
      } else {
        return Auth.verifyCurrentUserAttributeSubmit("email", this.code);
      }
    },
    resendCode: function() {
      if (this.isSignUp) {
        return Auth.resendSignUp(this.email);
      } else {
        return Auth.verifyCurrentUserAttribute("email");
      }
    },
    validateBeforeSubmit: async function() {
      this.error = null; // reset previous submission error...
      const isAllValidated = this.$validator.validateAll();

      if (!isAllValidated) {
        return;
      }
      const paramQueryExist = Object.keys(this.$route.query).length !== 0;

      try {
        await this.confirmEmailOwnership();
        this.$emit("confirmationComplete", true);
        this.$emit("hasQueryParam", paramQueryExist);
      } catch (error) {
        this.error = error;
        console.log("Error confirming validating email ownership:", error);
      }
    },
    handleResend: async function() {
      try {
        await this.resendCode();
        this.$toasted.show("Code resent");
      } catch (err) {
        console.log("error resending code: ", err);
      }
    }
  }
};
</script>

<!-- Using the `scoped` attribute -->
<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.85);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  transition: all 0.3s ease;
}

label {
  padding-left: 22px;
  text-indent: -22px;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
