<template>
  <div class="w-full">
    <div class="relative flex flex-col ">
      <Header :can-cancel="canCancel" :has-back-button="hasBackButton" />
      <div
        class="block items-center justify-evenly w-full p-5 bg-blue-primary lg:p-10 md:mb-0 min-h-screen h-full"
      >
        <h1 class="page-title text-center mb-10 text-white">
          <slot name="pageTitle"> </slot>
        </h1>
        <div class="h-4 w-full"></div>

        <div class="page-subtitle text-white mx-auto text-center  text-h3">
          <slot name="pageSubtitle"> </slot>
          <div class="h-4 w-full"></div>
        </div>

        <div
          v-if="hasTopFormContent"
          :class="topFormBackgroundColor"
          class="box w-3/4 p-10 mb-8 z-2 rounded text-black mx-auto"
        >
          <slot name="topForm"></slot>
        </div>

        <div
          :class="mainFormWidth"
          class="box p-5 bg-white z-2 rounded text-black mx-auto"
        >
          <slot> </slot>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.page-subtitle a,
.page-title a {
  color: white;
  text-decoration: underline;
}

.page-subtitle a:hover,
.page-title a:hover {
  color: #30bbf8;
  text-decoration: underline;
}
</style>

<script>
import "vue-select/dist/vue-select.css";
import Header from "@/components/header/Header.vue";

export default {
  name: "SignupLayout",
  props: {
    canCancel: {
      type: Boolean,
      default: true
    },
    hasBackButton: {
      type: Boolean,
      default: false
    },
    topFormBackgroundColor: {
      type: String,
      default: "bg-white"
    },
    mainFormWidth: {
      type: String,
      default: "w-3/4"
    }
  },
  components: {
    Header
  },
  computed: {
    hasTopFormContent() {
      return !!this.$slots.topForm;
    }
  }
};
</script>
