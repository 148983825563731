<template>
  <transition name="modal" v-show="value">
    <div class="modal-mask h-full flex justify-center items-center">
      <div
        class="modal-wrapper overflow-scroll flex h-full justify-center items-center w-full"
      >
        <remove-LEA-modal
          v-show="confirmLEARemove"
          title=""
          :body="confirmLEARemoveBody"
          actionButton="Remove"
          cancelButton="Cancel"
          actionButtonColor="btn-blue"
          cancelButtonColor="btn-grey-light-2"
          @cancel="confirmLEARemove = false"
          @confirm="removeLEAParticipant"
        ></remove-LEA-modal>

        <invite-response-modal
          v-show="confirmInviteInfo"
          title=""
          :body="confirmInviteInfoBody"
          actionButton="Okay"
          actionButtonColor="btn-blue"
          @confirm="confirmInviteInfo = false"
        ></invite-response-modal>

        <div
          class="modal-box flex flex-col modal-container bg-white rounded shadow m-auto w-3/4 md:w-3/4 lg:w-3/4 pt-6 pb-10 lg:pb-24"
          id="lea-invite-modal"
        >
          <div class="p-2 flex justify-between items-center">
            <h2 class="text-blue-primary mb-5">Invite LEAs</h2>
            <button @click.prevent="close">
              <h1 class="text-blue-primary mr-5">X</h1>
            </button>
          </div>
          <div class="text-left pl-2 pr-2">
            <h4 class="text-blue-primary">
              Choose an existing organization or enter a new email
            </h4>
          </div>
          <div class="pl-2 pr-2 flex justify-between items-center">
            <div class="flex-1 pr-1">
              <div class="dropdown">
                <label for="LEAAdmin" class="dropdown-height">
                  <v-select
                    id="member-select"
                    label="name"
                    :options="this.LEAAdminOptions"
                    v-model="LEAAdminInvitesMembers"
                    class="dropdown-lea"
                    @input="disable('member')"
                  >
                  </v-select>
                </label>
              </div>
              <div class="dropdown">
                <input
                  class="email-lea"
                  type="text"
                  v-model="LEAAdminInvitesEmails"
                  placeholder="email@email.com"
                  @input="disable('email')"
                />
              </div>
            </div>

            <div class="flex">
              <spinner v-show="loading ? true : false"></spinner>
              <button
                class="btn bg-blue-light text-white w-1/4 mt-4"
                :disabled="loading"
                @click="emailOrMemberCheck()"
              >
                Invite
              </button>
            </div>
          </div>

          <div class="flex justify-between lea-table items-center leading-4">
            <!-- {{LEAAdminInvitesEmails}} 
            {{LEAAdminInvitesMembers}}  -->
          </div>
          <div
            class="align-middle inline-block rounded-bl-lg rounded-br-lg w-full h-full"
          >
            <table class="modal-box flex flex-col flex-no-wrap w-full h-1/2">
              <thead class="text-blue-primary w-full">
                <tr class="flex w-full">
                  <th
                    class="w-1/4 px-6 py-4 text-left leading-4 text-blue-500 tracking-wider"
                  >
                    Email
                    <span
                      class="tooltip right"
                      data-toggle="tooltip"
                      :data-text="emailTooltipText"
                    >
                      <span
                        class="tooltip-icon bg-grey-medium text-white items-center"
                        >&#8505;</span
                      >
                    </span>
                  </th>
                  <th
                    class="w-1/4 px-6 py-4 text-left leading-4 text-blue-500 tracking-wider"
                  >
                    Name
                  </th>
                  <th
                    class="w-1/4 px-6 py-4 text-left leading-4 text-blue-500 tracking-wider"
                  >
                    Status
                  </th>
                  <th
                    class="w-1/4 px-6 py-4 text-center leading-4 text-blue-500 tracking-wider"
                  >
                    Actions
                  </th>
                  <!-- <th class=" border-b-2 border-gray-300"></th> -->
                </tr>
              </thead>
              <tbody
                class="bg-white flex-1 sm:flex-none w-full overflow-scroll tbody"
              >
                <tr
                  v-for="(invite, index) in existingInvites"
                  v-bind:key="'existingInvite' + invite.id"
                  v-bind:class="{
                    'block lg:flex items-center p-2': true,
                    'bg-blue-lightest': !(index % 2)
                  }"
                  class="flex w-full justify-start	"
                >
                  <td
                    class="justify-start w-1/4 px-6 py-4 whitespace-no-wrap overflow-scroll "
                  >
                    <!-- v-for="(users, index) in invite.users"
                    v-bind:key="index"
                    class="justify-start w-1/4 px-6 py-4 whitespace-no-wrap overflow-scroll "
                  >
                    {{ users.email }} -->
                    {{ invite.email }}
                  </td>
                  <td
                    class="justify-start w-1/4 px-6 py-4 whitespace-no-wrap overflow-scroll "
                  >
                    {{ invite.name }}
                  </td>
                  <td
                    class="justify-start w-1/4 px-6 py-4 whitespace-no-wrap overflow-scroll text-blue-900 leading-5"
                  >
                    {{ invite.inviteStatus }}
                  </td>
                  <td
                    class="justify-end w-1/4 text-center px-6 py-4 whitespace-no-wrap overflow-scroll text-blue-900 leading-5"
                  >
                    <button type="button" @click="remove(invite)">
                      Remove
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<style lang="css">
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.85);
  transition: opacity 0.3s ease;
}

.modal-container {
  transition: all 0.3s ease;
}

label {
  padding-left: 22px;
}

.export {
  margin-right: 5px;
}

/*
    * The following styles are auto-applied to elements with
    * transition="modal" when their visibility is toggled
    * by Vue.js.
    *
    * You can easily play with the modal transition by editing
    * these styles.
    */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

/* custom styles */
.lea-table {
  margin-top: 20px;
}

.flex-grow {
  flex-grow: 1;
  max-width: 50%;
}

.flex-grow .dropdown {
  display: flex;
  justify-content: space-between;
}

.dropdown-height {
  /* height: 55px; */
  margin-top: 0px;
  padding: 0px;
  flex-grow: 1;
}

.dropdown-height input {
  height: 50px;
  margin-top: 0;
}

/* .vs__selected-options {
  padding-left: 30px;
} */

.add-button {
  font-size: 40px;
  font-weight: 900;
  padding: 10px;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  padding-top: 5px;
}

.email-lea,
.dropdown {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.email-lea {
  justify-content: end;
}

.email-lea input {
  height: 605px;
  margin-top: 10px;
  max-width: 90%;
  margin-left: 40px;
  border: 1px;
}

.tbody {
  max-height: 500px;
}

.lea-invite-modal {
  min-width: 90%;
}
</style>

<script>
import "babel-core";

import ConfirmModal from "./ConfirmModal";
import InfoModal from "./InfoModal";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

import * as _ from "lodash";

export default {
  name: "LEAInviteModal",
  props: {
    value: {
      required: true
    },
    workshop: Object
    // invitations: Object
  },
  components: {
    "v-select": vSelect,
    "remove-LEA-modal": ConfirmModal,
    "invite-response-modal": InfoModal
  },
  data: () => ({
    disableEmailInput: false,
    disableMemberInput: false,

    LEAAdminInvites: [],
    LEAAdminInvitesMembers: [],
    LEAAdminInvitesEmails: [],
    leaEmailInvites: [],
    LEAAdminOptions: [],
    existingInvites: [],
    existingInvitesUnsorted: [],

    organizations: [],
    activeMenu: null,
    pendingParticipants: [],
    confirmedParticipants: [],
    allInvitees: [],
    currentParticipant: null,
    participants: [],
    inviteWithEmail: "",
    loading: false,
    updated: false,
    // modal
    confirmLEARemove: false,
    confirmLEARemoveBody: null,
    removeUser: null,
    testSelected: null,
    //info modal
    confirmInviteInfo: false,
    confirmInviteInfoBody: null,
    emailTooltipText:
      "The email displayed for LEAs who have joined is that of their LEA Point of Contact (POC). If an LEA registers under a different email than the one you invited, we'll display the updated email."
  }),
  methods: {
    disable(input) {
      if (input === "member") {
        this.LEAAdminInvitesEmails = null;
      } else {
        this.LEAAdminInvitesMembers = null;
      }
    },
    getInvites: function() {
      //WorkshopList.vue queries FacilitatorWorkshopController on load and this modal accesses it here.
      this.workshop.member_invitations.forEach(invitation => {
        invitation.email = invitation.owner.email; // add email to top of object for consistency for invitation handling.
        invitation.memberId = invitation.id;
        this.existingInvitesUnsorted.push(invitation);
      });
      this.workshop.email_invitations.forEach(invitation => {
        if (invitation.member) {
          invitation.email = invitation.member.owner.email;
          invitation.name = invitation.member.name;
          invitation.memberId = invitation.member.id; // add member info on top of object for consistency.
        }
        this.existingInvitesUnsorted.push(invitation);
      });
      this.existingInvites = _.orderBy(
        this.existingInvitesUnsorted,
        ["inviteStatus"],
        ["desc"]
      );
      // console.log(this.existingInvites)
    },
    getLEADropdown: function() {
      //populate LEA Admin options even if no invites present
      window.axios.get("/api/user/lea-list").then(res => {
        res.data.data.forEach(user => {
          user.roles.forEach(role => {
            if (role === "LEAAdmin") {
              if (!this.LEAAdminOptions.includes(user)) {
                this.LEAAdminOptions.push(user);
              }
            }
          });
        });
      });
    },
    getWorkshopLEAs: function() {
      let registeredMemberIds = this.workshop.participants.map(
        participant => participant.member.id
      );

      if (this.existingInvites.length > 0) {
        this.existingInvites.forEach(existing => {
          /* this.workshop.participants returns 
              the registrations table information
              if a user is in this table, 
              they have accepted the workshop invite already. */

          if (!existing.name) {
            existing.name = "No Account";
          }
          if (registeredMemberIds.includes(existing.memberId)) {
            existing.inviteStatus = "Joined";
          } else {
            existing.inviteStatus = "Pending";
          }
        });
        this.existingInvites = _.orderBy(
          this.existingInvitesUnsorted,
          ["inviteStatus"],
          ["desc"]
        );
      }
    },
    emailOrMemberCheck() {
      var invited = [];
      const regex = /\S+@\S+\.\S+/;
      if (this.LEAAdminInvitesMembers) {
        invited = this.LEAAdminInvitesMembers;
      }
      if (this.LEAAdminInvitesEmails) {
        invited = this.LEAAdminInvitesEmails;
      }
      var passedRegex;
      if (typeof invited === "object") {
        this.addLEAinvitesMember(invited);
      } else {
        passedRegex = regex.test(invited);
        if (passedRegex) {
          this.addLEAinvitesEmail(invited);
        } else {
          this.$toasted.show(
            "Please add a valid email or select an organization from the list"
          );
        }
      }
    },
    addLEAinvitesMember(user) {
      let existingMemArr = this.existingInvites.map(invite => invite.email);
      if (user.email !== undefined && !existingMemArr.includes(user.email)) {
        let newMember = { inviteStatus: "Invite sent", ...user };
        // this.existingInvites.unshift(newMember);
        // this.updated = true;
        this.updateWorkshopInvites(newMember);
      } else {
        this.$toasted.show(
          `${user.member.data.name} has already been invited through ${user.email}`
        );
      }
    },
    addLEAinvitesEmail(email) {
      let existingEmailArr = this.existingInvites.map(invite => invite.email);

      if (!existingEmailArr.includes(email.trim())) {
        let newRow = {
          email: email.trim(),
          name: "No Account",
          inviteStatus: "Invite sent"
        };

        // this.existingInvites.unshift(newRow);
        this.activeMenu = null;
        // this.updated = true;
        this.updateWorkshopInvites(newRow);
      } else {
        this.$toasted.show(`${email.trim()} has already been invited`);
      }
    },
    updateWorkshopInvites(newInvite) {
      // TODO:
      if (newInvite.inviteStatus === "Invite sent") {
        //Differentiate between member & email invites.
        if (newInvite.member) {
          // member invite
          let inviteData = {
            memberInvitationId: newInvite.member.data.id,
            memberInvitationEmail: newInvite.email
          };
          this.sendInvite(inviteData, newInvite);
          this.updated = true;
        } else if (newInvite.email) {
          //email invite
          this.sendInvite({ emailInvitation: newInvite.email }, newInvite);
          this.updated = true;
        } else {
          this.$toasted.show("No workshop invites sent");
          this.loading = false;
        }
        //send invites.
      } else {
        console.log("new invite has an invalid format.");
      }
    },
    menu(index) {
      if (this.activeMenu == index) {
        this.activeMenu = null;
      } else {
        this.activeMenu = index;
      }
    },

    remove(user) {
      this.removeUser = user;
      this.activeMenu = null;
      this.updated = true;

      this.confirmLEARemoveBody = `Are you sure you want to remove ${user.email} from your workshop?`;
      this.confirmLEARemove = true;
    },
    removeLEAParticipant() {
      var user = this.removeUser;
      this.loading = true;
      window.axios
        .delete("/api/workshops/" + this.workshop.id, { data: user })
        .then(() => {
          this.$toasted.show("Invite Removed");
          this.existingInvites = this.existingInvites.filter(e => e !== user);
          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
      this.confirmLEARemove = false;
    },
    close() {
      this.$toasted.clear();
      this.$emit("input", !this.value);
      this.$emit("reload", this.updated);
    },
    sendInvite(inviteData, newInvite) {
      this.loading = true;
      window.axios
        .patch(`/api/workshops/${this.workshop.id}/invite`, inviteData)
        .then(resp => {
          console.log(resp.data);
          if (resp.data === "alreadyRegistered") {
            this.confirmInviteInfoBody = `This LEA has already registered, to view all users registered view workshop participants.`;
            this.confirmInviteInfo = true;
          } else if (resp.data === "userNotEligable") {
            this.confirmInviteInfoBody = `Entered email already has a membership with CSforALL but is not signed up as an LEA POC. Please use another email.`;
            this.confirmInviteInfo = true;
          } else {
            if (resp.data.data) {
              newInvite = resp.data.data;
              newInvite.name = resp.data.data.member.data.name;
              newInvite.inviteStatus = "Pending";
              console.log("new invite from member email - ", newInvite);
            }

            this.existingInvites.unshift(newInvite);

            let newInviteName = newInvite.name;
            if (newInvite.name === "No Account") {
              newInviteName = newInvite.email;
            }

            this.$toasted.global.success(
              `Workshop invite sent to: ${newInviteName}`
            );

            this.$store.dispatch("getFacilitatorWorkshop", this.workshop.id);
          }
          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
      this.LEAAdminInvitesEmails = null;
      this.LEAAdminInvitesMembers = null;
    }
  },
  mounted() {
    this.loading = true;
    this.getInvites();
    this.getWorkshopLEAs();
    this.getLEADropdown();
    this.loading = false;
  }
};
</script>
