// Modified From:
// https://gist.github.com/incredimike/1469814#file-variouscountrylistformats-js
// License: CC0 1.0 Universal
// https://creativecommons.org/publicdomain/zero/1.0/

// Last Updated: July 30, 2020

// Country names object using 3-letter country codes to reference country name
// ISO 3166 Alpha-3 Format: [3 letter Country Code]: [Country Name]
// Sorted alphabetical by country name (special characters on bottom)
// USA is placed at the top for convenience.
export const COUNTRIES = [
  {
    code3: "USA",
    name: "United States of America"
  },
  {
    code3: "AFG",
    name: "Afghanistan"
  },
  {
    code3: "ALB",
    name: "Albania"
  },
  {
    code3: "DZA",
    name: "Algeria"
  },
  {
    code3: "ASM",
    name: "American Samoa"
  },
  {
    code3: "AND",
    name: "Andorra"
  },
  {
    code3: "AGO",
    name: "Angola"
  },
  {
    code3: "AIA",
    name: "Anguilla"
  },
  {
    code3: "ATA",
    name: "Antarctica"
  },
  {
    code3: "ATG",
    name: "Antigua and Barbuda"
  },
  {
    code3: "ARG",
    name: "Argentina"
  },
  {
    code3: "ARM",
    name: "Armenia"
  },
  {
    code3: "ABW",
    name: "Aruba"
  },
  {
    code3: "AUS",
    name: "Australia"
  },
  {
    code3: "AUT",
    name: "Austria"
  },
  {
    code3: "AZE",
    name: "Azerbaijan"
  },
  {
    code3: "BHS",
    name: "Bahamas"
  },
  {
    code3: "BHR",
    name: "Bahrain"
  },
  {
    code3: "BGD",
    name: "Bangladesh"
  },
  {
    code3: "BRB",
    name: "Barbados"
  },
  {
    code3: "BLR",
    name: "Belarus"
  },
  {
    code3: "BEL",
    name: "Belgium"
  },
  {
    code3: "BLZ",
    name: "Belize"
  },
  {
    code3: "BEN",
    name: "Benin"
  },
  {
    code3: "BMU",
    name: "Bermuda"
  },
  {
    code3: "BTN",
    name: "Bhutan"
  },
  {
    code3: "BOL",
    name: "Bolivia (Plurinational State of)"
  },
  {
    code3: "BES",
    name: "Bonaire, Sint Eustatius and Saba"
  },
  {
    code3: "BIH",
    name: "Bosnia and Herzegovina"
  },
  {
    code3: "BWA",
    name: "Botswana"
  },
  {
    code3: "BVT",
    name: "Bouvet Island"
  },
  {
    code3: "BRA",
    name: "Brazil"
  },
  {
    code3: "IOT",
    name: "British Indian Ocean Territory"
  },
  {
    code3: "BRN",
    name: "Brunei Darussalam"
  },
  {
    code3: "BGR",
    name: "Bulgaria"
  },
  {
    code3: "BFA",
    name: "Burkina Faso"
  },
  {
    code3: "BDI",
    name: "Burundi"
  },
  {
    code3: "CPV",
    name: "Cabo Verde"
  },
  {
    code3: "KHM",
    name: "Cambodia"
  },
  {
    code3: "CMR",
    name: "Cameroon"
  },
  {
    code3: "CAN",
    name: "Canada"
  },
  {
    code3: "CYM",
    name: "Cayman Islands"
  },
  {
    code3: "CAF",
    name: "Central African Republic"
  },
  {
    code3: "TCD",
    name: "Chad"
  },
  {
    code3: "CHL",
    name: "Chile"
  },
  {
    code3: "CHN",
    name: "China"
  },
  {
    code3: "CXR",
    name: "Christmas Island"
  },
  {
    code3: "CCK",
    name: "Cocos (Keeling) Islands"
  },
  {
    code3: "COL",
    name: "Colombia"
  },
  {
    code3: "COM",
    name: "Comoros"
  },
  {
    code3: "COD",
    name: "Congo (the Democratic Republic of the)"
  },
  {
    code3: "COG",
    name: "Congo"
  },
  {
    code3: "COK",
    name: "Cook Islands"
  },
  {
    code3: "CRI",
    name: "Costa Rica"
  },
  {
    code3: "HRV",
    name: "Croatia"
  },
  {
    code3: "CUB",
    name: "Cuba"
  },
  {
    code3: "CUW",
    name: "Curaçao"
  },
  {
    code3: "CYP",
    name: "Cyprus"
  },
  {
    code3: "CZE",
    name: "Czechia"
  },
  {
    code3: "CIV",
    name: "Côte d'Ivoire"
  },
  {
    code3: "DNK",
    name: "Denmark"
  },
  {
    code3: "DJI",
    name: "Djibouti"
  },
  {
    code3: "DMA",
    name: "Dominica"
  },
  {
    code3: "DOM",
    name: "Dominican Republic"
  },
  {
    code3: "ECU",
    name: "Ecuador"
  },
  {
    code3: "EGY",
    name: "Egypt"
  },
  {
    code3: "SLV",
    name: "El Salvador"
  },
  {
    code3: "GNQ",
    name: "Equatorial Guinea"
  },
  {
    code3: "ERI",
    name: "Eritrea"
  },
  {
    code3: "EST",
    name: "Estonia"
  },
  {
    code3: "SWZ",
    name: "Eswatini"
  },
  {
    code3: "ETH",
    name: "Ethiopia"
  },
  {
    code3: "FLK",
    name: "Falkland Islands [Malvinas]"
  },
  {
    code3: "FRO",
    name: "Faroe Islands"
  },
  {
    code3: "FJI",
    name: "Fiji"
  },
  {
    code3: "FIN",
    name: "Finland"
  },
  {
    code3: "FRA",
    name: "France"
  },
  {
    code3: "GUF",
    name: "French Guiana"
  },
  {
    code3: "PYF",
    name: "French Polynesia"
  },
  {
    code3: "ATF",
    name: "French Southern Territories"
  },
  {
    code3: "GAB",
    name: "Gabon"
  },
  {
    code3: "GMB",
    name: "Gambia"
  },
  {
    code3: "GEO",
    name: "Georgia"
  },
  {
    code3: "DEU",
    name: "Germany"
  },
  {
    code3: "GHA",
    name: "Ghana"
  },
  {
    code3: "GIB",
    name: "Gibraltar"
  },
  {
    code3: "GRC",
    name: "Greece"
  },
  {
    code3: "GRL",
    name: "Greenland"
  },
  {
    code3: "GRD",
    name: "Grenada"
  },
  {
    code3: "GLP",
    name: "Guadeloupe"
  },
  {
    code3: "GUM",
    name: "Guam"
  },
  {
    code3: "GTM",
    name: "Guatemala"
  },
  {
    code3: "GGY",
    name: "Guernsey"
  },
  {
    code3: "GIN",
    name: "Guinea"
  },
  {
    code3: "GNB",
    name: "Guinea-Bissau"
  },
  {
    code3: "GUY",
    name: "Guyana"
  },
  {
    code3: "HTI",
    name: "Haiti"
  },
  {
    code3: "HMD",
    name: "Heard Island and McDonald Islands"
  },
  {
    code3: "VAT",
    name: "Holy See"
  },
  {
    code3: "HND",
    name: "Honduras"
  },
  {
    code3: "HKG",
    name: "Hong Kong"
  },
  {
    code3: "HUN",
    name: "Hungary"
  },
  {
    code3: "ISL",
    name: "Iceland"
  },
  {
    code3: "IND",
    name: "India"
  },
  {
    code3: "IDN",
    name: "Indonesia"
  },
  {
    code3: "IRN",
    name: "Iran (Islamic Republic of)"
  },
  {
    code3: "IRQ",
    name: "Iraq"
  },
  {
    code3: "IRL",
    name: "Ireland"
  },
  {
    code3: "IMN",
    name: "Isle of Man"
  },
  {
    code3: "ISR",
    name: "Israel"
  },
  {
    code3: "ITA",
    name: "Italy"
  },
  {
    code3: "JAM",
    name: "Jamaica"
  },
  {
    code3: "JPN",
    name: "Japan"
  },
  {
    code3: "JEY",
    name: "Jersey"
  },
  {
    code3: "JOR",
    name: "Jordan"
  },
  {
    code3: "KAZ",
    name: "Kazakhstan"
  },
  {
    code3: "KEN",
    name: "Kenya"
  },
  {
    code3: "KIR",
    name: "Kiribati"
  },
  {
    code3: "PRK",
    name: "Korea (the Democratic People's Republic of)"
  },
  {
    code3: "KOR",
    name: "Korea (the Republic of)"
  },
  {
    code3: "KWT",
    name: "Kuwait"
  },
  {
    code3: "KGZ",
    name: "Kyrgyzstan"
  },
  {
    code3: "LAO",
    name: "Lao People's Democratic Republic"
  },
  {
    code3: "LVA",
    name: "Latvia"
  },
  {
    code3: "LBN",
    name: "Lebanon"
  },
  {
    code3: "LSO",
    name: "Lesotho"
  },
  {
    code3: "LBR",
    name: "Liberia"
  },
  {
    code3: "LBY",
    name: "Libya"
  },
  {
    code3: "LIE",
    name: "Liechtenstein"
  },
  {
    code3: "LTU",
    name: "Lithuania"
  },
  {
    code3: "LUX",
    name: "Luxembourg"
  },
  {
    code3: "MAC",
    name: "Macao"
  },
  {
    code3: "MDG",
    name: "Madagascar"
  },
  {
    code3: "MWI",
    name: "Malawi"
  },
  {
    code3: "MYS",
    name: "Malaysia"
  },
  {
    code3: "MDV",
    name: "Maldives"
  },
  {
    code3: "MLI",
    name: "Mali"
  },
  {
    code3: "MLT",
    name: "Malta"
  },
  {
    code3: "MHL",
    name: "Marshall Islands"
  },
  {
    code3: "MTQ",
    name: "Martinique"
  },
  {
    code3: "MRT",
    name: "Mauritania"
  },
  {
    code3: "MUS",
    name: "Mauritius"
  },
  {
    code3: "MYT",
    name: "Mayotte"
  },
  {
    code3: "MEX",
    name: "Mexico"
  },
  {
    code3: "FSM",
    name: "Micronesia (Federated States of)"
  },
  {
    code3: "MDA",
    name: "Moldova (the Republic of)"
  },
  {
    code3: "MCO",
    name: "Monaco"
  },
  {
    code3: "MNG",
    name: "Mongolia"
  },
  {
    code3: "MNE",
    name: "Montenegro"
  },
  {
    code3: "MSR",
    name: "Montserrat"
  },
  {
    code3: "MAR",
    name: "Morocco"
  },
  {
    code3: "MOZ",
    name: "Mozambique"
  },
  {
    code3: "MMR",
    name: "Myanmar"
  },
  {
    code3: "NAM",
    name: "Namibia"
  },
  {
    code3: "NRU",
    name: "Nauru"
  },
  {
    code3: "NPL",
    name: "Nepal"
  },
  {
    code3: "NLD",
    name: "Netherlands"
  },
  {
    code3: "NCL",
    name: "New Caledonia"
  },
  {
    code3: "NZL",
    name: "New Zealand"
  },
  {
    code3: "NIC",
    name: "Nicaragua"
  },
  {
    code3: "NER",
    name: "Niger"
  },
  {
    code3: "NGA",
    name: "Nigeria"
  },
  {
    code3: "NIU",
    name: "Niue"
  },
  {
    code3: "NFK",
    name: "Norfolk Island"
  },
  {
    code3: "MNP",
    name: "Northern Mariana Islands"
  },
  {
    code3: "NOR",
    name: "Norway"
  },
  {
    code3: "OMN",
    name: "Oman"
  },
  {
    code3: "PAK",
    name: "Pakistan"
  },
  {
    code3: "PLW",
    name: "Palau"
  },
  {
    code3: "PSE",
    name: "Palestine, State of"
  },
  {
    code3: "PAN",
    name: "Panama"
  },
  {
    code3: "PNG",
    name: "Papua New Guinea"
  },
  {
    code3: "PRY",
    name: "Paraguay"
  },
  {
    code3: "PER",
    name: "Peru"
  },
  {
    code3: "PHL",
    name: "Philippines"
  },
  {
    code3: "PCN",
    name: "Pitcairn"
  },
  {
    code3: "POL",
    name: "Poland"
  },
  {
    code3: "PRT",
    name: "Portugal"
  },
  {
    code3: "PRI",
    name: "Puerto Rico"
  },
  {
    code3: "QAT",
    name: "Qatar"
  },
  {
    code3: "MKD",
    name: "Republic of North Macedonia"
  },
  {
    code3: "ROU",
    name: "Romania"
  },
  {
    code3: "RUS",
    name: "Russian Federation"
  },
  {
    code3: "RWA",
    name: "Rwanda"
  },
  {
    code3: "REU",
    name: "Réunion"
  },
  {
    code3: "BLM",
    name: "Saint Barthélemy"
  },
  {
    code3: "SHN",
    name: "Saint Helena, Ascension and Tristan da Cunha"
  },
  {
    code3: "KNA",
    name: "Saint Kitts and Nevis"
  },
  {
    code3: "LCA",
    name: "Saint Lucia"
  },
  {
    code3: "MAF",
    name: "Saint Martin (French part)"
  },
  {
    code3: "SPM",
    name: "Saint Pierre and Miquelon"
  },
  {
    code3: "VCT",
    name: "Saint Vincent and the Grenadines"
  },
  {
    code3: "WSM",
    name: "Samoa"
  },
  {
    code3: "SMR",
    name: "San Marino"
  },
  {
    code3: "STP",
    name: "Sao Tome and Principe"
  },
  {
    code3: "SAU",
    name: "Saudi Arabia"
  },
  {
    code3: "SEN",
    name: "Senegal"
  },
  {
    code3: "SRB",
    name: "Serbia"
  },
  {
    code3: "SYC",
    name: "Seychelles"
  },
  {
    code3: "SLE",
    name: "Sierra Leone"
  },
  {
    code3: "SGP",
    name: "Singapore"
  },
  {
    code3: "SXM",
    name: "Sint Maarten (Dutch part)"
  },
  {
    code3: "SVK",
    name: "Slovakia"
  },
  {
    code3: "SVN",
    name: "Slovenia"
  },
  {
    code3: "SLB",
    name: "Solomon Islands"
  },
  {
    code3: "SOM",
    name: "Somalia"
  },
  {
    code3: "ZAF",
    name: "South Africa"
  },
  {
    code3: "SGS",
    name: "South Georgia and the South Sandwich Islands"
  },
  {
    code3: "SSD",
    name: "South Sudan"
  },
  {
    code3: "ESP",
    name: "Spain"
  },
  {
    code3: "LKA",
    name: "Sri Lanka"
  },
  {
    code3: "SDN",
    name: "Sudan"
  },
  {
    code3: "SUR",
    name: "Suriname"
  },
  {
    code3: "SJM",
    name: "Svalbard and Jan Mayen"
  },
  {
    code3: "SWE",
    name: "Sweden"
  },
  {
    code3: "CHE",
    name: "Switzerland"
  },
  {
    code3: "SYR",
    name: "Syrian Arab Republic"
  },
  {
    code3: "TWN",
    name: "Taiwan"
  },
  {
    code3: "TJK",
    name: "Tajikistan"
  },
  {
    code3: "TZA",
    name: "Tanzania, United Republic of"
  },
  {
    code3: "THA",
    name: "Thailand"
  },
  {
    code3: "TLS",
    name: "Timor-Leste"
  },
  {
    code3: "TGO",
    name: "Togo"
  },
  {
    code3: "TKL",
    name: "Tokelau"
  },
  {
    code3: "TON",
    name: "Tonga"
  },
  {
    code3: "TTO",
    name: "Trinidad and Tobago"
  },
  {
    code3: "TUN",
    name: "Tunisia"
  },
  {
    code3: "TUR",
    name: "Turkey"
  },
  {
    code3: "TKM",
    name: "Turkmenistan"
  },
  {
    code3: "TCA",
    name: "Turks and Caicos Islands"
  },
  {
    code3: "TUV",
    name: "Tuvalu"
  },
  {
    code3: "UGA",
    name: "Uganda"
  },
  {
    code3: "UKR",
    name: "Ukraine"
  },
  {
    code3: "ARE",
    name: "United Arab Emirates"
  },
  {
    code3: "GBR",
    name: "United Kingdom of Great Britain and Northern Ireland"
  },
  {
    code3: "UMI",
    name: "United States Minor Outlying Islands"
  },
  {
    code3: "URY",
    name: "Uruguay"
  },
  {
    code3: "UZB",
    name: "Uzbekistan"
  },
  {
    code3: "VUT",
    name: "Vanuatu"
  },
  {
    code3: "VEN",
    name: "Venezuela (Bolivarian Republic of)"
  },
  {
    code3: "VNM",
    name: "Viet Nam"
  },
  {
    code3: "VGB",
    name: "Virgin Islands (British)"
  },
  {
    code3: "VIR",
    name: "Virgin Islands (U.S.)"
  },
  {
    code3: "WLF",
    name: "Wallis and Futuna"
  },
  {
    code3: "ESH",
    name: "Western Sahara"
  },
  {
    code3: "YEM",
    name: "Yemen"
  },
  {
    code3: "ZMB",
    name: "Zambia"
  },
  {
    code3: "ZWE",
    name: "Zimbabwe"
  },
  {
    code3: "ALA",
    name: "Åland Islands"
  }
];
