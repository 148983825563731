const QUESTIONNAIRE_SET = "QUESTIONNAIRE_SET";
const QUESTIONNAIRE_CLEAR = "QUESTIONNAIRE_CLEAR";

const state = {
  questionnaire: {}
};

const getters = {
  questionnaire: state => state.questionnaire,
  getQuestionnaire: state => state.questionnaire
};

const actions = {
  setQuestionnaire: ({ commit }, questionnaire) => {
    commit(QUESTIONNAIRE_SET, { questionnaire });
  },

  clearQuestionnaire: ({ commit }) => {
    commit(QUESTIONNAIRE_CLEAR);
  }
};

const mutations = {
  QUESTIONNAIRE_SET: (state, payload) => {
    state.questionnaire = {
      ...state.questionnaire,
      ...payload.questionnaire
    };
  },

  QUESTIONNAIRE_CLEAR: state => {
    state.questionnaire = {};
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
