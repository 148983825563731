<template>
  <signup-layout :can-cancel="false" :has-back-button="true">
    <template #pageTitle>
      Learn more about CSforALL's
      <a href="https://www.csforall.org/become_a_member/" target="_blank"
        >member categories</a
      >
      and verify
      <a
        href="https://www.csforall.org/become_a_member/requirements/"
        target="_blank"
        >your eligibility</a
      >
      to join.
    </template>

    <template #pageSubtitle>
      Ensure that you are eligible for membership with CSforAll
      <a
        href="https://www.csforall.org/become_a_member/requirements/"
        target="_blank"
        >here</a
      >.
    </template>

    <div class="text-left text-21 mx-auto mx-2 max-w-lg">
      <div class="h-4 w-full"></div>
      <p class="font-bold text-h2">
        If you think you've reached this page in error, please try and
        <a @click.prevent="restartChecklist" href="#"
          >proceed through the checklist again</a
        >.
      </p>
      <div class="h-8 w-full"></div>
      <p class="font-bold text-h2">
        Are you a Teacher?
      </p>
      <p>
        The Computer Science Teachers Association (CSTA) provides teachers a
        community to connect, grow, and share with other computer science
        teachers. You can join
        <a
          target="_blank"
          class="underline font-bold"
          href="https://www.csteachers.org"
          >CSTA national</a
        >
        or your local
        <a
          target="_blank"
          class="underline font-bold"
          href="https://www.csteachers.org/305564/Page/Show?ClassCode=Page&Slug=about-csta-chapters"
          >CSTA chapter</a
        >.
      </p>
      <div class="h-8 w-full"></div>
      <p class="font-bold text-h2">Become a CSforALL Insider!</p>
      <p>
        Join CSforALL's
        <a
          class="underline font-bold"
          target="_blank"
          href="https://csforall.us16.list-manage.com/subscribe?u=e459b3c4b34620f61e2405c80&id=ff48bedf4b"
          >monthly newsletter</a
        >
        to stay informed on the latest news, opportunities, resources and
        events.
        <br />
        Follow us on social media -
        <a
          class="underline font-bold"
          target="_blank"
          href="https://www.facebook.com/CSforALLorg/"
          >Facebook</a
        >
        &nbsp;&#124;&nbsp;
        <a
          class="underline font-bold"
          target="_blank"
          href="https://www.instagram.com/CSforAll/"
          >Instagram</a
        >
        &nbsp;&#124;&nbsp;
        <a
          class="underline font-bold"
          target="_blank"
          href="https://twitter.com/csforall"
          >Twitter</a
        >
      </p>
      <div class="h-8 w-full"></div>
      <p class="font-bold text-h2">Highlight your CSEd Work!</p>
      <p>
        <a
          class="underline font-bold"
          target="_blank"
          href="https://medium.com/csforall-stories"
          >CSforALL's Medium</a
        >
        publishes thought-provoking, current event, and human-interest stories
        from guest authors.
        <br />
        If you have a story to share with the CSforAll community, request to be
        featured in our Medium's
        <a
          class="underline font-bold"
          target="_blank"
          href="https://docs.google.com/forms/d/11jnj4xGQ970y7ArDkLptTG3v4me5s1QRcwr4MJy5hxY/edit"
          >CSforALL Stories</a
        >.
      </p>
    </div>
  </signup-layout>
</template>
<style scoped></style>

<script>
import "vue-select/dist/vue-select.css";
import SignupLayout from "@/components/signup/SignupLayout";
import signupMixin from "@/components/signup/mixins/signupMixin";

export default {
  name: "LearnMore",
  components: {
    SignupLayout
  },
  mixins: [signupMixin],
  methods: {
    restartChecklist() {
      this.clearQuestionnaire();

      this.$router.push({ name: "user-pre-req" });
    }
  }
};
</script>
