<template>
  <div v-if="input.type === 'textarea'">
    <label class="text-h3 font-bold"
      >{{ input.attributes.title }}
      <span v-if="required" class="text-red">*</span></label
    >
    <p class="leading-loose text-h4 mb-2">{{ input.description }}</p>
    <textarea
      :name="input.attributes.name"
      :type="input.type"
      :disabled="disabled"
      v-model="value"
    >
    </textarea>
  </div>
</template>

<script>
export default {
  name: "TextareaInput",
  props: {
    answer: String,
    disabled: Boolean,
    input: Object
  },
  data() {
    return {
      value: this.answer ? this.answer : ""
    };
  },
  computed: {
    required() {
      return (
        this.input.validation && this.input.validation.includes("required")
      );
    }
  },
  watch: {
    value() {
      this.$emit("input", this.value);
    },
    answer() {
      this.value = this.answer;
    }
  },
  mounted() {
    this.value = this.answer;
  }
};
</script>
