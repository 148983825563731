<template>
  <signup-layout>
    <template #pageTitle>
      Account Creation
    </template>

    <form
      aria-label="Register"
      class=" lg:w-3/5 sm:w-4/5 mx-auto flex flex-col text-left justify-center vertical-align"
    >
      <div class="h-60 mb-6 flex flex-col justify-between">
        <p class="mx-auto text-center max-w-lg text-h3 font-bold mb-8">
          See If Your Organization Is Already Registered
        </p>

        <div class="flex mb-2">
          <div class="items-center w-full">
            <label for="organization">
              <v-select
                id="organization"
                label="name"
                :options="organizations"
                v-model="organization"
                placeholder="Enter parts of school name"
              >
              </v-select>
            </label>
          </div>
        </div>
      </div>

      <div class="mb-5 flex w-full self-center justify-between text-center ">
        <router-link type="button" class="btn btn-blue" :to="hitBack">
          Back
        </router-link>
        <router-link type="button" class="btn btn-blue" :to="cantFindOrgPath">
          Can't Find my Organization
        </router-link>
        <button
          type="button"
          class="btn btn-blue"
          :disabled="!isOrgSelected"
          @click="orgSelected"
        >
          Confirm
        </button>
      </div>
    </form>
  </signup-layout>
</template>

<script>
import SignupLayout from "@/components/signup/SignupLayout";
import { MEMBER_TYPES } from "@/const";
import vSelect from "vue-select";
import signupMixin from "@/components/signup/mixins/signupMixin";
import _ from "lodash";

export default {
  name: "SelectOrganization",
  components: {
    SignupLayout,
    vSelect
  },
  mixins: [signupMixin],
  data() {
    return {
      organization: null,
      organizations: []
    };
  },
  props: {
    nextStep: {
      type: String,
      required: true
    }
  },
  computed: {
    isOrgSelected() {
      const org = this.organization;
      return Boolean(org && Object.keys(org).length);
    },
    queryUrlExist() {
      return Object.keys(this.$route.query).length !== 0;
    },
    hitBack() {
      if (this.queryUrlExist) {
        return {
          name: "user-pre-req",
          query: this.$route.query
        };
      } else {
        return {
          name: "user-pre-req"
        };
      }
    },
    cantFindOrgPath() {
      if (this.queryUrlExist) {
        return {
          name: this.nextStep,
          query: this.$route.query
        };
      } else {
        return {
          name: this.nextStep
        };
      }
    }
  },
  methods: {
    async getOrganizations() {
      const params = {
        "filter[member_type]": MEMBER_TYPES.MEMBER
      };

      // TODO: only get member names and id, this gives back almost everything
      const response = await window.axios.get("/api/members", {
        params: params
      });
      this.organizations = _.orderBy(response.data, i => {
        return i.name.toLowerCase();
      });
    },
    orgSelected() {
      this.$router.push({ name: "AlreadyRegistered" });
    }
  },
  created() {
    this.getOrganizations();
  }
};
</script>

<style scoped>
button:disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>
