<template>
  <div class="w-full mb-2">
    <div class="w-1/4">
      <label class="text-h4 mt-6">{{ label }}</label>
    </div>
    <div class="flex-fill">
      <select
        v-model="selected"
        class="border-2 bg-white rounded h-12"
        @change="onChange"
      >
        <option
          v-show="
            !option.type ||
              option.type == 'both' ||
              option.type == user.member.type
          "
          v-for="option in options"
          v-bind:value="option.id"
          v-bind:key="option.id"
        >
          {{ option.option }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: ["label", "options", "value", "option"],
  data: () => ({
    selected: null
  }),
  computed: {
    ...mapGetters({
      user: "user"
    })
  },
  methods: {
    onChange: function() {
      this.$emit("optionUpdate", {
        property: this.option,
        value: this.selected
      });
    },
    setSelected: function() {
      if (!this.value || !this.value.data) {
        return;
      }
      this.selected = this.value.data.id;
    }
  },
  watch: {
    value: function() {
      this.setSelected();
    }
  },
  created() {
    this.setSelected();
  },
  mounted() {
    this.setSelected();
  }
};
</script>
