<template>
  <div class="fixed pin-x pin-t">
    <div class="w-1/4 mx-auto my-16 text-center">
      <div class="bg-blue w-16 h-16 p-4 m-auto rounded spinner"></div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.fixed,
.fixed > * {
  pointer-events: none;
}
</style>
