<template>
  <div class="w-full ">
    <Header v-bind:stages="['1', '2', '3']" />
    <div class="relative flex flex-col h-screen">
      <div
        class="overflow-auto block items-center justify-evenly w-full min-h-full bg-blue-primary "
      >
        <div class="text-white">
          <div class="h-8 w-full"></div>
          <h1 class="text-center">
            Thank You for your interest in CSforALL Membership
          </h1>
          <div class="h-8 w-full"></div>
        </div>

        <!-- white inner box -->
        <div
          class="box w-3/4 p-10 bg-white min-h-70 z-2 rounded text-black mx-auto"
        >
          <div class="max-width-lg text-center">
            <div class="h-4"></div>

            <!-- profile complete -->
            <div v-show="profileValidated" class="mx-4">
              <h2 class="text-left">
                We have received your application. Our membership team will
                review your profile and reach out to you with the email you
                supplied if we need further information for publishing. Please
                allow two to three weeks processing time. If you have any
                further questions you can reach out to us at
                <a
                  id="email-copy-1"
                  v-on:click="copyToClipboard(memberEmail)"
                  >{{ memberEmail }}</a
                >
              </h2>
              <div class="h-24 w-full"></div>
              <p class="text-blue">
                Thank you for your submission. You can access your profile below
              </p>
              <div class="h-8 w-full"></div>
              <button>
                <router-link
                  v-if="workshopRegister == true"
                  class="btn btn-blue w-full"
                  :to="{ path: '/workshops/' + workshopID }"
                  >Go to Workshop {{ workshopID }}</router-link
                >
                <router-link v-else class="btn btn-blue w-full" to="/home"
                  >Account Home</router-link
                >
              </button>
            </div>

            <!-- profile incomplete -->
            <div v-show="!profileValidated" class="mx-4">
              <h2 class="text-left">
                Your application is incomplete. You'll have to complete your
                profile before our membership team can review it for publishing.
                If you have any further questions you can reach out to us at
                <a
                  id="email-copy-2"
                  v-on:click="copyToClipboard(memberEmail)"
                  >{{ memberEmail }}</a
                >
              </h2>
              <div class="h-24 w-full"></div>
              <p class="text-blue">
                Thank you for your interest in CSforALL Membership. You can
                access your profile below
              </p>
              <div class="h-8 w-full"></div>
              <button>
                <router-link
                  v-if="workshopRegister == true"
                  class="btn btn-blue w-full"
                  :to="{ path: '/workshops/' + workshopID }"
                  >Go to Workshop</router-link
                >
                <router-link v-else class="btn btn-blue w-full" to="/home"
                  >Account Home</router-link
                >
              </button>
            </div>
            <div class="h-4 w-full"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.min-h-50 {
  min-height: 70vh;
}
</style>

<script>
import { mapGetters } from "vuex";
import Header from "../header/Header.vue";

export default {
  components: {
    Header
  },
  data() {
    return {
      memberEmail: "script@csforall.org",
      workshopRegister: false,
      workshopID: null
    };
  },
  computed: {
    ...mapGetters({
      user: "user"
    }),
    profileValidated: function() {
      return this.user.member.profile_validated;
    }
  },
  methods: {
    copyToClipboard(memberEmail) {
      const emails = document.querySelectorAll("#email-copy-1, #email-copy-2");
      const emailArray = [...emails];
      emailArray.forEach(email => {
        email.addEventListener("click", () => {
          const selection = window.getSelection();
          const range = document.createRange();
          range.selectNodeContents(email);
          selection.removeAllRanges();
          selection.addRange(range);
          try {
            document.execCommand("copy");
            selection.removeAllRanges();

            email.textContent = "Email Copied";
            email.classList.add("success");

            setTimeout(() => {
              email.textContent = memberEmail;
              email.classList.remove("success");
            }, 1200);
          } catch (e) {
            console.log("error");
          }
        });
      });
    }
  },
  mounted() {
    if (this.$route.params.workshop) {
      this.workshopRegister = true;
      this.workshopID = this.$route.params.workshop;
    }
  }
};
</script>
