var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loaded)?_c('div',{staticClass:"w-full h-screen"},[_c('verify-email-confirmation-code-modal',{directives:[{name:"show",rawName:"v-show",value:(_vm.showConfirmEmailModal),expression:"showConfirmEmailModal"}],attrs:{"email":_vm.email,"message":`We've sent a confirmation code to your email address. Please
            re-enter it here to confirm your account.`},on:{"confirmationComplete":_vm.emailConfirmationComplete}}),_c('div',{staticClass:"relative h-full"},[_c('div',{staticClass:"overflow-auto block bg-blue-primary w-full min-h-full p-10 flex flex-col items-center text-black z-10"},[(_vm.loading)?_c('div',[_c('spinner')],1):(_vm.loaded)?_c('div',{staticClass:"container mx-auto flex flex-col lg:flex-row rounded p-10 bg-white justify-evenly"},[_c('div',{staticClass:"w-3/4"},[_c('h1',{staticClass:"text-blue-primary mb-2"},[_vm._v(" "+_vm._s(_vm.organization)+" is inviting you to join them on CSforALL ")]),_c('span',{staticClass:"mb-10 text-blue-primary mb-10"},[_vm._v(" "+_vm._s(_vm.headerInfo)+" ")]),_c('form',{staticClass:"form flex flex-col mt-5",attrs:{"method":"POST","aria-label":"Register"},on:{"submit":function($event){$event.preventDefault();return _vm.validateBeforeSubmit.apply(null, arguments)}}},[_c('div',{class:{
                'mb-3 w-100': true,
                'has-error': _vm.errors.has('first name')
              }},[_c('label',{attrs:{"for":"first-name"}},[_vm._v("Your First Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.firstName),expression:"firstName"},{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"w-100",attrs:{"id":"first-name","type":"text","name":"first name"},domProps:{"value":(_vm.firstName)},on:{"input":function($event){if($event.target.composing)return;_vm.firstName=$event.target.value}}}),_c('div',{staticClass:"mt-4"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('first name')),expression:"errors.has('first name')"}],staticClass:"text-xs italic text-red"},[_vm._v(_vm._s(_vm.errors.first("first name")))])])]),_c('div',{class:{
                'mb-3 w-100': true,
                'has-error': _vm.errors.has('last name')
              }},[_c('label',{attrs:{"for":"last-name"}},[_vm._v("Your Last Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.lastName),expression:"lastName"},{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"w-100",attrs:{"id":"last-name","type":"text","name":"last name"},domProps:{"value":(_vm.lastName)},on:{"input":function($event){if($event.target.composing)return;_vm.lastName=$event.target.value}}}),_c('div',{staticClass:"mt-4"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('last name')),expression:"errors.has('last name')"}],staticClass:"text-xs italic text-red"},[_vm._v(_vm._s(_vm.errors.first("last name")))])])]),_c('div',{class:{
                'mb-3 w-100': true,
                'has-error': _vm.errors.has('email')
              }},[_c('label',{attrs:{"for":"email"}},[_vm._v("Your Email")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"},{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"w-100",attrs:{"id":"email","type":"email","name":"email","disabled":""},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing)return;_vm.email=$event.target.value}}}),_c('div',{staticClass:"mt-4"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('email')),expression:"errors.has('email')"}],staticClass:"text-xs italic text-red"},[_vm._v(_vm._s(_vm.errors.first("email")))])])]),_c('div',{class:{
                'mb-3 w-100': true,
                'has-error': _vm.errors.has('password')
              }},[_c('label',{attrs:{"for":"password"}},[_vm._v("Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"},{name:"validate",rawName:"v-validate",value:(`required|min:${_vm.VALIDATION.MIN_PW_LEN}`),expression:"`required|min:${VALIDATION.MIN_PW_LEN}`"}],ref:"password",staticClass:"form-control w-100",attrs:{"id":"password","type":"password","name":"password"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing)return;_vm.password=$event.target.value}}}),_c('div',{staticClass:"mt-4"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('password')),expression:"errors.has('password')"}],staticClass:"text-xs italic text-red"},[_vm._v(_vm._s(_vm.errors.first("password")))])])]),_c('div',{class:{
                'mb-3 w-100': true,
                'has-error': _vm.errors.has('password confirmation')
              }},[_c('label',{attrs:{"for":"password-confirm"}},[_vm._v("Confirm Password")]),_c('input',{directives:[{name:"validate",rawName:"v-validate",value:(
                  `required|confirmed:password|min:${_vm.VALIDATION.MIN_PW_LEN}`
                ),expression:"\n                  `required|confirmed:password|min:${VALIDATION.MIN_PW_LEN}`\n                "}],staticClass:"form-control",attrs:{"id":"password-confirm","type":"password","name":"password confirmation"}}),_c('div',{staticClass:"mt-4"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('password confirmation')),expression:"errors.has('password confirmation')"}],staticClass:"text-xs italic text-red"},[_vm._v(_vm._s(_vm.errors.first("password confirmation")))])])]),(!_vm.isSchool)?_c('div',{staticClass:"mb-3 w-100"},[_c('label',{attrs:{"for":"schoolName"}},[_vm._v("Your School (If Applicable)")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.schoolName),expression:"schoolName",modifiers:{"trim":true}}],attrs:{"id":"schoolName"},domProps:{"value":(_vm.schoolName)},on:{"input":function($event){if($event.target.composing)return;_vm.schoolName=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"mt-4"})]):_vm._e(),(!_vm.isDistrict)?_c('div',{staticClass:"mb-3 w-100"},[_c('label',{attrs:{"for":"schoolDistrict"}},[_vm._v("Your School District (If Applicable)")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.schoolDistrict),expression:"schoolDistrict",modifiers:{"trim":true}}],attrs:{"id":"schoolDistrict"},domProps:{"value":(_vm.schoolDistrict)},on:{"input":function($event){if($event.target.composing)return;_vm.schoolDistrict=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"mt-4"})]):_vm._e(),_c('div',{class:{
                'mb-3 w-100': true,
                'has-error': _vm.errors.has('title')
              }},[_c('label',{attrs:{"for":"title"}},[_vm._v("Your Title")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.title),expression:"title"},{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"w-100",attrs:{"id":"title","type":"title","name":"title"},domProps:{"value":(_vm.title)},on:{"input":function($event){if($event.target.composing)return;_vm.title=$event.target.value}}}),_c('div',{staticClass:"mt-4"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('title')),expression:"errors.has('title')"}],staticClass:"text-xs italic text-red"},[_vm._v(_vm._s(_vm.errors.first("title")))])])]),_c('div',{class:{
                'mb-3 w-100': true,
                'has-error': _vm.errors.has('your role')
              }},[_c('label',{attrs:{"for":"your-role"}},[_vm._v("Institutional Role")]),_c('div',{staticClass:"flex mb-2 w-100"},[_c('div',{staticClass:"items-center w-full font-bold"},[_c('v-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"label":"name","options":_vm.roles,"name":"institutional role","taggable":"","id":"your-role"},model:{value:(_vm.role),callback:function ($$v) {_vm.role=$$v},expression:"role"}})],1)]),_c('div',{staticClass:"mt-4"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('institutional role')),expression:"errors.has('institutional role')"}],staticClass:"text-xs italic text-red"},[_vm._v(_vm._s(_vm.errors.first("institutional role")))])])]),_vm._m(0)])]),_c('div',{staticClass:"w-2/4 flex items-center h-full ml-2"},[_c('div',{staticClass:"bg-blue-panel w-full rounded bg-grey-light p-5 flex flex-col"},[_c('h2',{staticClass:"text-blue-primary mt-10"},[_vm._v(" Workshop: "+_vm._s(_vm.workshop.title)+" ")]),_c('h4',{staticClass:"text-blue-primary mt-5"},[_vm._v(" "+_vm._s(_vm.formatDate(_vm.workshop.start_time))+" - "+_vm._s(_vm.formatDate(_vm.workshop.end_time))+" "+_vm._s(_vm.workshop.timezone)+" ")]),_c('p',{staticClass:"text-blue-primary mt-5"},[_vm._v(" Presenter Name: "+_vm._s(_vm.workshop.presenter_first_name)+" "+_vm._s(_vm.workshop.presenter_last_name)+" ")]),_c('p',{staticClass:"text-blue-primary mt-10"},[_vm._v(" Workshop Sponsored By: "+_vm._s(_vm.workshop.event_sponsor_host)+" ")])])])]):_vm._e()])])],1):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex my-5"},[_c('button',{staticClass:"btn btn-blue",attrs:{"type":"submit"}},[_vm._v(" Create Your Account ")])])
}]

export { render, staticRenderFns }