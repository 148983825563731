<template>
  <signup-layout :can-cancel="false" :has-back-button="true">
    <template #pageTitle>
      Your Organization Is Already Registered
    </template>

    <template #pageSubtitle>
      If you're having trouble accessing your profile, contact the
      administrators at
      <a href="mailto:script@csforall.org" target="_blank"
        >script@csforall.org</a
      >
    </template>

    <div class="text-left text-21 mx-auto mx-2 my-12 p-12 max-w-lg">
      <p class="font-bold text-h2">
        Looks like someone within your organization has already registered on
        your behalf. If you cannot access your existing account please contact
        us at
        <a href="mailto:membership@csforall.org" target="_blank"
          >membership@csforall.org</a
        >
      </p>
    </div>
  </signup-layout>
</template>
<style scoped></style>

<script>
import "vue-select/dist/vue-select.css";
import SignupLayout from "@/components/signup/SignupLayout";
import signupMixin from "@/components/signup/mixins/signupMixin";

export default {
  name: "AlreadyRegistered",
  components: {
    SignupLayout
  },
  mixins: [signupMixin],
  methods: {
    restartChecklist() {
      this.clearQuestionnaire();

      this.$router.push({ name: "user-pre-req" });
    }
  }
};
</script>
