<template>
  <div class="relative">
    <div class="container mx-auto pb-32">
      <breadcrumbs :breadcrumbs="crumbs"></breadcrumbs>

      <div v-show="shouldShow">
        <div class="flex flex-wrap">
          <div class="w-full md:w-3/4 md:pr-16 lg:pr-32">
            <div v-if="isAdminMode" class="mt-2 mb-3">
              <h2>User Info</h2>
              <p class="text-sm mb-1"><i>for admin only</i></p>
              <p><b>Email: </b>{{ profile.email }}</p>
              <p><b>Roles: </b>{{ getRoles(profile) }}</p>
            </div>

            <div class="flex flex-wrap mb-5">
              <h1 class="w-full lg:w-auto mr-5 mb-2 lg:mb-0">Profile</h1>
              <div class="w-full lg:flex-1 lg:text-right">
                <router-link :to="editLink" class="btn btn-sm btn-blue"
                  ><img
                    src="@/assets/img/pen.svg"
                    width="11"
                    class="mr-2"
                  />Edit Profile</router-link
                >
                <span
                  v-if="isAdminMode"
                  @click="handleDelete"
                  class="btn btn-sm btn-red inline-block ml-2"
                  >Delete Profile</span
                >
                <span
                  v-if="isAdminMode"
                  @click="masquerade"
                  class="btn btn-sm btn-red inline-block ml-2"
                  >Masquerade</span
                >
              </div>
            </div>

            <img
              :src="profile.member.logo_url"
              v-show="profile.member.logo_url"
              class="max-h-small mb-4 mr-2 block"
            />

            <template v-if="profile.member.teaser">
              <h3 class="mt-3 mb-2">Teaser</h3>
              <div class="divider mb-8 pb-8">
                <p v-text="profile.member.teaser"></p>
              </div>
            </template>
            <template v-if="profile.member.description">
              <h3 class="mt-3 mb-2">Description</h3>
              <div v-html="profile.member.description" class="mb-8"></div>
            </template>

            <div
              class="pb-8 border-gray-lighter"
              v-if="profile.member.type == MEMBER_TYPES.MEMBER"
            >
              <div class="flex flex-wrap">
                <div
                  class="w-full md:w-1/2 pr-5 flex"
                  v-if="
                    profile.member.student && profile.member.student.data.id
                  "
                >
                  <h3 class="mr-2 mb-5">
                    Students Served:
                    <span class="text-blue-primary">
                      {{ profile.member.student.data.option }}
                    </span>
                  </h3>
                </div>
                <div
                  class="w-full md:w-1/2 pr-5 flex"
                  v-if="
                    profile.member.teacher && profile.member.teacher.data.id
                  "
                >
                  <h3 class="mr-2 mb-5">
                    Teachers Served:
                    <span class="text-blue-primary">
                      {{ profile.member.teacher.data.option }}
                    </span>
                  </h3>
                </div>
              </div>
            </div>

            <div
              class="border-t-3 py-8 w-full pr-5"
              v-if="profile.member.memberImages.length > 0"
            >
              <h3 class="mt-3 mb-2">Photos</h3>
              <div class="mb-2 flex">
                <div
                  v-for="item in profile.member.memberImages"
                  v-bind:key="item"
                  class="w-1/3"
                >
                  <div class="text-center mx-2">
                    <img
                      :src="item"
                      v-show="item"
                      class="max-h-small mb-4 mr-2 block"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              class="w-full bg-blue-panel p-4 md:p-8 mb-5"
              v-if="profile.member.canAddCurriculum"
            >
              <div class="flex flex-wrap mb-5">
                <div class="w-full md:flex-1 pr-2 mb-2 md:mb-0">
                  <h3 v-if="user && user.member.status === 'published'">
                    Curriculum
                  </h3>
                  <h3
                    v-if="user && user.member.status === 'draft'"
                    class="opacity-25"
                  >
                    Curriculum
                  </h3>
                </div>
                <div
                  class="w-full md:w-auto md:text-right"
                  v-if="
                    user &&
                      user.member.status === 'published' &&
                      profile.member.canAddCurriculum
                  "
                >
                  <router-link
                    v-if="!isAdminMode"
                    to="/home/curriculum/edit"
                    class="btn btn-sm btn-blue"
                    >Add New Curriculum</router-link
                  >
                </div>
                <div
                  class="w-full md:w-auto md:text-right"
                  v-if="
                    user &&
                      user.member.status === 'draft' &&
                      profile.member.canAddCurriculum
                  "
                >
                  <span class="btn btn-inactive btn-sm btn-blue opacity-25"
                    >Add New Curriculum</span
                  >
                </div>
              </div>

              <div class="flex flex-wrap mt-2">
                <div
                  class="mb-2 text-blue font-bold"
                  v-if="
                    user &&
                      user.member.status === 'published' &&
                      profile.member.curricula.data.length < 1
                  "
                >
                  You have not added any curriculum.
                </div>
                <div
                  class="mb-2 text-red font-bold"
                  v-if="
                    user &&
                      user.member.status === 'draft' &&
                      profile.member.curricula.data.length < 1
                  "
                >
                  You cannot add curricum until your profile is published.
                </div>
                <div
                  class="mb-2 text-red font-bold"
                  v-if="
                    user &&
                      user.member.status === 'draft' &&
                      profile.member.curricula.data.length > 0
                  "
                >
                  You can not add or edit curriculum because your profile has
                  been un-published.
                </div>
                <template v-if="user && user.member.status === 'published'">
                  <div
                    class="w-full mb-4 bg-white rounded p-5"
                    v-for="curriculum in profile.member.curricula.data"
                    v-bind:key="curriculum.id"
                  >
                    <router-link
                      v-if="!isAdminMode"
                      :to="'/home/curriculum/' + curriculum.uuid"
                      class="mb-2 block leading-medium"
                      ><strong v-text="curriculum.name"></strong
                    ></router-link>
                    <router-link
                      v-else
                      :to="'/home/certify/' + curriculum.uuid"
                      class="mb-2 block leading-medium"
                      ><strong v-text="curriculum.name"></strong
                    ></router-link>
                    <p v-text="curriculum.teaser" class="leading-loosish"></p>
                    <div
                      v-show="curriculum.certified"
                      class="text-blue-primary flex mt-3 items-center"
                    >
                      <img
                        class="align-middle"
                        src="@/assets/img/check-ribbon.svg"
                        alt=""
                      />
                      <span class="align-middle ml-2 font-bold text-md"
                        >K-12 CS Framework Aligned</span
                      >
                    </div>
                  </div>
                </template>
              </div>
              <template
                v-if="
                  user &&
                    user.member.status === 'published' &&
                    profile.member.canAddCurriculum
                "
              >
                <div
                  class="mt-3 text-center w-full"
                  v-if="profile.member.curricula.data.length > 0"
                >
                  <router-link
                    v-if="!isAdminMode"
                    to="/home/curriculum/edit"
                    class="btn btn-sm btn-blue"
                    >Add New Curriculum</router-link
                  >
                </div>
              </template>
            </div>
          </div>
          <div class="w-full md:w-1/4">
            <h3 v-if="isPublicMember" class="mt-3 mb-2">Categories</h3>
            <div v-if="profile.member.type === MEMBER_TYPES.MEMBER">
              <h4 class="mt-6 mb-2" v-if="profile.member.types">Type</h4>
              <span
                class="tag"
                v-for="type in profile.member.types.data"
                v-bind:key="'type' + type.id"
              >
                {{ type.option }}
              </span>
            </div>
            <template v-if="hasAudiences">
              <h4 class="mt-10 mb-2" v-if="profile.member.audiences">
                Audiences
              </h4>
              <span
                class="tag"
                v-for="audience in profile.member.audiences.data"
                v-bind:key="'audience' + audience.id"
              >
                {{ audience.option }}
              </span>
            </template>
            <template v-if="hasLevels">
              <h4 class="mt-10 mb-2">Levels</h4>
              <span
                class="tag"
                v-for="level in profile.member.levels.data"
                v-bind:key="'level' + level.id"
              >
                {{ level.option }}
              </span>
            </template>
            <template v-if="hasRegions">
              <h4 class="mt-10 mb-2">Regions</h4>
              <span
                class="tag"
                v-for="region in profile.member.regions.data"
                v-bind:key="'region' + region.id"
              >
                {{ region.option }}
              </span>
            </template>
            <template v-if="hasSettings && profile.member.settings">
              <h4 class="mt-10 mb-2">Settings</h4>
              <span
                class="tag"
                v-for="setting in profile.member.settings.data"
                v-bind:key="'setting' + setting.id"
              >
                {{ setting.option }}
              </span>
            </template>
            <div v-if="profile.member.type === MEMBER_TYPES.RESEARCHER">
              <template v-if="hasOther">
                <h4 class="mt-6 mb-2">Other</h4>
                <span
                  class="tag"
                  v-for="other in profile.member.others.data"
                  v-bind:key="'other' + other.id"
                >
                  {{ other.option }}
                </span>
              </template>
            </div>
          </div>
        </div>
      </div>
      <spinner v-show="isSaving ? true : false"></spinner>
    </div>
    <div class="blue-bar"></div>
  </div>
</template>

<script>
import { MEMBER_TYPES } from "../../const";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      MEMBER_TYPES: MEMBER_TYPES,
      isSaving: false,
      profile: {
        member: {
          types: false,
          audiences: false,
          levels: false,
          regions: false,
          researchTypes: false,
          settings: false,
          student: false,
          teacher: false,
          logo_url: false,
          curricula: false,
          name: false,
          type: "",
          canAddCurriculum: false,
          memberImages: false
        },
        roles: []
      }
    };
  },
  methods: {
    getRoles: function(user) {
      if (user.roles.length > 0) {
        return user.roles.join(",\n");
      }
      return "no role";
    },
    setProfile: function() {
      if (this.$route.params.token && this.user.is_admin) {
        this.loadUser(this.$route.params.token);
      } else if (this.user && this.user.uuid) {
        this.profile = this.loadSelf();
      }
    },
    loadUser: function(uuid) {
      let params = {
        include: "member.curricula"
      };

      window.axios.get("/api/user/" + uuid, { params: params }).then(resp => {
        let user = Object.assign({}, resp.data.data[0]);
        user.member = Object.assign({}, resp.data.data[0].member.data);
        this.profile = user;
      });
    },
    loadSelf: function() {
      let params = {
        include: "member.curricula"
      };

      window.axios.get("/api/me", { params: params }).then(resp => {
        let user = Object.assign({}, resp.data.data[0]);
        user.member = Object.assign({}, resp.data.data[0].member.data);
        this.profile = user;
      });
    },
    handleDelete: function() {
      if (!this.isAdminMode) {
        return;
      }
      if (
        !confirm(
          "Are you sure you want to delete this user? This cannot be undone."
        )
      ) {
        return;
      }

      this.isSaving = true;
      const uuid = this.$route.params.token;

      window.axios
        .delete("/api/user/" + uuid)
        .then(() => {
          this.$router.push("/admin");
          this.$toasted.show("Profile removed");
          return;
        })
        .catch(() => {
          this.isSaving = false;
          alert("Unable to delete user");
        });
    },
    masquerade: function() {
      localStorage.setItem("Masquerade", this.profile.uuid);
      this.$store.dispatch("masqueradeOn");
      window.axios.get("/api/me").then(resp => {
        this.$store.dispatch("setUser", resp.data.data[0]);
        this.$router.push({ name: "profile" });
      });
    }
  },
  watch: {
    $route() {
      this.setProfile();
    },
    user() {
      this.setProfile();
    }
  },
  computed: {
    ...mapGetters({
      user: "user",
      isLoggedIn: "isLoggedIn"
    }),
    isPublicMember() {
      const memberType = this.profile?.member?.type;
      return memberType === MEMBER_TYPES.RESEARCHER || this.isOrgMember;
    },
    member: function() {
      return this.user && this.user.member;
    },
    hasLevels: function() {
      return this.member.levels && this.member.levels.data.length > 0;
    },
    hasAudiences: function() {
      return this.member.audiences && this.member.audiences.data.length > 0;
    },
    hasRegions: function() {
      return this.member.regions && this.member.regions.data.length > 0;
    },
    hasSettings: function() {
      return this.member.settings && this.member.settings.data.length > 0;
    },
    hasOther: function() {
      return this.member.others && this.member.others.data.length > 0;
    },
    crumbs: function() {
      if (this.isAdminMode) {
        return [
          {
            link: false,
            label: this.profile ? this.profile.member.name : ""
          }
        ];
      } else {
        return [
          {
            link: false,
            label: this.profile ? this.profile.member.name : ""
          }
        ];
      }
    },
    shouldShow: function() {
      if (!this.$route.params.token) {
        return true;
      }

      if (this.user && this.user.is_admin) {
        return true;
      }

      return false;
    },
    editLink: function() {
      if (this.$route.params.token && this.user.is_admin) {
        return "/admin/profile/edit/" + this.$route.params.token;
      }
      return "/home/profile/edit";
    },
    isAdminMode: function() {
      if (this.$route.params.token && this.user.is_admin) {
        return true;
      }
      return false;
    }
  },
  created() {
    if (this.$route.params.token) {
      this.$store.dispatch("setActiveNav", "admin");
    } else {
      this.$store.dispatch("setActiveNav", "profile");
    }
  },
  mounted() {
    this.setProfile();
  }
};
</script>
